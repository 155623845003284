import { Component, OnInit } from '@angular/core';
import { NgxPrintElementService } from "ngx-print-element";
import { CustomerService } from "src/app/services/customer.service";
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-printesdn',
  templateUrl: './printesdn.component.html',
  styleUrls: ['./printesdn.component.scss']
})
export class PrintesdnComponent implements OnInit {
  pageIndex: any = 1;
  key: any;
  field: "number";
  command: "mat";
  userData: any[] = [];
  basic_price: any[] = [];
  dataaa: any = [];
  table: any[] = [];
  otherCharges: number;
  pageSize: any=10;
  // pageIndex: any;
  searchData: string;
  vendordata: any[] = [];
  totalRecords: any;
  dataSource: any[] = [];
  vendorDetails: any;
  address: any;
  details: any[];
  ADDRESS: any;
  data1: any;
  duplicateinv_num: any[] = []
  duplicateinv_date: any[] = []
  invoice_number: any[] = []
  invoice_date: any[] = []
  deliverychallanno: any[] = []
  duplicatedc_number: any[] = []
  document_no: any;
  Viewsign: any;
  SignData: any;
  position: any;
  signNull: any;
  signbtn: boolean = false;
  signtext: boolean = false;
  signStatus: boolean = false;
  document_data: any;
  document_name: any;
  description: any;
  level: any;
  signature: any;
  document_no1: any;
  printstatus: any;
  approvalbtn: boolean = false;
  
 
  vendorName: any;
  logoData: any;
  companyName: any;
  companylogo: any;
  dmr_number: any[]=[];
  dmr_date: any[]=[];
  duplicatedmr_num: any[];
  duplicatedmr_date: any[];
  esdn_number: any[]=[];
  esdn_date: any[]=[];
  qc_number: any[]=[];
  qc_date: any[]=[];
  duplicateqc_num: any[];
  duplicateqc_date: any[];
  duplicateesdn_num: any[];
  duplicateesdn_date: any[];
  other_tax_split: any= 0;
  other_charges_split: any= 0;
  freight_split: any= 0;
  public config = {
    printMode: 'template', // template
    popupProperties: "window.open",
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],

  };
  doc_sign: any;
  
  constructor(
    private service: CustomerService,
    public print: NgxPrintElementService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer
  ) { }
  
  misnumber:1;
  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.data1 = params['misnumber'];
        console.log(this.data1,"data1----");
        
        this.doc_sign = params['doc_sign'];
        console.log(this.doc_sign);

      });

      this.route.queryParams
      .subscribe(params => {
        console.log(params);

        this.document_no = params['document_number'];
        this.position = params['position']
        console.log(this.document_no,"document_no");

      });
    this.getData();
  }
  backtoesdn() {
    this.router.navigate(['/inventory/esdn'], { queryParams: { tab: 'notifications' } })
  }

  getData(){
    
    let obj={
      "command":"view",
      // "perpage": this.pageSize,
      // "page": this.pageIndex,
      "esdn_number":this.data1,
      // "_id":this.data1
    }
    this.service.getEsdndata(obj).subscribe((res:any)=>{
      console.log(res,"resssssssssss");
      this.dataaa = res.data[0];
      this.userData = res.data
      console.log(this.userData,"dataaa");
      this.table = res.data;
      })
  }
}
