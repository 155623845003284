<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">OutGatePass RGP</h4>
        </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label> OutGatePass RGP </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)" #deletefromData>
                            <div class="row ">
                                <h2 *ngIf="dcnumber">DC Number:&nbsp;{{ dcnumber }}</h2>
                            </div>
                            <div class="row mb-3">
                                <div *ngIf="viewData" class="col-md-0 ml-2 mr-2 mb-2 ">
                                    <button mat-raised-button type="button" (click)="BacktoList()"
                                        class="savebutton">Back</button>
                                </div>
                                <div *ngIf="viewData" class="col-md-0 ml-2 mr-2">
                                    <button mat-raised-button type="button" (click)="signatureStatus(signaturestatus)"
                                        class="savebutton">Signature Status</button>
                                </div>
                                <div *ngIf="viewData" class="col-md-0 ml-2">
                                  <button  mat-raised-button type="button" *ngIf="docSignature !== 'SENT'" (click)="getApprovals(approvalmodeldatastatus)" [ngClass]="docSignature === 'SENT' ? 'disabled' : 'enabled'"  class="savebutton"  [disabled]="approvalbtn === true">
                                    Proceed for Approvals
                                    </button>
                              </div>
                                <div *ngIf="viewData" class="col mt-1 text-right">
                                    <p *ngIf="viewData&&viewnumber" class="viewdoc">View of Document
                                        Number: {{viewnumber}}</p>
                                        <!-- <p *ngIf="viewData&&signData_document_no" style="font-size: 16px;font-weight: 500;">View of Document
                                            Number: {{signData_document_no}}</p> -->
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 mb-2 mr-1 ml-2">
                                    <button type="submit" mat-raised-button class="savebutton "
                                        [disabled]="saveddataarray.length==0 || !formdata.rgptype || !formdata.companyname || !formdata.consigneename || !formdata.expectedReturnDays || !formdata.location || !formdata.tolocation || !formdata.purpose"
                                       >
                                        {{ btn }}
                                    </button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 ml-1 mr-2 mb-2">
                                    <button mat-raised-button type="button" (click)="clearpage1()"
                                    class="savebutton">Add New</button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0">
                                    <button mat-raised-button color="secondary" type="button"
                                    class="attachfile savebutton button1"
                                    (click)="openNewFileUploadModal(newFileUploadModal)">Attach File&nbsp;
                                </button>

                                    <!-- <button type="button" mat-raised-button class="attachfile" color="secondary"
                                        (click)="autoFileUpload2.click()">
                                        Attach File&nbsp;
                                        <mat-icon style="cursor: pointer; font-size: 24px">file_upload</mat-icon>
                                    </button>
                                    <div class="file">
                                        <input hidden class="form-control-file" name="file" type="file" multiple
                                            (change)="uploadgtfiles($event)" #autoFileUpload2>
                                        <ng-container *ngIf=" fileUploadUrlsgt.length > 0" class="mt-4">
                                            <div *ngFor="let item of filenamearray;let i=index;"
                                                style="white-space: nowrap;">{{i+1}}.&nbsp;{{item}}</div> -->

                                            <!-- <span style="margin-left:3px">Selected : {{fileUploadUrlsgreturn.length}} files</span>   -->
                                        <!-- </ng-container> -->
                                    <!-- </div> -->
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 ml-2">
                                    <button type="button" mat-raised-button class="savebutton"
                                        (click)="deliveryChallanData(dcmodel)">
                                        Delivery Challan
                                    </button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 ml-2">
                                    <button type="button" mat-raised-button class="savebutton"
                                        (click)="qrScanner(qrscan)">
                                        Scan QRCode
                                    </button>
                                </div>
                                <div *ngIf="!viewData && txnId" class="col mt-1 text-right">
                                    <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                               
                                <div class="col-md-6">
                                    <label>Consignor<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.companyname}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="companyname" [(ngModel)]="formdata.companyname" readonly/>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="companyname"
                                        #companyname="ngModel" [(ngModel)]="formdata.companyname" required>
                                        <option value="" selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData1" [ngValue]="item.company_name">
                                            {{ item.company_name }}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="companyname.invalid && (companyname.dirty || companyname.touched) ">
                                        <div style="color:red;" *ngIf="companyname.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                    <!-- <label>To Company Name<span class="mandetory">*</span></label>
                                    <select class="dropdownstyles" name="tocompanyname" #tocompanyname="ngModel"
                                        [(ngModel)]="formdata.tocompanyname" required>
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData" [ngValue]="item.name">{{item.name}}
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="tocompanyname.invalid && (tocompanyname.dirty || tocompanyname.touched) ">
                                        <div style="color:red;" *ngIf="tocompanyname.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3">
                                    <label>RGP Date of Issue</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.dateee}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="dateee" [(ngModel)]="formdata.dateee" readonly/>
                                    <input *ngIf="!viewData" type="date" [max]="today"  (change)="ExpectedReturnDaysCal()"
                                        class="inputstyles" dateFormat="yyyy/mm/dd" name="dateee" #dateee="ngModel" 
                                        [(ngModel)]="formdata.dateee" [disabled]="gamCompanyfields"/>
                                    <!-- <div *ngIf="dateee.invalid && (dateee.dirty || dateee.touched) ">
                                        <div style="color:red;" *ngIf="dateee.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3">
                                    <label>RGP Type<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.rgptype}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="rgptype" [(ngModel)]="formdata.rgptype" readonly/>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="transfertype"
                                        [(ngModel)]="formdata.rgptype" (change)="selectHiring()">
                                        <option value="" selected disabled>Select One</option>
                                        <option *ngFor="let item of rgp_type" [value]="item.rgp_type" >{{item.rgp_type}}</option>
                                        <!-- <option>Hiring</option>
                                        <option>Repairing/Refurbishment </option>
                                        <option>Loan </option>
                                        <option>Calibration </option>
                                        <option>Testing </option>
                                        <option>Refilling</option>
                                        <option>Replacement</option>
                                        <option>Others</option> -->
                                    </select>

                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Consignee Name<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.consigneename}}</p> -->
                                     <input *ngIf="viewData" type="text" class="inputstyles" name="consigneename" [(ngModel)]="formdata.consigneename" readonly/>
                                    <!-- <input type="text" placeholder="Enter Consignee Name" class="inputstyles"
                                        name="consigneename" [(ngModel)]="formdata.consigneename"> -->
                                    <mat-form-field *ngIf="!viewData" class="example-full-width mt-1 addinpfield"  appearance="none">
                                        <input type="text" placeholder="Search Consignee Name " aria-label="Number"
                                            matInput  class="inputdropdown2" name="consigneename"
                                            [(ngModel)]="formdata.consigneename" (keyup)="getvendordropdowndata($event)"
                                            [matAutocomplete]="auto1" />

                                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="selectedConsignee()">

                                            <mat-option *ngFor="let item of vendorData" [value]="item.name">
                                                {{item.name}}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <label>Consignee Address<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.consignee_address}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="consignee_address" [(ngModel)]="formdata.consignee_address" readonly/>
                                    <textarea *ngIf="!viewData" type="text" class="inputstyles" name="consignee_address" [(ngModel)]="formdata.consignee_address"
                                      placeholder="Enter Consignee Address" readonly ></textarea>
                                  </div>
                                <div class="col-md-3">
                                    <label>Work Order Number</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.workordernum}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="workordernum" [(ngModel)]="formdata.workordernum" readonly/>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="workordernum"
                                        #workordernum="ngModel" [(ngModel)]="formdata.workordernum"
                                        placeholder="Enter Work Order Number">
                                    <!-- <div *ngIf="workordernum.invalid && (workordernum.dirty || workordernum.touched)">
                                        <div style="color: red;" *ngIf="workordernum.errors">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <label>Transporter Name</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.transportername}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="transportername" [(ngModel)]="formdata.transportername" readonly/>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Transporter Name"
                                        class="inputstyles" name="transportername"
                                        [(ngModel)]="formdata.transportername">
                                </div>
                                <div class="col-md-3">
                                    <label>Vehicle Number</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.vehiclenumber}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="vehiclenumber" [(ngModel)]="formdata.vehiclenumber" readonly/>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Vehicle No"
                                        class="inputstyles" name="vehiclenumber" #vehiclenumber="ngModel"
                                        [(ngModel)]="formdata.vehiclenumber">
                                    <!-- <div
                                        *ngIf="vehiclenumber.invalid && (vehiclenumber.dirty || vehiclenumber.touched) ">
                                        <div style="color:red;" *ngIf="vehiclenumber.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                               
                            
                                <div class="col-md-3">
                                    <label>Work Order Date</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.Workorderdate}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="Workorderdate" [(ngModel)]="formdata.Workorderdate" readonly/>
                                    <input *ngIf="!viewData" type="date" [max]="today" 
                                        class="inputstyles textupper" name="Workorderdate" [(ngModel)]="formdata.Workorderdate">
                                </div>
                                <div  class="col-3">
                                    <label>Expected Return Days<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.expectedReturnDays}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="expectedReturnDays" [(ngModel)]="formdata.expectedReturnDays" readonly/>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="expectedReturnDays" (change)="ExpectedReturnDaysCal()"
                                        [(ngModel)]="formdata.expectedReturnDays">
                                        <option value="" selected disabled>Select One</option>
                                        <option *ngFor="let item of exp_ret_days" [value]="item.exp_ret_date">{{item.exp_ret_date}}</option>
                                        <!-- <option value="30"> 30 Days </option>
                                        <option value="60"> 60 Days </option>
                                        <option value="90"> 90 Days </option>
                                        <option value="180"> 180 Days </option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <label>Expected Return Date<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.expectedredate}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="expectedredate" [(ngModel)]="formdata.expectedredate" readonly/>
                                    <input *ngIf="!viewData" type="date" 
                                        class="inputstyles" name="expectedredate" [(ngModel)]="formdata.expectedredate" readonly>
                                </div>
                                <div class="col-md-3">
                                    <label>LR Date</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.lrdate}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="lrdate" [(ngModel)]="formdata.lrdate" readonly/>
                                    <input *ngIf="!viewData" type="date" [max]="today" 
                                        class="inputstyles " name="lrdate" [(ngModel)]="formdata.lrdate">
                                </div>
                              
                                <div class="col-md-3">
                                    <label>LR Number</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.lrnumber}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="lrnumber" [(ngModel)]="formdata.lrnumber" readonly/>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter LR Number"
                                        class="inputstyles" name="lrnumber" [(ngModel)]="formdata.lrnumber">
                                </div>
                                <div class="col-md-3">
                                    <label>Eway Bill No</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.ewaybillno}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="ewaybillno" [(ngModel)]="formdata.ewaybillno" readonly/>
                                    <input *ngIf="!viewData" type="text" class="inputstyles"
                                        placeholder="Enter Ewaybillno" #ewaybillno="ngModel"
                                        [(ngModel)]="formdata.ewaybillno" name="ewaybillno" ngModel="ewaybillno" />
                                    <!-- <div *ngIf="ewaybillno.invalid && (ewaybillno.dirty || ewaybillno.touched) ">
                                        <div style="color:red;" *ngIf="ewaybillno.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <label>Work Order Value</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.workordervalue}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="workordervalue" [(ngModel)]="formdata.workordervalue" readonly/>
                                    <input *ngIf="!viewData" type="text" class="inputstyles"
                                        placeholder="Enter Work Order Value" #workordervalue="ngModel"
                                        [(ngModel)]="formdata.workordervalue" name="workordervalue"
                                        ngModel="workordervalue"  (keypress)="keyPressNumbers($event)" />
                                    <!-- <div *ngIf="workordervalue.invalid && (workordervalue.dirty || workordervalue.touched) ">
                                        <div style="color:red;" *ngIf="workordervalue.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3">
                                    <label>From Location<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.location}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="location" [(ngModel)]="formdata.location" readonly/>
                                    <input *ngIf="!viewData" type="text" class="inputstyles"
                                        placeholder="Enter Location" #location="ngModel" [(ngModel)]="formdata.location"
                                        name="location" ngModel="location" required />
                                    <!-- <div *ngIf="location.invalid && (location.dirty || location.touched) ">
                                        <div style="color:red;" *ngIf="location.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
 
                                <div class="col-md-3">
                                    <label>To Location<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.tolocation}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="tolocation" [(ngModel)]="formdata.tolocation" readonly/>
                                    <input *ngIf="!viewData" type="text" class="inputstyles"
                                        placeholder="Enter Location" #tolocation="ngModel"
                                        [(ngModel)]="formdata.tolocation" name="tolocation" ngModel="tolocation"
                                        required />
                                    <!-- <div *ngIf="tolocation.invalid && (tolocation.dirty || tolocation.touched) ">
                                        <div style="color:red;" *ngIf="tolocation.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3">
                                    <label>Purpose<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.purpose}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="purpose" [(ngModel)]="formdata.purpose" readonly/>
                                    <mat-form-field *ngIf="!viewData" class="example-full-width addinpfield mt-1" appearance="none">
                                        <input type="text" placeholder="Search Purpose" aria-label="Number" matInput
                                            class="inputdropdown1" [(ngModel)]="formdata.purpose"
                                            name="purpose" #purpose="ngModel" required (keyup)="getPurpose($event)"
                                            [matAutocomplete]="auto2" />

                                        <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">

                                            <mat-option *ngFor="let item of purposeData" [value]="item.purpose">
                                                {{item.purpose}}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <!-- <input type="text" class="inputstyles" placeholder="Enter Purpose"
                                        [(ngModel)]="formdata.purpose" name="purpose" #purpose="ngModel" required /> -->
                                    <!-- <div *ngIf="purpose.invalid && (purpose.dirty || purpose.touched) ">
                                        <div style="color:red;" *ngIf="purpose.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3" *ngIf="!gamCompanyfields">
                                    <label>Request Date</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.requestdate}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="requestdate" [(ngModel)]="formdata.requestdate" readonly/>
                                    <input *ngIf="!viewData" type="date" [max]="today" 
                                        class="inputstyles textupper" name="requestdate" [(ngModel)]="formdata.requestdate">
                                </div>
                                <div class="col-md-3" *ngIf="!gamCompanyfields">
                                    <label>Request Number</label>
                                    <!-- <p *ngIf="viewData " class="viewdata"> {{formdata.requestnumber}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="requestnumber" [(ngModel)]="formdata.requestnumber" readonly/>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Request No"
                                        class="inputstyles" name="requestnumber" [(ngModel)]="formdata.requestnumber">
                                </div>
                                <div class="col-md-3" >
                                    <label>Receiver Name</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.receivername !== null?formdata.receivername:'NA'}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="receivername" [(ngModel)]="formdata.receivername" readonly/>
                                    <input *ngIf="!viewData" type="text" name="receivername" #receivername="ngModel"
                                        [(ngModel)]="formdata.receivername" class="inputstyles"
                                        placeholder="Enter Receiver name" />
                                    <!-- <div *ngIf="receivername.invalid && (receivername.dirty || receivername.touched) ">
                                        <div style="color:red;" *ngIf="receivername.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3">
                                    <label>Comments</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{formdata.comments !== null?formdata.comments:'NA'}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="comments" [(ngModel)]="formdata.comments" readonly/>
                                    <textarea rows="2" cols="50"  *ngIf="!viewData" type="text" name="comments" #comments="ngModel"
                                        [(ngModel)]="formdata.comments" class="inputstyles"
                                        placeholder="Enter Comments"></textarea>
                                    <!-- <div *ngIf="comments.invalid && (comments.dirty || comments.touched) ">
                                        <div style="color:red;" *ngIf="comments.errors?.required">
                                            is required
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-3" *ngIf="viewData" >
                                    <label>Reviewed Return Days</label>
                                    <!-- <p *ngIf="viewData" class="viewdata">{{formdata.reviewed_return_days}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="reviewed_return_days" [(ngModel)]="formdata.reviewed_return_days" readonly/>
                                </div>
                                <div class="col-md-3" *ngIf="viewData" >
                                    <label>Reviewed Return Date</label>
                                    <!-- <p *ngIf="viewData" class="viewdata">{{formdata.reviewed_return_date}}</p> -->
                                    <input *ngIf="viewData" type="text" class="inputstyles" name="reviewed_return_date" [(ngModel)]="formdata.reviewed_return_date" readonly/>
                                </div>
                            </div>
                            <!-- <div class="row mb-3">
                                <div class="col-md-3">
                                    <label>Delivery Challan Number</label>
                                    <input type="text" placeholder="Enter Delivery Challan No" class="inputstyles"
                                        name="dcnumber" [(ngModel)]="formdata.dcnumber">
                                </div>
                            </div> -->

                        </form>
                        <div class="row" *ngIf="!viewData">
                            <div class="col-4 mt-1">
                                <h4 class="totaltxt">Total Price:&nbsp;{{total |number}}</h4>
                            </div>
                            <div class="col-8 text-right float-right">
                                <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button"
                                   >
                                    <span class="material-icons" (click)="addoutgatePassRequest(outgatepassrgpdataform)"
                                        >
                                        add
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-md-12 col-12">
                                <div class="table-responsive mat-table-wrapper">
                                    <div class="example-table-container">
                                        <table mat-table [dataSource]="dataSource">
                                            <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="tableth">S.NO</th>
                                                <td mat-cell *matCellDef="let row;let i=index;">
                                                    {{i+1}}

                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="material_code">
                                                <th mat-header-cell *matHeaderCellDef class="tableth">Material Code
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.material_code }}

                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="material_description">
                                                <th mat-header-cell *matHeaderCellDef class="tableth">Material
                                                    Description
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.material_description || row.description || row.matcode}}
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="unit_of_measurement">
                                                <th mat-header-cell *matHeaderCellDef class="tablethuom">UOM
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.uom || row.unit_of_measurment || row.unit_of_measurement}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="storage_location">
                                                <th mat-header-cell *matHeaderCellDef class="tableth">Storage
                                                    Location</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.storagelocation || row.storage_location }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="request_quantity">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">Request
                                                    Quantity
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.reqqty || row.request_quantity || row.quantity }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="quantity">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">
                                                    RGP Quantity</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.qty || row.quantity}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="unit_price">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">
                                                    Unit Price</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.unit_price}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="total_price">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">
                                                    Basic Price</th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.basic_price === 0 ? 0 : row.basic_price|| row.basic_price || row.total_value}}
                                                  
                                                    <!-- {{ row.basic_price === 0 ? 0 : (row.basic_price !== null ? row.basic_price : 'NA') }} -->


                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="item_remarks">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">Item Remarks
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <p class="valuationtype"> {{row.itemremarks || row.item_remarks }}</p>


                                                </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="batch_no">
                                                <th mat-header-cell *matHeaderCellDef style="width:5%"> Batch No
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <p> {{row.batchno || row.batch_number}}</p>
                                                </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="valution_type">
                                                <th mat-header-cell *matHeaderCellDef class="tableth"> Valuation Type
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <p class="valuationtype"> {{row.valutiontype || row.valuation_type}}</p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="actionbth">
                                                    Action
                                                </th>
                                                <td mat-cell *matCellDef="let row;let i=index;">
                                                    <button *ngIf="!viewData" mat-icon-button
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="editdata(row,i,editoutgatepassrgpdataform)" class="edtic"
                                                        [disabled]="viewData===true">
                                                        <mat-icon matTooltip="Edit">edit</mat-icon>
                                                    </button>

                                                    <button *ngIf="!viewData" mat-icon-button
                                                       class="delbtn"
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="deleterow(i)" [disabled]="viewData===true">
                                                        <mat-icon matTooltip="Delete">delete</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                        </table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label> List </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>
                            <div class="row m-t-10">
                                <div class="col-4 mt-2 mb-3 d-flex">
                                    <label class="col-md-4 mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class=" col-md-8 dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">OutGatePass RGP</option>
                                        <option value="consignee_name">Consignee Name</option>
                                    
                                       
                                    </select>
                                    
                              
                                </div>
                                <div class="col-2 col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield inputstyles" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="search1()">
                                </div>
                                <div class="col col-md-0 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getData1()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="table-responsive mat-table-wrapper m-2">
                                    <div class="example-table-container">
                                        <table mat-table [dataSource]="dataSourcemain" matSort>


                                            <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="tableth1">S.NO</th>
                                                <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                    {{(i+1) + (paginator?.pageIndex *
                                                        paginator?.pageSize)}}

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef class="tableth2" mat-sort-header="date">Date
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.date | date: 'dd-MM-yyyy'}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="number">
                                                <th mat-header-cell *matHeaderCellDef class="tableth3" mat-sort-header="number">OutGatePass RGP
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.number }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="company_Name">
                                                <th mat-header-cell *matHeaderCellDef class="tableth3" mat-sort-header="company_name">Company Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.company_name }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tocompanyname">
                                                <th mat-header-cell *matHeaderCellDef class="tableth3" mat-sort-header="consignee_name">Consignee Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.consignee_name}}
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="contractor_name">
                                                <th mat-header-cell *matHeaderCellDef>Contractor Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.contractor_name}}
                                                </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="request_number">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="request_number">Request Number
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.request_number !== null ? row.request_number:'NA' }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="count">
                                                <th mat-header-cell *matHeaderCellDef class="tableth3" mat-sort-header="count">Count
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.count}}
                                                </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="transfer_type">
                                                <th mat-header-cell *matHeaderCellDef>Transfer Type
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.Transfer_type}}
                                                </td>
                                            </ng-container> -->
                                            <!-- <ng-container matColumnDef="ActiveStatus">
                                                <th mat-header-cell *matHeaderCellDef>Active Status
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.is_active}}
                                                </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef 
                                                    class="listactionbth text-center">
                                                    Action
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-center">
                                                    <div class="mb-0">

                                                    </div>
                                                    <!-- <button mat-icon-button style="margin-left:-11%"
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="editsaveddata(row);tabGroup.selectedIndex = 0">
                                                        <mat-icon>edit</mat-icon>
                                                    </button> -->
                                                    <mat-icon matTooltip="View" class="eyeicon"
                                                        (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                                    <mat-icon matTooltip="Edit"
                                                        class="listediticon"
                                                        (click)="editgtdata(row,Editmodel)">edit</mat-icon>
                                                    <mat-icon matTooltip="Delete"
                                                       class="listdelbtn"
                                                        (click)="deleteItem(row,deleteitemofList)">delete</mat-icon>
                                                    <mat-icon matTooltip="Print" (click)="printoutgatepass(row)" class="printcursor"
                                                        > local_printshop</mat-icon>&nbsp;

                                                    <mat-icon matTooltip="Upload"
                                                        (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                        class="listfilebtn">file_upload</mat-icon>&nbsp;
                                                    <mat-icon matTooltip="Reviewed Days"   class="printcursor" (click)="getReviewedDays(reviewedDaysmodel,row)">assignment_return</mat-icon>
                                                    <mat-icon matTooltip="Proceed for Approvals" class="printcursor"
                                                        [ngClass]="row.doc_sign === 'SENT' ? 'disabled' : 'enabled'"
                                                        (click)="getApprovals(approvalsmodel,row)"> how_to_reg
                                                    </mat-icon>
                                                    <!-- </button>
                                                    <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                    style="cursor:pointer;font-size:16px"></i> -->
                                                </td>
                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                        </table>
                                    </div>
                                    <div *ngIf="reaData==true" class="text-center">
                                        <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                            Loading...
                                        </h4>
                                        <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                            No Records Found
                                        </h4>
                                    </div>
                                <div class="d-flex justify-content-end">
                                    <ng-container *ngIf="totalRecords>0">
                                        <mat-paginator [length]="totalRecords" [pageSize]="10"
                                        [pageSizeOptions]="[5,10, 50, 100,500, 1000]" #paginator
                                        (page)="onpageevent($event)">
                                    </mat-paginator>
                                    <div class="go-to-container pt-1">
                                        <div class="go-to-label">Page Nos: </div>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
                                                <mat-option *ngFor="let pageNumber of pageNumbers" [value]="pageNumber">{{pageNumber}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>

            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label> Approval in process </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>
                            <div class="row m-t-10">
                                <div class="col-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnnameap" [(ngModel)]="columnnameap"
                                        (change)="onChangeap()">
                                        <option value="" selected disabled>Select Column</option>
                                        <!-- <ng-container *ngFor="let obj of columnnaplist">
                                            <ng-container *ngFor="let keyValuePair of obj | keyvalue">
                                                <option [ngValue]="keyValuePair.value">{{ keyValuePair.key | titlecase}}</option>
                                              </ng-container>
                                            </ng-container> -->
                                        <option value="document_number">Document Number</option>
                                        <option value="concern_name">Concern Name</option>
                                    </select>
                                    
                              
                                </div>
                                <div class="col-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield inputstyles" placeholder="Search"
                                        [(ngModel)]="searchDataap" (input)="searchap1()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getDataap1()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="table-responsive mat-table-wrapper m-2">
                                    <div class="example-table-container approvalprocess">
                                        <table mat-table [dataSource]="dataSourcemainap" matSort>
                                            <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef >S.NO</th>
                                                <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                    {{ calculateSerialNumber(i) }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="document_number">
                                                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="document_number">Document Number
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-left">
                                                    {{row.document_number}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="pending_at">
                                                <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header="pending_at">Pending At
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-left">
                                                    {{row.pending_at !== "" ? row.pending_at :'N/A' }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="company_Name">
                                                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="company_name">Company Name
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-left text-ellipis">
                                                    {{row.company_name }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="concern_name">
                                                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="concern_name">Concern Name
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-left text-ellipis">
                                                    {{row.concern_name}}
                                                </td>
                                            </ng-container>

                                           
                                            <ng-container matColumnDef="description">
                                                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="description">Description
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-left">
                                                    {{row.description }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="level">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="level">Pending Level
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.level }}
                                                </td>
                                            </ng-container>
                                           
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef 
                                                    class="listactionbth text-center">
                                                    Action
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="text-center">
                                                    <mat-icon matTooltip="View" class="eyeicon"
                                                        (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                                    <mat-icon matTooltip="Upload"
                                                        (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                        class="listfilebtn">file_upload</mat-icon>
                                                </td>
                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsap;sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsap"></tr>
                                        </table>
                                    </div>
                                    <div *ngIf="reaDataap" class="text-center">
                                        <h4 class="text-secondary m-3">
                                            No Records Found
                                        </h4>
                                    </div>
                                <div class="d-flex justify-content-end" *ngIf="!reaDataap && totalRecordsap > 5">
                                    <mat-paginator [length]="totalRecordsap" [pageIndex]="pageIndexap-1" [pageSize]="pageSizeap"
                                        [pageSizeOptions]="[5,10, 50, 100,500, 1000]" #paginatorap
                                        (page)="onpageeventap($event)">
                                    </mat-paginator>
                                    <div class="go-to-container pt-1">
                                        <div class="go-to-label">Page Nos: </div>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="goToap" (selectionChange)="goToChangeap()">
                                                <mat-option *ngFor="let pageNumber of pageNumbersap" [value]="pageNumber">{{pageNumber}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>

            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'OutGatePassRGP'"></app-activitylog>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #outgatepassrgpdataform>
    <div mat-dialog-title class="dialog-title">
        <div class="row">
            <div class="col-11">
                <h2>Add Items</h2>
            </div>
            <div class="col-1">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon float-right"
                    (click)="closedialogdata()" >
                    <mat-icon class="icon1">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <form #myformdata="ngForm" (ngSubmit)="saveaddeddata(myformdata)">
            <div class="row">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-10">
                    <mat-form-field class="example-full-width editinp" appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                            class="inputdropdown" name="matcode" #matcode="ngModel"
                            [(ngModel)]="dialogdata.matcode" (keyup)="getmaterialDATA($event)"
                            [matAutocomplete]="auto4" />

                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                            (optionSelected)="selectedmastergroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="matcode.invalid && (matcode.dirty || matcode.touched) ">
                        <div class="validtxt" *ngIf="matcode.errors?.required">
                            is required
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Storage Location</label>
                </div>
                <div class="col-md-4">

                    <mat-form-field class="example-full-width addinpfield" appearance="none">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                             class="inputdropdown22" name="storagelocation" #storagelocation="ngModel"
                            [(ngModel)]="dialogdata.storagelocation" (keyup)="getstorageDATA($event)"
                            [matAutocomplete]="auto5" />

                        <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">

                            <mat-option *ngFor="let item of smsdata1" [value]="item">
                                {{item}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <label>RGP Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="number" class="inputstyles" placeholder="Enter Quantity"
                        [(ngModel)]="dialogdata.quantity" name="quantity" #quantity="ngModel" [ngvalue]="item_quantity"
                        (keyup)="getRGPQTY($event)" [min]="0" required />
                    <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched) ">
                        <div class="validtxt" *ngIf="quantity.errors?.required">
                            is required
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Unit Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="number" class="inputstyles" placeholder="Enter Unit Price"
                        [(ngModel)]="dialogdata.unit_price" name="unit_price" #unit_price="ngModel" min="0"
                        (keypress)="keyPressNumbers($event)" (keyup)="getRGPQTY($event)" required />
                    <div *ngIf="unit_price.invalid && (unit_price.dirty || unit_price.touched) ">
                        <div class="validtxt" *ngIf="unit_price.errors?.required">
                            is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Unit of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" placeholder="Enter UOM" name="uom" #uom="ngModel"
                        [(ngModel)]="dialogdata.uom" required />
                    <div *ngIf="uom.invalid && (uom.dirty || uom.touched) ">
                        <div class="validtxt" *ngIf="uom.errors?.required">
                            is required
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Request Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="reqqty" placeholder="Request Quantity" [(ngModel)]="dialogdata.reqqty"
                        (keypress)="keyPressNumbers($event)">
                </div>
                <div class="col-md-2">
                    <label>Basic Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="number" class="inputstyles" name="basic_price" placeholder="Basic Price" [(ngModel)]="dialogdata.basic_price" 
                        (keypress)="keyPressNumbers($event)" readonly>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Item Remarks</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="itemremarks" placeholder="Item Remarks" [(ngModel)]="dialogdata.itemremarks">
                </div>
                <!-- <div class="col-md-2">
                    <label>Batch No<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="batchno" [(ngModel)]="dialogdata.batchno">
                </div> -->
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="dialogdata.valutiontype">
                        <option value="" selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>

            </div>
            <br/>
            <div class="row">
                <div class="col-12 save text-center mt-2">
                    <button mat-raised-button class="savebutton addbtn"
                    [disabled]="!dialogdata.matcode || !dialogdata.uom || !dialogdata.quantity || 
                    !dialogdata.reqqty || !dialogdata.valutiontype || dialogdata.unit_price === ''||
                    dialogdata.unit_price === null || dialogdata.unit_price === undefined || 
                    dialogdata.basic_price === null || dialogdata.basic_price === undefined || 
                    dialogdata.basic_price === ''">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content><br />
    <!-- <div *ngIf="smsdata" class="dialogdata">
        <table>
            <thead>
                <tr class="tablestyles">
                    <th>Company Name</th>
                    <th>Storage Location</th>
                    <th>Valuation Type</th>
                    <th>Quantity</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of smsdata">
                    <td style="width: 28%">{{ item.company_name }}</td>
                    <td>{{ item.storage_location }}</td>
                    <td>{{ item.valuation_type }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{item.value | number:'1.2-2'}}</td>
                </tr>
            </tbody>
        </table>
    </div> -->
</ng-template>

<ng-template #editoutgatepassrgpdataform>
    <div mat-dialog-title class="dialog-title">
        <div class="row">
            <div class="col-11">   
                <h2>Edit Items</h2>
            </div>
            <div class="col-1">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon float-right"
                    (click)="closemodel()" >
                    <mat-icon class="icon1">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content class="editItems">
        <form #myeditformdata="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
            <div class="row">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-10">
                    <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="dialogdataedit.matcode"  (change)="selectedmastereditgroup()">
            <option selected disabled>Select One</option>
            <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
        </select> -->
                    <mat-form-field class="example-full-width editinp"  appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                             class="inputdropdown" name="matcode"
                            [(ngModel)]="dialogdataedit.matcode" (keyup)="getmaterialDATA1($event)"
                            [matAutocomplete]="auto6" />

                        <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                            (optionSelected)="selectedmastereditgroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>


            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Storage Location</label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="dialogdataedit.storagelocation">
            <option selected disabled>Select One</option>
           
            <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
        </select> -->
                    <mat-form-field class="example-full-width addinpfield" appearance="none">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                             class="inputdropdown" name="storagelocation"
                            [(ngModel)]="dialogdataedit.storagelocation" (keyup)="getstorageDATA1($event)"
                            [matAutocomplete]="auto7" />

                        <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">

                            <mat-option *ngFor="let item of smsdata1" [value]="item">
                                {{item}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <label>Unit Of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="uom" [(ngModel)]="dialogdataedit.uom">
                    <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                <option selected disabled>Select One</option>
                <option>hello</option>
               
            </select> -->
                </div>

            </div>

            <div class="row">
                <div class="col-md-2">
                    <label>Request Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="dialogdataedit.reqqty"
                        (keypress)="keyPressNumbers($event)">
                </div>
                <div class="col-md-2">
                    <label> RGP Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="qty" [(ngModel)]="dialogdataedit.qty" 
                        (keyup)="getRGPQTYedit($event)" (keypress)="keyPressNumbers($event)" [min]="0" />
                      

                </div>


            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Unit Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="number" class="inputstyles" placeholder="Enter Unit Price"
                        [(ngModel)]="dialogdataedit.unit_price" name="unit_price" #unit_price="ngModel"
                        (keypress)="keyPressNumbers($event)" (keyup)="getRGPQTYedit($event)" min="0" required />
                    <div *ngIf="unit_price.invalid && (unit_price.dirty || unit_price.touched) ">
                        <div class="validtxt" *ngIf="unit_price.errors?.required">
                            is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Basic Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="number" class="inputstyles" name="basic_price"  #basic_price="ngModel" min="0"
                        [(ngModel)]="dialogdataedit.basic_price" readonly>
                </div>

                <!-- <div class="col-md-2">
                    <label>Batch No<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="batchno" [(ngModel)]="dialogdataedit.batchno" readonly>
                </div> -->

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Item Remarks</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="itemremarks" placeholder="Item Remarks" [(ngModel)]="dialogdataedit.itemremarks">
                </div>
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="dialogdataedit.valutiontype">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-12 text-center">
                    <button mat-raised-button class="savebutton text-center" 
                    [disabled]="!dialogdataedit.matcode ||
                                        !dialogdataedit.uom || !dialogdataedit.reqqty || 
                                        !dialogdataedit.valutiontype || !dialogdataedit.qty ||
                                        dialogdataedit.unit_price === null || 
                                        dialogdataedit.unit_price === undefined || dialogdataedit.unit_price === '' || 
                                        dialogdataedit.basic_price === null || 
                                        dialogdataedit.basic_price === undefined || 
                                        dialogdataedit.basic_price === ''" >Update</button>
                </div>
            </div>

        </form>
    </mat-dialog-content>
    <!-- <table *ngIf="smsdata" class="mt-3">
        <thead>
            <tr class="tablestyles">
                <th>Company Name</th>
                <th>Storage Location</th>
                <th>Valuation Type</th>
                <th>Quantity</th>
                <th>Value</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of smsdata">
                <td style="width: 28%">{{ item.company_name }}</td>
                <td>{{ item.storage_location }}</td>
                <td>{{ item.valuation_type }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.value | number: "1.2-2" }}</td>
            </tr>
        </tbody>
    </table> -->
</ng-template>
<ng-template #deleteitemofList>
    <div mat-dialog-title class="mb-0">
    <div class="row">
        <div class="col-11">
            <h1>Delete Outgatepass RGP</h1>
        </div>
        <div class="col-1 text-right">
            <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            >
           <mat-icon class="icon1">close</mat-icon>
       </button>
        </div>
    </div>
    </div>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-12">
                <label class="docfield">Reason</label>
                <input type="text" placeholder="Please Enter Reason" class="inputstyles ml-2 deledititemclose" name="reason"
                    [(ngModel)]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
                    (input)="deletemodel.reason=$event.target.value.trim()"  />
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-center">
        <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">
            Close</button>&nbsp; -->
        <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton" [disabled]="!deletemodel.reason">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #deletedocsigned>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Signed Already</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
        <p>Still want to delete ? </p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteSeleted(deletedocsigneddata)">
            Yes
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton">
            No</button>
    </div>
</ng-template>

<ng-template #deletedocsigneddata>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>&nbsp;</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
        <p><b>Document Number :</b> {{document_no}}</p>
        <div class="row">
            <div class="col-md-12">
                <label class="docfield">Reason</label>
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [angModel]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
                    (input)="deletemodel.reason=$event.target.value.trim()"  />
            </div>
            <div class="col-md-12">
                <label class="docfield">Password</label>
                <input type="text" placeholder="Enter Password" class="inputstyles" name="pwd"
                    [(ngModel)]="deletemodel.pwd"  />
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteFile()" [disabled]="!deletemodel.reason">
            Confirm
        </button>
        <!-- <button mat-button mat-dialog-close class="matButton" style="background: transparent">
            No</button> -->
    </div>
</ng-template>

<ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title">
        <!-- <h2>Out Gate Pass RGP Request: {{ outgatepassrgpnum }}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            class="fileclosebtn">
            <mat-icon class="icon1">close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-md-8">
                <h2>Out Gate Pass RGP Request: {{ outgatepassrgpnum }}</h2>
            </div>
            <div class="col-md-4">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon fileclosebtn" (click)="closemodel()"
               >
                <mat-icon class="icon1">close</mat-icon>
            </button>
            </div>

        </div>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedata">
            <!-- <span *ngFor="let item of filedata"> -->
            <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
            <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
      style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table class="text-center">
                <thead class="tablethcol text-center">
                    <!-- <tr> -->
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <th>Action</th>
                    <!-- </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedata">
                        <td>{{ item.created_by }}</td>
                        <td>{{ item.created_date }}</td>
                        <td class="tablebody">
                            {{ item.file_name }}
                          </td>
                        <td>
                            <i class="fas fa-eye text-cyan m-r-20 viewiconpointer" 
                              title="View File" (click)="viewDoc1(item)"></i>
                            <button mat-icon-button class="listdelbtn"
                                aria-label="Example icon button with a vertical three dot icon"
                                (click)="deleterowfile(deletefileModel, item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="filedata.length===0">
                No Data Found
            </div>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
            <!-- </span> -->
        </div>
    </div>
    <br />
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon>
    </button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadWbsFile($event)"  
            #autoFileUpload1 />
        <ng-container *ngIf="fileUploadUrls.length > 0">
            <div *ngFor="let item of filenamearray; let i = index">
                <!-- {{ i + 1 }}.&nbsp;{{ item }} -->
            </div>
            
        </ng-container>
    </div>
</ng-template>
<ng-template #deletefileModel>
    <h1 mat-dialog-title>Delete File</h1>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton" class="closebtn1">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #Editmodel>
    <div mat-dialog-title>
       <div class="row">
        <div class="col-12 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
        >
        <mat-icon class="icon1">close</mat-icon>
    </button>
</div>
       </div>
    </div>
    <!-- <h1 mat-dialog-title></h1> -->
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-2">
                <label class="docfield">Reason</label>
            </div>
            <div class="col-md-4">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles editreason" name="reason"
                    [ngModel]="editModel.reason" (ngModelChange)="editModel.reason=$event.trim()" 
                    (input)="editModel.reason=$event.target.value.trim()"  />
            </div>
        </div>
    </div>
    <br />
    <div mat-dialog-actions class="justify-content-center">
        <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
        <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton" [disabled]="!editModel.reason" class="submitbtn">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #qrscan>
    <h1 mat-dialog-title>Scanner</h1>
    <div mat-dialog-content>
        <p *ngIf="information.length>0">{{information}}</p>
        <!-- <p  *ngIf="!information.length > 0"(change)="infoScan()">{{errorScanner}}</p> -->
        <zxing-scanner *ngIf="scannerEnabled" #scanner (scanSuccess)="scanSuccessHandler($event)">
        </zxing-scanner>
        <button (click)="enableScanner()" *ngIf="!scannerEnabled" type="button" class="btn btn-success">Try
            Again</button>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton closebtn1" >Close</button>&nbsp;
        <button mat-button cdkFocusInitial class="matButton" (click)="confirmQrCode()">
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #dcmodel>
    <div mat-dialog-title class="dialog-title">
        <!-- <h2>Search Delivery Challan</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -10%;">
            <mat-icon class="icon1">close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-md-8">
                <h2>Search Delivery Challan</h2>
            </div>
            <div class="col-md-4 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            >
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <form #mypoform="ngForm" (ngSubmit)="submitdcno(mydcform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>Select Delivery Challan Number<span class="mandetory">*</span></label> -->
                <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
            [(ngModel)]="copieddmr"> -->
                <mat-form-field class="example-full-width dropdn1" appearance="outline">
                    <input type="text" placeholder="Search Delivery Challan Number " aria-label="Number" matInput
                         class="inputdropdown dropdn2" name="dcnumber"
                        [(ngModel)]="dcnumber" (keyup)="getdcDATA11($event)" [matAutocomplete]="auto44" (input)="trimInput()"/>

                    <mat-autocomplete autoActiveFirstOption #auto44="matAutocomplete">

                        <mat-option *ngFor="let item of DCDATA" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-2">
        <button mat-raised-button class="savebutton submitBtn" 
             [disabled]="!dcnumber">Submit</button>
            </div>
        </div>

    </form>
</ng-template>

<!-- Approvals -->
<ng-template #approvalsmodel>
    <!-- <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Number:{{documnet_nodata}}</h1>
        
    </span> -->
    <div mat-dialog-title>
    <div class="row">
        <div class="col-md-8">
            <h1 >Document Number:{{documnet_nodata}}</h1>
        </div>
        <div class="col-md-4">
            <button mat-icon-button class="closeicon" [mat-dialog-close]="true">
                <mat-icon class="icon1">close</mat-icon>
            </button>
        </div>
        </div>
    </div>
    <div mat-dialog-content>
        <p>Do you confirm to proceed for Signatures</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()">
            Confirm
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton">
            Cancel</button>
    </div>
</ng-template>

<!-- signature status -->

<ng-template #signaturestatus>
    <!-- <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Signature Status</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span> -->
    <div mat-dialog-title class="mb-0">
        <div class="row">
          <div class="col-md-8">
            <h1 >Signature Status</h1>
          </div>
          <div class="col-md-4 text-right">
             <button mat-icon-button class="closeicon" [mat-dialog-close]="true">
            <mat-icon class="icon1">close</mat-icon>
        </button>
          </div>
        </div>

    </div>
    <div mat-dialog-content>
        <p>Document Number:{{viewnumber}}</p>
        <table *ngIf="viewData" class="signTable">
            <thead  class="text-center headerclass tablethcol">
                <th>S.No</th>
                <th>Signature Levels</th>
                <th>Signed By</th>
                <th>Date & Time</th>
                <th>Status</th>
                <!-- <th>Signature</th> -->
            </thead> 
            <tbody class="text-center">
                <tr *ngFor="let item of SignData; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.description}}</td>
                    <!-- <td>{{item.updated_by}}</td> -->
                    <ng-container *ngIf="item.updated_by">
                        <span *ngFor="let word of item.updated_by.split(' '); let last = last">
                            {{ word }}
                            <br *ngIf="word.length > 2 && !last"> <!-- Break line if word length is more than 2 -->
                        </span>
                    </ng-container>
                    <td>{{item.updated_date| date: "dd-MM-yyyy hh:mm:ss a"}}</td>
                    <td><span *ngIf="item.status==='COMPLETED'"  ><mat-icon class="checkIn">check_circle</mat-icon></span>
                        <span *ngIf="item.status==='PENDING'" ><mat-icon class="warningicon">warning</mat-icon></span>
                        <!-- <span *ngIf="item.status===null" >NA</span> -->
                    </td>
                    <!-- <td>
                        <img *ngIf="item.signature!==null" [src]="item.signature" class="sign-width" />
                        <p class="ml-1 mb-0" *ngIf="item.signbtn===false&&item.signature===null">Need to be Signature</p>
                        <span>
                            <button type="button" class="btn btn-custom mr-3" *ngIf="item.signbtn===true&&item.signature===null"
                            (click)="getSignatureView(item,viewSignmodel)">Sign</button>
                            <button type="button" class="btn btn-custom" *ngIf="item.signbtn===true&&item.signature===null"
                            (click)="deleteItem1(item,deleteitemofList1)">Cancel</button>
                      </span> 
                    </td> -->

                </tr>
                <tr *ngIf="SignData.length===0">
                    <td colspan="3">No Records Found</td>
                </tr>
        </table>

    </div>
    <!-- <div mat-dialog-actions> -->
    <!-- <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
            Confirm
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton" >
            Cancel</button>
    </div> -->
</ng-template>

<!-- Approvals -->
<ng-template #approvalmodeldatastatus> 
   
    <div mat-dialog-title class="mb-0">
        <div class="row">
            <div class="col-md-8">
               <h1 *ngIf="viewnumber">Document Number:{{viewnumber}}</h1>
            </div>
            <div class="col-md-4">
                <button mat-icon-button class="closeicon" [mat-dialog-close]="true">
                    <mat-icon class="icon1">close</mat-icon>
                </button>
            </div>

        </div>

    </div>
    <div mat-dialog-content>
        <p>Do you confirm to proceed for Signatures</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
            Confirm
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton" >
            Cancel</button>
    </div>
  </ng-template>

  <!-- model -->
<!-- <ng-template #viewSignmodel>
    <h1 mat-dialog-title>View Signature</h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 text-center">
          <img [src]="Viewsign" class="sign-width" />
        </div>
      </div>
    </div><br>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="SignatureFile()" class="matButton"
        [disabled]="!Viewsign">Confirm</button>
    </div>
  </ng-template> -->

  <!-- Cancel -->
  <!-- <ng-template #deleteitemofList1>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-12">
                <label style="padding-top: 5px">Reason</label>
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [(ngModel)]="deletemodel.reason" style="width: 100%" />
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton">
            Close</button>&nbsp;
        <button  mat-button cdkFocusInitial (click)="deleteFile1()" class="matButton" [disabled]="!deletemodel.reason">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #deletedocsigned>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Signed Already</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
        <p>Still want to delete ? </p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteSeleted1(deletedocsigneddata1)" >
            Yes
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton" >
            No</button>
    </div>
</ng-template>

<ng-template #deletedocsigneddata1>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>&nbsp;</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
       <p><b>Document Number :</b> {{document_no}}</p>
       <div class="row">
        <div class="col-md-12">
            <label style="padding-top: 5px">Reason</label>
            <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                [(ngModel)]="deletemodel.reason" style="width: 100%" />
        </div>
        <div class="col-md-12">
            <label style="padding-top: 5px">Password</label>
            <input type="text" placeholder="Enter Password" class="inputstyles" name="pwd"
                [(ngModel)]="deletemodel.pwd" style="width: 100%" />
        </div>
    </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteFile1()"  [disabled]="!deletemodel.reason">
            Confirm
        </button>
        <button mat-button mat-dialog-close class="matButton" style="background: transparent">
            No</button>
    </div>
</ng-template> -->


<!-- reviewedDaysmodel -->
<ng-template #reviewedDaysmodel>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Number:{{doc_no}}</h1>
        <button mat-icon-button class="close-button1" [mat-dialog-close]="true">
            <mat-icon class="close-icon1">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
      <div class="row">
        <div  class="col-12">
            <label>Reviewed Days of Return<span class="mandetory">*</span></label>
            <select class="dropdownstyles" name="expectedReturnDays" (change)="reviewedExpectedReturnDaysCal()"
                [(ngModel)]="reviewedexpectedReturnDays">
                <option value="" selected disabled>Select One</option>
                <option value="30"> 30 Days </option>
                <option value="60"> 60 Days </option>
                <option value="90"> 90 Days </option>
                <option value="180"> 180 Days </option>
            </select>
        </div>
        <div class="col-md-12">
            <label>Reviewed Date of Return<span class="mandetory">*</span></label>
            <input  type="date" 
                class="inputstyles textupper" name="expectedredate" [(ngModel)]="reviewedexpectedredate" readonly>
        </div>
      </div>


    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="getReviewedData()" class="submitbtn">
            Submit
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton">
            Cancel</button>
    </div>
</ng-template>
<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title">
        
        <div class="row">
            <div class="col-md-8">
                <h2>File Upload</h2>
            </div>
            <div class="col-md-4">
                <button mat-icon-button aria-label="close dialog"  mat-dialog-close class="closeicon closeicon1"
                    (click)="closemodel()" >
                    <mat-icon class="icon1">close</mat-icon>
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="!editeddata">
    <div mat-dialog-content class="row">
        <div *ngIf="selectedfiles.length > 0">
            <table>
                <thead class="table2">
                    <th>S.NO</th>
                    <th>File Name</th>
                    <th>Action</th>
                </thead>
                <tbody class="text-center">
                    <tr *ngFor="let item of selectedfiles;let i=index;">
                        <td>{{i+1}}</td>
                        <td class="tablebody1">{{item.name}}</td>
                        <td>
                            <button mat-icon-button class="delete1"
                            (click)="deleteFileItem(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="selectedfiles.length===0">
                No Data Found
            </div>
        </div>
    </div><br>
    <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()" >
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadgtfiles($event)"
            #autoFileUpload2>
    </div>
  </div>
    <div *ngIf="editeddata">
      <div mat-dialog-content class="row">
        <div *ngIf="filedata">
          <table>
            <thead class="tablethcol text-center">
              <!-- <tr> -->
              <th>Created By</th>
              <th>Created Date</th>
              <th>File Name</th>
              <th>Action</th>
              <!-- </tr> -->
            </thead>
            <tbody>
              <tr *ngFor="let item of filedata">
                <td>{{ item.created_by }}</td>
                <td>{{ item.created_date }}</td>
                <td class="tablebody ">
                  {{ item.file_name }} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 enabled"
                    title="View File" (click)="viewDoc1(item)"></i>
                </td>
                <td>
                  <button mat-icon-button class="listdelbtn"
                    aria-label="Example icon button with a vertical three dot icon"
                    (click)="deleterowfile(deletefileModel, item)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" *ngIf="filedata.length===0">
            No Data Found
        </div>
          <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
          <!-- </span> -->
        </div>
      </div>
      <br />
      <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
        Attach File
      </button>
      <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadWbsFile($event)"
          #autoFileUpload1 />
        <ng-container *ngIf="fileUploadUrls.length > 0">
          <!-- <div *ngFor="let item of filenamearray; let i = index">
            {{ i + 1 }}.&nbsp;{{ item }}
          </div> -->
          <!-- <span >{{createNewFile.fileName}}</span>   -->
        </ng-container>
      </div>
    </div>
  </ng-template>