import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpParams } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import * as moment from "moment";
import * as XLSX from 'xlsx';
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, take } from "rxjs/operators";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import { CustomerService } from "src/app/services/customer.service";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
export interface FilteredOption {
  username: string;
}
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  allSelecteddmr = false;
  @ViewChild('selectdmr') selectdmr: MatSelect;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) Dpaginator: MatPaginator;
  @ViewChild(MatPaginator) Rpaginator: MatPaginator;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTriggerlst: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTriggerdash: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTriggerreport:MatAutocompleteTrigger;
  @ViewChild ("autoFileUpload") autoFileUpload:ElementRef;
  userdetails: any = {}
  auditStockDisplayedItems = new MatTableDataSource();
  auditDashboardData = new MatTableDataSource()
  plantWiseData = new MatTableDataSource()
  displayedColumns: any[] = [
    "sno",
    "date",
    "auditname",
    "status",
    "action"
  ];
  displayedColumns2: any[] = [
    "sno",
    "created_date",
    "companyId",
    "companyName",
    "auditName",
    "status",
    "totalMaterialsCount",
    "countedMaterials",
    "excessCount",
    "shortageCount",
    "percentageOfCount",
    "unitPrice",
    "totalValue",
    "countedValue",
    "shortageValue",
    "excessValue"
  ]
  auditStockDisplayedColumns: any[]=[
    "sno",
    "storageLocation",
    "binLocation",
    "materialCode",
    "materialDescription",
    "auditStatus",
    "closingQuantity",
    "physicalQuantity",
    "shortageQuantity",
    "excessQuantity",
    "unitPrice",
    "closingValue",
    "shortageValue",
    "excessValue"
  ]
  displayedColumns1: any[] = [
    "sno",
    "auditname",
    "companyName",
    "action"
  ];
  pageIndexAuditlist: any = 1;
  pageSizeAuditlist: any = 10;
  totalRecordsdashboard:any = 0;
  pageIndexAuditlistDashboard: any = 1;
  pageSizeAuditlistDashboard: any = 10;
  pageIndexAuditlistReport :any=1;
  pageSizeAuditlistReport :any=10;
  startDate: any;
  endDate: any;
  totalRecordDashboarddata: any=0;
  totalAuditStockData:any=0;
  demo1TabIndex: any = 0;
  //company data
  companyData1: any[]=[];
  companyData2: any[]=[];
  companyData3: any[]=[];
  selectedAuditCompany: any='';
  selectedPlantwiseCompany: any='';
  auditsummaryCompany: any='';
  AuditCompanyName: any;
  AuditCompanyName1: any;
  noData: boolean=true;
  auditNamedashboard: string;
  selectedOption:any = "ALL";
  auditName: any;
  auditDashboardlist: any[]=[];
  selectedAuditId: any;
  AuditCompanyCode: any;
  selectedAuditNamelist: any;
  auditNamelist: any;

  noDatadashboard: boolean=false;
  noDataReport :boolean=false;
  selectedAuditDashboardCompany: any;
  selecteddashboardCompanyName: any[]=[];
  auditNameD: any;
  auditDData: any[]=[];
  selectedAuditDId: any={};
  AuditCompanyDCode: any;
  selectedAuditNamelistD: any;
  selectedAuditReport: any;
  arcompanyName: any;
  auditNameR: any;
  AuditCompanyRCode: any;
  selectedAuditRId:any= {};
  selectedAuditNamelistR: any;
  auditDashboardlistR: any[]=[];

  selectedApprovers: any[] = [];
  selectedApprovers1: any[] = [];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  fruits: any[]=[];
  myControl = new FormControl();
  // myControl = new FormControl('', this.validateInput.bind(this));
  fetchedApprovers: any[]=[]
  formdata: any={};
  AuditCompanyIdc: any;
  AuditCompanyNamec: any;
  selectedUsers: any[]=[];
  approvers: any;
  modalData: any={};
  selectedAuditFile: any;
  selectedFileName: any;
  AuditNamec: any;
  AuditCompanyCodec: any;
  user:any
  filteredOptions: Observable<FilteredOption[]>;
  companyData11: any[]=[];
  selectedAuditCompanyC: any;
  noDatalist: boolean=false;
  selectedAuditCompanyNAmeC: any;
  previousValue: any;
  previousValuelist: any;
  previousValueD: any;
  previousValueR: any;
  currentDate: any=moment();
  model: {start: any, end: any}
  ranges: any = {
    // 'Today': [moment(), moment()],
  //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  //   // 'This Month': [moment().startOf('month'), moment().endOf('month')],
  // 'This Month':[moment().startOf('month'),moment(new Date(this.currentdate.setMonth(this.currentdate.getMonth())))],
  //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  //   // 'This Year': [moment().subtract('year').startOf('year'), moment().subtract('year').endOf('year')],
  //   'This Year': [moment().startOf('year'),moment(new Date(this.currentdate.setMonth(this.currentdate.getMonth()))) ],
  //   'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  //   'Last 2 Years': [moment().subtract(2, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

  }
  fileUploadUrlsqc: any[] = [];
  createNewFile: any={};
  filenamearray: any[]=[];
  selectedfiles: any[]=[];
  auditApproverslist: string;
  auditnum: any;
  rejectModel: any={};
  auditStatus1: any;
  approveModel: any={};
  // pickerOptions:any={}
  // Method to calculate the start date of the previous month
  calculateStartDate(): moment.Moment {
    return moment().subtract(1, 'month').startOf('month');
  }

  // Method to calculate the end date of the current month
  calculateEndDate(): moment.Moment {
    return moment().endOf('month');
  }

  pickerOptions: any = {
    startDate: moment().subtract(1, 'months').startOf('month').toDate(), // Start date set to the beginning of the previous month
    endDate: moment().endOf('month').toDate(), // End date set to the end of the current month
    alwaysShowCalendars: true,
    autoApply: true,
    maxDate: this.currentDate,
    singleCalendar: true, // Only show left calendar
    locale: {
      applyLabel: 'ok',
      format: 'DD-MM-YYYY'
    }
  };
  private companySearchSubject: Subject<string> = new Subject();
  private companySearchSubject1: Subject<string> = new Subject();
  private companySearchSubjectReport: Subject<string> = new Subject();
  private approverSearchSubject: Subject<string> = new Subject();
  constructor(public custservice: CustomerService, private dialog: MatDialog, public router: Router, public keycloackService: KeycloakService,
    private alertcall: AlertCallsService) {
      this.companySearchSubject.pipe(
        debounceTime(500), 
        distinctUntilChanged() 
      ).subscribe(searchTerm => {
        this.getCompanyData("lst", searchTerm, this.companyData11);
      });

      this.companySearchSubject1.pipe(
        debounceTime(500), 
        distinctUntilChanged() 
      ).subscribe(searchTerm => {
        this.getCompanyData("lst", searchTerm, this.companyData1);
      });
   
      this.companySearchSubjectReport.pipe(
        debounceTime(500), 
        distinctUntilChanged() 
      ).subscribe(searchTerm => {
        this.getCompanyData("lst", searchTerm, this.companyData3);
      });

      this.approverSearchSubject.pipe(
        debounceTime(500),
        distinctUntilChanged() 
      ).subscribe(searchTerm => {
        this.fetchApprovers(searchTerm);
      });
  }
  ngOnInit(): void {
    this.userdetails = this.keycloackService.getKeycloakInstance().tokenParsed
    this.currentDate = moment();
    this.startDate = this.currentDate;
    this.endDate = this.currentDate;
    this.model = {start: moment(),end:moment()}
    //  this.model = {
    //   start: moment().subtract(1, 'month').startOf('month'),
    //   end: moment().endOf('month')
    // };
    this.auditTabChanged({ index: this.demo1TabIndex });
  }
  
  ngAfterViewInit(): void {
    this.getAuditDashboardData();
  }  
 
  
  datesUpdated(_ev:any){
    if(this.model.end){
      this.startDate =moment(this.model.start).format("YYYY-MM-DD")
      this.endDate = moment(this.model.end).format("YYYY-MM-DD")
      this.getPlantWiseData(); 
      this.fetchAuditDData();
    }
  }
  auditTabChanged(event:any){
    if(event.index == 0){
      //audit dashboard
      this.model = {start: moment(),end:moment()}
      this.selecteddashboardCompanyName=[]
      this.auditNameD=""
      this.plantWiseData.data=[]
      this.allSelecteddmr=false
      this.selectedAuditDId={}
      //Report
      this.auditNameR=""
      this.arcompanyName=""
      this.auditStockDisplayedItems.data=[]
      this.selectedAuditRId={}
      //audit list
      this.selectedOption= "ALL";
      this.AuditCompanyCode=""
      this.AuditCompanyName=""
      this.auditNamelist=""
      this.auditDashboardData.data=[]
      this.getsuditCreatelist();
      this.getApprovers('');   
    }else if(event.index == 1){
     //audit created
      this.AuditCompanyNamec=""
      this.AuditCompanyCodec=""
      this.AuditNamec=""
      this.selectedAuditFile=""
      this.formdata.approvers = []
      this.selectedApprovers = []
      this.selectedfiles = []
      this.filenamearray = []
      this.selectedAuditFile=undefined
      //audit dashboard
      this.model = {start: moment(),end:moment()}
      this.selecteddashboardCompanyName=[]
      this.auditNameD=""
      this.plantWiseData.data=[]
      this.allSelecteddmr=false
      this.selectedAuditDId={}
     //Report
      this.auditNameR=""
      this.arcompanyName=""
      this.auditStockDisplayedItems.data=[]
      this.selectedAuditRId={}
      this.getAuditCompanies();
      this.getAuditDashboardData();
    }else if(event.index == 2){
    //audit created
    this.AuditCompanyNamec=""
    this.AuditCompanyCodec=""
    this.AuditNamec=""
    this.selectedAuditFile=""
    this.formdata.approvers = []
      this.selectedApprovers = []
      this.selectedfiles = []
      this.filenamearray = []
      this.selectedAuditFile=undefined
    //audit list
    this.selectedOption= "ALL";
    this.AuditCompanyName=""
    this.AuditCompanyCode=""
    this.auditNamelist=""
    this.auditDashboardData.data=[]
     //Report
      this.auditNameR=""
      this.arcompanyName=""
      this.auditStockDisplayedItems.data=[]
      this.selectedAuditRId={}
      this.getPlantwiseCompanyData();
      this.getPlantWiseData();
    }else if(event.index == 3){
    //audit created
     this.AuditCompanyNamec=""
     this.AuditCompanyCodec=""
     this.AuditNamec=""
     this.selectedAuditFile=""
     this.formdata.approvers = []
      this.selectedApprovers = []
      this.selectedfiles = []
      this.filenamearray = []
      this.selectedAuditFile=undefined
       //audit list
       this.selectedOption= "ALL";
       this.AuditCompanyName=""
       this.auditNamelist=""
       this.AuditCompanyCode=""
       this.auditDashboardData.data=[]
       //audit dashboard
       this.model = {start: moment(),end:moment()}
       this.selecteddashboardCompanyName=[]
       this.auditNameD=""
       this.selectedAuditDId={}
       this.plantWiseData.data=[]
       this.allSelecteddmr=false
      this.getsuditSummary();
      this.onAuditNameSelect();
    }
  } 
// Company Data search and api call
getCompanyData(command: string, key: string, companyData: any) {
  let obj: any = {};
  obj['command'] = command;
  obj['lmt'] = 100000;
  obj['pid'] = 1;
  obj['key'] = key || '';
  obj['showLoader'] = 'hide'
  
  this.custservice.getSearchedAuditCompanies(obj).subscribe((res: any) => {
    if (res.data) {
      companyData.length = 0; 
      Array.prototype.push.apply(companyData, res.data); 
      // console.log(res.data[0], res.data[0]['company_name'], "@@@@@@@@@@@@@@"); 
    }
  });
}
//
onCompanySelectionC(ev: any) {
  this.selectedAuditCompany = ev.target.value;
  if (this.selectedAuditCompany.length > 2 || !this.selectedAuditCompany) {
    this.getAuditCompanies();
  }
}
// audit company
// audit summary
getsuditCreatelist() {
  // this.getCompanyData("lst", this.selectedAuditCompanyC, this.companyData11);
  this.companySearchSubject.next(this.selectedAuditCompanyC);
}
getAuditCompanies() {
  // this.getCompanyData("lst", this.selectedAuditCompany, this.companyData1);
  this.companySearchSubject1.next(this.selectedAuditCompany);
  // if(this.demo1TabIndex == 1){
  //   setTimeout(()=> { 
  //     this.AuditCompanyName=this.companyData1[0]['company_name']
  //   this.onCompanyOptionSelected();
  // }, 150)
  // }
}

onCompanySelectionCL(ev: any) {
  this.previousValue = this.selectedAuditCompanyC; // Store the previous value
  this.AuditCompanyCodec = "";
  this.AuditNamec = "";
  this.selectedAuditCompanyC = ev.target.value;
  if (this.selectedAuditCompanyC !== this.previousValue) {
    if (this.selectedAuditCompanyC.length > 2 || !this.selectedAuditCompanyC) {
      this.getsuditCreatelist();
    }
    if (this.AuditCompanyNamec == "") {
      this.AuditCompanyCodec = "";
      this.AuditNamec = "";
    }
  }
}
openDropdown() {
  setTimeout(() => {
    this.autocompleteTrigger.openPanel();
  }, 0);
}
openDropdownlst(){
  setTimeout(() => {
    this.autocompleteTriggerlst.openPanel();
  }, 0);
}
openDropdowndashaudit(){
  setTimeout(() => {
    this.autocompleteTriggerdash.openPanel();
  }, 0);
}
openDropdownreport(){
  setTimeout(() => {
    this.autocompleteTriggerreport.openPanel();
  }, 0);
}
onCompanySelection(ev: any) {
  this.AuditCompanyCode=""
  this.auditNamelist=""
  this.selectedAuditId = {}
  this.selectedAuditCompany = ev.target.value;
    if (this.selectedAuditCompany.length > 2 || !this.selectedAuditCompany) {
      this.getAuditCompanies();
      this.getAuditDashboardData();
  }
}
selectedcompanyaudit(){
  this.AuditCompanyCode=""
  if(this.AuditCompanyNamec !== '' || this.AuditCompanyNamec !== undefined || this.AuditCompanyNamec !== null){
    const selectedCompany = this.companyData11.find((ele: any) => ele.company_name === this.AuditCompanyNamec); 
    if(selectedCompany){
      this.AuditCompanyCodec = selectedCompany.company_code
      // this.onAuditNameChange();
      this.selectAuditNAme();
    }
  }
}

// audit name select
selectAuditNAme(){
  let params = new HttpParams();
  if(this.AuditCompanyCodec){
    params = params.set("companyId", this.AuditCompanyCodec);
  }
  this.custservice.getAuditCount(params).subscribe((res: any) => {
    if (res && res.retrivedata) {
      this.AuditNamec = res.retrivedata;
    } else {
      this.AuditNamec = '';
    }
  });
}


onCompanyOptionSelected(){
  this.auditDashboardlist = []
  this.auditNamelist=""
  this.AuditCompanyCode=""
  this.selectedAuditId = {}
  if(this.AuditCompanyName === ''){
    this.auditNamelist=""
    this.selectedAuditId = {}
  }
  if(this.AuditCompanyName !== '' || this.AuditCompanyName !== undefined || this.AuditCompanyName !== null){
    const selectedCompany = this.companyData1.find((ele: any) => ele.company_name === this.AuditCompanyName); 
    if(selectedCompany){
      this.AuditCompanyCode = selectedCompany.company_code
      this.getAuditDashboardData();
      this.onAuditNameChange();
    }
  }
}
private debounceTimerAuditNumber: any;
//audit name
onAuditNameChange(){
  let obj={
    "compinesList":[this.AuditCompanyName],
    "auditName": this.auditNamelist !== '' ? this.auditNamelist:''
  }
  // let params1 = new HttpParams()
  // params1 = params1.set("companyName",this.AuditCompanyName)
  // if(this.auditName){
  //   params1 = params1.set("auditName", this.auditNamelist !== '' ? this.auditNamelist:'');
  // }

  if (this.debounceTimerAuditNumber) {
    clearTimeout(this.debounceTimerAuditNumber);
  }

  this.debounceTimerAuditNumber = setTimeout(() => {
    this.custservice.getAuditDetails(obj).subscribe((res:any) => {
      if(res['status'] == 'Success'){
        this.auditDashboardlist = res.data
      }else{
        this.auditDashboardlist = []
      }
    })    
  }, 500); 
}
onAuditlistSelection(){
  if(this.auditNamelist === ''){
    this.getAuditDashboardData();
  }
  this.onAuditNameChange()
}
onAuditlistOptionSelected(){
  if(this.auditNamelist === ''){
    this.selectedAuditId = {}
  }
  if(this.auditNamelist !== '' || this.auditNamelist !== undefined || this.auditNamelist !== null){
    // this.getAuditDashboardData();
    this.onAuditNameChange()

    if(this.auditDashboardlist.length > 0){
      this.selectedAuditId = this.auditDashboardlist.find((ele:any) => ele.auditName === this.auditNamelist)
      }else{
        this.selectedAuditId = {}
      }
  }
}

  //filter by status
  onStatusChange(value: any) {
    this.selectedOption = value.value
    // let params= new HttpParams().set("companyId", this.AuditCompanyCode)
      this.pageIndexAuditlist = 1;
      this.getAuditDashboardData();
  }
// plantwise
getPlantwiseCompanyData() {
  this.getCompanyData("lst", this.selectedAuditDashboardCompany, this.companyData2);
}
onCompanySelectionD(ev: any) {
  this.previousValueD = this.selectedAuditDashboardCompany; 
  this.selectedAuditDashboardCompany = ev.target.value;
  if (this.selectedAuditDashboardCompany !== this.previousValueD) {
    if (this.selectedAuditDashboardCompany.length > 2 || !this.selectedAuditDashboardCompany) {
      this.getPlantwiseCompanyData();
    }
  }
}
onCompanyOptionSelectedD(){
  this.auditNameD=""
  this.selectedAuditId = {}
  if(this.AuditCompanyName === ''){
    this.auditNameD = ''
    this.selectedAuditId = {}
  }
  if(this.selecteddashboardCompanyName.length>0){
    const selectedCompanyD = this.companyData2.find((ele: any) => ele.company_name === this.selecteddashboardCompanyName); 
    if(selectedCompanyD){
      this.AuditCompanyDCode = selectedCompanyD.company_code
      this.getPlantWiseData();
      this.fetchAuditDData();
    }
  }
}
// audit summary
getsuditSummary() {
  // this.getCompanyData("lst", this.auditsummaryCompany, this.companyData3);
  this.companySearchSubjectReport.next(this.auditsummaryCompany);
}
onCompanySelectionR(ev: any) {
  this.previousValueR = this.auditsummaryCompany; 
  this.auditsummaryCompany = ev.target.value;
  if (this.auditsummaryCompany !== this.previousValueR) {
    if (this.auditsummaryCompany.length > 2 || !this.auditsummaryCompany) {
      this.getsuditSummary();
    }
  }
}
onCompanyOptionSelectedR(){
  this.auditNameR=""
  if(this.arcompanyName === ''){
    this.auditNameR = ''
    this.selectedAuditRId = {}
  }
  if(this.arcompanyName !== '' || this.arcompanyName !== undefined || this.arcompanyName !== null){
    const selectedCompanyR = this.companyData3.find((ele: any) => ele.company_name === this.arcompanyName); 
    if(selectedCompanyR){
      this.AuditCompanyRCode = selectedCompanyR.company_code
      this.onAuditNameChangeR();
    }
  }
}
onAuditNameChangeR(){
  let obj={
    "compinesList":[this.arcompanyName],
    "auditName":this.auditNameR !== '' ? this.auditNameR:''
  }
  // let params1 = new HttpParams()
  // params1 = params1.set("companyName",this.arcompanyName)
  // if(this.auditName){
  //   params1 = params1.set("auditName", this.auditNameR !== '' ? this.auditNameR:'');
  // }
  this.custservice.getAuditDetails(obj).subscribe((res:any) => {
    if(res['status'] == 'Success'){
      this.auditDashboardlistR = res.data
    }
  })    
}
onAuditlistSelectionR(ev: any) {
  this.selectedAuditNamelistR = ev.target.value;
  if (this.selectedAuditNamelistR.length > 2 || !this.selectedAuditNamelistR) {
    this.pageIndexAuditlistDashboard=1
    this.onAuditNameChangeR();
  }
}
onAuditlistOptionSelectedR(){
  if(this.auditNameR === ''){
    this.selectedAuditDId = {}
  }
  if(this.auditNameR !== '' || this.auditNameR !== undefined || this.auditNameR !== null){
    if(this.auditDashboardlistR.length > 0){
      this.selectedAuditRId = this.auditDashboardlistR.find((ele:any) => ele.auditName === this.auditNameR)
      if(this.selectedAuditRId){
        this.onAuditNameSelect();
      }
      }else{
        this.selectedAuditDId = {}
      }
  }
}

//audit dashboard
   onpageeventdashboard(event: any) {
    this.pageIndexAuditlist = event.pageIndex + 1;
    this.pageSizeAuditlist = event.pageSize;
    this.getAuditDashboardData();
  }

  calculateSerialNumber(index: number): number {
   const displayedIndex = index + 1 + (this.pageIndexAuditlist - 1) * this.pageSizeAuditlist;
   return isNaN(displayedIndex) ? 0 : Math.min(displayedIndex, this.totalRecordsdashboard);
 }
 getAuditDashboardData1(){
  this.AuditCompanyName = "";
  this.auditNamelist = "";
  this.selectedOption = "ALL";
  this.AuditCompanyCode = "";
  this.auditDashboardData.data=[]
  this.getAuditDashboardData();
 }
  getAuditDashboardData() {
    this.auditDashboardData.data=[]
    let params = new HttpParams()
    params = params.append('perPage',  this.pageSizeAuditlist)
    params = params.append('page',  this.pageIndexAuditlist)
    params = params.set("companyId", this.AuditCompanyCode!==""?this.AuditCompanyCode :undefined )
    params = params.set("status", this.selectedOption)
    // if(this.AuditCompanyCode !== "" || this.AuditCompanyCode !== null || this.AuditCompanyCode !== undefined){
    // }
    if (this.auditNamelist !== "" && this.auditNamelist !== undefined && this.auditNamelist !== null  ) {
      params = params.set("auditName", this.auditNamelist)
    }
    // if (this.selectedOption === "ALL") {
    //   params = params.delete("status");
    // }else{
    //   params = params.set("status", this.selectedOption)
    // }
    this.custservice.getAuditDocuments(params).subscribe((res: any) => {
      if(res['status'] == 'Success'){
       this.noDatalist = false;
        this.totalRecordsdashboard = res?.count;
        if (res.data.length == 0) {
          this.noDatalist = true
        }
        if (this.paginator) { 
          if (this.totalRecordsdashboard <= this.pageSizeAuditlist) {
            this.paginator.firstPage();
            // this.paginator.pageSizeOptions = [this.totalRecordsdashboard];
            this.paginator.length = this.totalRecordsdashboard;
          } else {
            this.paginator.pageSizeOptions = [5, 10, 25, 50, 75, 100];
            this.paginator.length = this.totalRecordsdashboard;
          }
        }
        this.auditDashboardData = new MatTableDataSource(res.data);
      }else{
        this.noDatalist = true
      }
  })
}
//isApprover
isApprover(data: any) {
  const user = this.userdetails.preferred_username
  let approvers: any[] | undefined = data?.approvers ? data.approvers : data.approver
  if (Array.isArray(approvers) && approvers.length !== 0) {
    if (approvers.length === 1) {
      let providedApprovers: any = []
      approvers.map((ele) => {
        providedApprovers.push(ele.userName)
      })
      return providedApprovers.includes(user)
    } else {
      let providedApprovers: any = []
      approvers.map((ele) => {
        providedApprovers.push(ele.userName)
      })
      return providedApprovers.includes(user)
    }
  }
}

//Dashboard          
// onStartDateChange(event: MatDatepickerInputEvent<Date>) {
//   if (event.value !== null) {
//     this.startDate = event.value;
//     this.getPlantWiseData();
//     this.fetchAuditDData();
//   }
// }

// onEndDateChange(event: MatDatepickerInputEvent<Date>) {
//   if (event.value !== null) {
//     this.endDate = event.value;
//     this.getPlantWiseData(); 
//     this.fetchAuditDData();
//   }
// }
// formatDate(inputDate: any): any {
//   const year = inputDate.getFullYear();
//   const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
//   const day = inputDate.getDate().toString().padStart(2, '0');

//   return `${year}-${month}-${day}`;
// }
// dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
//   if(dateRangeEnd.value){
//   this.startDate = moment(dateRangeStart.value).format("YYYY-MM-DD")
//   this.endDate = moment(dateRangeEnd.value).format("YYYY-MM-DD")
//   }
// }
fetchAuditDData() {
  this.auditDData=[]
  let obj={
    "fromDate": this.startDate,
    "toDate": this.endDate,
    "compinesList": this.selecteddashboardCompanyName,
    "auditName": this.auditNameD
  }
  // let params = new HttpParams()
  // params = params.set("companyName",this.selecteddashboardCompanyName)
  // params = params.set("fromDate", this.startDate);
  // params = params.set("toDate", this.endDate);
  // if(this.auditNameD){
  //   params = params.set("auditName", this.auditNameD);
  // }
  this.custservice.getAuditDetails(obj).subscribe((res:any) => {
    if(res['status'] == 'Success'){
    this.auditDData = res.data
    }
  })
}
// onAuditlistSelectionD(ev: any) {
//   this.selectedAuditDId = {}
//   if(ev.target.value !== undefined){
//     this.selectedAuditNamelistD = ev.target.value
//   }
//   if (this.selectedAuditNamelistD.length > 2 || !this.selectedAuditNamelistD) {
//     this.pageIndexAuditlistDashboard=1
//     this.fetchAuditDData();
//   }
// }
onAuditlistSelectionD(ev: any) {
  this.selectedAuditDId = {};
  if (ev && ev.target && ev.target.value !== undefined) {
    this.selectedAuditNamelistD = ev.target.value;
    if (this.selectedAuditNamelistD.length > 2 || !this.selectedAuditNamelistD) {
      this.pageIndexAuditlistDashboard = 1;
      this.fetchAuditDData();
    }
  }
}

onAuditlistOptionSelectedD(){
  this.selectedAuditDId = {}
  if(this.auditNameD === ''){
    this.selectedAuditDId = {}
    this.getPlantWiseData();
  }
  if(this.auditNameD !== '' || this.auditNameD !== undefined || this.auditNameD !== null){
    if(this.auditDData.length > 0){
      this.selectedAuditDId = this.auditDData.find((ele:any) => ele.auditName === this.auditNameD)
      if(this.selectedAuditDId){
        this.getPlantWiseData();
      }
      }else{
        this.selectedAuditDId = {}
        this.getPlantWiseData();
      }
  }else{
    this.selectedAuditDId = {}
    this.getPlantWiseData();
  }
}

onpageeventD(ev: any) {
  this.pageIndexAuditlistDashboard =  ev.pageIndex + 1;
  this.pageSizeAuditlistDashboard = ev.pageSize;
  this.getPlantWiseData();
}
calculateSerialNumberD(index: number): number {
  const displayedIndex = index + 1 + (this.pageIndexAuditlistDashboard - 1) * this.pageSizeAuditlistDashboard;
  return isNaN(displayedIndex) ? 0 : Math.min(displayedIndex, this.totalRecordDashboarddata);
}
getPlantWiseData1(){
  this.model = {start: moment(),end:moment()}
       this.selecteddashboardCompanyName=[]
       this.auditNameD=""
       this.selectedAuditDId={}
       this.plantWiseData.data=[]
       this.allSelecteddmr=false
      this.getsuditSummary();
      this.onAuditNameSelect();
}
getPlantWiseData() {
  this.plantWiseData.data=[]
  if (!this.startDate || !this.endDate) {
    console.log("Please select both start and end dates.");
    return;
  }
  let obj={
    'perPage': this.pageSizeAuditlistDashboard,
    'page':  this.pageIndexAuditlistDashboard,
    "fromDate": this.startDate,
    "toDate": this.endDate,
    "compinesList": this.selecteddashboardCompanyName,
    "auditId":this.selectedAuditDId['_id']
  }
  // let params = new HttpParams();
  // params = params.append('perPage',  this.pageSizeAuditlistDashboard)
  // params = params.append('page',  this.pageIndexAuditlistDashboard)
  // params = params.set("fromDate", this.startDate);
  // params = params.set("toDate", this.endDate);
  // if(Object.values(this.selectedAuditDId).length !==0){
  //   "auditId",this.selectedAuditDId._id
  // }
  // console.log(params);
  
  this.custservice.getPlantWiseData(obj).subscribe((res: any) => {
    if(res['status'] == "Success"){
        this.noDatadashboard = false;
        this.totalRecordDashboarddata = res?.count;
      if (res.data.length === 0) {
        this.noDatadashboard = true;
      } 
      if (this.Dpaginator) { 
        if (this.totalRecordDashboarddata <= this.pageSizeAuditlist) {
          this.Dpaginator.firstPage();
          // this.Dpaginator.pageSizeOptions = [this.totalRecordDashboarddata];
          this.Dpaginator.length = this.totalRecordDashboarddata;
        } else {
          this.Dpaginator.pageSizeOptions = [5, 10, 25, 50, 75, 100];
          this.Dpaginator.length = this.totalRecordDashboarddata;
        }
      }
      this.plantWiseData = new MatTableDataSource(res.data)
    }else{
      this.noDatadashboard = true
    }
  });
}


//Report
onpageeventR(ev: any) {
  this.pageIndexAuditlistReport =  ev.pageIndex + 1;
  this.pageSizeAuditlistReport = ev.pageSize;
  this.onAuditNameSelect();
}
calculateSerialNumberR(index: number): number {
  const displayedIndex = index + 1 + (this.pageIndexAuditlistReport - 1) * this.pageSizeAuditlistReport;
  return isNaN(displayedIndex) ? 0 : Math.min(displayedIndex, this.totalAuditStockData);
}
onAuditNameSelect1(){
  this.auditNameR=""
      this.arcompanyName=""
      this.auditStockDisplayedItems.data=[]
      this.selectedAuditRId={}
      this.getAuditCompanies();
      this.getAuditDashboardData();
}
onAuditNameSelect(){
  this.auditStockDisplayedItems.data=[]
  if(this.selectedAuditRId){
    let params = new HttpParams()
    params = params.append('perPage',  this.pageSizeAuditlistReport)
    params = params.append('page',  this.pageIndexAuditlistReport)
    params = params.set("audit_id",this.selectedAuditRId['_id'])
    this.custservice.getAuditStockDocuments(params).subscribe((res:any) => {
      if(res['status'] == "Success"){
        this.noDataReport = false
        this.totalAuditStockData = res?.count
        if (res.data.length === 0) {
          this.noDataReport = true;
        } 
        if (this.Rpaginator) { 
          if (this.totalAuditStockData <= this.pageSizeAuditlistReport) {
            this.Rpaginator.firstPage();
            // this.Rpaginator.pageSizeOptions = [this.totalAuditStockData];
            this.Rpaginator.length = this.totalAuditStockData;
          } else {
            this.Rpaginator.pageSizeOptions = [5, 10, 25, 50, 75, 100];
            this.Rpaginator.length = this.totalAuditStockData;
          }
        }
        this.auditStockDisplayedItems= new MatTableDataSource(res.data)
        this.auditStatus1 = res.auditMasterStatus
      }else{
        this.noDataReport = true
      }
    })
  }
}


//download pdf
downLoadPdf() {
  let params = new HttpParams()
  params = params.set("getPdf","getPdf")
  if(Object.values(this.selectedAuditRId).length !==0){
    params = params.set("_id",this.selectedAuditRId['_id'])
    this.custservice.getTotalAuditValues(params).subscribe((res:any) =>{
      if (res.status === "success") {
        window.open(res.location, '_blank')
        // saveAs(res.pdfLocation,"downLoad_file.pdf")
      }
      if(res.status === "Fail"){
        Swal.fire({
          text: res["message"],
          icon: "error",
          width: 500,
        });
      }
    })
  }else{
    this.alertcall.showWarning('Error', 'Please Enter the details of Company Name and Audit Number');
  }

}
//download excel
downLoadExcel() {
  let params = new HttpParams()
  params = params.set("getExcel","getExcel")
  if(Object.values(this.selectedAuditRId).length !==0){
    params = params.set("_id",this.selectedAuditRId['_id'])
    this.custservice.getTotalAuditValues(params).subscribe((res:any) =>{
      if (res.status === "success") {
        saveAs(res.location,"downLoad_file.xlsx")
      }
      if(res.status === "Fail"){
        Swal.fire({
          text: res["message"],
          icon: "error",
          width: 500,
        });
      }
  
    })
  }else{
    this.alertcall.showWarning('Error', 'Please Enter the details of Company Name and Audit Number');
  }

}

// approvers 
addApprover(event: MatAutocompleteSelectedEvent): void {
  this.selectedApprovers.push(event.option.value);
}

removeApprover(approver: any): void {
  const index = this.selectedApprovers.indexOf(approver);
  if (index !== -1) {
    this.selectedApprovers.splice(index, 1);
  }
}
// checkAndAdd(event: MatChipInputEvent): void {
//   const input = event.input;
//   const value = event.value;
//   this.filteredOptions.pipe(take(1)).subscribe((options: FilteredOption[]) => {
//     const existsInOptions = options.some(option => option.username === value);
//     const isDuplicate = this.selectedApprovers.includes(value);
//   if (!existsInOptions) {
//     // this.alertcall.showWarning('Error','Please select an option from the filtered list.');
//     if (input) {
//       input.value = ''; 
//     }
//   } else
//    if (isDuplicate) {
//       this.alertcall.showWarning('Error', 'Duplicate approvers are not allowed.');
//       if (input) {
//         input.value = '';
//       }
//   } else {
//     this.add(event);
//   }
// })
// }

checkAndAdd(event: MatChipInputEvent): void {
  const input = event.input;
  const value = event.value;

  if (value.trim()) {
    this.filteredOptions.pipe(take(1)).subscribe((options: FilteredOption[]) => {
      const existsInOptions = options.some(option => option.username === value);
      const isDuplicate = this.selectedApprovers.includes(value);

      if (existsInOptions && !isDuplicate) {
        this.add(event);
      } else if (!existsInOptions) {
        this.alertcall.showWarning('Error', 'Please select an option from the filtered list.');
      } else if (isDuplicate) {
        this.alertcall.showWarning('Error', 'Duplicate approvers are not allowed.');
      }

      if (input) {
        input.value = ''; 
      }
    });
  }
}


add(event: MatChipInputEvent): void {
  const input = event.input;
  const value = event.value;
  // Add our fruit
  if ((value || '').trim()) {
    this.fruits.push(value.trim());
    this.selectedApprovers.push(value.trim())
  }
  // Reset the input value
  if (input) {
    input.value = '';
  }

  this.fruitCtrl.setValue(null);
  this.myControl.setValue(null)
}

remove(fruit: string): void {
  const index = this.fruits.indexOf(fruit);
  const ind = this.selectedApprovers.indexOf(fruit)
  if (index >= 0 || ind > 0) {
    this.fruits.splice(index, 1);
    this.selectedApprovers.splice(ind, 1)
  }
}

selected(event: MatAutocompleteSelectedEvent): void {
  const selectedValue = event.option.viewValue.trim();
  if (selectedValue === "No users found.") {
    this.fruitInput.nativeElement.value = '';
    this.myControl.setValue('');
    return;
  }
  var username=event.option.viewValue.split("|");
  if (!this.selectedApprovers.includes(username[0])) {
  this.fruits.push(username[0]);
  this.selectedOption
    this.selectedApprovers.push(username[0])
    this.selectedApprovers1.push(username)
    this.formdata.approvers = this.selectedApprovers
}
else {
  this.alertcall.showWarning('Error', 'Duplicate approvers are not allowed.');
}
  this.fruitInput.nativeElement.value = '';
  this.fruitCtrl.setValue(null);
  this.myControl.setValue(null)
}

// getApprovers(value: any) {
//   if(value.length > 2){
//     let params = new HttpParams();
//     params = params.set('userName',value)
//     this.custservice.getSearchUsers(value).subscribe((res: any) => {
//       this.fetchedApprovers = res.data      
//       this.filteredOptions = this.myControl.valueChanges
//         .pipe(
//           startWith(''),
//           map(value => typeof value === 'string' ? value : value),
//           map(name => name ? this._filter(name) : this.fetchedApprovers.slice())
//         );
//     })
//   }
//   else{
//     this.filteredOptions =this.myControl.valueChanges
//     this.fetchedApprovers = [];
//   }
// }

getApprovers(value: any) {
  if (value.length > 2) {
    this.approverSearchSubject.next(value);
  } else {
    this.fetchedApprovers = [];
    this.filteredOptions = this.myControl.valueChanges
  }
}

fetchApprovers(value: string) {
  let params = new HttpParams();
  params = params.set('userName', value)
  this.custservice.getSearchUsers(value).subscribe((res: any) => {
    this.fetchedApprovers = res.data;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value),
      map(name => name ? this._filter(name) : this.fetchedApprovers.slice())
    );
  });
}
private _filter(value: string): FilteredOption[] {
  const filterValue = value.toLowerCase();
  return this.fetchedApprovers.filter((approver: any) => approver.username.toLowerCase().includes(filterValue))
}
submitData(fr: any) {
  if(fr.invalid){
    return
  }
  // let companyName: any;
  this.companyData11.find((ele: any) => {
    if (ele.company_name == this.AuditCompanyNamec) {
      this.AuditCompanyIdc = ele.company_name
    }
  })
  let formData = new FormData()
  formData.append("companyName", this.AuditCompanyNamec)
  formData.append("companyId", this.AuditCompanyCodec)
  formData.append("auditName", this.AuditNamec)
  // formData.append("approvers", this.formdata.approvers)
  formData.append("file", this.selectedAuditFile)
  console.log(this.selectedAuditFile,'@@@@@@@@@@@@@@@@@@@@@@@@@@@');
  
 if(this.selectedAuditFile !== undefined){ 
  this.custservice.uploadAuditDocument(formData).subscribe((res: any) => {
    if (res.status === "Success") {
      this.modalData._id = res.data._id
      Swal.fire({
        text: res['message'],
        title: res['auditName'],
        icon: "success",
        width: 500,
      });
      this.auditnum = res.data;
      this.addApproversData()
      this.AuditCompanyNamec=""
      this.AuditCompanyCodec=""
      this.AuditNamec=""
      fr.resetForm();
      // this.selectedCompany = ""
      // this.autoFileUpload.nativeElement.value = null
      this.selectedFileName = ""
      this.auditApproverslist=""
      this.selectedApprovers = []
      this.selectedfiles = []
      this.filenamearray = []
    } else if (res.status === 'Fail') {
      Swal.fire({
        text: res["message"],
        icon: "error",
        width: 500,
      });
    }
  })
}else{
  this.alertcall.showWarning('Error', 'Please Attach the file');
}
}
uploadgifiles(fileInput: any) {
  if (fileInput && fileInput.target && fileInput.target.files && fileInput.target.files.length > 0 ) {
    const uploadedFile = fileInput.target.files[0];
    this.fileUploadUrlsqc = uploadedFile;
    this.selectedAuditFile = uploadedFile;
    this.selectedFileName = uploadedFile.name;
    this.createNewFile.fileName = uploadedFile.name;
    const fileURL = URL.createObjectURL(uploadedFile);
    this.filenamearray = [uploadedFile.name]; 
    this.selectedfiles = [uploadedFile]; 
    console.log(this.selectedfiles,fileURL);
  }
}


// uploadgifiles(fileInput: any) {
  // this.selectedAuditFile = ev.target.files[0]
  // this.selectedFileName = ev.target.files[0].name
  // if (fileInput && fileInput.target && fileInput.target.files && fileInput.target.files.length > 0 ) {
  //   this.fileUploadUrlsqc = fileInput.target.files;
  //   this.selectedAuditFile = fileInput.target.files[0]
  //   this.selectedFileName = fileInput.target.files[0].name
  //   // this.fileUploadUrlsqc = fileInput.target.files[0];
  //   this.createNewFile.fileName = fileInput.target.files[0].name;
  //   const uploadedFile = fileInput.target.files[0];
  //   const fileURL = URL.createObjectURL(uploadedFile);
  //     // this.srcpdf = fileURLs
  //   // this.srcpdf = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
  //   for (const file of this.fileUploadUrlsqc) {
  //     this.filenamearray.push(file.name)
  //     this.selectedfiles.push(file)
  //     console.log(this.selectedfiles);
  //   }
  // }
// }
addApproversData() {
  let selectedUsers: any = []
  this.selectedApprovers1.forEach((ele: any) => {
    selectedUsers.push({ userName: (ele[0] || ele.userName), email: ele[1].trim(), approveStatus: false, date: moment(new Date()).format("YYYY-MM-DD")})
  })
  let obj = {
    "auditId": this.auditnum,
    "approvers": selectedUsers
  }
  this.custservice.addApprovers(obj).subscribe((res: any) => {
    if (res.status === "Success") {
      selectedUsers=[]
      this.formdata.approvers = []
      this.selectedApprovers = []
      this.selectedfiles = []
      this.filenamearray = []
      this.selectedAuditFile=undefined
      this.getAuditDashboardData();
      this.selectedApprovers1 = [];
    }
  })
}
toggleAllSelectiondmr() {
  if (this.allSelecteddmr) {
    this.selectdmr.options.forEach((item: MatOption) => item.select());
  } else {
    this.selectdmr.options.forEach((item: MatOption) => item.deselect());
  }
}
optionClickdmr() {
  this.auditNameD=""
  let newStatusdmr = true;
  this.selectdmr.options.forEach((item: MatOption) => {
    if (!item.selected) {
      newStatusdmr = false;
    }
  });
  this.allSelecteddmr = newStatusdmr;
  // this.onCompanyOptionSelectedD();
  this.getPlantWiseData();
  this.fetchAuditDData();
}

openfileuploadmodel1(data: any) {
  this.dialog.open(data, {
    width: '800px'
  })
}
deleteFileItem(index:any){
      this.selectedfiles = []
      this.filenamearray = []
      this.selectedAuditFile=undefined
  this.selectedfiles.splice(index,1)
 }
 closemodel(){
  this.dialog.closeAll();
 }
 @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
 SampleFileDownload() {
   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
   ws['!rows'] = [];
   ws['!rows'][0] = { hidden: false };
   ws['!rows'][1] = { hidden: false };
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   XLSX.writeFile(wb, `SampleFile.xlsx`);
 }
// reject
 openDialog(data:any){
  this.dialog.open(data, {
    width: '400px'
  })
  this.rejectModel.reason = "";
 }
 rejectData(){
  let obj={
    key: 'REJECT',
    auditId: this.selectedAuditRId['_id'],
    reason: this.rejectModel.reason,
    action: 'REJECTED',
  }
  this.custservice.auditComplete(obj).subscribe((res:any)=>{
    if(res.status === "Success"){
      this.alertcall.showSuccess("Accepted",res['message']);
      this.dialog.closeAll();
      this.rejectModel.reason="";
      this.onAuditNameSelect();
    }
  })
 }
//  Approve
 openDialog1(data:any){
  this.dialog.open(data, {
    width: '400px'
  })
  this.approveModel.reason = "";
 }
 approveData(){
  let obj={
    key: 'APPROVE',
    auditId: this.selectedAuditRId['_id'],
    reason: this.approveModel.reason,
    action: 'APPROVED',
  }
  this.custservice.auditComplete(obj).subscribe((res:any)=>{
    if(res.status === "Success"){
      this.alertcall.showSuccess("Accepted",res['message']);
      this.dialog.closeAll();
      this.approveModel.reason="";
      this.onAuditNameSelect();
    }
  })
 }

  auditNumberSelected(){
    this.pageIndexAuditlist=1
    this.getAuditDashboardData();
  }
}
