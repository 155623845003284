import { AuthService } from "./../../auth/auth.service";
import { CustomerService } from "./../../services/customer.service";
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import { GreenkoUtils } from "src/app/utils/flip.utils";
import Swal from "sweetalert2";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { filter, map, startWith } from "rxjs/operators";
import { Base64 } from "js-base64";
@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  @ViewChild(RouterOutlet, { static: true }) outlet!: RouterOutlet;
  currentComponent: any = null;


  filteredOptions: Observable<any[]>;
  myControl = new FormControl();

  // VersiondropdownData:any=[{menu:"Inventory", trigger:"v1Inventory"},{menu:"Reports",trigger:"v1reports"},{menu:"GSS",trigger:"v1gss"},{menu:"C&P",trigger:"v1cp"}];
  public config: PerfectScrollbarConfigInterface = {};

  userDetails: any = {};
  searchValue: any;
  showMe = false;
  showSearch = false;
  showNavbar = false;
  totalRecords: any = 0;
  pageIndex: any = 1;
  pageSize: any = 10;
  iframeSrc: string;
  stimer: any;
  currentTime: any;
  diffTime: any;
  logoutTime: any;
  hrs: number = 0;
  sec: number = 0;
  min: number = 0;
  Displaytimer: any;
  chattoken: any;
  amgreen: boolean;
  logourl: any;
  isshowLogo: string;
  logoText: any;
  mainLogo: string;
  amGreenEnv1: boolean;
  isnonGroup:any;
  userdetails: any;
  projectId: any;
  projectsData: any[]=[];
  selectedProject: any;
  selectedProj:any;
  resp: any;
  emailVerifiers: any={};
  validdmsUser: any[]=[];
  selectedNav: string;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public customerService: CustomerService,
    public authService: AuthService,
    public alertCall: AlertCallsService,
    public utils: GreenkoUtils,
    private sanitizer: DomSanitizer,
    private keycloackService: KeycloakService
  ) {
    this.amGreenEnv1 = environment.amGreen
    let menurole:any = localStorage.getItem("user-menu-role")
    if(environment.amGreen && JSON.parse(menurole).includes("SUPER_ADMIN")){
      this.amGreenEnv = false;
    }else{
      this.amGreenEnv = environment.amGreen
    }
    // this.router.events.subscribe((res) => {
    //   const urlWithoutFragment = this.router.url.split('#')[0];
    //   if (this.router.url === "/maindashboard" || urlWithoutFragment === "/maindashboard" || this.router.url === "/maindashboard/details" ||
    //     this.router.url === "/maindashboard/folders" ||  this.router.url === "/issuelog" || this.router.url === "/emails" || this.router.url === "/maindashboard/pmc_folders"
    //   || this.router.url === "/maindashboard/externalDrawings" || this.router.url === "/maindashboard/projectDashboard" || this.router.url === "/dms/dmsVendor" || this.router.url === "/dms/dmscreatefolder"
    //   || this.router.url === "/logistics/" 
    //   ) {
    //     this.showSearch = true;
    //     this.searchValue = null;
    //   } else {
    //     this.showSearch = false;
    //     this.searchValue = null;
    //   }

    // });    
    this.router.events.subscribe((event) => {
      const option:any  =localStorage.getItem('Option')
    this.selectedNav = option

    const projData: any = localStorage.getItem('selectedProject'); 
    if (projData && projData !== 'undefined' && projData !== '') {
      const seletedProjed =JSON.parse(projData)
      this.selectedProject = seletedProjed
      this.selectedProj =seletedProjed?.proj_short_name
    }
      if (event instanceof NavigationEnd) {
        // Split the URL at the '?' and use the first part
        const baseUrl = this.router.url.split('?')[0].split('#')[0];
        
        this.updateSearchVisibility(baseUrl);
      }
    });
  }
  private updateSearchVisibility(url: string) {
   
    const searchRoutes = [
      "/maindashboard",
      "/maindashboard/details",
      "/maindashboard/folders",
      "/maindashboard/submenu",
      "/issuelog",
      "/emails",
      "/maindashboard/pmc_folders",
      "/maindashboard/externalDrawings",
      "/maindashboard/projectDashboard",
      "/maindashboard/projectscurve",
      "/maindashboard/projectabstract",
      "/logistics",
      "/dms/dmsVendor",
      "/dms/dmscreatefolder",
    ];
    const isSearchRoute = searchRoutes.includes(url) || url.includes('/logistics') || url.includes('/dms');
    if (isSearchRoute) {
      this.showSearch = true;
      this.searchValue = null;
    } else {
      this.showSearch = false;
      this.searchValue = null;
      this.selectedProject=''
      this.selectedNav=''
      this.selectedProj=''
      localStorage.removeItem('Option');
      localStorage.removeItem('selectedProject');
    }


    if (url === '/maindashboard') {
      this.searchValue = null;
      this.selectedProject = '';   
      this.selectedNav = '';       
      this.selectedProj = '';
      localStorage.removeItem('Option');
      localStorage.removeItem('selectedProject');
    }
  }
  toggleNavBar() {
    this.showNavbar = !this.showNavbar;
    this.showMobileMenu = !this.showMobileMenu;
  }

  tabStatus = "justified";

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  activities$: any = [];

  options = {
    theme: "light", // two possible values: light, dark
    dir: "ltr", // two possible values: ltr, rtl
    layout: "vertical", // fixed value. shouldn't be changed.
    sidebartype: "full", // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: "fixed", // two possible values: fixed, absolute
    headerpos: "fixed", // two possible values: fixed, absolute
    boxed: "full", // two possible values: full, boxed
    navbarbg: "skin6", // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: "skin6", // six possible values: skin(1/2/3/4/5/6)
    logobg: "skin6", // six possible values: skin(1/2/3/4/5/6)
  };

  applications$: any = [];
  tokenValue: any;
  decodedTokenValue: any;
  companyName: any;
  tokenData: any[] = [];
  urlName: any[] = [];
  nongroupedCompanyNames: boolean = true;
  amGreenEnv:boolean;
  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.getCurrentComponent();
    });
    this.nonGroupedCompanies();
    this.defaultSidebar = this.options.sidebartype;
    this.getActivity();
    // this.userDetails = this.authService.currentUserDetails;
    this.userDetails = this.keycloackService.getKeycloakInstance().tokenParsed
    // this.getUserProjects();
    this.handleSidebar();
   
    this.searchValue = null;
    if(this.tokenValue){
      this.tokenValue = localStorage.getItem('token');    
      this.decodedTokenValue =JSON.parse(atob(this.tokenValue));
      this.tokenData = this.decodedTokenValue.data
      this.companyName = this.decodedTokenValue.data.company_name
      this.chattoken= this.decodedTokenValue.data.token
      this.iframeSrc = "https://chat.intellaire.com/#/login?name=chatapp&access_token=" + this.chattoken +"&callFrom=iframe"
      // console.log(this.iframeSrc);
      this.startTimer()
    }
    
    }
  
    ngAfterViewInit() {
      // Listen to route changes
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.getCurrentComponent();
      });
    }
  
    getCurrentComponent() { 
      if (this.outlet && this.outlet.isActivated) {
        this.route.queryParams.subscribe((params: any) => {
          // this.selectedProj = params.name || this.selectedProj;  
          // this.selectedNav = params.type || this.selectedNav; 
          if(params.name === undefined){
            const projData: string | null= localStorage.getItem('selectedProject');
            if (projData && projData !== 'undefined' && projData !== '') {
              try {
                const selectedProject = JSON.parse(projData);
                this.selectedProject = selectedProject;
                this.selectedProj = selectedProject?.proj_short_name;
              } catch (error) {
                console.error('Error parsing selectedProject from localStorage:', error);
                this.selectedProj = '';
              }

            }
          }else{
            this.selectedProj = params.name || this.selectedProj;  
          }
          if (params.type) {
            this.selectedNav = params.type || this.selectedNav; 
          } else {
            if (this.router.url.includes('logistics')) {
              this.selectedNav = 'Logistics';
              const projtData:any= localStorage.getItem('projectDetails');
            if (projtData && projtData !== 'undefined' && projtData !== '') {
                const selectedProjects = JSON.parse(projtData);
                this.selectedProj = selectedProjects?.proj_short_name ;
             }
            } 
          }
        });
      }
    } 
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
      case "iconbar":
        if (this.innerWidth < 1170) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case "overlay":
        if (this.innerWidth < 1400) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case "full":
      case "iconbar":
        this.options.sidebartype = "mini-sidebar";
        break;

      case "overlay":
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case "mini-sidebar":
        if (this.defaultSidebar === "mini-sidebar") {
          this.options.sidebartype = "full";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  getUserProjects() {
    this.customerService.getUserProjects().subscribe((resp: any) => {
      if (resp && resp.success === 1) {
        this.applications$ = resp.details;
      } else {
        this.applications$ = [];
        // this.logOut();
      }
    });
  }

  getActivity() {
    let obj = {
      lmt: this.pageSize,
      pid: this.pageIndex,
    };
    this.customerService.getProjectActivity(obj).subscribe((resp: any) => {
      if (resp.status_code === 200) {
        this.activities$ = resp.data;
        this.totalRecords = resp.count;
        this.loadProjects()
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      }
    });
  }
  

  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivity();
  }
  // async logOut() {
  //   // this.router.navigateByUrl('**');

  //   const url: any = localStorage.getItem("redirect_uri");
  //   this.removeLocalItem("authentication");
  //   this.removeLocalItem("ip");
  //   this.removeLocalItem("currentUser");
  //   this.removeLocalItem("redirect_appurl");
  //   this.removeLocalItem("token");
  //   this.removeLocalItem("user-menu-role");
  //   this.removeLocalItem("user-project-role");
  //   this.removeLocalItem("redirect_uri");
  //   localStorage.clear();
  //   this.keycloackService.clearToken();


  //   let clients:any = environment.clients;
  //   let origin = window.location.hostname;//  window.origin.split('.', 1)
  //   let client =  '';
  //   let clientObj:any = {}
  //   if (origin && origin.includes('.')) {
  //     let clientarr = origin.split('.', 1)
  //     client = clientarr.length ? clientarr[0] :"" 
  //   }
  //   clientObj = clients[client]
  
  //   if (clients[client] && Object.keys(clientObj).length) {
  //     await this.keycloackService.logout(clientObj.redirectUri);

  //   }else{

  //     await this.keycloackService.logout("http://localhost:9201/login");
  //   }
  //   // localStorage.clear();
  //   // window.location.href = url + "/logout";
  // }
  async logOut() {
    // this.router.navigateByUrl('**');

    // const url: any = localStorage.getItem("redirect_uri");
    this.removeLocalItem("authentication");
    this.removeLocalItem("ip");
    this.removeLocalItem("currentUser");
    this.removeLocalItem("redirect_appurl");
    this.removeLocalItem("token");
    this.removeLocalItem("user-menu-role");
    this.removeLocalItem("user-project-role");
    this.removeLocalItem("redirect_uri");
    localStorage.clear();

    // let clients: any = environment.clients;
    let origin = window.location.hostname; //  window.origin.split('.', 1)
    let client = "";
    if (origin && origin.includes(".")) {
      let clientarr = origin.split(".", 1);
      client = clientarr.length ? clientarr[0] : "";
    }
    // clientObj = clients[client];
    this.keycloackService.clearToken();
    // await this.keycloackService.logout().then((res:any)=>{
    // }).catch((err:any)=>{
    // })
    if (client ) {
      await this.keycloackService.logout("https://"+origin);
    } else {
      await this.keycloackService.logout("http://localhost:9201/login");
    //    await this.keycloackService.logout().then((res:any)=>{
    //   console.log("logged out");
    // }).catch((err:any)=>{
    // })
    }
    // localStorage.clear();
    // window.location.href = url + "/logout";
  }
  public removeLocalItem(key: any) {
    localStorage.removeItem(key);
  }
  openNewApplication(data: any) {
    
    if (data) {
      const url =
        data.app_url +
        "?code=5&response_type=" +
        localStorage.getItem("authentication") +
        "?redirect_uri=" +
        data.app_url;
      window.location.href = url;
    }
  }

  filterProjects() {
    // this.utils.searchText.emit(this.searchValue);
    this.utils.searchText.emit(this.selectedProj);
  }

  sideMenuClick() {
    if (this.options.sidebartype === "mini-sidebar") {
      this.showMobileMenu = !this.showMobileMenu;
    }
  }

  //routing links
  // version1 -> Inventory
  dmrrouting() {
    this.router.navigate(["/inventory/dmr"]);
  }
  qualitycheckrouting() {
    this.router.navigate(["/inventory/qualitycheck"]);
  }
  goodsreceiptrouting() {
    this.router.navigate(["/inventory/goodsreceipt"]);
  }
  goodsissuerouting() {
    this.router.navigate(["/inventory/goodsissue"]);
  }
  goodsreturnrouting() {
    this.router.navigate(["/inventory/goodsreturn"]);
  }
  deliverychallanrouting() {
    this.router.navigate(["/inventory/deliverychallan"]);
  }
  outgatepassrgprouting() {
    this.router.navigate(["/inventory/outgatepassrgp1"]);
  }
  outgatepassnrgprouting() {
    this.router.navigate(["/inventory/nrgp"]);
  }
  esdnrouting() {
    this.router.navigate(["/inventory/esdn"]);
  }
  storagelocationrouting() {
    this.router.navigate(["/inventory/storagelocation"]);
  }
  auditrouting(){
    this.router.navigate(["/inventory/audit"]);
  }
  dmrbulkupdate(){
    this.router.navigate(["/inventory/dmrbulkupdate"]);
  }
  qrprintdmr(){
    this.router.navigate(["/inventory/qrprint-dmr"]);
  }
  qrcodegenerator(){
    this.router.navigate(["/inventory/qrcodegenerator"]);
  }
  //version1 -> Reports
  inventoryreportsrouting() {
    this.router.navigate(["/reports/ir"]);
  }
  gssreportsrouting() {
    this.router.navigate(["/reports/gss_reports"]);
  }
  transitreportsrouting() {
    this.router.navigate(["/reports/transitreports"]);
  }
  //version1 -> gss
  gateinwardrouting() {
    this.router.navigate(["/gss/gate_inward"]);
  }
  gateoutwardrgprouting() {
    this.router.navigate(["/gss/gate-outward-rgp"]);
  }
  gateoutwardnrgprouting() {
    this.router.navigate(["/gss/gate-outward-nrgp1"]);
  }
  gateoutwardgirouting() {
    this.router.navigate(["/gss/gate-outward-gi"]);
  }
  //version1 -> c&p
  purchaseorderrouting() {
    this.router.navigate(["/cp/purchase_order"]);
  }
  purchaseorderboqrouting(){
    this.router.navigate(["/cp/po-boq"]);
  }
  exceluploadrouting() {
    this.router.navigate(["/cp/po-excel-upload"]);
  }

  //version1 -> RMC
  rmcgoodsreceiptrouting(){
    this.router.navigate(["/rmc/rmcgoodsreceipt"]);
  }
  rmcgoodsissuerouting(){
    this.router.navigate(["/rmc/rmcgoodsissue"]);
  }
  rmcgoodsreturnrouting(){
    this.router.navigate(["/rmc/rmcgoodsreturn"]);
  }
  rmcissuerouting(){
    this.router.navigate(["/rmc/rmcissue"]);
  }

  // version1 -> Reusable Items
  reusableItemInwardrouting(){
    this.router.navigate(["/reusableitems/reusableitemsinward"])
  }
  reusableItemoutwardrouting(){
    this.router.navigate(["/reusableitems/reusableitemsoutward"])
  }
  reusableItemreturnrouting(){
    this.router.navigate(["/reusableitems/reusableitemsreturn"])
  }
  //version2-> planing
  v2goodsissuerequestrouting() {
    this.router.navigate(["/planning2/goodsissuerequest2"]);
  }

  //version2 -> gss
  v2gateinwardrouting() {
    this.router.navigate(["/gss2/gate-inward2"]);
  }
  v2gateoutwardrgprouting() {
    this.router.navigate(["/gss2/gate-outward-rgp2"]);
  }
  v2gateoutwardnrgprouting() {
    this.router.navigate(["/gss2/gate-outward-nrgp2"]);
  }
  v2gateoutwardgirouting() {
    this.router.navigate(["/gss2/gate-outward-gi2"]);
  }

  //version2 ->reports
  v2inventoryreportsrouting() {
    this.router.navigate(["/reports2/inventoryreports2"]);
  }
  v2gssreportsrouting() {
    this.router.navigate(["/reports2/gssreports2"]);
  }

  materialmasterrouting() {
    this.router.navigate(["/material"]);
  }
  vendormasterrouting() {
    this.router.navigate(["/material/vendor"]);
  }
  contractorsrouting() {
    this.router.navigate(["/material/contractors"]);
  }
  companiesrouting() {
    this.router.navigate(["/material/companys"]);
  }
  taxlistrouting() {
    this.router.navigate(["/material/tax"]);
  }
  financialrouting() {
    this.router.navigate(["/material/financeyear"]);
  }
  servicemasterrouting() {
    this.router.navigate(["/material/servicemaster"]);
  }
  vehiclemasterrouting() {
    this.router.navigate(["/material/vehiclemaster"]);
  }

  plantmasterrouting(){
    this.router.navigate(["/material/plantmaster"]);
  }
  projectmasterrouting(){
    this.router.navigate(["/material/projectmaster"]);
  }
  costcenterrouting(){
    this.router.navigate(["/material/costcenter"]);
  }
  verticalmasterrouting(){
    this.router.navigate(["/material/verticalmaster"]);
  }
  equipmentmasterrouting(){
    this.router.navigate(["/material/equipmentmaster"]);
  }
  designmixmasterrouting(){
    this.router.navigate(["/material/designmixmaster"]);
  }
  clustermasterrouting(){
    this.router.navigate(['/material/clustermaster']);
  }
  authorizationmasterrouting(){
    this.router.navigate(['/material/authorizationchainmas']);
  }
  storagelocationsrouting(){
    this.router.navigate(['/material/storagelocations']); 
  }
  businessunitrouting(){
    this.router.navigate(['/material/businessunit'])
  }
  ticketrouting() {
    this.router.navigate(["/tickets"]);
  }
  galleryrouting() {
    this.router.navigate(["/gallery"]);
  }
  userprofilerouting() {
    this.router.navigate(["/userprofiles"]);
  }
  pendingsignaturesrouting() {
    this.router.navigate(["/pending-signatures"]);
  }
  autotriggeremailrouting(){
    this.router.navigate(["/autotriggeremail/autotriggeremails"]);
  }
  usermanagementrouting() {
    this.router.navigate(["/users"]);
  }
  userprofileswbsrouting() {
    this.router.navigate(["/projectwbs"]);
  }
  menurolesrouting() {
    this.router.navigate(["/menus"]);
  }
 
  settingsrouting() {
    this.router.navigate(["/settings"]);
  }
  projectsrouting() {
    this.router.navigate(["/maindashboard"]);
  }
  gamdashboardrouting() {
    this.router.navigateByUrl("/GamsDashboard");
  }
  projectdashboardrouting() {
    this.router.navigateByUrl("/projectdashboard");
  }
  poreportsrouting() {
    this.router.navigateByUrl("/reports/poreports");
  }
  v1purchaserequestrouting() {
    this.router.navigateByUrl("/planning/purchaserequest");
  }
  v1goodsIssueRequestrouting(){
    this.router.navigateByUrl("/planning/materialreqRequest");
  }
  // v1addpurchaserequestrouting(){
  //   this.router.navigateByUrl('/planning/addpurchaserequest');
  // }
  v1workrequestrouting() {
    this.router.navigateByUrl("/planning/workrequest");
  }
  v1workorderrouting() {
    this.router.navigateByUrl("/cp/workorder");
  }
  v1manrouting(){
    this.router.navigateByUrl("/cp/man");
  }
  v1workordersupplyrouting(){
    this.router.navigateByUrl("/cp/workordersupply");
  }
  prreportsrouting() {
    this.router.navigateByUrl("/reports/prreports");
  }
  rmcreportsrouting(){
    this.router.navigateByUrl("/reports/rmcreports");
  }
  nfareportsrouting(){
    this.router.navigateByUrl("/reports/nfareports");
  }
  esdnreportsrouting(){
    this.router.navigateByUrl("/reports/esdnreports");
  }
  machineryreports(){
    this.router.navigateByUrl("/reports/machineryreports")
  }
  mosreports(){
    this.router.navigateByUrl("/reports/mosreports")
  }
 
  reusableitemsreportsrouting(){
    this.router.navigateByUrl("/reports/reusableitemsreports");
  }
  storesdashboardrouting() {
    this.router.navigateByUrl("/inventory/storesdashboard");
  }
  gamsdashboardrouting(){
    this.router.navigateByUrl("/inventory/gamdashboard")
  }
  v1authorizationchainrouting(){
    this.router.navigateByUrl("/planning/authorizationchain");
  }
  documentapprovalsrouting(){
    this.router.navigateByUrl("/documentapprovals/mypendingapprovals");
  }
  cmpdocumentapprovalsrouting(){
    this.router.navigateByUrl("/documentapprovals/mycompletedapprovals");
  }
  nfadocuments() {
    this.router.navigateByUrl("/planning/nfa");
  }
  billAprrovals() {
    this.router.navigateByUrl("/planning/billapprovals");
  }
    
  VerifySecureUrl(url: string) {
    // alert(url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    
  }
  machineryrouting(){
    this.router.navigateByUrl("/machinery/vehiclelog");
  }
  materialorderstatusrouting(){
    this.router.navigateByUrl("/materialorderstatus/mosmodule");
  }

  //timer
  async CountDownTime() {
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    await sleep(1000);
    let val = this.logoutTime;
    (this.hrs = 0), (this.min = 0), (this.sec = 0);
    let val2 = val;
    if (val2 >= 3600000) {
      this.hrs = Math.floor(val2 / 3600000);
      val2 = val2 - this.hrs * 3600000;
    }
    if (val2 < 3600000 && val2 >= 60000) {
      this.min = Math.floor(val2 / 60000);
      val2 = val2 - this.min * 60000;
    }
    if (val2 < 60000) {
      this.sec = Math.floor(val2 / 1000);
    }
    val = val - 1000;
  }
  state: any = false;
  startTimer() {
    this.stimer = localStorage.getItem("StartTime");
    let sTime1 = Date.parse(this.stimer);
    this.currentTime = new Date();
    let sTime2 = Date.parse(this.currentTime);
    let sTimerCount = sTime1 + 10800000;
    this.logoutTime = sTimerCount - sTime2;
    this.CountDownTime();
    // console.log(String(this.hrs).padStart(2, '0'),String(this.min).padStart(2, '0'),String(this.sec).padStart(2, '0'));

    this.Displaytimer =
      String(this.hrs).padStart(2, "0") +
      ":" +
      String(this.min).padStart(2, "0") +
      ":" +
      String(this.sec).padStart(2, "0");
    // this.hrs + ":" + this.min + ":"+this.sec
    setTimeout(() => {
      if (!this.state) {
        this.startTimer();
      }
      if (this.hrs === 0 && this.min === 10 && this.sec === 0) {
        Swal.fire({
          icon: "warning",
          title: "warning",
          text: "Your Session Expires in 10 Minutes",
        });
      } else if (this.hrs === 0 && this.min === 0 && this.sec <= 0) {
        localStorage.clear();
        this.state = true;
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Session Expired, Its Time to logout",
        });
        // let url = localStorage.getItem("redirect_uri");
        // window.location.href = url + "/logout";
      }
    }, 1000);
  }

  // nonGroupedCompanies() {
  //   let clients:any = environment.clients;
  //   let origin = window.location.hostname;
  //   let client =  '';
  //   let clientObj:any = {}
  //   if (origin && origin.includes('.')) {
  //     let clientarr = origin.split('.', 1)
  //     client = clientarr.length ? clientarr[0] :"" 
  //   }
  //   clientObj = clients[client]  
  //   if (clients[client] && Object.keys(clientObj).length) {
  //     this.urlName[0]=clientObj.realm
  //     this.nongroupedCompanyNames = false;
  //   }
  //   if (this.nongroupedCompanyNames) {
  //       this.urlName[0] = "Greenko";
  //     }
  //   // const currentUrl = window.location.hostname;
  //   // this.urlName = currentUrl.split(".", 1);
  //   // console.log(this.urlName[0]);
  //   // environment.clients.forEach((ele: any) => {
  //   //   if (ele == this.urlName[0]) {
  //   //     this.urlName[0] = ele;
  //   //     this.nongroupedCompanyNames = false;
  //   //   }
  //   // });
  //   // if (this.nongroupedCompanyNames) {
  //   //   this.urlName[0] = "Greenko";
  //   // }
  // }

  nonGroupedCompanies() {
    // let clients: any = environment.clients;
    let origin = window.location.hostname;
    if(environment.logoimageUrl == ""){
      var companyName:any = origin.split(".", 1)[0];
      var companyNames= Object.keys( environment.clients )
      this.logoText = companyNames.includes(companyName) ? companyName : '' 
    }
    this.isnonGroup=environment.isnonGroup
    this.mainLogo = environment.logoimageUrl ? environment.logoimageUrl : ''
    // if (origin && origin.includes(".")) {
    //   let clientarr = origin.split(".", 1);
    //   client = clientarr.length ? clientarr[0] : "";
    // }
    // // clientObj = clients[client];
    // if (client && !(client == 'testgeps' || client=='gepsv2')) {
    //   this.urlName[0] =client;
    //   this.nongroupedCompanyNames = false;
    // }

    // if (this.nongroupedCompanyNames) {
    //   this.urlName[0] = "Greenko";
    // }
    // if(client && (origin.split(".", 1)[0] === "dms" && origin.split(".", 2)[1] === "amgreen")){
    //   this.amgreen=true
    // }
    // const currentUrl = window.location.hostname;
    // this.urlName = currentUrl.split(".", 1);
    // console.log(this.urlName[0]);
    // environment.clients.forEach((ele: any) => {
    //   if (ele == this.urlName[0]) {
    //     this.urlName[0] = ele;
    //     this.nongroupedCompanyNames = false;
    //   }
    // });
    // if (this.nongroupedCompanyNames) {
    //   this.urlName[0] = "Greenko";
    // }
  }
  openDropdown() {
    this.myControl.setValue(this.myControl.value || '');
  }
  loadProjects() {
      this.userdetails =this.keycloackService.getKeycloakInstance().tokenParsed
      
      if (this.userdetails) {
        const params = new HttpParams()
          .set('email', this.userdetails.email)
          .set('role', this.userdetails.resource_access?.geps?.roles[0]);
      this.customerService.getUserListProjects(params).subscribe((resp: any) => {
        if (resp.status_code === 200) {
          this.projectsData = resp.data;
          
        }
      });
    }
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.projectsData.filter((option:any) =>
      option.proj_short_name.toLowerCase().includes(filterValue)
    );
  }
  onProjectSelected(project: any) {
    this.selectedProject=''
    if (project) {
          const obj = this.projectsData.find((x:any) => x.proj_short_name === project);
          if (obj) {
            this.selectedProject = obj; 
          }
        } else {
          this.selectedProject = null;
        }
    this.onOptionChange(this.selectedNav);
    
  }
  onOptionChange(event: any) {
    // const selectedOption = (event.target as HTMLSelectElement).value;
    if(this.selectedProject == null || this.selectedProject == undefined || this.selectedProject == ''){
      this.onProjectSelected(this.selectedProj)
    }
    const selectedOption =event
    this.navigateToRoute(selectedOption);
  }
  
  navigateToRoute(option: string) {
    localStorage.setItem('Option',option)
    localStorage.setItem('selectedProject',JSON.stringify(this.selectedProject))
    let item = this.selectedProject ;

    const baseQueryParams = { id: item.proj_id, start: item.plan_start_date, end: item.scd_end_date,name: item.proj_short_name,type:option };
    switch (option) {
        case 'Project_WBS':
            this.router.navigate(['/maindashboard/details'], { queryParams: baseQueryParams });
            break;
        // case 'Drawings':
        //     this.router.navigate(['/maindashboard/folders'], { queryParams: baseQueryParams });
            // break;
            case 'drawings':
              this.router.navigate(['/maindashboard/submenu'],  { queryParams: { ...baseQueryParams, mainMenuType: option } });
              break;
              case 'documents':
            this.router.navigate(['/maindashboard/submenu'],  { queryParams: { ...baseQueryParams, mainMenuType: option } });
            break;
        case 'Issue_log':
            this.router.navigate(['/issuelog'], { queryParams: baseQueryParams });
            break;
        case 'Default_emails':
            this.router.navigate(['/emails'], { queryParams: baseQueryParams });
            break;
        case 'PMC_drawings':
            this.router.navigate(['/maindashboard/pmc_folders'], { queryParams: baseQueryParams });
            break;
        case 'External':
            this.router.navigate(['/maindashboard/externalDrawings'], { queryParams: baseQueryParams });
            break;
        case 'DMS':
            this.getDmsKeyCloak(item);
            break;
        case 'Dashboard':
            this.dashboard(item);
            break;
        case 'S-Curve':
            this.scurve(item);
            break;
        case 'Abstract':
            this.abstract(item);
            break;
        case 'Logistics':
            this.logistics(item);
            break;
        default:
            console.error(`No route mapped for option: ${option}`);
            break;
    }
}

  
  getDmsKeyCloak(item:any){
    let params = new HttpParams()
    if (this.userDetails.email) {
      params = params.append('userName', this.userDetails.email)
    }
    // alert( this.userDetails)
    this.customerService.getDmsKeyCloak(params).subscribe((res: any) => {
      if(res && res['status'] == "success"){
        this.validdmsUser =res.data
        this.validdmsUser.forEach((ele:any) => {  
          if(ele.username === this.userDetails.preferred_username ){
            this.dmsVerifiers(item);
          }else{
            this.alertCall.showWarning("Error", "Invalid Users");
          }
        });
      }else{
        this.alertCall.showWarning("Error", res["message"]);
      }
    });
  
  }
  dmsVerifiers(item: any) {
    let id = item.proj_id;
    this.customerService.getDmsData(id).subscribe((res: any) => {
      if (res) {
        this.resp = res.data;
        if (this.resp.length > 0) {
          this.emailVerifiers = this.resp[0].emailVerifiers;
          localStorage.removeItem("$breadcrumbsData");
          localStorage.removeItem("$currentBreadCrumb");
          localStorage.removeItem("$currentProjectDetails");
          // let encode= Base64.btoa(JSON.stringify(this.resp))
          if (
            this.emailVerifiers.stage1.length > 0 &&
            this.emailVerifiers.stage2.length > 0 &&
            this.emailVerifiers.stage3.length > 0 &&
            this.emailVerifiers.vendor.length > 0
          ) {
            localStorage.setItem(
              "emailVerifiers",
              JSON.stringify(this.emailVerifiers)
            );
            this.router.navigate(["/dms/dmscreatefolder"], {
              queryParams: { id: item.proj_id },
            });
          }
        } else {
          this.router.navigate(["/dms/dmsVendor"], {
            queryParams: { id: item.proj_id, tab: "dmsvendor" },
          });
          // this.router.navigate(['/dms'], { queryParams: { 'id': item.proj_id,tab:'dmsvendor'} })
        }
      }
    });
  }
  dashboard(data: any) {
    let Data: any;
    Data = Base64.btoa(JSON.stringify(data));
    this.router.navigate(["/maindashboard/projectDashboard"], {
      queryParams: { id: Data },
    });
  }
  scurve(data: any) {
    let Data: any;
    Data = Base64.btoa(JSON.stringify(data));
    this.router.navigate(["/maindashboard/projectscurve"], {
      queryParams: { id: Data },
    });
  }
  abstract(data: any) {
    let Data: any;
    Data = Base64.btoa(JSON.stringify(data));
    this.router.navigate(["/maindashboard/projectabstract"], {
      queryParams: { id: Data },
    });
  }
  logistics(item:any) {
    let params = new HttpParams()
    if (this.userDetails.email) {
      params = params.append('userName', this.userDetails.email)
    }
    this.customerService.getLogisticsKeyCloak(params).subscribe((res: any) => {
      if(res && res['status'] == "success"){
        if(res.data.length > 0){
          this.validdmsUser =res.data
          this.validdmsUser.forEach((ele:any) => {  
            if(ele.username === this.userDetails.preferred_username ){
              let Data: any;
              Data = Base64.btoa(JSON.stringify(item));
              this.router.navigate(["/logistics"],  {
                queryParams: { pdata: Data },});
            }
            // else{
            //   this.alertCall.showWarning("Error", "Invalid Users");
            // }
          });
        }else{
          this.alertCall.showWarning("Error", "Invalid Users");
        }
      }else{
        this.alertCall.showWarning("Error", res["message"]);
      }
  
      
  })
}
}
