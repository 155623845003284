<!-- Filter Form -->
<form>
  <div class="row">
    <ng-container *ngFor="let filterKey of filters">
      <div [ngClass]="filterKey.colClass" *ngIf="filterKey.inputType == 'date'">
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <input
          type="date"
          [(ngModel)]="filterValues[filterKey.value]"
          class="inputstyles"
          [name]="filterKey.value"
          [max]="today"
        />
        <div *ngIf="filterKey.label == 'To Date' && !datevalidation()">
          <p class="errormsg">To Date must be greater than the From Date.</p>
        </div>
      </div>
      <div
        class="col-md-2"
        *ngIf="
          type == 'GoodsTransfer' && filterKey.inputType == 'select_transfer'
        "
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-select
          placeholder="{{ filterKey.placeHolder }}"
          class="dropdownstyles"
          [name]="filterKey.value"
          [(ngModel)]="filterValues[filterKey.value]"
        >
          <mat-option value="" selected disabled>Select One</mat-option>
          <mat-option value="RGP">RGP</mat-option>
          <mat-option value="NRGP">NRGP</mat-option>
        </mat-select>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_company'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="width: 100%"
        >
          <mat-select
            placeholder="{{ filterKey.placeHolder }}"
            [(ngModel)]="filterValues[filterKey.value]"
            [name]="filterKey.value"
            multiple
            #selectcmp (keydown.control.a)="onSelectAllcompanyName($event)"
          >
            <div class="select-all ml-3">
              <mat-checkbox
                [(ngModel)]="allSelectedcmp"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelection()"
                >Select All</mat-checkbox
              >
            </div>
            <mat-option
              *ngFor="let item of companyData"
              [value]="item.company_name"
              (click)="optionClick()"
              >{{ item.company_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'search_material'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="width: 100%"
        >
        {{selectedmatcode}}
          <input
            type="text"
            aria-label="Number"
            matInput
            style="border: none"
            class="inputdropdown"
            [name]="filterKey.value"
            placeholder="{{ filterKey.placeHolder }}"
            [(ngModel)]="filterValues[filterKey.value]"
            (keyup)="getmaterialDATA($event)"
            [matAutocomplete]="auto4"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto4="matAutocomplete"
            (optionSelected)="selectedmastergroup()"
          >
            <mat-option
              *ngFor="let item of masterData"
              [value]="item.system_reference_1"
            >
              {{ item.system_reference_1 }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_plantname'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="font-size: 12px; width: 100%"
        >
          <mat-select
            placeholder="{{ filterKey.placeHolder }}"
            [(ngModel)]="filterValues[filterKey.value]"
            #selectplantName
            multiple  (keydown.control.a)="onSelectAllplantName($event)"
          >
            <div class="select-all">
              <mat-checkbox
                [(ngModel)]="allSelectedplantName"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelectionplantName()"
                >Select All</mat-checkbox
              >
            </div>
            <mat-option
              (click)="optionClickplantName()"
              *ngFor="let item of plantdata"
              [value]="item.name"
              >{{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_valuationtype'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="font-size: 12px; width: 100%"
        >
          <mat-select
            placeholder="{{ filterKey.placeHolder }}"
            [(ngModel)]="filterValues[filterKey.value]"
            multiple
            #selectvaltype (keydown.control.a)="onSelectAllvalType($event)"
          >
            <div class="select-all">
              <mat-checkbox
                [(ngModel)]="allSelectedvaltype"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelectionvaltype()"
                >Select All</mat-checkbox
              >
            </div>
            <mat-option
              (click)="optionClickvaltype()"
              *ngFor="let valution of valutiondata"
              [value]="valution.code"
              >{{ valution.code }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_ponumber'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="font-size: 12px; width: 100%"
        >
          <mat-select
            placeholder="Selected Number List"
            [(ngModel)]="filterValues[filterKey.value]"
            [name]="filterKey.value"
            multiple
          >
            <mat-option
              *ngFor="let item of purchaseOrderData"
              [value]="item.number"
              >{{ item.number }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'search_ponumber'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="width: 100%"
        >
          <input
            type="text"
            placeholder="{{ filterKey.placeHolder }} "
            aria-label="Number"
            matInput
            style="border: none; margin-bottom: 1%"
            class="inputdropdown"
            [name]="filterKey.value"
            [(ngModel)]="filterValues[filterKey.value]"
            (keyup)="getpurchaseorderDATA($event)"
            [matAutocomplete]="auto42"
          />

          <mat-autocomplete autoActiveFirstOption #auto42="matAutocomplete">
            <mat-option
              *ngFor="let item of purchaseOrderData"
              [value]="item.number"
            >
              {{ item.number }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_prnumber'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="font-size: 12px; width: 100%"
        >
          <mat-select
            placeholder="Selected Number List"
            [(ngModel)]="filterValues[filterKey.value]"
            [name]="filterKey.value"
            multiple
          >
            <mat-option
              *ngFor="let item of purchaseRequestdata"
              [value]="item.number"
              >{{ item.number }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'search_vehiclenumber'"
      >
        <label>{{ filterKey.label }}</label>
        <mat-form-field
        class="selectC"
        appearance="outline"
        style="width: 100%"
      >
          <input
            type="text"
            class="inputdropdown"
            placeholder="{{ filterKey.placeHolder }}"
            aria-label="Number"
            matInput
            style="border: none"
            [name]="filterKey.value"
            [(ngModel)]="filterValues[filterKey.value]"
            (input)="emptyVehiclenum(vehiclenumber)"
            (keyup)="filter($event.target.value)"
            (focus)="filter($event.target.value)"
            [matAutocomplete]="autovehicle"
            #triggerVehicle="matAutocompleteTrigger"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autovehicle="matAutocomplete"
          >
            <mat-option
              *ngFor="let item of vehicledata"
              [value]="item.vehicle_number"
            >
              {{ item.vehicle_number }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'search_vendorname'"
      >
        <label>{{ filterKey.label }}</label>
        <mat-form-field
        class="selectC"
        appearance="outline"
        style="width: 100%"
      >
          <input
            type="text"
            class="inputdropdown"
            matInput
            style="border: none"
            placeholder="{{ filterKey.placeHolder }}"
            [name]="filterKey.value"
            [(ngModel)]="filterValues[filterKey.value]"
            (input)="venderNameSearch(vendornames)"
            (focus)="filtervenderName($event.target.value)"
            (keyup)="filtervenderName($event.target.value)"
            [matAutocomplete]="auto9"
            #triggerVendor="matAutocompleteTrigger"
          />
          <mat-autocomplete autoActiveFirstOption #auto9="matAutocomplete">
            <mat-option
              *ngFor="let item of vendorData"
              [value]="item.contractor_name"
            >
              {{ item.contractor_name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <!-- mos -->
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_projectname'"
      >
        <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="width: 100%; font-size: 14px"
        >
          <mat-select
            [(ngModel)]="filterValues[filterKey.value]"
            multiple
            placeholder="{{ filterKey.placeHolder }}"
            class="inputdropdown"
            required
            #selectprojectname (keydown.control.a)="onSelectAllprojectName($event)"
          >
            <div class="select-all">
              <mat-checkbox
                (change)="toggleAllSelectionProjects()"
                [(ngModel)]="allSelectedProjects"
                [ngModelOptions]="{ standalone: true }"
              >
                Select All
              </mat-checkbox>
            </div>
            <mat-option
              disabled
              *ngIf="datafetching"
              style="font-size: 12px !important"
            >
              Data Fetching...
            </mat-option>
            <mat-option
              (click)="optionClickProjects()"
              *ngFor="let option of filteredProjects"
              [value]="option.proj_id"
            >
              {{ option.proj_short_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_materialname'"
      >
        <label>{{ filterKey.label }}</label>
        <mat-form-field class="selectC" appearance="outline">
          <mat-select
            #materialSelect
            [(ngModel)]="filterValues[filterKey.value]"
            multiple
            placeholder="{{ filterKey.placeHolder }}"
            class="inputdropdown"
            (opened)="onOpened()"
            (closed)="clearSearchInput()"
            required
            #selectmaterialname
          >
            <div class="dropdown-container">
              <div class="search-input-container">
                <input
                  #searchInputField
                  class="input90"
                  placeholder="Search..."
                  [(value)]="searchInput"
                  (input)="
                    getselectmaterialDATA($event);
                    changeText($event);
                    $event.stopPropagation()
                  "
                  (keydown.space)="$event.stopPropagation()"
                  [disabled]="allSelectedmatname && !searchInput" (keydown.control.a)="onSelectAllmatName($event)"
                />
              </div>
              <div class="select-all" *ngIf="filteredData.length > 0">
                <mat-checkbox
                  (change)="toggleAllSelectionmatname()"
                  [(ngModel)]="allSelectedmatname"
                  [ngModelOptions]="{ standalone: true }"
                >
                  Select All
                </mat-checkbox>
              </div>

              <mat-option *ngIf="isLoading" disabled>
                Data Fetching...
              </mat-option>

              <div
                class="no-data-found"
                *ngIf="!isLoading && filteredData.length === 0"
                disabled
              >
                No data found
              </div>
              <mat-option
                (click)="optionClickmatname(item)"
                (keydown.space)="$event.preventDefault()"
                *ngFor="let item of filteredData"
                [value]="item.system_reference_1"
              >
                {{ item.system_reference_1 }}
              </mat-option>
            </div>
          </mat-select>
          <button
            *ngIf="materialName.length"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearMaterialSelection()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div
        [ngClass]="filterKey.colClass"
        *ngIf="filterKey.inputType == 'select_approvaltype'"
      >
        <label>{{ filterKey.label }}</label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="width: 100%; font-size: 14px"
        >
          <mat-select
            [(ngModel)]="filterValues[filterKey.value]"
            placeholder="{{ filterKey.placeHolder }}"
            class="inputdropdown"
          >
            <mat-option
              *ngFor="let option of mosApprovalType"
              [value]="option.value"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

<!-- goodsissue -->
      <div
      [ngClass]="filterKey.colClass"
      *ngIf="filterKey.inputType == 'select_contractor'"
    >
      <label>{{ filterKey.label }}<span class="mandetory">*</span></label>
        <mat-form-field
          class="selectC"
          appearance="outline"
          style="font-size: 12px; width: 100%"
        >
          <mat-select
          [name]="filterKey.value"
          placeholder="{{ filterKey.placeHolder }}"
          [(ngModel)]="filterValues[filterKey.value]"
            #selectcgi
            multiple
          >
            <div class="select-all">
              <mat-checkbox
                [(ngModel)]="allSelectedcgi"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelectioncgi()"
                >Select All</mat-checkbox
              >
            </div>
            <mat-option
              (click)="optionClickcgi()"
              *ngFor="let item of filteredContractorData"
              [value]="item.contractor_name"
              >{{ item.contractor_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div [ngClass]="filterKey.colClass"
      *ngIf="filterKey.inputType == 'value'">
        <label class="mt-5 mr-2">
          <input type="checkbox" [(ngModel)]="isChecked" />
          {{ filterKey.label }}
        </label>
      </div>

      <!-- available stock -->
      <div
      [ngClass]="filterKey.colClass"
      *ngIf="filterKey.inputType == 'search_company'"
    >
      <label>{{ filterKey.label }}</label>
      <mat-form-field
      class="selectC"
      appearance="outline"
      style="width: 100%"
    >
      <input
        type="text"
        placeholder="{{ filterKey.placeHolder }} "
        aria-label="Number"
        matInput
        class="inputdropdown"
        [name]="filterKey.value"
        [(ngModel)]="filterValues[filterKey.value]"
        (keyup)="SearchCompanyData($event)"
        [matAutocomplete]="auto11"
      />

      <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete">
        <mat-option *ngFor="let item of companyData1" [value]="item.company_name">
          {{ item.company_name }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    </div>
    <div
    [ngClass]="filterKey.colClass"
    *ngIf="filterKey.inputType == 'select_status'"
  >
    <label>{{ filterKey.label }}</label>
    <mat-form-field
    class="selectC"
    appearance="outline"
    style="font-size: 12px; width: 100%"
  >
    <mat-select
      placeholder="{{ filterKey.placeHolder }} "
      [name]="filterKey.value"
      [(ngModel)]="filterValues[filterKey.value]"
      #select1
      multiple   (keydown.control.a)="onSelectAllRGP($event)"
    >
      <div class="select-all">
        <mat-checkbox
          [(ngModel)]="allSelected1"
          [ngModelOptions]="{ standalone: true }"
          (change)="toggleAllSelection1()"
          >Select All</mat-checkbox
        >
      </div>
      <mat-option
        *ngFor="let item of RGPstatus"
        (click)="optionClick1()"
        [value]="item"
        >{{ item }}</mat-option
      >
    </mat-select>
  </mat-form-field>
    </div>
    </ng-container>
    <div class="col-md-2" [ngClass]=" (filters?.length < 5 && type !== 'OutGatePassRGPDashboardReports') ? 'mt-5 pt-2' : ''">
      <button
      mat-raised-button *ngIf="type == 'OutGatePassRGPDashboardReports'"
      class="downloadbtn"
      type="button"
      (click)="getTableData()"
      style="background-color: rgba(0, 0, 0, 0.12)"
    >
      VIEW
    </button>
      <button
      mat-raised-button *ngIf="type=='AvailableStock' || type == 'SingleMaterialStock'"
      [disabled]="!isFormValid()"
      (click)="getTableData()"
      color="secondary"
      style="
        background: linear-gradient(#5bade8, #5ae6ed);
        color: black;
      "
    >
      Search
    </button>
      <button
        mat-raised-button *ngIf="!(type =='AvailableStock'  || type == 'SingleMaterialStock' || type == 'OutGatePassRGPDashboardReports')"
        class="btnclr"
        [disabled]="!isFormValid()"
        (click)="getTableData()"
      >
        VIEW
      </button>
      <button
        mat-raised-button
        class="mx-2 btnclr" *ngIf="type !=='OutGatePassRGPDashboardReports'"
        [disabled]="!isFormValid()"
        (click)="resetForm()"
      >
        RESET
      </button>
    </div>
    
    <div class="col text-right" [ngClass]="(filters?.length < 5 && type !=='OutGatePassRGPDashboardReports') ? 'mt-5 pt-2' : ''" *ngIf="tableData.length > 0 && !consolidateLink">
      <button
        mat-raised-button
        class="btn btn-success expt"
        (click)="module === 'MOS' ? mosExcel() : exporttoExcel()"
      >
        Export to Excel
      </button>
    </div>
    <div class="col text-right" [ngClass]="filters?.length < 5? 'mt-5 pt-2' : ''" *ngIf="type == 'ConsolidatedStatement' && module === 'Stores' && consolidateLink">
      <button
        mat-raised-button
        class="btn btn-success expt"
        (click)="exporttoExcelConsolidatedStatement()"
      >
        Export to Excel
      </button>
    </div>
  </div>
  <!-- Table -->
  <div class="row mt-3">
    <div class="col col-12">
      <div class="table-responsive" class="tableclass" #TABLE>
        <table class="table table-bordered" *ngIf="isDataLoaded">
          <tr class="d-none">
            <td>
              Report Generated Date:&nbsp;{{ today | date : "dd-MM-yyyy" }}
            </td>
          </tr>
          <tr class="d-none">
            <td *ngFor="let displayValue of getRelevantFilterValues()">
              <ng-container *ngIf="displayValue.value && (displayValue.label === 'From Date' || displayValue.label === 'To Date')">
                {{ displayValue.label }}: {{ displayValue.value | date: 'dd/MM/yyyy' }}
              </ng-container>
              <ng-container *ngIf="!(displayValue.label === 'From Date' || displayValue.label === 'To Date')">
                {{ displayValue.label }}: {{ displayValue.value || "N/A" }}
              </ng-container>
            </td>
          </tr>

          <tr class="center" class="headerstyles" *ngIf="tableData.length > 0">
            <ng-container *ngIf="headerKeys.length>0">
              <th scope="col" class="border">S.No</th>

              <ng-container *ngFor="let header of headerKeys">
                <th scope="col" class="border" *ngIf="type != 'Goods Issue'"> {{header}}</th>
                <th scope="col" class="border" *ngIf="type == 'Goods Issue' && !(header == 'Unit Price' || header == 'Total Price')">{{header}}</th>
                <th scope="col" class="border" *ngIf="type == 'Goods Issue' && isCheckedval && (header == 'Unit Price' || header == 'Total Price')">{{header}}</th>
              </ng-container>
            </ng-container>
          </tr>

          <tbody *ngIf="!xldownloadlink && tableData.length > 0">
            <tr
              *ngFor="let report of tableData; let i = index"
              [style.background-color]="rowClicked == i ? 'lightgreen' : ''"
              (click)="avlstkhighlight(i)"
              class="border"
            >
              <td>{{ i + 1 }}</td>
              <ng-container *ngFor="let header of headerKeys" >
                <td *ngIf="type == 'Goods Issue' && !(header == 'Unit Price' || header == 'Total Price')">
                  {{ (report[tableHeaders[header]] == null || (report[tableHeaders[header]]+'' || '').trim() === '') ? 'N/A' : report[tableHeaders[header]] }}
                </td>
                <td *ngIf="type == 'Goods Issue' && isCheckedval && (header == 'Unit Price' || header == 'Total Price')">
                  {{ (report[tableHeaders[header]] == null || (report[tableHeaders[header]]+'' || '').trim() === '') ? 'N/A' : report[tableHeaders[header]] }}
                </td>
                <td *ngIf="type != 'Goods Issue'">
                  <ng-container *ngIf="isStaticService">
                    <ng-container
                      *ngIf="
                        header.toLowerCase().includes('date') &&
                          report[tableHeaders[header]]?.endsWith('Z');
                        else plainText
                      "
                    >
                      {{ report[tableHeaders[header]] | date : "dd/MM/yyyy" }}
                    </ng-container>
                    <ng-template #plainText>
                      {{ (report[tableHeaders[header]] == null || (report[tableHeaders[header]]+'' || '').trim() === '') ? 'N/A' : report[tableHeaders[header]] }}
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="!isStaticService">
                    <ng-container
                      *ngIf="
                        header.toLowerCase().includes('date') && report[header]?.endsWith('Z');
                        else dynamicPlainText
                      "
                    >
                      {{ report[header] | date : 'dd/MM/yyyy' }}
                    </ng-container>
                    <ng-template #dynamicPlainText>
                      {{ (report[header] == null || (report[header]+'' || '').trim() === '') ? '-' : report[header] }}
                      </ng-template>
                  </ng-container>
                  
                  
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tbody *ngIf="!xldownloadlink && tableData.length == 0">
            <tr>
              <td [attr.colspan]="headerKeys.length + 1">No Data Found</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
      class="text-center m-4"
      *ngIf="(xldownloadlink && tableData.length === 0)"
    >
      <h3>
        Data is too large, please download data from the below excel link
      </h3>
      <button
        mat-raised-button
        class="btn btn-success m-3"
        (click)="getxldownload()"
        style="border: none; background-color: #62beda"
      >
        Export to Excel
      </button>
    </div>
    </div>
  </div>
  
</form>
