<!-- <div class="page-breadcrumb page-header">
  <div class="row">
    <div class="col-12 align-self-center">
      <h4 class="page-title">Out Gate Pass NRGP</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
<!-- </div>
  </div>
</div> -->
<div class="nrgp-tab">
  <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabChanged($event)">
    <mat-tab>
      <ng-template mat-tab-label> Out Gate Pass NRGP </ng-template>
      <ng-template matTabContent>
        <mat-card>
          <mat-card-content>
            <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)">
              <div class="row mb-2">
                <div *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                  <button mat-raised-button type="button" (click)="BacktoList()"
                      class="savebutton">Back</button>
              </div>
              <div *ngIf="viewData" class="col-md-1 mr-5">
                  <button mat-raised-button type="button" (click)="signatureStatus(signaturestatus)"
                      class="savebutton">Signature Status</button>
              </div>
              <div *ngIf="viewData" class="col-md-2 ml-1">
                <!-- <button  mat-raised-button type="button" *ngIf="docSignature !== 'SENT' " (click)="getApprovals(approvalmodeldatastatus)" [ngClass]="docSignature === 'SENT' ? 'disabled' : 'enabled'"  class="savebutton"  [disabled]="approvalbtn === true">
                  Proceed for Approvals
                  </button> -->
                  <button
                    mat-raised-button
                    type="button"
                    *ngIf="docSignature !== 'SENT'"
                    (click)="onProceedForApproval()"
                    [ngClass]="docSignature == 'SENT' ? 'disabled' : 'enabled'"
                    class="savebutton"
                    [disabled]="isButtonDisabled()">
                    Proceed for Approvals
                  </button>
            </div>
              <div *ngIf="viewData" class="col mt-1 text-right">
                  <p *ngIf="viewData" style="font-size: 16px;font-weight: 500;">View of Document
                      Number: {{viewnumber}}</p>
              </div>
                <div *ngIf="!viewData" class="col-md-0 mr-1 ml-2">
                  <button mat-raised-button  class="savebutton" type="submit" 
                    color="secondary" [disabled]="!model1.companyname || !model1.cosignee || !model1.location || !model1.tolocation || !model1.purpose || dataSource?.data?.length == 0">
                    {{ btn }}
                  </button>
                </div>
                <div *ngIf="!viewData" class="col-md-0 ml-1 mr-2 mb-2">
                  <button mat-raised-button type="button" (click)="clearpage1()"
                  class="savebutton">Add New</button>
              </div>
                <div *ngIf="!viewData" class="col-md-0">

                  <button mat-raised-button color="secondary" type="button"
                        class="attachfile savebutton button1"
                        (click)="openNewFileUploadModal(newFileUploadModal)">Attach File&nbsp;
                    </button>

                  <!-- <button type="button" mat-raised-button class="attachfile" color="secondary"
                    (click)="autoFileUpload2.click()">
                    Attach File&nbsp;
                    <mat-icon style="cursor: pointer; font-size: 24px">file_upload</mat-icon>
                  </button>
                  <div class="file">
                    <input hidden class="form-control-file" name="file" type="file" multiple
                      (change)="uploadgtfiles($event)" #autoFileUpload2 />
                    <ng-container *ngIf="fileUploadUrlsgt.length > 0" class="mt-4">
                      <div *ngFor="let item of filenamearray; let i = index">
                        {{ i + 1 }}.&nbsp;{{ item }}
                      </div> -->

                      <!-- <span style="margin-left:3px">Selected : {{fileUploadUrlsgreturn.length}} files</span>   -->
                    <!-- </ng-container>
                  </div> -->
                </div>

                <div *ngIf="!viewData" class="col-md-0 ml-2">
                  <button type="button" mat-raised-button class="savebutton"
                      (click)="deliveryChallanData(dcmodel)">
                     Delivery Challan
                  </button>
              </div>
                <div *ngIf="!viewData" class="col-md-0 ml-2">
                  <button type="button" mat-raised-button class="savebutton"  (click)="openpurchaseordermodel(pomodel)">
                 Return To RGP
              </button>
              </div>
              <div *ngIf="!viewData && txnId" class="col mt-1 text-right">
                <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
            </div>
              </div>
              <div class="row">
                <!-- <div class="col-md-3">
                  <label
                    >Work Order Number<span class="mandetory">*</span></label
                  >
                  <input
                    type="text"
                    class="inputstyles"
                    name="wonumber"
                    placeholder="Enter your Order Number"
                    [(ngModel)]="model1.wonumber"
                  />
                </div> -->
                <div class="col-md-6">
                  <label>Consignor<span class="mandetory">*</span></label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.companyname !== null ? model1.companyname :'NA'}}</p>
                  <select *ngIf="!viewData" class="dropdownstyles" name="companyname" [(ngModel)]="model1.companyname">
                    <option [value]="undefined" selected disabled>Select One</option>
                    <option *ngFor="let item of companyData1" [ngValue]="item.company_name">
                      {{ item.company_name }}
                    </option>
                  </select>
                </div>


                <div class="col-md-6">
                  <label>Consignee Name<span class="mandetory">*</span></label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.cosignee !== null ? model1.cosignee : 'NA'}}</p>
                  <!-- <input type="text" class="inputstyles" name="cosignee" placeholder="Enter your Consignee Name "
                    [(ngModel)]="model1.cosignee" /> -->
                  <mat-form-field *ngIf="!viewData" class="example-full-width" style=" width:100%;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    height: 35px;" appearance="none">
                    <input type="text" placeholder="Search Consignee Name " aria-label="Number" matInput
                      style="border:none" class="inputdropdown2" name="consigneename" [(ngModel)]="model1.cosignee"
                      (keyup)="getvendordropdowndata($event)" [matAutocomplete]="auto2" (blur)="validateConsigneeName()" (input)="trimInput()"  autocomplete="off"/>

                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="selectedConsignee($event)">

                      <mat-option *ngFor="let item of vendorData" [value]="item.name">
                        {{item.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="invalidConsignee">{{ errorMessage }}</mat-error>
                  </mat-form-field>
                </div>
            
                <div class="col-md-3">
                  <label>Consignee Address<span class="mandetory">*</span></label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.consignee_address !== null ? model1.consignee_address :'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="consignee_address" [(ngModel)]="model1.consignee_address"
                    placeholder="Enter Consignee Address" readonly />
                </div>
                <div class="col-md-3">
                  <label>Purchase Order</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.order_no !== null ? model1.order_no : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" order_no class="inputstyles" name="order_no" placeholder="Enter your Purchase Order "
                    [(ngModel)]="model1.order_no" (input)="trimInput()"/>
                </div>
               
                <!-- <div class="col-md-3">
                  <label>To Company Name<span class="mandetory">*</span></label>
                  <select
                    class="dropdownstyles"
                    name="tocompanyname"
                    [(ngModel)]="model1.tocompanyname"
                  >
                    <option [value]="undefined"selected disabled>Select One</option>
                    <option
                      *ngFor="let item of companyData"
                      [ngValue]="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div> -->
              <div class="col-md-3">
                <label>NRGP Type</label>
                <p *ngIf="viewData" class="viewdata"> {{(model1.nrgptype !== null && model1.nrgptype !== '') ? model1.nrgptype : 'NA'}}</p>
                <select *ngIf="!viewData"   class="dropdownstyles" name="nrgptype" [(ngModel)]="model1.nrgptype" [disabled]="btn === 'Update' && model1.nrgptype === 'sale' && (model1.nrgp_status !== 'L1-PENDING' && model1.nrgp_status !== '')">
                <option value="">Select One</option>
                <option *ngFor="let item of nrgp_type" [value]="item.name" >{{item.name | titlecase}}</option>
            </select>
              </div>
                <div class="col-md-3">
                  <label>NRGP Date of Issue</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.date !== null ? model1.date :'NA'}}</p>
                  <input *ngIf="!viewData" type="date" [max]="today"  class="inputstyles" name="date"
                    [(ngModel)]="model1.date" dateFormat="yyyy/mm/dd" [disabled]="gamCompanyfields"/>
                </div>

                <div class="col-md-3">
                  <label>Transporter Name</label>
                  <p *ngIf="viewData" class="viewdata"> {{(model1.tname !== null && model1.tname !== '') ? model1.tname :'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="tname" placeholder="Enter your Transporter Name"
                    [(ngModel)]="model1.tname" (input)="trimInput()"/>
                </div>
             
                <div class="col-md-3">
                  <label>Vehicle Number</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.vehnumber}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="vehnumber" placeholder="Ener your Vehicle Number "
                    [(ngModel)]="model1.vehnumber" (ngModelChange)="model1.vehnumber = $event.toUpperCase()"  maxlength="10" 
                    pattern="[a-zA-Z0-9]*"  (input)="trimInput()"/>
                </div>
                <div class="col-md-3">
                  <label>Purchase Order Date</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.order_nodate !== null ? model1.order_nodate :'NA'}}</p>
                  <input *ngIf="!viewData" type="date" [max]="today"  class="inputstyles" name="order_nodate"
                    [(ngModel)]="model1.order_nodate" dateFormat="yyyy/mm/dd" />
                </div>
               
                           
                <div class="col-md-3">
                  <label>Rejection Number</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.rejection_number !== null ? model1.rejection_number :'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="rejection_number" placeholder="Ener your Rejection Number "
                    [(ngModel)]="model1.rejection_number" (input)="trimInput()"/>
                </div>  
                <div class="col-md-3">
                  <label>LR Number </label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.lrnumber !==null ? model1.lrnumber :'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="lrnumber" placeholder="Enter your  LR Number "
                    [(ngModel)]="model1.lrnumber" (input)="trimInput()"/>
                </div>
            
                <div class="col-md-3">
                  <label>LR Date</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.lrdate !== null ? model1.lrdate :'NA'}}</p>
                  <input *ngIf="!viewData" type="date" [max]="today"  class="inputstyles" name="lrdate"
                    [(ngModel)]="model1.lrdate" dateFormat="yyyy/mm/dd" />
                </div>
                <div class="col-md-3">
                  <label>Material Value</label>
                  <p *ngIf="viewData" class="viewdata"> {{total |number}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="materialvalue" placeholder="Enter your Material Value"
                     [value]="total |number" readonly/>
                     <!-- [(ngModel)]="model1.materialvalue" -->
                </div>
               
                <div class="col-md-3">
                  <label>Eway Bill No</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.ewaybillno !== null ? model1.ewaybillno : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" placeholder="Enter Ewaybillno"
                     [(ngModel)]="model1.ewaybillno" name="ewaybillno" (input)="trimInput()"
                      />
                </div>
                <div class="col-md-3">
                  <label>From Location<span class="mandetory">*</span></label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.location !== null ? model1.location : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" location class="inputstyles" name="location" placeholder="Enter your Location "
                    [(ngModel)]="model1.location" required (input)="trimInput()"/>
                </div>
            
                <div class="col-md-3">
                  <label>To Location<span class="mandetory">*</span></label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.tolocation !== null ? model1.tolocation :'NA'}}</p>
                  <input *ngIf="!viewData" type="text" tolocation class="inputstyles" name="tolocation" placeholder="Enter your Location "
                    [(ngModel)]="model1.tolocation" required (input)="trimInput()"/>
                </div>
                <div class="col-md-3">
                  <label>Purpose<span class="mandetory">*</span></label>
                  <!-- <input
                    type="text"
                    class="inputstyles"
                    name="purpose"
                    placeholder="Enter your Purpose "
                    [(ngModel)]="model1.purpose"
                  /> -->
                  <p *ngIf="viewData" class="viewdata"> {{model1.purpose !== null ? model1.purpose : 'NA'}}</p>
                  <mat-form-field *ngIf="!viewData" class="example-full-width" style=" width:100%;
                  border: 1px solid rgba(0, 0, 0, 0.12);
                  border-radius: 5px;
                  height: 35px;" appearance="none">
                    <input type="text" placeholder="Search Purpose" aria-label="Number" matInput style="border:none"
                      class="inputdropdown1" [(ngModel)]="model1.purpose" name="purpose" #purpose="ngModel" required
                      (keyup)="getPurpose($event)" [matAutocomplete]="auto1" (input)="trimInput()"/>

                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">

                      <mat-option *ngFor="let item of purposeData" [value]="item.purpose">
                        {{item.purpose}}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
             
             
                <div class="col-md-3" *ngIf="!gamCompanyfields">
                  <label>Request Number</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.reqnumber !== null ? model1.reqnumber : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="reqnumber" placeholder="Ener your Request Number"
                    [(ngModel)]="model1.reqnumber" (input)="trimInput()"/>
                </div>

                <div class="col-md-3" *ngIf="!gamCompanyfields">
                  <label>Request Date</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.reqdate !== null ? model1.reqdate : 'NA'}}</p>
                  <input *ngIf="!viewData" type="date" [max]="today"  class="inputstyles" name="reqdate"
                    [(ngModel)]="model1.reqdate" dateFormat="yyyy/mm/dd" />
                </div>
             
                <div class="col-md-3">
                  <label>Receiver Name</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.receivername !== null ? model1.receivername : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" class="inputstyles" name="receivername" placeholder="Enter your Receiver Name"
                    [(ngModel)]="model1.receivername" (input)="trimInput()"/>
                </div>
                <div class="col-md-3">
                  <label>Delivery Challan Number</label>
                  <p *ngIf="viewData" class="viewdata"> {{model1.dcnumber !== null ? model1.dcnumber : 'NA'}}</p>
                  <input *ngIf="!viewData" type="text" placeholder="Enter Delivery Challan No" class="inputstyles"
                      name="dcnumber" [(ngModel)]="model1.dcnumber" (input)="trimInput()">
              </div>
              <div class="col-md-3 ">
                <label>Comments</label>
                <p *ngIf="viewData" class="viewdata"> {{model1.cmts !== null ? model1.cmts : 'NA'}}</p>
                <input *ngIf="!viewData" type="text" class="inputstyles" name="cmts" placeholder="Ener your Comments "
                  [(ngModel)]="model1.cmts" (input)="trimInput()"/>
              </div>
              </div>             
            </form>
            <!-- add pop up -->
            <div  class="row p-10">
              <div class="col-4 mt-1">
                <h4 style="font-weight:450;">Total Price:&nbsp;{{total | number}}</h4> 
              </div>
              <div class="col-8 float-right text-right">
                <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button"
                  (click)="addNrgp(nrgpModel)">
                  <span class="material-icons"> add </span>
                </button>
              </div>
            </div>
            <!-- table -->
            <ng-container>
              <div class="row p-t-10">
                <div class="col-12 col-md-12">
                  <div class="table-responsive mat-table-wrapper">
                    <div class="example-table-container">
                      <table mat-table [dataSource]="dataSource" >
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef style="width: 5%">
                            S.NO
                          </th>
                          <td mat-cell *matCellDef="let row; let i = index">
                            {{ i + 1 }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="material_code">
                          <th mat-header-cell *matHeaderCellDef style="width: 5%">
                            Material Code
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.material_code }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="material_description">
                          <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-left">
                            Material Description
                          </th>
                          <td mat-cell *matCellDef="let row " class="text-center">
                            {{ row.material_description || row.description ||  row.matcode}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="unit_of_measurement">
                          <th mat-header-cell *matHeaderCellDef style="width: 4%">
                            UOM
                          </th>
                          <td mat-cell *matCellDef="let row"  >
                            {{ row.uom || row.unit_of_measurment || row.unit_of_measurement}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="storage_location">
                          <th mat-header-cell *matHeaderCellDef style="width: 5%" >
                            Storage Location
                          </th>
                          <td mat-cell *matCellDef="let row" >
                            {{ row.storagelocation || row.storage_location }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="request_quantity">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            Request Quantity
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ (row.reqqty | number:'1.3-3') || (row.request_quantity | number:'1.3-3')|| (row.quantity | number:'1.3-3')}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            NRGP Quantity
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ (row.qty | number:'1.3-3')|| (row.quantity | number:'1.3-3')}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="unit_price">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            Unit Price
                          </th>
                          <td mat-cell *matCellDef="let row" >
                            {{ row.unit_price | number:'1.2-2'}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="total_price">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            Basic Price
                          </th>
                          <td mat-cell *matCellDef="let row" >
                            {{ row.basic_price | number:'1.2-2'}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="valuation_type">
                          <th mat-header-cell *matHeaderCellDef style="width: 5%">
                            Valuation Type
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <p class="valuationtype">{{ row.valutiontype || row.valuation_type }}</p>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="item_remarks">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            Item Remarks
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <p class="itemrmks">{{  row.itemremarks ||row.item_remarks  || 'NA' }}</p>
                          </td>
                        </ng-container>
                       
                        <!-- <ng-container matColumnDef="batch_no">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="width: 5%"
                          >
                            Batch No
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <p>{{ row.batchno || row.batch_number }}</p>
                          </td>
                        </ng-container> -->
                        
                        <!-- Action -->
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef style="width: 10%">
                            Action
                          </th>
                          <td mat-cell *matCellDef="let row; let i = index">
                          <span class="d-flex justify-content-center">
                            <button *ngIf="!viewData" mat-icon-button aria-label="Example icon button with a vertical three dot icon" class="edtic"
                            (click)="editdata(row, i, nrgpEditModel)" [disabled]="viewData===true" matTooltip="Edit">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button *ngIf="!viewData" mat-icon-button style="color: red; border: none" matTooltip="Delete"
                            aria-label="Example icon button with a vertical three dot icon" (click)="deleterow(i)" [disabled]="viewData===true">
                            <mat-icon>delete</mat-icon>
                          </button>
                          </span>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>List</ng-template>
      <ng-template matTabContent>
        <mat-card>
          <mat-tab-content>
            <ng-container>
              <div class="row">
                <div class="col-md-5 col-lg-4 mt-2 mb-3 d-flex">
                  <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                  <select class="searchdropdownstyles" name="columnname" [(ngModel)]="columnname" (change)="onChange()">
                    <option value="" selected disabled>Select Column</option>
                    <option value="number">Number</option>
                    <!-- <option value="company_name">Company Name</option> -->
                  </select>
                </div>
                <div class="col-md-3 col-lg-3 mt-2 mb-3">
                  <input  type="search" class="searchfield inputstyles" placeholder="Search" [(ngModel)]="searchData"
                    (input)="search1()"  [disabled]="!columnname"/>
                </div>
                <div class="col-lg-5 col-md-4 mt-2 mb-3 text-right">
                  <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getNRGPlist1()"
                  class="custom-icon-button ml-3">
                  <span class="material-icons">
                      refresh
                  </span>
              </button>
              </div>
              </div>
              <div class="row p-t-10">
                <div class="col-12 col-md-12">
                  <div class="table-responsive mat-table-wrapper">
                    <div class="example-table-container">
                      <table mat-table [dataSource]="dataSourcemain" matSort>
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            S.NO
                          </th>
                          <td mat-cell *matCellDef="let row; let i = index">
                            {{(i+1) + (paginator?.pageIndex *
                              paginator?.pageSize)}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Number">
                          <th mat-header-cell *matHeaderCellDef style="width: 10%" mat-sort-header="number">
                            Number
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.number }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef style="width: 9%" mat-sort-header="date">
                            Date
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.date | date: "dd-MM-yyyy" }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="company_Name">
                          <th mat-header-cell *matHeaderCellDef style="width: 19%" mat-sort-header="company_name">
                            Company Name
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.company_name }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="tocompanyname">
                          <th mat-header-cell *matHeaderCellDef style="width: 19%" mat-sort-header="consignee_name">
                            Consignee Name
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.consignee_name }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="request_number" >
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="request_number">
                            Request Number
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.request_number }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="count">
                          <th mat-header-cell *matHeaderCellDef  mat-sort-header="count">
                            Count
                          </th>
                          <td mat-cell *matCellDef="let row">
                            {{ row.count }}
                          </td>
                        </ng-container>
                          <ng-container matColumnDef="nrgp_type">
                            <th mat-header-cell *matHeaderCellDef >
                            NRGP Type
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ (row.nrgp_type !== null && row.nrgp_type !== "") ? row.nrgp_type : 'NA' }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="nrgp_status">
                            <th mat-header-cell *matHeaderCellDef  >
                            NRGP Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.nrgp_status !== null ? row.nrgp_status : 'NA' }}
                            </td>
                          </ng-container>
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef style="width: 15%;text-align: center !important;" class="text-center">
                            Action
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            <div class="mb-0"></div>
                            <mat-icon matTooltip="View" style="border:none;background: none;cursor: pointer;"
                            (click)="viewreason(row)" >remove_red_eye</mat-icon>&nbsp;
                            <!-- <mat-icon  
                            style="
                                border: none;
                                background: none;
                                cursor: pointer;color: blue;
                                
                              " 
                              [ngClass]="row.doc_sign === 'SENT' ? 'hidden-icon' : 'enabled'"
                              (click)="editNrgpData(row, EditListModel)" matTooltip="Edit">edit</mat-icon> -->
                              <mat-icon  
                              style="border: none; background: none; cursor: pointer; color: blue;"
                              [hidden]="row.doc_sign === 'SENT' || row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED')"
                              (click)="editNrgpData(row, EditListModel)" matTooltip="Edit">edit</mat-icon>
                            <mat-icon style="
                                color: red;
                                border: none;
                                background: none;
                                cursor: pointer;
                              " (click)="deleteNrgpItem(row, deleteItemModel)" matTooltip="Delete">delete</mat-icon>&nbsp;
                            <mat-icon (click)="printNRGP(row)" style="cursor: pointer" matTooltip="Print">
                              local_printshop</mat-icon>&nbsp;
                            <mat-icon (click)="
                                openfileuploadmodel(fileuploadmodel, row)
                              " style="cursor: pointer; font-size: 24px;color:#28b531" matTooltip="File Upload">file_upload</mat-icon>&nbsp;

                            <!-- <mat-icon 
                                matTooltip="Send For Review"
                                [ngClass]="((row.nrgp_type === 'sale' && (row.nrgp_status === 'APPROVED' || (row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED')))) || row.nrgp_type !== 'sale') ? 'disabled' : 'enabled'"
                                (click)="(row.nrgp_type === 'sale' && !(row.nrgp_status === 'APPROVED' || (row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED')))) ? opensignmodel(signmodel, row) : null">
                                rate_review
                            </mat-icon> -->
                            <mat-icon 
                                matTooltip="Review"
                                [ngClass]="row.nrgp_type !== 'sale' ? 'disabled' : 'enabled'"
                                (click)="row.nrgp_type === 'sale' ? opensignmodel(signmodel, row) : null">
                                rate_review
                            </mat-icon>
                            <mat-icon 
                            matTooltip="Proceed for Approvals"
                            [ngClass]="((row.nReviewrgp_type === 'sale' && row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED')) || row.doc_sign === 'SENT') ? 'disabled' : 
                                        ((row.nrgp_type === 'sale' && row.nrgp_status === 'APPROVED') || row.nrgp_type !== 'sale') ? 'enabled' : 'disabled'"
                            (click)="ngClassCheck(row) ? getApprovals(approvalsmodel, row) : null">
                            how_to_reg
                        </mat-icon>
                        

                          
                          <!-- <mat-icon 
                              matTooltip="Proceed for Approvals" 
                              [ngClass]="((row.nrgp_type === 'sale' && row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED')) || row.doc_sign == 'SENT') ? 'disabled' : 
                                         ((row.nrgp_type === 'sale' && row.nrgp_status === 'APPROVED') || row.nrgp_type !== 'sale') ? 'enabled' : 'disabled'"
                              (click)="(row.nrgp_type === 'sale' && !(row.nrgp_status?.includes && row.nrgp_status.includes('REJECTED'))) ? getApprovals(approvalsmodel, row) : null">
                              how_to_reg
                          </mat-icon> -->
                          
                          </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef style="width: 15%; text-align: center !important;" class="text-center">
                            Action
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            <div class="mb-0"></div>
                            <mat-icon matTooltip="View" style="border:none;background: none;cursor: pointer;" (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                            <mat-icon  
                              style="border: none; background: none; cursor: pointer; color: blue;"
                              [hidden]="row.doc_sign === 'SENT'"
                              (click)="editNrgpData(row, EditListModel)" matTooltip="Edit">edit</mat-icon>
                            <mat-icon style="color: red; border: none; background: none; cursor: pointer;" (click)="deleteNrgpItem(row, deleteItemModel)" matTooltip="Delete">delete</mat-icon>&nbsp;
                            <mat-icon (click)="printNRGP(row)" style="cursor: pointer" matTooltip="Print">local_printshop</mat-icon>&nbsp;
                            <mat-icon (click)="openfileuploadmodel(fileuploadmodel, row)" style="cursor: pointer; font-size: 24px; color:#28b531" matTooltip="File Upload">file_upload</mat-icon>&nbsp;
                            <mat-icon matTooltip="Proceed for Approvals" 
                                      [ngClass]="{'hidden-icon': row.doc_sign === 'SENT', 'enabled': row.doc_sign !== 'SENT'}" 
                                      (click)="getApprovals(approvalsmodel, row)">how_to_reg</mat-icon>
                          </td>
                        </ng-container> -->
                        

                        <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                      </table>
                    </div>
                    <div *ngIf="reaData == true" class="text-center">
                      <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                        Loading...
                      </h4>
                      <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                        No Records Found
                      </h4>
                    </div>
                    <!-- <mat-paginator [length]="totalRecords" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 75, 100]"
                      #masterpaginator (page)="onpageevent($event)">
                    </mat-paginator>   -->
                    <div class="d-flex justify-content-end" *ngIf="totalRecords > 0">
                      <mat-paginator [length]="totalRecords" [pageSize]="10"
                          [pageSizeOptions]="[5,10, 50, 100,500, 1000]" #paginator
                          (page)="onpageevent($event)">
                      </mat-paginator>
                      <div class="go-to-container pagenos">
                          <div class="go-to-label">Page Nos: </div>
                          <mat-form-field>
                              <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
                                  <mat-option *ngFor="let pageNumber of pageNumbers" [value]="pageNumber">{{pageNumber}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-tab-content>
        </mat-card>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> Approval in process </ng-template>
      <ng-template matTabContent>
          <mat-card>
              <mat-card-content>
                  <ng-container>
                      <div class="row m-t-10">
                          <div class="col-lg-4 col-md-5 mt-2 mb-3 d-flex">
                              <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                              <select class="dropdownstyles" name="columnnameap" [(ngModel)]="columnnameap"
                                  (change)="onChangeap()">
                                  <option value="" selected disabled>Select Column</option>
                                  <!-- <ng-container *ngFor="let obj of columnnaplist">
                                      <ng-container *ngFor="let keyValuePair of obj | keyvalue">
                                          <option [ngValue]="keyValuePair.value">{{ keyValuePair.key | titlecase}}</option>
                                        </ng-container>
                                      </ng-container> -->
                                      <option value="document_number">Document Number</option>
                                      <option value="concern_name">Concern Name</option>
                              </select>
                              
                        
                          </div>
                          <div class="col-md-3 col-lg-3 mt-2 mb-3 ">
                              <input type="search" class="searchfield inputstyles" placeholder="Search"
                                  [(ngModel)]="searchDataap" (input)="searchap1()">
                          </div>
                          <div class="col-lg-5 col-md-4 mt-2 mb-3 text-right">
                              <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getDataap1()"
                              class="custom-icon-button ml-3">
                              <span class="material-icons">
                                  refresh
                              </span>
                          </button>
                          </div>
                          <div class="table-responsive mat-table-wrapper m-2">
                              <div class="example-table-container approvalprocess">
                                  <table mat-table [dataSource]="dataSourcemainap" matSort>
                                      <ng-container matColumnDef="sno">
                                          <th mat-header-cell *matHeaderCellDef >S.NO</th>
                                          <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                              {{ calculateSerialNumber(i) }}
                                          </td>
                                      </ng-container>
                                      <ng-container matColumnDef="document_number">
                                          <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="document_number">Document Number
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-left">
                                              {{row.document_number}}
                                          </td>
                                      </ng-container>
                                      <ng-container matColumnDef="pending_at">
                                          <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="document_name">Pending At
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-left">
                                              {{row.pending_at !== "" ? row.pending_at :'N/A' }}
                                          </td>
                                      </ng-container>
                                      <ng-container matColumnDef="company_Name">
                                          <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="company_name">Company Name
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-left text-ellipis">
                                              {{row.company_name }}
                                          </td>
                                      </ng-container>

                                      <ng-container matColumnDef="concern_name">
                                          <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="concern_name">Concern Name
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-left text-ellipis">
                                              {{row.concern_name}}
                                          </td>
                                      </ng-container>

                                     
                                      <ng-container matColumnDef="description">
                                          <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header="description">Description
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-left">
                                              {{row.description }}
                                          </td>
                                      </ng-container>
                                      <ng-container matColumnDef="level">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header="level">Pending Level
                                          </th>
                                          <td mat-cell *matCellDef="let row">
                                              {{row.level }}
                                          </td>
                                      </ng-container>
                                     
                                      <ng-container matColumnDef="action">
                                          <th mat-header-cell *matHeaderCellDef 
                                              class="listactionbth text-center">
                                              Action
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="text-center">
                                              <mat-icon matTooltip="View" class="eyeicon"
                                                  (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                              <mat-icon matTooltip="Upload"
                                                  (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                  class="listfilebtn">file_upload</mat-icon>
                                          </td>
                                      </ng-container>


                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsap;sticky: true">
                                      </tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsap"></tr>
                                  </table>
                              </div>
                              <div *ngIf="reaDataap" class="text-center">
                                  <h4 class="text-secondary m-3">
                                      No Records Found
                                  </h4>
                              </div>
                          <div class="d-flex justify-content-end" *ngIf="!reaDataap && totalRecordsap > 5">
                              <mat-paginator [length]="totalRecordsap" [pageIndex]="pageIndexap-1" [pageSize]="pageSizeap"
                                  [pageSizeOptions]="[5,10, 50, 100,500, 1000]" #paginatorap
                                  (page)="onpageeventap($event)">
                              </mat-paginator>
                              <div class="go-to-container pt-1">
                                  <div class="go-to-label">Page Nos: </div>
                                  <mat-form-field>
                                      <mat-select [(ngModel)]="goToap" (selectionChange)="goToChangeap()">
                                          <mat-option *ngFor="let pageNumber of pageNumbersap" [value]="pageNumber">{{pageNumber}}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                              </div>
                          </div>
                          </div>
                      </div>
                  </ng-container>
              </mat-card-content>
          </mat-card>

      </ng-template>
  </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Activity Log
      </ng-template>
      <ng-template matTabContent>
        <app-activitylog [activitylogfor]="'OutGatePassNRGP'"></app-activitylog>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-template #nrgpModel>
  <div mat-dialog-title class="dialog-title mb-0">
    <!-- <h2>Add Items</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
      style="border: none; background: none;margin-top: -5%;">
      <mat-icon class="icon1">close</mat-icon>
    </button> -->
    <div class="row">
      <div class="col-11">
        <h2>Add Items</h2>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
      </div>
    </div>
  </div>
  <mat-dialog-content style="overflow: initial">
    <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
      <div class="row">
        <div class="col-md-2">
          <label>Material Code/Name<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-10">
          <mat-form-field class="example-full-width" style="
              width: 100%;
              border: 1px solid;
              border-radius: 5px;
              height: 52%;
            " appearance="none">
            <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
              style="border: none" class="inputdropdown3" name="matcode" [(ngModel)]="model.matcode"
              (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />

            <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="selectedmastergroup()">
              <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                {{ item.system_reference_1 }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        
      </div>
      <div class="row uom">
        <div class="col-md-2">
          <label>Storage Location</label>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width" style="
              width: 100%;
              border: 1px solid;
              border-radius: 5px;
              height: 52%;
            " appearance="none">
            <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput style="border: none"
              class="inputdropdown3" name="storagelocation" [(ngModel)]="model.storagelocation"
              (keyup)="getstorageDATA($event)" [matAutocomplete]="auto5" />

            <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
              <mat-option *ngFor="let item of smsdata1" [value]="item">
                {{ item }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <label>Unit Of Measurement<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="text" class="inputstyles" name="uom" placeholder="Enter Unit of Measurement" [(ngModel)]="model.uom" />
        </div>
      
      </div>
      <div class="row mt-0">
        <div class="col-md-2">
          <label>Request Quantity<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="reqqty" [(ngModel)]="model.reqqty" placeholder="Enter Request Quantity"
            (keypress)="keyPressNumbers($event)" (keyup)="getNRGPQTY()" (input)="qtydecimalFilter($event)"/>
        </div>
        <div class="col-md-2">
          <label>NRGP Quantity<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="qty" [(ngModel)]="model.qty" 
           (keypress)="keyPressNumbers($event)"  (keyup)="getNRGPQTY()" (input)="qtydecimalFilter($event)"/>
        </div>
       
      </div>
      <div class="row mt-3">

        <div class="col-md-2">
          <label>Unit Price<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" placeholder="Enter Unit Price" [(ngModel)]="model.unit_price"
            name="unit_price" #unit_price="ngModel" (keyup)="getNRGPQTY()" min="0" (input)="decimalFilter($event)"/>
        </div>
        <div class="col-md-2">
          <label>Total Price<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="basic_price" [(ngModel)]="model.basic_price"
            (keypress)="keyPressNumbers($event)" readonly>
        </div>
       
      </div>
      <div class="row mt-3">
        <div class="col-md-2">
          <label>Valuation Type<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <select class="valutiontype" name="valution_type" [(ngModel)]="model.valutiontype">
            <option selected disabled value="undefined">Select One</option>
            <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
              {{ valution.code }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label>Item Remarks</label>
        </div>
        <div class="col-md-4">
          <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model.itemremarks" placeholder="Enter Item Remarks"/>
        </div>
        <!-- <div class="col-md-2">
          <label>Batch No<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input
            type="text"
            class="inputstyles"
            name="batchno"
            [(ngModel)]="model.batchno"
          />
        </div> -->
      </div>
      <br />
     <div class="text-center">
      <button mat-raised-button class="savebutton" 
      [disabled]="!model.matcode || !model.uom || !model.reqqty || !model.qty || model.unit_price === null || model.unit_price === undefined || model.unit_price === '' || !model.basic_price || !model.valutiontype" >
        Add
      </button>
      </div>
    </form>
  </mat-dialog-content>
  <br />
  <!-- <div *ngIf="smsdata" class="dialogdata">
    <table>
      <thead>
        <tr class="tablestyles">
          <th>Company Name</th>
          <th>Storage Location</th>
          <th>Valuation Type</th>
          <th>Quantity</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of smsdata">
          <td style="width: 28%">{{ item.company_name }}</td>
          <td>{{ item.storage_location }}</td>
          <td>{{ item.valuation_type }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{item.value | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</ng-template>
<!-- edit table data -->
<ng-template #nrgpEditModel>
  <div mat-dialog-title class="dialog-title mb-0">
    <!-- <h2>Edit Items</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
      style="border: none; background: none;margin-top: -5%;">
      <mat-icon class="icon1">close</mat-icon>
    </button> -->
    <div class="row">
      <div class="col-11">
        <h2>Edit Items</h2>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button> 
      </div>
    </div>
  </div>
  <mat-dialog-content style="overflow: initial">
    <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
      <div class="row">
        <div class="col-md-2">
          <label>Material Code/Name<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-10">
          <mat-form-field class="example-full-width" style="
              width: 100%;
              border: 1px solid;
              border-radius: 5px;
              height: 52%;
            " appearance="none">
            <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
              style="border: none" class="inputdropdown3" name="matcode" [(ngModel)]="model2.matcode" #matcode="ngModel"
              (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" required/>
              <div *ngIf="matcode.dirty">
                <div class="editItemerrors" *ngIf="matcode.errors?.required">
                  Material Code is required
                </div>
              </div> 
            <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
              (optionSelected)="selectedmastereditgroup()">
              <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                {{ item.system_reference_1 }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      
      </div>
      <div class="row uom">
        <div class="col-md-2">
          <label>Storage Location</label>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width" style="
              width: 100%;
              border: 1px solid;
              border-radius: 5px;
              height: 52%;
            " appearance="none">
            <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput style="border: none"
              class="inputdropdown3" name="storagelocation" [(ngModel)]="model2.storagelocation"
              (keyup)="getstorageDATA1($event)" [matAutocomplete]="auto7"/>

            <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
              <mat-option *ngFor="let item of smsdata1" [value]="item">
                {{ item }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <label>Unit Of Measurement<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="text" class="inputstyles" name="uom" [(ngModel)]="model2.uom" #uom="ngModel" required/>
          <div *ngIf="uom.dirty">
            <div class="editItemerrors" *ngIf="uom.errors?.required">
             UOM is required
            </div>
          </div> 
        </div>
      
      </div>
      <div class="row mt-0">
        <div class="col-md-2">
          <label>Request Quantity<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="reqqty" [(ngModel)]="model2.reqqty" #reqqty="ngModel"
            (keypress)="keyPressNumbers($event)" (keyup)="getNRGPQTYedit()" (input)="qtydecimalFilter($event)" required/>
            <div *ngIf="reqqty.dirty">
              <div class="editItemerrors" *ngIf="reqqty.errors?.required">
                Request Quantity is required
              </div>
            </div> 
        </div>
        <div class="col-md-2">
          <label> NRGP Quantity<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="qty" [(ngModel)]="model2.qty" #qty="ngModel"
            (keypress)="keyPressNumbers($event)" (keyup)="getNRGPQTYedit()" (input)="qtydecimalFilter($event)" required/>
            <div *ngIf="qty.dirty">
              <div class="editItemerrors" *ngIf="qty.errors?.required">
                NRGP Quantity is required
              </div>
            </div> 
        </div>
      
      </div>
      <div class="row mt-3">
        <div class="col-md-2">
          <label>Unit Price<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" placeholder="Enter Unit Price" [(ngModel)]="model2.unit_price"
            name="unit_price" #unit_price="ngModel" (keyup)="getNRGPQTYedit()" min="0" (input)="decimalFilter($event)" required />
          <div *ngIf="unit_price.dirty">
            <div class="editItemerrors" *ngIf="unit_price.errors?.required">
              Unit Price is required
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label>Total Price<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input type="number" class="inputstyles" name="basic_price" min="0" [(ngModel)]="model2.basic_price"
            (keypress)="keyPressNumbers($event)" readonly>
        </div>
        
      </div>
      <div class="row mt-3">
        <div class="col-md-2">
          <label>Valuation Type<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <select class="valutiontype" name="valution_type" [(ngModel)]="model2.valutiontype">
            <option selected disabled>Select One</option>
            <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
              {{ valution.code }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label>Item Remarks</label>
        </div>
        <div class="col-md-4">
          <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model2.itemremarks"  />
        </div>
        <!-- <div class="col-md-2">
          <label>Batch No<span class="mandetory">*</span></label>
        </div>
        <div class="col-md-4">
          <input
            type="text"
            class="inputstyles"
            name="batchno"
            [(ngModel)]="model2.batchno"
            readonly
          />
        </div> -->

      </div>
      <br/>
      <div class="text-center">
      <button mat-raised-button class="savebutton" [disabled]="!model2.matcode || !model2.uom || !model2.reqqty || !model2.qty || 
      model2.basic_price=== undefined || model2.basic_price === null || 
      model2.basic_price === '' || !model2.valutiontype || 
      model2.unit_price === undefined || model2.unit_price === null || 
      model2.unit_price === ''">
        Update
      </button>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>
<!-- reason for edit list -->
<ng-template #EditListModel>
  <div mat-dialog-title class="mt-2">
    <div class="row">
      <div class="col-12 text-right">
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
    </div>
    </div>
  </div>
  <!-- <h1 mat-dialog-title></h1> -->
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-2">
        <label style="padding-top: 5px">Reason</label>
      </div>
      <div class="col-md-8">
        <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
          [ngModel]="editModel.reason" (ngModelChange)="editModel.reason=$event.trim()" 
          (input)="editModel.reason=$event.target.value.trim()" />
      </div>
    </div>
  </div>
  <br />
  <div mat-dialog-actions class="justify-content-center">
    <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
    <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton" [disabled]="!editModel.reason" style="background-color: #8dd94a;">
      Submit
    </button>
  </div>
</ng-template>
<!-- reason for delete list -->
<ng-template #deleteItemModel>
  <div mat-dialog-title class="mb-0">
    <!-- <h1>Delete Activity</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
      style="border: none; background: none; margin-top: -13%">
      <mat-icon class="icon1">close</mat-icon>
    </button> -->
    <div class="row">
      <div class="col-11">
        <h1>Delete Outgatepass NRGP</h1>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
      </div>
    </div>
  </div>
  
  <div mat-dialog-content>
    <p>Are you sure, you want to delete record?</p>
    <div class="row">
      <div class="col-md-2">
        <label style="padding-top: 5px">Reason</label>
      </div>
      <div class="col-md-8">
        <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
          [ngModel]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
          (input)="deletemodel.reason=$event.target.value.trim()"  />
      </div>
    </div>
  </div>
  <br />
  <div mat-dialog-actions class="justify-content-center">
    <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
    <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton" [disabled]="!deletemodel.reason">
      Delete
    </button>
  </div>
</ng-template>
<!-- upload file in list -->
<ng-template #fileuploadmodel>
  <div mat-dialog-title class="dialog-title mb-0">
    <!-- <h2>Out Gate Pass: {{ nrgpnum }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
      style="border: none; background: none; margin-top: -6%">
      <mat-icon class="icon1">close</mat-icon>
    </button> -->
      <div class="row">
        <div class="col-11">
          <h2>Out Gate Pass: {{ nrgpnum }}</h2>
        </div>
        <div class="col-1 text-right">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
        <mat-icon class="icon1">close</mat-icon>
      </button>
        </div>
    </div>
  </div>
  <div mat-dialog-content class="row">
    <div *ngIf="filedata">
      <!-- <span *ngFor="let item of filedata"> -->
      <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
      <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
      style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
      <table class="text-center">
        <thead style="background: #e9ecef">
          <!-- <tr> -->
          <th>Created By</th>
          <th>Created Date</th>
          <th>File Name</th>
          <th>Action</th>
          <!-- </tr> -->
        </thead>
        <tbody>
          <tr *ngFor="let item of filedata">
            <td>{{ item.created_by }}</td>
            <td>{{ item.created_date }}</td>
            <td class="tablebody">
              {{ item.file_name }}
            </td>
            <td>
              <i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                title="View File" (click)="viewDoc(item)"></i>
              <button mat-icon-button style="color: red; border: none; cursor: pointer"
                aria-label="Example icon button with a vertical three dot icon"
                (click)="deleterowfile(deletefileModel, item)" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="filedata.length===0">
        No Data Found
    </div>
      <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
      <!-- </span> -->
    </div>
  </div>
  <br />
  <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
    Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon>
  </button>
  <div class="file">
    <input hidden class="form-control-file" name="file" type="file" multiple
      (change)="uploadWbsFile($event)" #autoFileUpload1 accept=
      "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
      text/plain, application/pdf"/>
    <ng-container *ngIf="fileUploadUrls.length > 0">
      <div *ngFor="let item of filenamearray1; let i = index">
        <!-- {{ i + 1 }}.&nbsp;{{ item }} -->
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #deletefileModel>
  <h1 mat-dialog-title>Delete File</h1>
  <div mat-dialog-content>Are you sure, you want to delete file?</div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
    <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">
      Delete
    </button>
  </div>
</ng-template>


<!-- rgp model -->
<ng-template #pomodel>
  <div mat-dialog-title class="dialog-title mb-0">
      <!-- <h2>Select RGP </h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
          style="border:none;background:none;margin-top: -9%;">
          <mat-icon class="icon1">close</mat-icon>
      </button> -->
      <div class="row">
        <div class="col-11">
          <h2>Select RGP </h2>
        </div>
        <div class="col-1 text-right">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
          <mat-icon class="icon1">close</mat-icon>
      </button>
        </div>
      </div>
  </div>
  <form #myrgpform="ngForm" (ngSubmit)="submitpono(myrgpform)">
    <div class="row" style="margin-left: 5px">
      <h2 *ngIf="dcnumber">DC Number:&nbsp;{{ dcnumber }}</h2>
    </div>
      <div class="row">
          <div class="col-md-10 mt-2">
            <!-- <label class="text-left">Select RPG Number<span class="mandetory">*</span></label><br> -->
              <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
          [(ngModel)]="copieddmr"> -->
              <mat-form-field class="example-full-width" appearance="outline" style="width:100%;height: 35px;">
                  <input type="text" placeholder="Search RGP Number " aria-label="Number" matInput
                     class="inputdropdown" name="rgpnumber"
                      [(ngModel)]="rgpnumber" (keyup)="getpurchaseorderDATA($event)" [matAutocomplete]="auto4" (input)="trimInput()"/>

                  <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">

                      <mat-option *ngFor="let item of rgpdata" [value]="item.document_number">
                          {{item.document_number}}</mat-option>
                  </mat-autocomplete>
              </mat-form-field>
          </div>
          <div class="col-md-2 mt-2 pt-1">
      <button mat-raised-button  class="savebutton submitBtn" style="font-weight: 500;background-color: #8dd94a;
          " [disabled]="!rgpnumber">Submit</button>
          </div>
      </div>

  </form>
</ng-template>


<ng-template #dcmodel>
  <div mat-dialog-title class="dialog-title mb-0">
      <!-- <h2>Select Delivery Challan</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
          style="border:none;background:none;margin-top: -9%;">
          <mat-icon class="icon1">close</mat-icon>
      </button> -->
        <div class="row">
          <div class="col-11">
            <h2>Select Delivery Challan</h2>
          </div>
          <div class="col-1 text-right">
            <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
          <mat-icon class="icon1">close</mat-icon>
        </button>
          </div>
      </div>
  </div>
  <form #mypoform="ngForm" (ngSubmit)="submitdcno(mydcform)">
      <div class="row">
          <div class="col-md-10 mt-2">
              <!-- <label>Select Delivery Challan Number<span class="mandetory">*</span></label> -->
              <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
          [(ngModel)]="copieddmr"> -->
              <mat-form-field class="example-full-width" appearance="outline" style="width:100%;">
                  <input type="text" placeholder="Search Delivery Challan Number " aria-label="Number" matInput
                      style="border:none;margin-bottom: 1%;" class="inputdropdown1" name="dcnumber"
                      [(ngModel)]="dcnumber" (keyup)="getDCDATA($event)" [matAutocomplete]="auto44" (input)="trimInput()" />

                  <mat-autocomplete autoActiveFirstOption #auto44="matAutocomplete">

                      <mat-option *ngFor="let item of DCDATA" [value]="item.number">
                          {{item.number}}</mat-option>
                  </mat-autocomplete>
              </mat-form-field>
          </div>
          <div class="col-md-2 mt-2 pt-1">
            <button mat-raised-button class="savebutton submitBtn" style="font-weight: 500;background-color: #8dd94a;" [disabled]="!dcnumber">Submit</button>
          </div>
      </div>

  </form>
</ng-template>
<!-- Approvals -->
<ng-template #approvalsmodel>
  <!-- <span class="d-flex justify-content-between">
      <h1 mat-dialog-title>Document Number:{{documnet_nodata}}</h1>
      <button mat-icon-button class="close-button closeicon" [mat-dialog-close]="true" style="margin-top: 0%;">
          <mat-icon class="icon1">close</mat-icon>
      </button>
  </span> -->
  <div mat-dialog-title class="mb-0">
    <div class="row">
      <div class="col-11">
        <h1>Document Number:{{documnet_nodata}}</h1>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
      <p>Do you confirm to proceed for Signatures</p>
  </div>
  <div mat-dialog-actions>
      <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
          Confirm
      </button>&nbsp;
      <button mat-button mat-dialog-close class="matButton" >
          Cancel</button>
  </div>
</ng-template>

<!-- <ng-template #viewmodel>
  <h1 mat-dialog-title></h1>
  <div mat-dialog-content>
      <div class="row">
          <div class="col-md-2">
              <label style="padding-top:5px;">Reason</label>
          </div>
          <div class="col-md-4">
              <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                  [(ngModel)]="viewModel.reason" style="width: 181%;">
          </div>
      </div>
  </div><br>
  <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="viewreason()" class="matButton"
          [disabled]="!viewModel.reason">Submit</button>
  </div>
</ng-template> -->

<!-- signature status -->

<ng-template #signaturestatus>
  <!-- <span class="d-flex justify-content-between">
      <h1 mat-dialog-title>Signature Status</h1>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
  </span> -->
  <div mat-dialog-title class="mb-0">
    <div class="row">
      <div class="col-11">
        <h1>Signature Status</h1>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
      <p>Document Number:{{viewnumber}}</p>
      <table *ngIf="viewData" class="signTable">
        <thead style="background: #e9ecef" class="text-center headerclass">
            <th>S.NO</th>
            <th>Signature Levels</th>
            <th>Signed By</th>
            <th>Date & Time</th>
            <th>Status</th>

        </thead>
        <tbody class="text-center">
            <tr *ngFor="let item of SignData; let i = index">
                <td>{{i+1}}</td>
                <td>{{item.description}}</td>
                <td>{{item.updated_by}}</td>
                <td>{{item.updated_date | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                <td><span *ngIf="item.status==='COMPLETED'"  ><mat-icon style="color: green; border: none">check_circle</mat-icon></span>
                    <span *ngIf="item.status==='PENDING'" ><mat-icon style="color: orange; border: none">warning</mat-icon></span>
                    <!-- <span *ngIf="item.status===null" >NA</span> -->
                </td>

            </tr>
            <tr *ngIf="SignData.length===0">
                <td colspan="3">No Records Found</td>
            </tr>
    </table>

  </div>

</ng-template>

<!-- Approvals -->
<ng-template #approvalmodeldatastatus> 
  <!-- <span class="d-flex justify-content-between">
      <h1 mat-dialog-title *ngIf="viewnumber">Document Number:{{viewnumber}}</h1>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
  </span> -->
  <div mat-dialog-title class="mb-0">
    <div class="row">
      <div class="col-11">
        <h1 *ngIf="viewnumber">Document Number:{{viewnumber}}</h1>
      </div>
      <div class="col-1 text-right">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
      <mat-icon class="icon1">close</mat-icon>
    </button>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
      <p>Do you confirm to proceed for Signatures</p>
  </div>
  <div mat-dialog-actions>
      <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
          Confirm
      </button>&nbsp;
      <button mat-button mat-dialog-close class="matButton" >
          Cancel</button>
  </div>
</ng-template>


<ng-template #newFileUploadModal>
  <div mat-dialog-title class="dialog-title mb-0">
      <!-- <h2 class="file1">File Upload</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon close2"
                  (click)="closemodel()" >
                  <mat-icon class="icon1">close</mat-icon>
              </button> -->
                <div class="row">
                  <div class="col-11">
                    <h2 class="file1">File Upload</h2>
                  </div>
                  <div class="col-1 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
                  <mat-icon class="icon1">close</mat-icon>
                </button>
                  </div>
                </div>
  </div>
  <div  *ngIf="!editeddata">
  <div mat-dialog-content class="row">
      <div *ngIf="selectedfiles.length > 0">
          <table>
              <thead class="table2">
                  <th>S.NO</th>
                  <th>File Name</th>
                  <th>Action</th>
              </thead>
              <tbody class="text-center">
                  <tr *ngFor="let item of selectedfiles;let i=index;">
                      <td>{{i+1}}</td>
                      <td class="tablebody1">{{item.name}}</td>
                      <td>
                          <button mat-icon-button class="delete1" matTooltip="Delete"
                          (click)="deleteFileItem(i)">
                              <mat-icon>delete</mat-icon>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()" >
      Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
  <div class="file">
      <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadprfiles($event)"
          #autoFileUpload2 accept=
          "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf">
  </div>
</div>

<div  *ngIf="editeddata">
  <div mat-dialog-content class="row">
    <div *ngIf="filedata">
      <table>
        <thead style="background: #e9ecef;">
          <!-- <tr> -->
          <th>Created By</th>
          <th>Created Date</th>
          <th>File Name</th>
          <th>Action</th>
          <!-- </tr> -->
        </thead>
        <tbody>
          <tr *ngFor="let item of filedata">
            <td>{{item.created_by}}</td>
            <td>{{item.created_date}}</td>
            <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 " style="cursor: pointer;"
                title="View File" (click)="viewDoc(item)"></i></td>
            <td>
              <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                aria-label="Example icon button with a vertical three dot icon" matTooltip="Delete"
                (click)="deleterowfile(deletefileModel,item)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="filedata.length===0">
        No Data Found
    </div>
    </div>
  </div><br>
  <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
  <div class="file">
    <input hidden class="form-control-file" name="file" type="file" multiple
      (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 accept=
      "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
      text/plain, application/pdf"/>
    <!-- <ng-container *ngIf="fileUploadUrls.length>0">
      <div *ngFor="let item of filenamearray1;let i=index">{{i+1}}.&nbsp;{{item}}</div>
      
    </ng-container> -->
  </div>
</div>
</ng-template>

<!-- sign -->
<ng-template #signmodel>
  <div mat-dialog-title class="dialog-title mb-0">
      <div class="row">
        <div class="col-11">
          <div class="col-11">
            <h2 *ngIf="signatureNumber">Document Number:{{signatureNumber}}</h2>
          </div>
        </div>
        <div class="col-1 text-right">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
        <mat-icon class="icon1">close</mat-icon>
      </button>
        </div>
    </div>
  </div>
  <div mat-dialog-content class="row">
    <div *ngIf="nrgp_log_data">
      <table class="text-center">
        <thead style="background: #e9ecef">
          <th>S.No</th>
          <th>Level</th>
          <th>Reviewer(s)</th>
          <th>Status</th>
          <th>Reviewed On</th>
          <th>Action</th>
        </thead>
        <tbody>
         
          <tr  *ngFor="let item of nrgp_log_data;let i=index;">
            <td>{{i+1}}</td>
            <td>{{item.description}}</td>
            <td [style.width.px]="250">
              <span [style.whiteSpace]="'normal'" *ngIf="item.status == 'PENDING' || item.status == null">
                <ng-container *ngIf="item.approvers?.length">
                  {{ getApproversNames(item.approvers) }}
                </ng-container>
              </span>
              <span [style.whiteSpace]="'normal'" *ngIf="item.status !== 'PENDING'">
                {{ item.reviewedBy }}
              </span>
            </td>
            
            <td>
             {{item.status}}
            </td>
            <td>{{item.date !== null ? (item.date | date: "dd-MM-yyyy") : '-'}}</td>
            <td>
            
            
              <ng-container *ngFor="let ele of signatureStatusData">
                <span class="d-flex justify-content-center" *ngIf="item.status !== 'APPROVED' || item.status !== 'REJECTED'">
                  <button mat-raised-button color="primary" style="margin-left: 10px;" 
                  (click)="isLevelPending(item, ele.nrgp_status, i) ? approveSignature(ele, 'APPROVED') : null"  
                    [ngClass]="{'enabled': isLevelPending(item,ele.nrgp_status, i), 'disabled': !isLevelPending(item,ele.nrgp_status, i)}">
                    Approve
                  </button>
                  <button mat-raised-button color="warn" style="margin-left: 10px;"
                  (click)="isLevelPending(item, ele.nrgp_status, i) ? approveSignature(ele, 'REJECTED') : null" 
                    [ngClass]="{'enabled': isLevelPending(item,ele.nrgp_status, i), 'disabled': !isLevelPending(item,ele.nrgp_status, i)}">
                    Reject
                  </button>
                </span>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="nrgp_log_data?.length===0">
        No Data Found
    </div>
    </div>
  </div>
  <br />
</ng-template>