import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core"
import { MatAutocompleteTrigger } from "@angular/material/autocomplete"
import { MatOption } from "@angular/material/core"
import { MatSelect } from "@angular/material/select"
import { Observable } from "rxjs"
import { CustomerService } from "src/app/services/customer.service"
import * as XLSX from "xlsx"
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, OnChanges {
  @Input() type: string
  @Input() tableHeaders: {}
  @Input() module: string
  @Input() headerType: string
  @Input() service: string
  @Input() command: string
  @Input() bodyType: string
  @Input() responceKey: string
  @Input() filters: any[]

  companyData: any = []
  tableData: any = []
  today: string = new Date().toISOString().split("T")[0]

  @Output() onSubmit = new EventEmitter<any>()
  @Output() onReset = new EventEmitter<void>()
  @Output() onExport = new EventEmitter<void>()

  filterValues: any = {}

  headerKeys: string[] = []
  isStaticService: boolean = false
  isDataLoaded: boolean = false
  allSelectedcmp: boolean = false
  @ViewChild("selectcmp") selectcmp: MatSelect
  //@Output() dataSubmitted = new EventEmitter<any>() // EventEmitter to pass data
  plantdata: any[] = []
  allSelectedplantName = false
  @ViewChild("selectplantName") selectplantName: MatSelect
  valutiondata: any[] = []
  allSelectedvaltype: boolean = false
  @ViewChild("selectvaltype") selectvaltype: MatSelect
  @ViewChild("selectprojectname") selectprojectname: MatSelect
  @ViewChild("selectmaterialname") selectmaterialname: MatSelect
  rowClicked: any
  purchaseOrderData: any[] = []
  selectedponumber: any
  selectedmaterial: any
  masterData: any[] = []
  selectematcode: any
  selectedmatcode: any
  purchaseRequestdata: any[] = []
  consolidateLink: any
  selectedcontractorName: any
  contractorData: any[]=[]
  allSelectedcgi = false
  @ViewChild("selectcgi") selectcgi: MatSelect
  isChecked: boolean = false
  xldownloadlink: any ;
  filteredContractorData: any[]=[]
  companyData1: any[]=[]
  selectedcompname:any
  allSelected1 = false
  @ViewChild("select1") select1: MatSelect
  RGPstatus: any[] = ["open", "closed", "cancelled"]
  constructor(public custservice: CustomerService) {}

  ngOnInit() {
    this.initializeFilterValues()
    this.getcompanydata()
    this.getplantdata()
    this.getvalutionData()
    this.getPONumberData()
    this.getpurchaseRequestdata()
    this.getmasterdata()
    if (this.module == "RMC" || this.module == "Stores") {
      this.getvalutionData()
    }
    if (this.module == "RMC") {
      this.getplantdata()
    }
    if(this.type == 'Goods Issue'){
      this.getcontractordata()
    }
    if(this.type == 'AvailableStock'){
      this.getcompanydata1()
    }
    // if (this.module == "MOS") {
    //   this.loadProjects()
    //   this.getmaterialdata()
    // }
    this.isStaticService = this.headerType === "static"
  }
  initializeFilterValues() {
    if(this.filters?.length > 0){
    this.filters.forEach((filter) => {
      if (filter.inputType === "date") {
        const today = new Date()
        if (filter.label === "From Date") {
          // Set default to yesterday
          const yesterday = new Date(today)
          yesterday.setDate(today.getDate() - 1)
          this.filterValues[filter.value] = yesterday
            .toISOString()
            .split("T")[0] // Format as YYYY-MM-DD
        } else if (filter.label === "To Date") {
          // Set default to today
          this.filterValues[filter.value] = today.toISOString().split("T")[0] // Format as YYYY-MM-DD
        }
      } else if (filter.inputType === "select_company") {
        this.filterValues[filter.value] = [] // Initialize as empty array
      } else if (filter.inputType === "select_plantname") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "select_valuationtype") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "search_ponumber") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "search_material") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "select_ponumber") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "search_vehiclenumber") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "search_vendorname") {
        this.filterValues[filter.value] = []
      } else if (filter.inputType === "select_transfer") {
        this.filterValues[filter.value] = []
        // } else if (filter.inputType === "select_projectname") {
        //   this.filterValues[filter.value] = []
        // } else if (filter.inputType === "select_materialname") {
        //   this.filterValues[filter.value] = []
        // } else if (filter.inputType === "select_approvaltype") {
        //   // this.filterValues[filter.value] = []
        //   this.filterValues[filter.value] = ""
      }else if (filter.inputType === "select_prnumber") {
        this.filterValues[filter.value] = []
      }else if (filter.inputType === "select_contractor") {
        this.filterValues[filter.value] = []
      }else if (filter.inputType === "search_company") {
        this.filterValues[filter.value] = []
      }else if (filter.inputType === "select_status") {
        this.filterValues[filter.value] = []
      }
    })
  }
    console.log(this.filterValues, "00000", this.selectcmp)
    // this.filterValues.allSelected = false // Initialize allSelected
  }

  getcompanydata() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1,
    }
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData = res.data
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    // Log when the inputs change
    if (changes.companyData) {
      console.log(
        "Updated company data from parent:",
        changes.companyData.currentValue
      )
    }

    if (changes.maxDate) {
      console.log("Updated max date from parent:", changes.maxDate.currentValue)
    }
  }

  isFormValid(): boolean {
    for (const filter of this.filters) {
      const value = this.filterValues[filter.value]

      if (filter.inputType === "date") {
        // Check if the date field is filled
        if (!value) return false
        if (!this.datevalidation()) return false
      } else if (filter.inputType === "select_company") {
        // Check if the company selection is made
        if (value.length === 0) return false
      } else if (filter.inputType === "select_plantname") {
        if (value.length === 0) return false
      } else if (filter.inputType === "select_valuationtype") {
        if (value.length === 0) return false
      } else if (filter.inputType === "search_ponumber") {
        if (value.length === 0) return false
      } else if (filter.inputType === "search_material") {
        // this.filterValues[filter.value] = []
        if (value.length === 0) return false
      } else if (filter.inputType === "select_ponumber") {
        if (value.length === 0) return false
      } else if (filter.inputType === "select_valuationtype") {
        // Check if the company selection is made
        if (value.length === 0) return false
      } else if (filter.inputType === "select_plantname") {
        // Check if the company selection is made
        if (value.length === 0) return false
        // } else if (filter.inputType === "select_projectname") {
        //   if (value.length === 0) return false
      }else if (filter.inputType === "select_prnumber") {
        if (value.length === 0) return false
      }else if (filter.inputType === "select_contractor") {
        if (value.length === 0) return false
      }else if (filter.inputType === "search_company") {
        if (value.length === 0) return false
      }else if (filter.inputType === "select_status") {
        if (value.length === 0) return false
      }
    }

    // Ensure the date range is valid
    if (this.filterValues.from_date && this.filterValues.to_date) {
      return (
        new Date(this.filterValues.to_date) >=
        new Date(this.filterValues.from_date)
      )
    }

    return true // All checks passed
  
  }
  onSelectAllcompanyName(event:any){
    event.preventDefault();
    this.allSelectedcmp = !this.allSelectedcmp;
    this.toggleAllSelection();
  }
  toggleAllSelection() {
    if (this.allSelectedcmp) {
      this.selectcmp.options.forEach((item: MatOption) => item.select())
    } else {
      this.selectcmp.options.forEach((item: MatOption) => item.deselect())
    }
  }
  optionClick() {
    let newStatus = true
    this.selectcmp.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false
      }
    })
    this.allSelectedcmp = newStatus
  }
  resetForm() {
    this.initializeFilterValues() // Re-initialize filterValues to reset
    this.tableData = []
    this.isDataLoaded = false
    this.allSelectedcmp = false
    this.allSelectedplantName = false
    this.allSelectedvaltype = false
    this.allSelectedcgi=false
    this.isChecked=false
    this.isCheckedval=false
    this.allSelected1=false
    // this.allSelectedProjects = false;
    // this.allSelectedmatname = false;
    // this.allSelectedProjects = false;
    // this.selectedProjects=[];
    // this.materialName = [];
    // this.filterValues = {};
  }
  isCheckedval: boolean = false
  getTableData() {
    const mainObj: any = {
      command: this.command,
    }
    if(this.filters?.length > 0){
      for (const filter of this.filters) {
        const key = filter.value
        mainObj[key] = this.filterValues[key]
        if (this.type == 'AvailableStock') {
          let array: any[] = [];
          array.push(this.filterValues[key]);
          mainObj[key] = array; 
        }
        if(this.type == 'SingleMaterialStock'){
          const parts = this.filterValues[key].split('-');
          mainObj[key] = parts[0];
        }
      }
    }
    if (this.module === "MOS") {
      mainObj["getData"] = ""
    }
    if(this.type == 'Goods Issue'){
       mainObj["value"] = this.isChecked
    }
    if(this.type == 'OutGatePassRGPDashboardReports' && this.module == 'Stores'){
      mainObj['lmt'] = 1000000,
      mainObj['pid'] = 1
    }
    ;(
      this.custservice[this.service as keyof CustomerService] as (
        arg: any
      ) => Observable<any>
    )(mainObj).subscribe((res: any) => {
      if (mainObj.value) {
        this.isCheckedval = true 
      } else {
        this.isCheckedval = false
      }
   
      if(!(this.type == 'ConsolidatedStatement' && this.module === 'Stores')){
        this.tableData = res?.[this.responceKey]
        this.isDataLoaded = true
        this.headerKeys = this.isStaticService
          ? Object.keys(this.tableHeaders)
          : this.getHeaders(this.tableData)
      }
      if(res['status_code'] == 500){
        this.tableData =[]
      }
      if(this.type == 'ConsolidatedStatement' && this.module === 'Stores'){
        this.consolidateLink = res?.[this.responceKey]
      }
      if (res?.[this.responceKey].length === 0 && res?.file_path !== "") {
        this.xldownloadlink = res?.file_path        
      }

    })
  }

  getxldownload() {
    saveAs(this.xldownloadlink, "downLoad_file.xlsx")
  }
  exporttoExcelConsolidatedStatement(){
    window.open(this.consolidateLink)
  }
  // mosExcel() {
  //   const mainObj: any = {
  //     command: this.command,
  //   }
  //   for (const filter of this.filters) {
  //     const key = filter.value
  //     mainObj[key] = this.filterValues[key]
  //   }
  //   if (this.module === 'MOS') {
  //     mainObj['getData'] = 'EXCEL';
  //   }
  //   if (!mainObj['dateField']) {
  //     mainObj['dateField'] = 'created_date';
  //   }
  //   this.custservice.mosreports(mainObj).subscribe({
  //     next: (res: any) => {
  //       if (res.status === 'success') {
  //         window.open(res.location, '_blank', 'noopener,noreferrer');
  //       } else {
  //         console.error('Failed to retrieve Excel file:', res.message);
  //       }
  //     },
  //     error: (error: any) => {
  //       console.error('Error fetching the Excel file:', error);
  //     }
  //   });
  // }

  getHeaders(data: any) {
    const headers: string[] = []
    if (data) {
      data.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.includes(key)) {
            headers.push(key);
          }
        })
      })
    }
    return headers
  }
  @ViewChild("TABLE", { static: false }) TABLE: ElementRef

  exporttoExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.TABLE.nativeElement
    )
    ws["!rows"] = []
    ws["!rows"][0] = { hidden: false }
    ws["!rows"][1] = { hidden: false }
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
    XLSX.writeFile(wb, `${this.type}Details.xlsx`)
  }
  datevalidation() {
    if (
      (this.filterValues.from_date &&
        this.filterValues.to_date &&
        this.filterValues.from_date > this.filterValues.to_date) ||
      (this.filterValues.fromDate &&
        this.filterValues.toDate &&
        this.filterValues.fromDate > this.filterValues.toDate)
    ) {
      return false
    }
    return true
  }

  getRelevantFilterValues() {
    const displayValues: { label: any; value: any }[] = []
    if(this.filters?.length > 0){
    this.filters.forEach((filter) => {
      const key = filter.value

      // Check for the date values
      if (key === "fromDate" || key === "from_date") {
        const dateValue =
          this.filterValues["fromDate"] || this.filterValues["from_date"]
        if (dateValue) {
          displayValues.push({
            label: filter.label,
            value: dateValue,
          })
        }
      } else if (key === "toDate" || key === "to_date") {
        const dateValue =
          this.filterValues["toDate"] || this.filterValues["to_date"]
        if (dateValue) {
          displayValues.push({
            label: filter.label,
            value: dateValue,
          })
        }
      } else if (key === "company_name" || key === "companyName") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "plantName") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "valuation_type") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "search_ponumber") {
        displayValues.push({
          label: filter.lafilter.inputTypebel,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "search_material") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "select_ponumber") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "search_vehiclenumber") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      } else if (key === "search_vendorname") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      }else if (key === "search_company") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      }else if (key === "select_status") {
        displayValues.push({
          label: filter.label,
          value: this.filterValues[key] || "N/A",
        })
      }
    })

    return displayValues
  }
  }
  //plant name
  onSelectAllplantName(event:any){
    event.preventDefault();
    this.allSelectedplantName = !this.allSelectedplantName;
    this.toggleAllSelectionplantName();
  }
  toggleAllSelectionplantName() {
    if (this.allSelectedplantName) {
      this.selectplantName.options.forEach((item: MatOption) => item.select())
    } else {
      this.selectplantName.options.forEach((item: MatOption) => item.deselect())
    }
  }
  optionClickplantName() {
    let newStatusplantName = true
    this.selectplantName.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatusplantName = false
      }
    })
    this.allSelectedplantName = newStatusplantName
  }
  getplantdata() {
    let obj
    this.custservice.getplantMaster(obj).subscribe((res: any) => {
      if (res && res["status"] == "Success") {
        this.plantdata = res.data
      }
    })
  }
  // valuation type
  getvalutionData() {
    let obj = {
      lmt: 100000,
      pid: 1,
      command: "lst",
      key: "",
    }
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data
    })
  }
  onSelectAllvalType(event:any){
    event.preventDefault();
    this.allSelectedvaltype = !this.allSelectedvaltype;
    this.toggleAllSelectionvaltype();
  }
  toggleAllSelectionvaltype() {
    if (this.allSelectedvaltype) {
      this.selectvaltype.options.forEach((item: MatOption) => item.select())
    } else {
      this.selectvaltype.options.forEach((item: MatOption) => item.deselect())
    }
  }
  optionClickvaltype() {
    let newStatusvaltype = true
    this.selectvaltype.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatusvaltype = false
      }
    })
    this.allSelectedvaltype = newStatusvaltype
  }

  //row highlight
  avlstkhighlight(idx: any) {
    if (this.rowClicked === idx) this.rowClicked = -1
    else this.rowClicked = idx
  }
  // po number
  getpurchaseorderDATA(ev: any) {
    console.log(ev.target.value)
    this.selectedponumber = ev.target.value
    if (this.selectedponumber.length > 2) {
      this.getPONumberData()
    }
    if (!this.selectedponumber) {
      this.getPONumberData()
    }
  }
  getPONumberData() {
    let obj = {
      command: "lst",
      lmt: 1000000,
      pid: 1,
      key: this.selectedponumber || "",
    }
    this.custservice.getpurchaseorderdata(obj).subscribe((res: any) => {
      this.purchaseOrderData = res.data
    })
  }
  // material
  selectedmastergroup() {
    this.masterData.forEach((el: any) => {
      if (this.selectematcode == el.system_reference_1) {
        this.selectedmatcode = el.code
      }
    })
  }
  getmaterialDATA(ev: any) {
    this.selectedmaterial = ev.target.value
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata()
    }
    if (!this.selectedmaterial) {
      this.getmasterdata()
    }
  }
  getmasterdata() {
    let obj = {
      command: "lst",
      lmt: 100,
      pid: 1,
      key: this.selectedmaterial,
    }
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      this.masterData = res.data
    })
  }
  //purchase request number
  getpurchaseRequestdata() {
    let obj = {
      command: "lst",
      lmt: 1000000,
      pid: 1,
    }
    this.custservice.getpurchaseRequestdata(obj).subscribe((res: any) => {
      this.purchaseRequestdata = res.data
    })
  }
  //vendor and vehicle
  @ViewChild("triggerVehicle")
  autocompleteTriggerVehicle: MatAutocompleteTrigger
  @ViewChild("triggerVendor") autocompleteTriggerVendor: MatAutocompleteTrigger
  vendorData: any = []
  vehicledata: any = []
  filter(val: string): void {
    console.log(val)
    this.custservice
      .vehicleMasterListData({
        command: "lst",
        key: val,
        showLoader: "hide",
        lmt: 1000,
      })
      .subscribe((res: any) => {
        this.vehicledata = res.data.filter((item: any) => {
          return item.vehicle_number.toLowerCase().includes(val)
        })
        if (this.vehicledata.length > 0) {
          this.autocompleteTriggerVehicle.openPanel()
        }
      })
  }

  filtervenderName(val: string): void {
    console.log(val)
    this.custservice
      .vehicleMasterListData({
        command: "lst",
        key: val,
        showLoader: "hide",
        lmt: 1000,
      })
      .subscribe((res: any) => {
        this.vendorData = res.data.filter((item: any) => {
          return item.contractor_name.toLowerCase().includes(val)
        })
        if (this.vendorData.length > 0) {
          this.autocompleteTriggerVendor.openPanel()
        }
      })
  }
  // // projects
  // selectedProjects: any = []
  // allSelectedProjects: any
  // projectIds: any = []
  // userdetails: any;
  // projectsData: any
  // datafetching: boolean = false
  // filteredProjects: any
  // selectedProj: any;
  // project_id: any
  // proj_name: any
  // loadProjects() {
  //   this.datafetching = true
  //   this.userdetails = this.keycloackService.getKeycloakInstance().tokenParsed

  //   if (this.userdetails) {
  //     const params = new HttpParams()
  //       .set('email', this.userdetails.email)
  //       .set('role', this.userdetails.resource_access?.geps?.roles[0]);
  //     this.custservice.getUserListProjects(params).subscribe((resp: any) => {
  //       if (resp.status_code === 200) {
  //         this.projectsData = resp.data;
  //         this.filteredProjects = [...this.projectsData];
  //         this.datafetching = false

  //       }
  //     });
  //   }
  // }
  // selectedProject() {
  //   this.projectsData.forEach((ele: any) => {
  //     if (ele.proj_short_name == this.selectedProj) {
  //       this.project_id = ele.proj_id;
  //       this.proj_name = ele.name
  //     }
  //   });
  // }
  // filterProjects(searchTerm: string): void {
  //   if (!searchTerm) {
  //     this.filteredProjects = this.projectsData;
  //   } else {
  //     const searchLower = searchTerm.toLowerCase();
  //     this.filteredProjects = this.projectsData.filter((project: any) =>
  //       project.proj_short_name.toLowerCase().includes(searchLower)
  //     );
  //   }
  // }
  // onSelectAllprojectName(event:any){
  //   event.preventDefault();
  //   this.allSelectedProjects = !this.allSelectedProjects;
  //   this.toggleAllSelectionProjects();
  // }
  // toggleAllSelectionProjects() {
  //   if (this.allSelectedProjects) {
  //     this.selectprojectname.options.forEach((item: MatOption) => item.select())
  //   } else {
  //     this.selectprojectname.options.forEach((item: MatOption) => item.deselect())
  //   }
  // }
  // optionClickProjects() {
  //   let newStatusprojectname = true
  //   this.selectprojectname.options.forEach((item: MatOption) => {
  //     if (!item.selected) {
  //       newStatusprojectname = false
  //     }
  //   })
  //   this.allSelectedProjects = newStatusprojectname
  // }
  // // select material
  // previousFilteredData: any[] = [];
  // selectedMaterialArr: any = []
  // selectedAll: any = false;
  // newMaterialArray: any = [];
  // newMaterialSelectedArray: any = []
  // storedInput: any = ""
  // selectedMaterialItems: any[] = [];
  // allSelectedmatname = false;
  // filteredData: any[];
  // materialSearchTerm: string = '';
  // materialControl = new FormControl();
  // searchInput: string = '';
  // @ViewChild('materialSelect') materialSelect!: MatSelect;
  // materialName: any = [];
  // filteredItems: any[] = [];
  // selectedAllInput: any = ""
  // lastSearched: any = ""
  // isLoading: boolean = false
  // selectedItems = new FormControl([]);
  // allSelected = false;
  // indeterminate = false;
  // selectedmaterialname: any;
  // private searchSubject: Subject<string> = new Subject();
  // mosApprovalType = [
  //   { value: 'bom_approval', viewValue: 'BOM Approval Date' },
  //   { value: 'technical_specification', viewValue: 'Technical Specification Date' },
  //   { value: 'mnre_approval', viewValue: 'MNRE Approval Date' },
  //   { value: 'transco_approval', viewValue: 'Transco Approval Date' },
  //   { value: 'man_approval', viewValue: 'MAN Approval Date' },
  //   { value: 'pr_status_Date', viewValue: 'PR Status Date' },
  //   { value: 'po_release', viewValue: 'PO Release Date' },
  //   { value: 'created_date', viewValue: 'Created Date' }
  // ];
  // onOpened() {
  //   if (this.storedInput) {
  //     this.searchInput = this.storedInput;
  //     this.selectedmaterialname = this.storedInput;
  //   }
  //   this.getmasterdata();
  // }
  // addUniqueObject(item: any) {
  //   if (!this.selectedMaterialItems.some(existingItem => existingItem.system_reference_1 === item.system_reference_1)) {
  //     this.selectedMaterialItems.push(item);
  //   }
  // }

  // removeObject(item: any) {
  //   const index = this.selectedMaterialItems.findIndex(existingItem => existingItem.system_reference_1 === item.system_reference_1);
  //   if (index !== -1) {
  //     this.selectedMaterialItems.splice(index, 1);
  //   }
  // }
  // closeDropdown() {
  //   if (this.materialSelect) {
  //     this.materialSelect.close();
  //   }
  // }
  // onSelectAllmatName(event:any){
  //   event.preventDefault();
  //   this.allSelectedmatname = !this.allSelectedmatname;
  //   this.toggleAllSelectionmatname();
  // }
  // toggleAllSelectionmatname() {
  //   if (this.allSelectedmatname) {
  //     this.selectmaterialname.options.forEach((item: MatOption) => item.select())
  //   } else {
  //     this.selectmaterialname.options.forEach((item: MatOption) => item.deselect())
  //   }
  // }
  // optionClickmatname() {
  //   let newStatusmatname = true
  //   this.selectmaterialname.options.forEach((item: MatOption) => {
  //     if (!item.selected) {
  //       newStatusmatname = false
  //     }
  //   })
  //   this.allSelectedmatname = newStatusmatname
  // }
  // combineUniqueObjects(arr1: any[], arr2: any[], key: string): any[] {
  //   const combined = [...arr1, ...arr2];
  //   const uniqueObjects = combined.filter((obj, index, self) =>
  //     index === self.findIndex((o) => o[key] === obj[key])
  //   );
  //   return uniqueObjects;
  // }
  // getmaterialdata() {
  //   this.filteredData = []
  //   this.masterData = []
  //   this.isLoading = true
  //   let obj = {
  //     command: "lst",
  //     lmt: 1000,
  //     pid: 1,
  //     showLoader: "hide",
  //     key: this.selectedmaterialname,
  //   };
  //   this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
  //     this.lastSearched = this.selectedmaterialname

  //     this.masterData = res.data;
  //     this.filteredData = this.masterData;
  //     this.filteredItems = [...new Set(res.data)];
  //     this.updateSelectAllState();
  //     this.isLoading = false
  //     this.filteredData = this.combineUniqueObjects(this.filteredData, this.selectedMaterialItems, 'system_reference_1');

  //     if (!this.compareArrays(this.filteredData, this.previousFilteredData)) {
  //       this.allSelectedmatname = false;
  //     }
  //     if (this.isFilteredDataInSelectedArray(this.filteredData, this.selectedMaterialArr)) {
  //       this.allSelectedmatname = true;
  //     } else {
  //       this.allSelectedmatname = false;
  //     }
  //     this.previousFilteredData = [...this.filteredData];
  //   });
  // }
  // getselectmaterialDATA(ev: any) {
  //   this.selectedmaterialname = ev.target.value;
  //   this.searchInput = this.selectedmaterialname;
  //   if (this.selectedmaterialname.length > 2) {
  //     this.getmaterialdata();
  //   }
  //   if (!this.selectedmaterialname) {
  //     this.getmaterialdata();
  //   }

  // }
  // isFilteredDataInSelectedArray(filteredData: any[], selectedMaterialArr: any[]): boolean {
  //   return filteredData.every(item => selectedMaterialArr.includes(item.system_reference_1));
  // }
  // updateSelectAllState(): void {
  //   const selectedLength = this.selectedItems.value.length;
  //   const itemsLength = this.filteredItems.length;
  //   this.allSelected = selectedLength === itemsLength;
  //   this.indeterminate = selectedLength > 0 && selectedLength < itemsLength;
  // }
  // handleInputChange(value: string): void {
  //   this.searchSubject.next(value);
  // }
  // compareArrays(arr1: any[], arr2: any[]): boolean {
  //   if (arr1.length !== arr2.length) {
  //     return false;
  //   }
  //   for (let i = 0; i < arr1.length; i++) {
  //     if (arr1[i].system_reference_1 !== arr2[i].system_reference_1) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  //goods issue
  searchContractor(ev: any) {
    this.selectedcontractorName = ev.target.value
    if (this.selectedcontractorName.length > 2) {
      this.getcontractordata()
    }
    if (!this.selectedcontractorName) {
      this.getcontractordata()
    }
  }
  getcontractordata() {
    let obj = {
      command: "cml",
      lmt: 100000,
      pid: 1,
      key: this.selectedcontractorName || "",
      // showLoader: 'hide'
    }
    this.custservice.getSearchGoodsIssue1(obj).subscribe((res: any) => {
      this.contractorData = res.data
      this.filteredContractorData = this.contractorData.filter(item => item.contractor_name.trim() !== "");
    })
  }
    toggleAllSelectioncgi() {
      if (this.allSelectedcgi) {
        this.selectcgi.options.forEach((item: MatOption) => item.select())
      } else {
        this.selectcgi.options.forEach((item: MatOption) => item.deselect())
      }
    }
    optionClickcgi() {
      let newStatuscgi = true
      this.selectcgi.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatuscgi = false
        }
      })
      this.allSelectedcgi = newStatuscgi
    }
    isCellEmpty(header: string): boolean {
      if ((header === 'Unit Price' || header === 'Total Price') && this.isCheckedval && this.type == 'Goods Issue') {
        const cellValue = this.isStaticService
        ? Object.keys(this.tableHeaders)
        : this.getHeaders(this.tableData)                 
        return cellValue == null || (cellValue + '').trim() === '';
      }
      return false;
    }

    getcompanydata1() {
      let obj = {
        command: "lst",
        lmt: 100000,
        pid: 1,
        key:this.selectedcompname,
        showLoader: 'hide'
      }
      this.custservice.usercompanyData(obj).subscribe((res: any) => {
        this.companyData1 = res.data
      })
    }
    SearchCompanyData(ev: any) {
      console.log(ev.target.value)
      this.selectedcompname = ev.target.value
      if (this.selectedcompname.length > 2) {
        this.getcompanydata1()
      }
      if (!this.selectedcompname) {
        this.getcompanydata1()
      }
    }
    //outgatepass rgp
    onSelectAllRGP(event: KeyboardEvent) {
      event.preventDefault();
      this.allSelected1 = !this.allSelected1;
      this.toggleAllSelection1();
    }
    toggleAllSelection1() {
      if (this.allSelected1) {
        this.select1.options.forEach((item: MatOption) => item.select())
      } else {
        this.select1.options.forEach((item: MatOption) => item.deselect())
      }
    }
    optionClick1() {
      let newStatus = true
      this.select1.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false
        }
      })
      this.allSelected1 = newStatus
    }
    statusData(event: any) {
      this.RGPstatus.push(event.target.value)
    }
  }
