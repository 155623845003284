import { Component, OnInit, VERSION } from '@angular/core';
import { NgxPrintElementService } from "ngx-print-element";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import Swal from 'sweetalert2';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-printgoodsreceipt2',
  templateUrl: './printgoodsreceipt2.component.html',
  styleUrls: ['./printgoodsreceipt2.component.scss']
})
export class Printgoodsreceipt2Component implements OnInit {
  key: any;
  field: "number";
  command: "mat";
  userData: any[] = [];
  basic_price: any[] = [];
  dataaa: any[] = [];
  table: any[] = [];
  otherCharges: number;
  pageSize: any;
  pageIndex: any;
  searchData: string;
  vendordata: any[] = [];
  totalRecords: any;
  dataSource: any[] = [];
  vendorDetails: any;
  address: any;
  details: any[];
  ADDRESS: any;
  data1: any;
  duplicateinv_num: any[] = []
  duplicateinv_date: any[] = []
  invoice_number: any[] = []
  invoice_date: any[] = []
  deliverychallanno: any[] = []
  duplicatedc_number: any[] = []
  document_no: any;
  Viewsign: any;
  SignData: any;
  position: any;
  signNull: any;
  signbtn: boolean = false;
  signtext: boolean = false;
  signStatus: boolean = false;
  document_data: any;
  document_name: any;
  description: any;
  level: any;
  signature: any;
  document_no1: any;
  printstatus: any;
  approvalbtn: boolean = false;
  vendorName: any;
  logoData: any;
  companyName: any;
  companylogo: any;
  dmr_number: any[]=[];
  dmr_date: any[]=[];
  duplicatedmr_num: any[];
  duplicatedmr_date: any[];
  esdn_number: any[]=[];
  esdn_date: any[]=[];
  qc_number: any[]=[];
  qc_date: any[]=[];
  duplicateqc_num: any[];
  duplicateqc_date: any[];
  duplicateesdn_num: any[];
  duplicateesdn_date: any[];
  other_tax_split: any= 0;
  other_charges_split: any= 0;
  freight_split: any= 0;
  migst: any=0;
  mcgst: any=0;
  msgst: any=0;
  mtcs: any=0;
  mvat: any=0;
  mstax: any=0;
  mcst: any=0;
  figst: any=0;
  fcgst: any=0;
  fsgst: any=0;
  ftcs: any=0;
  fvat: any=0;
  fstax: any=0;
  fcst: any=0;
  tabletaxData: any[]=[];
  base64Pdfdata: any;
  constructor(private dialog: MatDialog,
    private service: CustomerService,
    public print: NgxPrintElementService,
    private route: ActivatedRoute,
    private router: Router,
    private alertcall: AlertCallsService,
    public sanitizer: DomSanitizer
    // @Inject(MAT_DIALOG_DATA) public data1:any
  ) { }
  public config = {
    printMode: 'template', // template
    popupProperties: "window.open",
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],

  };

  total: any = 0;
  Amount: any = 0;
  totalDiscount: any = 0;
  totalTax: any = 0;
  value: any;
  other_tax_value: any[] = [];
  total_freight: any[] = [];
  other_charges: any[] = [];
  grandTotal: any;
  grandTotall: any;
  sbtotal: any[] = [];
  outputWords = "";
  roundOff: any;
  doc_sign: any;
  ngOnInit(): void {
    // console.log(this.data1);

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.data1 = params['dmrumber'];
        this.doc_sign = params['doc_sign'];
        console.log(this.doc_sign);

      });
    this.route.queryParams
      .subscribe(params => {
        console.log(params);

        this.document_no = params['document_number'];
        this.position = params['position']
        console.log(this.position);

      });
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.document_no1 = params['document_number1'];
      });
    // console.log(this.dataaa);

    // console.log(this.other_charges);
    // console.log(this.other_tax_value);
    // console.log(this.total_freight);
    this.getUserFormData();
    this.getSignatureData();
    this.getSignature();
    // if(this.document_no){
    //   this.getSignatureData();
    //   this.getSignature();
    // }
  }
  name = 'Angular ' + VERSION.major;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  // ValueFromComp1(var1:any)
  // {
  //     this.ValueFromComponent1=var1;
  // }
  backtodmr() {
    if (this.data1) {
      this.router.navigate(['/inventory/goodsreceipt'], { queryParams: { tab: 'notifications' } })
    } else if (this.document_no) {
      this.router.navigate(['/pending-signatures'], { queryParams: { tab: 'notifications' } })
    } else {
      this.router.navigate(['/reports/transitreports'], { queryParams: { tab: 'notifications' } })
    }
  }
  getUserFormData() {
    this.userData = [];
    let obj = {
      key: this.data1 || this.document_no || this.document_no1,
      field: "number",
      command: "mat",
    };
    this.service.editgoodsreceiptdata(obj).subscribe((res: any) => {
      this.dataaa = res.data;
      this.userData.push(this.dataaa[0]);
      console.log(this.userData);
      this.vendorName = this.dataaa[0].vendor_name;
      this.companyName = this.dataaa[0].company_name;
      console.log(this.vendorName);
      this.table = res.data;
      // this.userData.push(this.table);
      this.table.forEach((element: any) => {
        // this.invoice_number:any[]=[]
        this.dmr_number.push(element.dmr_number)
        this.dmr_date.push(element.dmr_date)
        this.qc_number.push(element.qc_number)
        this.qc_date.push(element.qc_date)
        this.esdn_number.push(element.esdn_number)
        this.esdn_date.push(element.esdn_date)
        this.invoice_number.push(element.invoice_number)
        this.invoice_date.push(element.invoice_date)
        this.deliverychallanno.push(element.dc_number)
        this.duplicatedmr_num = [...new Set(this.dmr_number)];
        this.duplicatedmr_date = [...new Set(this.dmr_date)];
        this.duplicateqc_num = [...new Set(this.qc_number)];
        this.duplicateqc_date = [...new Set(this.qc_date)];
        this.duplicateesdn_num = [...new Set(this.esdn_number)];
        this.duplicateesdn_date = [...new Set(this.esdn_date)];
        this.duplicateinv_num = [...new Set(this.invoice_number)];
        this.duplicateinv_date = [...new Set(this.invoice_date)];
        this.duplicatedc_number = [...new Set(this.deliverychallanno)]
        console.log(this.duplicateinv_num,'$$$$$$$$$$$$$$$$$$$$$$$$$');
        

        //material data
        // console.log(element.material_tax_data[0].igst);
        if(element.material_tax_data.length>0){
          this.migst +=element.material_tax_data[0].igst
          this.mcgst +=element.material_tax_data[1].cgst
          this.msgst +=element.material_tax_data[2].sgst
          this.mtcs +=element.material_tax_data[3].tcs
          this.mvat +=element.material_tax_data[4].vat
          this.mstax +=element.material_tax_data[5].stax
          this.mcst +=element.material_tax_data[6].cst
        }
        //freight data
        if(element.freight_tax_data.length>0){
          this.figst +=element.freight_tax_data[0].igst
          this.fcgst +=element.freight_tax_data[1].cgst
          this.fsgst +=element.freight_tax_data[2].sgst
          this.ftcs +=element.freight_tax_data[3].tcs
          this.fvat +=element.freight_tax_data[4].vat
          this.fstax +=element.freight_tax_data[5].stax
          this.fcst  +=element.freight_tax_data[6].cst
        }
      });
      // console.log(this.duplicateinv_num);
      this.findsum(this.table);
      // console.log(res);
      // console.log(this.dataaa);

      // console.log(this.sbtotal);

      // console.log(this.userData);

      // console.log(this.other_charges);
      // console.log(this.other_tax_value);
      // console.log(this.total_freight);
      // console.log(this.dataaa);
      this.getvendordata();
      this.getCompanylogosdata();


    });
  }

  getvendordata() {
    this.vendordata=[]
    console.log(this.vendorName);
    let obj = {
      command: "mat",
      lmt: 100000,
      pid: 1,
      key: this.vendorName,
    };
    this.service.getvendormasterdata(obj).subscribe((res: any) => {
      this.vendordata = res.data;
    });
  }

  getaddress(data: any) {
    // console.log(data);
    let ADDRESS: any;
    // this.getvendordata(data)
    this.vendordata.forEach((element: any) => {
      if (element.name == data) {
        ADDRESS = element.address_1;
      }
    });
    return ADDRESS;
  }
  findsum(table: any) {
    this.value = this.table;

    for (let j = 0; j < table.length; j++) {
      this.Amount = this.value[j].accepted_quantity * this.value[j].unit_price
      this.total += this.Amount;
      this.other_tax_split +=this.value[j].other_tax_split;
      // console.log(this.other_tax_split);
      this.other_charges_split +=this.value[j].other_charges_split;
      this.freight_split+=this.value[j].freight_split
      this.totalDiscount += this.value[j].discount_value;

      this.totalTax += this.value[j].tax_value;

      let c = Number(this.total) - Number(this.totalDiscount);
      let d = this.totalTax;
      let e = this.other_tax_split;
      let f = this.freight_split;
      let g =  this.other_charges_split;

      console.log(c, d, e, f, g);
      this.grandTotal =
        +Number(c) + Number(d) + Number(e) + Number(f) + Number(g);
      console.log(this.grandTotal);

      // this.grandTotall = this.grandTotal.toFixed(2);
      this.grandTotall=Math.round(this.grandTotal)
      console.log(this.grandTotall);

      this.roundOff =  Math.round(this.grandTotal)- this.grandTotal;
      

      this.outputWords = this.convertNumberToWords(this.grandTotall);
      this.otherCharges = Number(g);
    }
  }

  onPrint() {
    window.print();
  }

  getSignatureData() {
    const postData = new FormData();
    postData.append("command", "mat");
    postData.append("document_number", this.document_no || this.data1 || this.document_no1);
    postData.append("document_name", "GoodsReceipt");
    this.service.DocumentSign(postData).subscribe((res: any) => {
      this.SignData = res.data;
      this.printstatus = res.print_status;
      this.SignData.forEach((ele: any) => {
        ele['signbtn'] = false
        this.signNull = ele.signature
        if (ele.status === "PENDING" && ele.signature === null) {
          // alert(1)
          this.signStatus = false
          if (this.position == ele.level) {
            console.log("entered");

            ele['signbtn'] = true
            //  this.signbtn=true;
          }
        } else if (ele.status === "COMPLETED" && ele.signature !== null) {
          this.signStatus = true
          ele.signature = this.sanitizer.bypassSecurityTrustResourceUrl(ele.signature);
        }

      })
      console.log(this.SignData);

    });
  }
  getSignatureView(row: any, data: any) {
    this.document_no = row.document_number;
    this.document_name = row.document_name;
    this.description = row.description;
    this.level = row.level;
    // this.document_data=row
    // console.log(this.document_data);
    this.dialog.open(data, {
      width: "400px"
    })

    this.getSignatureData();
  }
  getSignature() {
    const postData = new FormData();
    postData.append("command", "lst");
    this.service.SignatureUpload(postData).subscribe((res: any) => {
      console.log(res);
      // this.Viewsign=res.data[0].signature;
      this.Viewsign = this.sanitizer.bypassSecurityTrustResourceUrl(res.data[0].signature);
      console.log(this.Viewsign);
      this.signature = res.data[0].signature
    });
  }

  SignatureFile() {
    // this.imageUrl = this.Viewsign.toDataURL();
    console.log(this.Viewsign);
    const imageBlob = this.dataURItoBlob(this.signature);
    var file = new File([imageBlob], "fileName.jpeg", {
      type: "'image/jpeg'"
    });
    const postData = new FormData();
    postData.append("command", "add");
    postData.append("document_number", this.document_no);
    postData.append("level", this.level);
    postData.append('description', this.description);
    postData.append("document_name", this.document_name);
    postData.append("image", file);
    this.service.DocumentSign(postData).subscribe((res: any) => {
      console.log(res);
      if (res && res.status_code === 200) {
        this.dialog.closeAll();
        Swal.fire({
          text: res["message"],
          title: res["reference"],
          icon: "success",
          // title: res['reference'],
          width: 500,
        });
        this.getSignature();
        this.getSignatureData();
      } else {
        Swal.fire({
          text: res["message"],
          title: res["reference"],
          icon: "success",
          // title: res['reference'],
          width: 500,
        });
      }
      // this.reaData = false;
      //  this.totalRecords = res?.count;
      // this.dataSourcemain.data = res.data;
      // console.log(this.dataSourcemain.data);

      // if (res.data.length == 0) {
      //   this.reaData = true;
      // }
    });
  }

  dataURItoBlob(dataURI: any) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //approval icon
  getApprovals(data: any) {
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })
  }

  getConfirmData() {
    let obj = {
      command: "add",
      document_name: "GoodsReceipt",
      document_number: this.data1
    };
    this.service.AutoDocUpdate2(obj).subscribe((res: any) => {
      if (res && res['status_code'] == 200) {
        this.dialog.closeAll()
        this.alertcall.showSuccess("Accepted", res['message'])
        this.approvalbtn = true
        // this.getData()
      } else {
        this.dialog.closeAll()
        this.alertcall.showWarning("Error", res['message'])
      }
    });
  }


  //convert to words

  convertNumberToWords(amount: any) {
    console.log(amount);

    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    console.log(number);

    var n_length = number.length;
    console.log(n_length);

    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j] as any);
            n_array[i] = 0;
          }
        }
      }
      let value;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
          console.log(value);

        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";

        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string + 'Rupees';
  }


  //logo 
  getCompanylogosdata() {
    let obj = {
      "command": "mat",
      "lmt":10000,
      "pid":1
    }
    this.service.getcompanymasterdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.logoData = res.data;
        // console.log(this.logoData);
        // alert(this.companyName);
        this.logoData.forEach((ele: any) => {
          if (this.companyName === ele.name) {
            
            console.log(ele.logo);
            this.companylogo = ele.logo
          }
        });
      }
    })
  }

  //print as pdf
  printFormats2() {
    let params = new HttpParams()
        params = params.append('number', this.data1)
         this.service.generateStoresPdf2(params).subscribe((res: any) => {
          if(res['status'] == 'Success'){
            const data = res.base64;
            // console.log(res.base64Pdf);
            this.base64Pdfdata = res.base64;
           setTimeout(() => {
             this.openPdfInNewTab(data);
           }, 10);
          }
    })
     }

  openPdfInNewTab(base64Content:any) {
    return new Promise<void>((resolve,_reject) => {
      const byteArray = new Uint8Array(atob(base64Content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      // document.querySelector("iframe").src = url;
      resolve();
      window.open(url, '_blank');
    }).catch(error => {
      console.error('Error displaying PDF:', error);
    });
  }
}

