import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { CustomerService } from 'src/app/services/customer.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
@Component({
  selector: 'app-gamdashboard',
  templateUrl: './gamdashboard.component.html',
  styleUrls: ['./gamdashboard.component.scss']
})
export class GamdashboardComponent implements OnInit {
  demo1TabIndex: any = 0;
  data1: any = [];
  rgpDetails: any[] = [];
  siteToSitedata: any[] = [];
  repairData: any[] = [];
  materialData: any[] = [];
  overdueRgpData: any[] = [];
  companyData: any;
  today: string = new Date().toISOString().split('T')[0];
  yesterday: string =  moment().subtract(1, 'day').format('YYYY-MM-DD');
  dmrdashboardfromdate: any;
  dmrdashboardtodate: any;
  allSelecteddmr: boolean=false;
  dmrcompanyname: any;
  DmrReportTable: boolean=false;
  dmrData: any=[];
  reaData: boolean=false;
  constructor(private custservice: CustomerService) { }
  currentDate: Date = new Date();
  ngOnInit(): void {
    this.getData();
    let grandTotalItemsCount = 0;
    let grandTotalItemsValue = 0;

    this.rgpDetails.forEach((data: any) => {
      grandTotalItemsCount += data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount;
      grandTotalItemsValue += data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue;
    });
    this.siteToSitedata.forEach((data: any) => {
      grandTotalItemsCount += data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount;
      grandTotalItemsValue += data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue;
    });
    this.repairData.forEach((data: any) => {
      grandTotalItemsCount += data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount;
      grandTotalItemsValue += data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue;
    });
    this.overdueRgpData.forEach((data: any) => {
      grandTotalItemsCount += data.overDueSiteToSiteItemsCount + data.overDueRepairItemsCount + data.totalOpenItems;
      grandTotalItemsValue += data.overDueSiteToSiteItemsValue + data.overDueRepairItemsValue + data.totalOpenItemsValues;
    });
    this.materialData.forEach((data: any) => {
      grandTotalItemsCount += data.dueClosedItemsCount + data.dueOpenItemsCount + data.totalItems + data.closedItemPercentage;
      grandTotalItemsValue += data.dueClosedItemsValue + data.dueOpenItemsValue + data.totalItemsValues + data.closedItemValuePercentage;
    });
    this.getcompanydata();
    this.setYesterdayDate();
    this.dmrdashboardtodate = this.getCurrentDate();
    console.log(grandTotalItemsCount,grandTotalItemsValue);
    
  }
  getData() {
    this.custservice.getgamdashboarddata().subscribe((res: any) => {
      this.data1 = res
      this.rgpDetails = res.data.rgpDetails;
      this.siteToSitedata = res.data.siteToSiteData;
      this.repairData = res.data.repairData;
      this.materialData = res.data.materialData;
      this.overdueRgpData = res.data.overDueRgpData;
    })
  }
  calculateGrandTotal(field: string): number {
    return this.rgpDetails.reduce((acc, data) => acc + data[field], 0);
  }
  calculateGrandTotalsitetosite(field: string): number {
    return this.siteToSitedata.reduce((acc, data) => acc + data[field], 0);
  }
  calculateGrandTotalRepair(field: string): number {
    return this.repairData.reduce((acc, data) => acc + data[field], 0);
  }
  calculateGrandTotalmaterialData(field: string): number {
    return this.materialData.reduce((acc, data) => acc + data[field], 0);
  }
  calculateGrandTotaloverdueRgpData(field: string): number {
    return this.overdueRgpData.reduce((acc, data) => acc + data[field], 0);
  }
  calculateGrandTotalOpenItems() {
    let totalOpenItemsCount = 0;
    for (let data of this.rgpDetails) {
      totalOpenItemsCount += (data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount);
    }
    return totalOpenItemsCount;
  }
  calculateGrandTotalOpenItemsValue() {
    let totalOpenItemsValue = 0;
    for (let data of this.rgpDetails) {
      totalOpenItemsValue += (data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue);
    }
    return totalOpenItemsValue.toFixed(2);
  }
  calculateGrandTotalOpenItemssite() {
    let totalOpenItemsCount = 0;
    for (let data of this.siteToSitedata || this.repairData) {
      totalOpenItemsCount += (data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount);
    }
    return totalOpenItemsCount;
  }
  calculateGrandTotalOpenItemsValuesite() {
    let totalOpenItemsValue = 0;
    for (let data of this.siteToSitedata || this.repairData) {
      totalOpenItemsValue += (data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue);
    }
    return totalOpenItemsValue.toFixed(2);
  }
  calculateGrandTotalOpenItemsrepair() {
    let totalOpenItemsCount = 0;
    for (let data of this.repairData) {
      totalOpenItemsCount += (data.dueItemsCount + data.noDueItemsCount + data.overDueItemsCount);
    }
    return totalOpenItemsCount;
  }
  calculateGrandTotalOpenItemsValuerepair() {
    let totalOpenItemsValue = 0;
    for (let data of this.repairData) {
      totalOpenItemsValue += (data.dueItemsValue + data.noDueItemsValue + data.overDueItemsValue);
    }
    return totalOpenItemsValue.toFixed(2);
  }

  // DMR Dashboard
  getcompanydata() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData = res.data;
    });
  } 
  validateDateRange() {
    if (this.dmrdashboardfromdate && this.dmrdashboardtodate && this.dmrdashboardfromdate > this.dmrdashboardtodate) {
      return false;
    }
    return true;
  } 
  @ViewChild('selectdmr') selectdmr: MatSelect;
  toggleAllSelectionofdmr() {
    if (this.allSelecteddmr) {
      this.selectdmr.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectdmr.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickdmr() {
    this.allSelecteddmr = this.dmrcompanyname.length === this.companyData.length;
  }
  getCurrentDate(): string {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substring(0, 10);
    return formattedDate;
  }
  setYesterdayDate() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.dmrdashboardfromdate = this.formatDate(yesterday);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  clearFormDmrdata() {
    // (<HTMLFormElement>document.getElementById("form")).reset();
    this.dmrcompanyname=""
    this.DmrReportTable = false
    this.allSelecteddmr = false;
    this.dmrdashboardfromdate=this.yesterday
    this.dmrdashboardtodate=this.today
  }
  getDmrData(){
    let obj = {
      "company_name": this.dmrcompanyname,
      "fromDate": this.dmrdashboardfromdate,
      "toDate": this.dmrdashboardtodate
    } 
    this.custservice.getDmrData(obj).subscribe((res:any)=>{
      this.dmrData = res.data
      this.DmrReportTable =true;
      this.reaData = false;
      if (res.data.length == 0) {
        this.reaData = true;
      }
    })
  }
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  exportExcelDmrData() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    ws['!rows'] = [];
    ws['!rows'][0] = { hidden: false };
    ws['!rows'][1] = { hidden: false };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `DMRDashboardData${moment().format('LL,LTS')}.xlsx`);
  }
}
