<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Goods Return</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
<!-- </div>
    </div>
</div> -->


<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex"
        (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                Goods Return
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)">
                            <div class="row mb-3">

                                <div *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                                    <button mat-raised-button type="button" (click)="BacktoList()"
                                        class="savebutton">Back</button>
                                </div>
                                <div *ngIf="viewData" class="col mt-1 text-right">
                                    <p *ngIf="viewData" class="viewdoc">View of Document
                                        Number:
                                        {{viewnumber}}</p>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 mb-2 ml-2">
                                    <button mat-raised-button class="savebutton" color="secondary"
                                        [disabled]="saveddataarray.length==0 || !model1.wonumber || !model1.dateee || !model1.companyname || !model1.contractorname || !model1.requestdate || !model1.requestnumber || !model1.givername || !model1.locations || !model1.returntype">{{btn}}</button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 ml-2 mb-2">
                                    <button mat-raised-button type="button" (click)="clearpage1()"
                                        class="savebutton">Add New</button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-0 ml-3 mb-2">
                                    <button mat-raised-button type="button" class="savebutton" color="secondary"
                                        (click)="giData(gimodel)">Select Goods Issue</button>
                                </div>
                                <div *ngIf="!viewData" class="col-md-2 ml-2">
                                    <button type="button" mat-raised-button class=" attachfile" color="secondary"
                                        (click)="openNewFileUploadModal(newFileUploadModal , btn)">
                                        Attach File&nbsp; <mat-icon
                                            class="attachFileIcon">file_upload</mat-icon></button>
                                    <!-- <div class="file">
                                      <input hidden class="form-control-file" name="file" type="file" multiple
                                      (change)="uploadgrfiles($event)" #autoFileUpload2  >
                                      <ng-container *ngIf=" fileUploadUrlsgreturn.length > 0" class="mt-4">
                                    <div *ngFor="let item of filenamearray;let i=index;">{{i+1}}.&nbsp;{{item}}</div> 

                                      <span style="margin-left:3px">Selected : {{fileUploadUrlsgreturn.length}} files</span>  
                                      </ng-container>         
                                      </div> -->
                                </div>
                                <div *ngIf="!viewData && txnId" class="col mt-1 text-right">
                                    <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <label>Work Order Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.wonumber}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="wonumber"
                                        placeholder="Enter your  Order Number" [(ngModel)]="model1.wonumber" (input)="trimInput()">
                                </div>

                                <div class="col-md-3">
                                    <label>Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.dateee}}</p>
                                    <input *ngIf="!viewData" type="date" 
                                        class="inputstyles uppercasetxt" name="dateee" [max]="today" [(ngModel)]="model1.dateee"
                                        dateFormat="yyyy/mm/dd">
                                </div>
                                <div class="col-md-3">
                                    <label>Company Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.companyname}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="companyname"
                                        [(ngModel)]="model1.companyname" (input)="trimInput()">
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData" [ngValue]="item.company_name">
                                            {{item.company_name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label>Contractor Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.contractorname}}</p>
                                    <mat-form-field *ngIf="!viewData" class="example-full-width matinputfield" appearance="none">
                                        <input type="text" class="inputdropdown1" placeholder="Search Contractor Name"
                                            aria-label="Number" matInput name="contractorname"
                                            [(ngModel)]="model1.contractorname" (input)="trimInput()"
                                            (keyup)="filtecontractordatadata($event)" [matAutocomplete]="auto1">
                                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                            <mat-option *ngFor="let item of contracordata" [value]="item.name">
                                                {{ item.name }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <!-- <select *ngIf="!viewData" class="dropdownstyles" name="contractorname" [(ngModel)]="model1.contractorname">
                                        <option [value]="undefined"selected disabled>Select One</option>
                                        <option *ngFor="let item of contracordata" [ngValue]="item.name">{{item.name}}
                                        </option>
                                    </select> -->
                                    <!-- <input type="text" style="height:110%;" placeholder="Enter Contractor Name"
                                        class="inputstyles" name="contractorname" [(ngModel)]="model1.contractorname"> -->

                                </div>
                                <!-- <div class="col-md-3">
                                    <label>From Company Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.fromcompany}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="fromcompany" [(ngModel)]="model1.fromcompany">
                                        <option [value]="undefined"selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData" [ngValue]="item.company_name">{{item.company_name}}
                                        </option>
                                    </select>
                                    <input type="text" style="height:110%" placeholder="Enter From Company Name"
                                        class="inputstyles" name="fromcompany" [(ngModel)]="model1.fromcompany">

                                </div> -->
                            </div>
                            <div class="row mb-3">

                                <div class="col-md-3">
                                    <label>Request Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.requestdate}}</p>
                                    <input *ngIf="!viewData" type="date" 
                                        class="inputstyles uppercasetxt" name="requestdate" [(ngModel)]="model1.requestdate">
                                </div>
                                <div class="col-md-3">
                                    <label>Request Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.requestnumber}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Request No"
                                        class="inputstyles" name="requestnumber" [(ngModel)]="model1.requestnumber" (input)="trimInput()">
                                </div>
                                <div class="col-md-3">
                                    <label>Giver Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.givername}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="givername"
                                        placeholder="Enter your Giver Name " [(ngModel)]="model1.givername" (input)="trimInput()"
                                        placeholder="Enter Giver Name">
                                </div>
                                <div class="col-md-3">
                                    <label>Locations<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.locations}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Locations"
                                        class="inputstyles" name="locations" [(ngModel)]="model1.locations" (input)="trimInput()">
                                </div>
                            </div>

                            <div class="row mb-3">

                                <div class="col-md-3">
                                    <label>Return Type<span class="mandetory">*</span></label>
                                    <!-- <select class="dropdownstyles" name="returntype" [(ngModel)]="model1.returntype">
                                        <option selected disabled >Select One</option>
                                        <option > Type1 </option>
                                        <option > Type2 </option>
                                    </select> -->
                                    <p *ngIf="viewData" class="viewdata"> {{model1.returntype}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Return Type"
                                        class="inputstyles" name="returntype" [(ngModel)]="model1.returntype" (input)="trimInput()">
                                </div>
                                <div class="col-md-6">
                                    <label>Comments</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.comments!==null?model1.comments:'NA'}}</p>
                                    <textarea rows="2" cols="50" class="txtresize" *ngIf="!viewData" type="text"
                                        placeholder="Enter Comments" class="inputstyles" name="comments"
                                        [(ngModel)]="model1.comments" (input)="trimInput()"></textarea>
                                </div>
                            </div>




                        </form>

                        <div *ngIf="!viewData" class="row p-10">
                            <div class="col-12  float-right text-right">
                                <!-- <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                                (click)="goodsreturnmodel(openaccordian)">
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button> -->
                            </div>
                        </div>

                        <ng-container>
                            <div class="row p-t-10">
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container tablewidth">
                                            <table #myTable>
                                                <tr >
                                                    <th class="tablestyles">S.NO</th>
                                                    <th class="tablestyles">Material Code</th>
                                                    <th class="tablestyles">Material Description</th>
                                                    <th class="tablestyles">UOM</th>
                                                    <th class="tablestyles" *ngIf="!viewData">Issued Quantity</th>
                                                    <th class="tablestyles" *ngIf="!viewData">Balance Quantity</th>
                                                    <th class="tablestyles">Quantity</th>
                                                    <th class="tablestyles">Storage Location</th>
                                                    <th class="tablestyles">Valuation Type</th>
                                                    <th class="tablestyles">Item Remarks</th>
                                                    <th class="tablestyles" *ngIf="!viewData">Action</th>
                                                </tr>

                                                <tr *ngFor="let model1 of MATDATA;let i=index;trackBy: customTrackBy">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <!-- <td width="20%"><span class="example-full-width" >{{model.LABEL_NAME}}</span></td> -->
                                                    <td>
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="matcode" [(ngModel)]="model1.material_code"
                                                                readonly [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="matname" [(ngModel)]="model1.material_description"
                                                                readonly [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value="" name="uom"
                                                                [(ngModel)]="model1.unit_of_measurment" readonly
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>

                                                    <td *ngIf="!viewData">
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="issuedquantity{{i}}"
                                                                [(ngModel)]="MATDATA[i].issued_quantity"
                                                                pattern="^[0-9]*$" readonly>
                                                        </mat-form-field>
                                                    </td>
                                                    <td *ngIf="!viewData">
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="balanceqty{{i}}"
                                                                [(ngModel)]="MATDATA[i].balance_quantity"
                                                                pattern="^[0-9]*$" readonly>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width ml-1">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="qty{{i}}"
                                                                [(ngModel)]="MATDATA[i].quantity"
                                                                (keyup)="Quantity(MATDATA[i].quantity,model1)"
                                                                pattern="^[0-9]*$" [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>

                                                    <td>
                                                        <mat-form-field *ngIf="viewData" class="example-full-width">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="storage_location"
                                                                [(ngModel)]="model1.storage_location" readonly
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>
                                                        <mat-form-field *ngIf="!viewData" class="example-full-width matinputfield1"
                                                            appearance="none">
                                                            <input type="text" placeholder="Search Storage Location "
                                                                aria-label="Number" matInput 
                                                                class="inputdropdown borderfield" name="storagelocation"
                                                                [(ngModel)]="MATDATA[i].storagelocation"
                                                                (keyup)="getstorageDATA($event)"
                                                                [matAutocomplete]="auto5" />

                                                            <mat-autocomplete autoActiveFirstOption
                                                                #auto5="matAutocomplete">

                                                                <mat-option *ngFor="let item of storageData"
                                                                    [value]="item.storage_location">
                                                                    {{item.storage_location}}</mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field *ngIf="viewData"
                                                            class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="valution_type" [(ngModel)]="model1.valuation_type"
                                                                readonly [disabled]="viewData===true">
                                                        </mat-form-field>
                                                        <select *ngIf="!viewData" class="dropdownstyles1"
                                                            name="valution_type" [(ngModel)]="model1.valuation_type">
                                                            <option selected disabled>Select One</option>
                                                            <option *ngFor="let valution of valutiondata"
                                                                [ngValue]="valution.code">{{valution.code}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <mat-form-field *ngIf="MATDATA[i].item_remarks !== null" class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="itemremarks{{i}}" 
                                                                [(ngModel)]="MATDATA[i].item_remarks"
                                                                [disabled]="viewData===true">

                                                        </mat-form-field>
                                                        <mat-form-field *ngIf="MATDATA[i].item_remarks === null" class="example-full-width ml-1">
                                                            <input matInput placeholder="Enter text" value="NA"
                                                                name="itemremarks{{i}}" 
                                                              
                                                                [disabled]="viewData===true">

                                                        </mat-form-field>

                                                    </td>
                                                    <td>
                                                        <!-- <button mat-icon-button
                            aria-label="Example icon button with a vertical three dot icon"
                           >
                            <mat-icon #myButton (click)="savedata(model.received_quantity,model.acceptedqty,model.rejectedqty,model.itemremarks)" >save</mat-icon>
                        </button> -->
                                                        <button mat-icon-button *ngIf="!viewData"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="deleterowItem(i)">
                                                            <mat-icon class="delicon">delete</mat-icon>
                                                        </button>
                                                    </td>

                                                </tr>
                                                <!-- <tr>
                          <td style="height: 45px;text-align: center;" *ngIf="commentsList == 0" colspan="4">No Records Found</td>
                        </tr> -->
                                            </table>

                                        </div>


                                    </div>
                                </div>
                            </div><br>

                        </ng-container>
                    </mat-card-content>
                </mat-card>

            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">MRN Number</option>
                                        <!-- <option value="company_name">Company Name</option>
                                        <option value="from_company_name">From Company Name</option> -->
                                        <option value="contractor_name">Contractor Name</option>
                                        <option value="request_number">Request Number</option>
                                        <option value="return_type">Return Type</option>
                                    </select>
                                </div>
                                <div class="col-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="search()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getData()"
                                        class="custom-icon-button ml-3">
                                        <span class="material-icons">
                                            refresh
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef class="tableth">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (paginator.pageIndex *
                                                        paginator.pageSize)}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="MRN_Number">
                                                    <th mat-header-cell *matHeaderCellDef class="tableth10"
                                                        mat-sort-header="number">MRN Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Date">
                                                    <th mat-header-cell *matHeaderCellDef class="tableth9"
                                                        mat-sort-header="date">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="company_Name">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header="company_name">Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name }}
                                                    </td>
                                                </ng-container>

                                                <!-- <ng-container matColumnDef="fromcompanyname">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="from_company_name">From Company Name</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.from_company_name}}
                                                    </td>
                                                </ng-container> -->

                                                <ng-container matColumnDef="contractor_name">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header="contractor_name">Contractor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.contractor_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="request_number">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        mat-sort-header="request_number">Request Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.request_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="return_type">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="return_type">
                                                        Return Type
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.return_type}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                                    <th mat-header-cell *matHeaderCellDef>Active Status
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.is_active}}
                                                    </td>
                                                </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef class="tableth16"
                                                        class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <!-- <button mat-icon-button style="margin-left:-11%"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="editsaveddata(row);tabGroup.selectedIndex = 0">
                                                            <mat-icon>edit</mat-icon>
                                                        </button> -->
                                                        <div class="mb-0">
                                                            <mat-icon matTooltip="View"
                                                               class="viewicon"
                                                                (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                                            <!-- <mat-icon matTooltip="Edit" style="border:none;background: none;cursor: pointer;"
                                                            (click)="editgrdata(row,Editmodel)" >edit</mat-icon> -->
                                                            <mat-icon matTooltip="Delete"
                                                                class="deleteicon"
                                                                (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>&nbsp;
                                                            <mat-icon matTooltip="Print" (click)="printgoodsreturn(row)"
                                                                class="pointercursor"> local_printshop</mat-icon>


                                                            &nbsp;
                                                            <mat-icon matTooltip="Upload"
                                                                (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                                class="fileuploadicon">file_upload</mat-icon>
                                                        </div>
                                                        <!-- <button mat-icon-button
                                                            style="color: red;border:none;background: none;"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="deleteItem(row,deleteItemModel)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                        <button 
                                                        class="savebutton" style="margin-left: 1%;height: 21px;padding-right: 33px;font-size: 87%;"
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="printgoodsreturn(row)">Print
                                                       
                                                    </button>&nbsp;
                                                    <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                        style="cursor:pointer;font-size:16px"></i> -->
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaData==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <!-- <mat-paginator [length]="totalRecords" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                            (page)="onpageevent($event)">
                                        </mat-paginator> -->
                                        <div class="d-flex justify-content-end">
                                            <mat-paginator [length]="totalRecords" [pageSize]="10"
                                                [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #paginator
                                                (page)="onpageevent($event)">
                                            </mat-paginator>
                                            <!-- <div class="go-to-container">
                                                <div class="go-to-label">Page Nos: </div>
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
                                                        <mat-option *ngFor="let pageNumber of pageNumbers"
                                                            [value]="pageNumber">{{pageNumber}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'GoodsReturn'"></app-activitylog>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>



<ng-template>
    <div mat-dialog-title class="dialog-title">
        <h2>Add Items</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            class="closeicon">
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="matcontent">
        <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
            <div class="row mb-4">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-10">
                    <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model.matcode"  (change)="selectedmastergroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                    <mat-form-field class="example-full-width matinputfield "  appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                             class="inputdropdown borderfield" name="matcode" [(ngModel)]="model.matcode"
                            (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />

                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                            (optionSelected)="selectedmastergroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Storage Location<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                    <mat-form-field class="example-full-width matinputfield"  appearance="none">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                            class="inputdropdown borderfield" name="storagelocation"
                            [(ngModel)]="model.storagelocation" (keyup)="getstorageDATA($event)"
                            [matAutocomplete]="auto5" />

                        <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">

                            <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                {{item.storage_location}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <label>Unit Of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="uom" [(ngModel)]="model.uom">
                    <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                </div>

                <!-- <div class="col-md-2">
                    <label>Request Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model.reqqty"
                        (keypress)="keyPressNumbers($event)">
                </div> -->

            </div><br>

            <div class="row">
                <div class="col-md-2">
                    <label>Return Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="qty" [(ngModel)]="model.qty">
                </div>
                <div class="col-md-2">
                    <label>Item Remarks<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model.itemremarks">
                </div>

                <!-- <div class="col-md-2">
                    <label>Batch No<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="batchno"
                        [(ngModel)]="model.batchno">
                </div> -->
            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valutiontype">
                        <option value="">Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valutiontype">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>

            </div><br> -->



            <button mat-raised-button class="savebutton" class="additemsbtn"
                [disabled]="!model.matcode">Add</button>
        </form>
    </mat-dialog-content>
</ng-template>
<ng-template #goodsissueeditmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Edit Items</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            class="closeicon">
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="matcontent">
        <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
            <div class="row mb-4">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model2.matcode"  (change)="selectedmastereditgroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                    <mat-form-field class="example-full-width matinputfield"  appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                             class="inputdropdown borderfield" name="matcode" [(ngModel)]="model2.matcode"
                            (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" />

                        <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                            (optionSelected)="selectedmastereditgroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <label>Storage Location<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model2.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                    <mat-form-field class="example-full-width matinputfield"  appearance="none">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                            class="inputdropdown borderfield" name="storagelocation"
                            [(ngModel)]="model2.storagelocation" (keyup)="getstorageDATA1($event)"
                            [matAutocomplete]="auto7" />

                        <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">

                            <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                {{item.storage_location}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Unit Of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="uom" [(ngModel)]="model2.uom">
                    <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                </div>
                <!-- <div class="col-md-2">
                    <label>Request Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model2.reqqty"
                        (keypress)="keyPressNumbers($event)">
                </div> -->
                <div class="col-md-2">
                    <label> Return Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="qty" [(ngModel)]="model2.qty"
                        (keypress)="keyPressNumbers($event)">
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Item Remarks<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model2.itemremarks" readonly>
                </div>
                <!-- <div class="col-md-2">
                    <label>Batch No<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="batchno"
                        [(ngModel)]="model2.batchno" readonly>
                </div> -->
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valutiontype">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>

            </div><br>
            <!-- <div class="row">       
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                    <div class="col-md-4">
                        <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valutiontype">
                            <option selected disabled>Select One</option>
                            <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                            </option>
                        </select>
                    </div>
               
            </div><br> -->

            <button mat-raised-button class="savebutton" class="updateitemsbtn">Update</button>
        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #deleteItemModel>
    
    <div mat-dialog-title>
        <h1 >Delete Activity</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon icon2" (click)="closemodel()"
            >
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-2">
                <label class="lablereason">Reason</label>
            </div>
            <div class="col-md-9">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles " name="reason"
                    [ngModel]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
                    (input)="deletemodel.reason=$event.target.value.trim()" >
            </div>
        </div>

    </div><br>
    <div mat-dialog-actions class="justify-content-center">
        <!-- <button mat-button mat-dialog-close class="matButton"
            style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
        <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton"
            [disabled]="!deletemodel.reason">Delete</button>
    </div>
</ng-template>
<ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Goods Return: {{grnumber}}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            >
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedata">
            <!-- <span *ngFor="let item of filedata"> -->
            <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
            <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
    style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table>
                <thead  class="tablebgcolor text-center">
                    <!-- <tr> -->
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <th>Action</th>
                    <!-- </tr> -->
                </thead>
                <tbody class="text-center">
                    <tr *ngFor="let item of filedata">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 pointercursor"
                                title="View File" (click)="viewDoc(item)"></i></td>
                        <td>
                            <button mat-icon-button class="deleteicon"
                                aria-label="Example icon button with a vertical three dot icon"
                                (click)="deleterowfile(deletefileModel,item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p *ngIf="filedata.length === 0" class="text-center">No Data Found</p>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
            <!-- </span> -->
        </div>
    </div><br>
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
            (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 accept=
            "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
            text/plain, application/pdf"/>
        <ng-container *ngIf="fileUploadUrls.length>0">
            <!-- <span >Selected: {{fileUploadUrls.length}} files</span>   -->
        </ng-container>
    </div>
</ng-template>
<ng-template #deletefileModel>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton"
            class="closecolor">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
    </div>
</ng-template>
<ng-template #Editmodel>
    <h1 mat-dialog-title></h1>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-2">
                <label class="lablereason">Reason</label>
            </div>
            <div class="col-md-9">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles deletefield" name="reason"
                    [(ngModel)]="editModel.reason" >
            </div>
        </div>
    </div><br>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton"
            class="closecolor">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton submitbtn" [disabled]="!editModel.reason"
            >Submit</button>
    </div>
</ng-template>


<ng-template #gimodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Goods Issue</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon icon1" (click)="closemodel()"
            >
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <!-- <form #mygiform="ngForm" (ngSubmit)="submitgino(mygiform)">
        <div class="row">
            <div class="col-md-10 mt-2 ">
                <mat-form-field class="example-full-width p-0 matinputfield"  appearance="none">
                    <input type="text" placeholder="Search Goods Issue " aria-label="Number" matInput
                         class="inputdropdown3 goodsissuefiled" name="ginumber"
                        [(ngModel)]="ginumber" (keyup)="getgoodsIssueDATA($event)" [matAutocomplete]="auto44"  />
                    <mat-autocomplete [autoActiveFirstOption] #auto44="matAutocomplete">
                        <mat-option *ngFor="let item of filteredGiData" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-2">
                <button mat-raised-button type="submit"  class="savebutton submitBtn"
                     [disabled]="!ginumber">Submit</button>
            </div>
        </div>
    </form> -->


    <form #mygiform="ngForm" (ngSubmit)="submitgino(mygiform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>Select Delivery Challan Number<span class="mandetory">*</span></label> -->
                <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
            [(ngModel)]="copieddmr"> -->
                <mat-form-field class="example-full-width matinputfield" appearance="none">
                    <input type="text" placeholder="Search Goods Issue" aria-label="Number" matInput
                         class="inputdropdown3 goodsissuefiled" name="ginumber"
                        [(ngModel)]="ginumber" (keyup)="getgoodsIssueDATA($event)" [matAutocomplete]="auto44" (input)="trimInput()"/>

                    <mat-autocomplete autoActiveFirstOption #auto44="matAutocomplete">

                        <mat-option *ngFor="let item of giDATA" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-2">
        <button mat-raised-button class="savebutton submitBtn" 
             [disabled]="!ginumber">Submit</button>
            </div>
        </div>

    </form>
</ng-template>


<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title">
        <h2>File Upload</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon " (click)="closemodel()"
          >
            <mat-icon class="close1">close</mat-icon>
        </button>
    </div>
    <div>
        <div mat-dialog-content>
            <div *ngIf="selectedfiles.length > 0">
                <table>
                    <thead class="tablebgcolor text-center">
                        <th>S.NO</th>
                        <th>File Name</th>
                        <th>Action</th>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let item of selectedfiles;let i=index;">
                            <td>{{i+1}}</td>
                            <td class="tablebody1">{{item.name}}</td>
                            <td>
                                <button mat-icon-button class="deleteicon"
                                    (click)="deleteFileItem(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div><br>
        <button type="button" mat-raised-button class="attachfile ml-1" color="secondary"
            (click)="autoFileUpload2.click()">
            Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
        <div class="file">
            <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadgifiles($event)"
                #autoFileUpload2 accept=
                "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf">
        </div>
    </div>

    <!-- editfile -->
    <!-- <div *ngIf="issubmitClicked">
      <div *ngIf="filedata && filedata.length>0">
        <table>
          <thead style="background: #e9ecef">
          
            <th>Created By</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Action</th>
           
          </thead>
          <tbody>
            <tr *ngFor="let item of filedata">
              <td>{{ item.created_by }}</td>
              <td>{{ item.created_date }}</td>
              <td>
                {{ item.file_name }} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                  title="View File" (click)="viewDoc(item)"></i>
              </td>
              <td>
                <button mat-icon-button style="color: red; border: none; cursor: pointer"
                  aria-label="Example icon button with a vertical three dot icon"
                  (click)="deleterowfile(deletefileModel, item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
       
      </div>
  
      <br />
      <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
          (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
        <ng-container *ngIf="fileUploadUrls.length > 0">
    
        </ng-container>
      </div>
    </div> -->
</ng-template>