import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Overlay } from 'ngx-toastr';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.scss']
})
export class ActivitylogComponent implements OnInit {
  activityLogDownload: any=[];
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef; 
  @ViewChild('masterpaginatorlog') masterpaginatorlog:MatPaginator;
  activitylogcolumns: any[] = [
    "sno",
    "username",
    "created_date",
    "reference_number",
    "description",
    "remarks",
    "action",
    "download"
  ];
  pageIndexlog:any=0;
  pageSizelog:any=10;
  logsearchData: any;
  reaDatalog: boolean;
  totalRecordslog: any;
  logdata: any;
  dataSourcemainlog = new MatTableDataSource();
  @Input() activitylogfor:string=''
  constructor(
    private custservice: CustomerService,
    public overlay: Overlay,
    private alertcall: AlertCallsService
  ) { }

  ngOnInit(): void {
    console.log("??????")
    this.getlogdata()
  }

  getlogdata1(){
    if(this.masterpaginatorlog){
      this.masterpaginatorlog.firstPage();
    }
    this.getlogdata();
    this.logsearchData = "";
  }
  getlogdata(){
    let obj={
      command:"log",
      lmt: this.pageSizelog,
      pid: this.pageIndexlog+1,
      key:this.activitylogfor
    }
    this.custservice.getActivityLog(obj).subscribe((res:any)=>{
      this.logdata = res.log;
      this.reaDatalog = false;
    this.totalRecordslog = res?.count;
    this.dataSourcemainlog = res.log;
    // this.dataSourcemainlog.sort = this.sort;
    if (res.log.length == 0) {
      this.reaDatalog = true;
    }
    })
  }
  logsearch() {
    let obj = {
      command: "log",
      lmt: this.pageSizelog,
      pid: this.pageIndexlog+1,
      key: this.activitylogfor,
      number: this.logsearchData,
      showLoader:'hide' 
    }
    this.custservice.getActivityLog(obj).subscribe((res: any) => {
      // if (res.log.length > 0) {
      //   this.logdata = res.log
      // }
      this.logdata = res.log;
      this.reaDatalog = false;
    this.totalRecordslog = res?.count;
    this.dataSourcemainlog = res.log;
    if (res.log.length == 0) {
      this.reaDatalog = true;
    }
    })
  }
  logSearchData(){
    if(this.logsearchData.length> 2){
      if(this.masterpaginatorlog){
        this.masterpaginatorlog.firstPage();
      }
      this.logsearch()
    }else if(this.logsearchData.length === 0){
      this.getlogdata()
    }
  }
  onpageeventlog(event: any) {
    this.pageIndexlog = event.pageIndex;
    this.pageSizelog = event.pageSize;
    if(!this.logsearchData){
      this.getlogdata();
    }
    else{
    this.logsearch();
    }
  }
  //
      //download log
      downloadLog(row:any){
        console.log(row);
        let obj = {
          command: "log",
          id:row.id
        }
        this.custservice.viewActivityLog(obj).subscribe((res: any) => { 
          if(res.log.length>0){
            this.activityLogDownload =res.log
            this.alertcall.showSuccess('Accepted', 'File Downloading');
            console.log(this.activityLogDownload);
            setTimeout(() => {
            this.exportExcellog()
            }, 1000);
          }else{
            this.alertcall.showWarning("Warning", 'No Data Found');
          }
        })
      }
      getHeaders() {
        let headers: string[] = [];
        if(this.activityLogDownload) {
          this.activityLogDownload.forEach((value:any) => {
            Object.keys(value).forEach((key) => {
              if(!headers.find((header) => header == key)){
                headers.push(key)
              }
            })
          })
        }
        return headers;
      }
      exportExcellog(){
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLELog.nativeElement); 
        const wb: XLSX.WorkBook = XLSX.utils.book_new();  
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
        XLSX.writeFile(wb, `ActivityLogData${moment().format('LL,LTS')}.xlsx`);  
      }
}
