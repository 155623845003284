import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiclelogComponent } from './vehiclelog/vehiclelog.component';
import { RouterModule, Routes } from '@angular/router';
import { UserRolesAuthGuard } from 'src/app/auth/user-roles-auth.guard';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatTableDataSource } from "@angular/material/table";
// import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
// import {MatTableDataSource} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
// import  Moment from 'moment';
import { ReusableModule } from 'src/app/reusable/reusable.module';
// import { MatTableDataSource } from "@angular/material/table";


// import { NgxMatDatetimePickerModule } from 'ngx-mat-datetime-picker';

const routes: Routes=[
  {
    path: 'vehiclelog',
    canActivate: [ UserRolesAuthGuard],
    data: {
      title: 'Vehicle Log',
      permission: {
        only: ['SUPER_ADMIN', 'MACHINERY'],
        redirectTo: '/ui/403',
      },
    },
    component: VehiclelogComponent,
  },
]

@NgModule({
  declarations: [
    VehiclelogComponent
    
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTabsModule,
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    RouterModule.forChild(routes),
    ReusableModule
  ]
})
export class MachineryModule {
  

}
