import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-startaudit',
  templateUrl: './startaudit.component.html',
  styleUrls: ['./startaudit.component.scss']
})
export class StartauditComponent implements OnInit {
  demo1TabIndex: any = 0;
  model:any={}
  docName: any;
  materialData:any=[]
  totalData:any=[]
  selectedMaterial:any={}
  constructor(public custservice:CustomerService, public route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res:any)=>{
      this.docName = res.data
    })
    this.getScannedData()
  }
  getScannedData(){
    let obj={
      docName: this.docName,
      materialCode: this.selectedMaterial.material_code,
      materialName: this.selectedMaterial.material_description
    }
    this.custservice.getScannedData(obj).subscribe((res:any)=>{
if(res.status === "Success"){
  this.materialData = res.data
  this.totalData = res.data
}
    })
  }
  getData(ev:any){
    let value = ev.target.value.toLowerCase();
    if (!value) {
      return this.materialData=this.totalData;
    }else{
      this.materialData=this.totalData.filter((ele:any)=>ele.material_description.toLowerCase().includes(value))
    }
  }
  onSelectionChange(_ev:any){
    this.selectedMaterial = this.model.materialName
    this.model.materialcode = this.selectedMaterial.material_code
    this.model.storagelocation = this.selectedMaterial.storage_location
    this.model.uom = this.selectedMaterial.unit_of_measurement
    this.model.closingstock = this.selectedMaterial.closing_quantity
    this.model.alreadyscannedstock = this.selectedMaterial.scanned_quantity
    this.model.physicalstock=""
    this.model.difference =""
  }
  displayFn(user:any): string | undefined {
    return user ? user.material_description : undefined;
  }
  getPhysicalStock(ev:any){
    let value = ev.target.value;
    this.model.difference = this.model.closingstock - (this.model.alreadyscannedstock + value)
  }
  submitData(fr:any){
    let obj={
"auditId": this.selectedMaterial._id,
"docName": this.docName,
"physicalStock": Number(this.model.physicalstock) 
    }
    this.custservice.saveAuditItem(obj).subscribe((res:any)=>{
if(res.status === "Success"){
  Swal.fire({
    text: res["message"],
    icon: "success",
    width: 500,
  });
  fr.resetForm();
}
    })
  }
  clearForm(){
    this.model={}
  }
  finishAudit(){
    let obj={
"auditId":  this.totalData[0].audit_id,
 "key":"COMPLETED"
    }
    this.custservice.auditComplete(obj).subscribe((res:any)=>{
if(res.status === "Success"){
  Swal.fire({
    text: res["message"],
    icon: "success",
    width: 500,
  });
}
    })
  }
}
