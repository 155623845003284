<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" [dir]="options.dir"
  [attr.data-theme]="options.theme" [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos" [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav class="d-flex top-navbar navbar-expand-lg"
      [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'">
      <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" [attr.data-logobg]="options.logobg">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)"> 
          <i class="ti-menu" (click)="toggleNavBar()"></i>
          <!-- <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i> -->
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="javascript:void(0)" [routerLink]="'/maindashboard'">
          <!-- Logo icon -->
          <!-- <b class="logo-icon" *ngIf="isshowLogo == 'no'" style="text-transform: capitalize">
            {{ logoText }}</b>
            <b  class="logo-icon" *ngIf=" isshowLogo == 'yes'">
              <img *ngIf="
              companyName == 'GREENKO' || 'Digitele' || 'Digitele Networks'
            " [src]="logourl" width="150" alt="homepage" class="light-logo" />
            </b> -->


          <!-- <b class="logo-icon" *ngIf="!nongroupedCompanyNames" style="text-transform: capitalize">
            {{ urlName[0] }}</b>
            <b  class="logo-icon">
              <img *ngIf="
              companyName == 'GREENKO' || 'Digitele' || 'Digitele Networks'
            " [src]="logourl" width="150" alt="homepage" class="light-logo" />
            </b> -->
            <b class="logo-icon" *ngIf="mainLogo">
              <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
              <!-- Dark Logo icon -->
              <img *ngIf="
                  companyName == 'GREENKO' || 'Digitele' || 'Digitele Networks'
                " [src]="mainLogo" width="150" alt="homepage" class="dark-logo" />
              <!-- Light Logo icon -->
              <img *ngIf="
                  companyName == 'GREENKO' || 'Digitele' || 'Digitele Networks'
                " [src]="mainLogo" width="150" alt="homepage" class="light-logo" />
            </b>
            <b class="logo-text" *ngIf="logoText">
              <h3 *ngIf="companyName !== 'MEIL-HES'" style="text-transform: capitalize;">
                <b>{{logoText}}</b>
              </h3>
              <h3 *ngIf="companyName == 'MEIL-HES'" style="margin-top: 18px">
                <b>MEIL-HES<br />(Pinnapuram PSP Project) JV</b>
              </h3>
            </b>
          <!--End Logo icon -->
          <!-- Logo text -->

          <span *ngIf="
              (companyName == 'GREENKO' || 'Digitele' || 'Digitele Networks') &&
              !amGreenEnv1 && isnonGroup == 'no'
            " class="logo-text">
            | GEPS
            <!-- <img src="assets/greenko/logo_greeenko_eps.svg" alt="homepage" class="dark-logo" />
            <img src="assets/greenko/logo_greeenko_eps.svg" class="light-logo" alt="homepage" /> -->
          </span>
          <!-- <span *ngIf="companyName=='MEIL-HES'" class="logo-text"> | MEIL-HES
          </span> -->
        </a>
        <a class="navbar-brand" href="javascript:void(0)">
          <i class="ti-menu" (click)="toggleNavBar()"></i></a> 
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent">
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg"
        [ngbCollapse]="!isCollapsed">
        <!-- Vertical Header -->
        <div *ngIf="options.layout === 'horizontal'" class="w-100">
          <app-horizontal-navigation class="w-100 d-flex align-items-center justify-content-between">
          </app-horizontal-navigation>
        </div>

        <!-- Horizontal Header  -->
        <div *ngIf="options.layout === 'vertical'" class="w-100 ml-4">
          <ul class="navbar-nav float-left mr-auto search-bar mt-2">
            <li class="nav-item search1" style="width: 260px" *ngIf="showSearch">
              <mat-form-field appearance="outline" [floatLabel]="'always'" class="w-full w-100">
                <!-- <mat-label>Search Projects</mat-label> -->
                <!-- <input matInput type="text" [(ngModel)]="searchValue" placeholder="Search Projects"
                  (input)="filterProjects()" />
                <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                  (click)="searchValue = ''; filterProjects()">
                  <mat-icon>close</mat-icon> -->
                  <input type="text" placeholder="Search Project Title" matInput [formControl]="myControl" [(ngModel)]="selectedProj"  (focus)="openDropdown()"  (input)="filterProjects()"
                  [matAutocomplete]="auto" >
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="onProjectSelected($event.option.value)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.proj_short_name">
                      {{option.proj_short_name}}
                  </mat-option>
              </mat-autocomplete>
              </mat-form-field>
            </li>
          </ul>
          <!-- *ngIf="showSearch" -->
          <ul class="navbar-nav float-left mr-auto search-bar projectbar ml-2">
            <li class="nav-item search1"   *ngIf="showSearch">
              <select class="dropdownstyles w-full w-100"  [disabled]="!selectedProj" [(ngModel)]="selectedNav"  (change)="onOptionChange(selectedNav)">
                <option value="" selected disabled>Project Options</option>
                <option value="Project_WBS" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','VIEW_PROJECT_WBS'])">Project WBS</option>
                <option value="drawings" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','DRAWINGS_LAYOUT'])">Drawings</option>
                <option value="documents" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','DOCUMENTS_LAYOUT'])">Documents</option>

                <option value="Issue_log" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','VIEW_PROJECT_ISSUE_LOG'])">Issue Log</option>
                <option value="Default_emails" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','VIEW_DRAWING_EMAILS'])">Default Emails</option>
                <!-- <option value="PMC drawings" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','VIEW_DRAWING_EMAILS'])">PMC Drawings</option> -->
                <!-- <option value="External" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','EXTERNAL_DRAWINGS'])">External</option> -->
                <option value="DMS" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','DMS'])">DMS</option>
                <option value="Dashboard" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','WBS_DASHBOARD'])">Dashboard</option>
                <option value="S-Curve" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','S_CURVE'])">S-Curve</option>
                <option value="Abstract" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','ABSTRACT'])">Abstract</option>
                <option value="Logistics" *ngIf="authService.checkUserPermission(['SUPER_ADMIN','LOGISTICS'])">Logistics</option>
              </select>
              </li>
            </ul>
          <ul class="navbar-nav flright" [ngClass]="{'flright12':showSearch}">
            <!-- <ul class="list-style-none"> -->
            <!-- <button class="mt-3" mat-button   matTooltip="UserName"
              placement="left"><i class="fa fa-user"></i>&nbsp;<span class="usernameDetails">{{userDetails.username}}</span></button> -->
            <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
                <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"><i
                      class="fas fa-user mt-1"></i> madan.G</button>
                </a>
                <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
                  <span class="with-arrow">
                    <span class="bg-success"></span>
                  </span>
                  <a class="dropdown-item " href="javascript:void(0)">
                    dropdown</a>
                  <a class="dropdown-item" href="javascript:void(0)">
                    dropdown</a>
                </div>
              </li> -->
            <!-- </ul> -->
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <!-- <button class="mt-3" mat-button   matTooltip="Projects" (click)="projectsrouting()"
              placement="left"  *ngIf="authService.checkUserPermission(['SUPER_ADMIN','VIEW_PROJECTS'])"><i class="fa fa-home"></i></button> -->

              <button class="mt-3" mat-button [matMenuTriggerFor]="dashBoards" matTooltip="Dashboards" placement="left"
                *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_PROJECTS',
                    'PROJECT_DASHBOARD',
                    'GAMS_DASHBOARD'
                  ])
                ">
                <i class="fa fa-home"></i>
              </button>
              <mat-menu #dashBoards="matMenu">
                <button mat-menu-item (click)="projectsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_PROJECTS'
                    ])
                  ">
                  Projects
                </button>
                <button mat-menu-item (click)="gamdashboardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GAMS_DASHBOARD'
                    ])
                  ">
                  GAMS Dashboard
                </button>
                <button mat-menu-item (click)="projectdashboardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PROJECT_DASHBOARD'
                    ])
                  ">
                  PROJECT Dashboard
                </button>
              </mat-menu>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
                <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="Projects"
                    placement="left"><i class="fa fa-home mt-1 "></i></button>
                </a>
                <div class="dropdown-menu dropdown-menu-right user-dd  pb-0 " ngbDropdownMenu>
                  <span class="with-arrow">
                    <span class="bg-success"></span>
                  </span>
                  <a class="dropdown-item " href="javascript:void(0)">
                    dropdown</a>
                  <a class="dropdown-item" href="javascript:void(0)">
                    dropdown</a>
                </div>
              </li> -->
            </ul>

            <ul class="list-style-none">
              <button class="mt-3" mat-button *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'DAILY_MATERIAL_RECEIPT',
                    'QUALITY_CHECK',
                    'GOODS_RECEIPT',
                    'GOODS_ISSUE','PURCHASE_REQUEST','WORK_REQUEST','NFA_REQUEST','MATERIAL_REQUEST','BILL_APPROVAL_NOTE',
                    'GOODS_RETURN',
                    'DELIVERY_CHALLAN',
                    'OUT_GATE_PASS_RGP',
                    'OUT_GATE_PASS_NRGP',
                    'ESDN',
                    'INVENTORY_REPORTS',
                    'GSS_REPORTS',
                    'GATE_INWARD',
                    'GATE_OUTWARD_RGP',
                    'GATE_OUTWARD_NRGP',
                    'GATE_OUTWARD_GI',
                    'PURCHASE_ORDER','SALE_ORDER_SUPPLY_MATERIALS','PO_BOQ',
                    'PO_EXCEL_UPLOAD',
                    'GOODS_ISSUE_REQUEST_V2',
                    'GATE_INWARD_V2',
                    'GATE_OUTWARD_RGP_V2',
                    'GATE_OUTWARD_NRGP_V2',
                    'GATE_OUTWARD_GI_V2',
                    'INVENTORY_REPORTS_V2','DOCUMENT_APPROVALS','MATERIAL_ORDER_STATUS','NFA_REPORTS','MACHINERY_REPORTS','MATERIAL_ORDER_STATUS_REPORTS',
                    'GSS_REPORTS_V2','RMC_REPORTS','ESDN_REPORTS','REUSABLE_ITEMS_REPORTS','TRANSIT_REPORTS','CLUSTER_MASTER','BUSSINESS_MASTER','VERTICAL_MASTER','AUTHORIZATION_CHAIN_MAS','DMR_BULK_UPDATE','MACHINERY','QR_PRINT_DMR',
                    'RMC_RECEIPT','RMC_CONSUMPTION','RMC_RETURN','RMC_SUPPLY','RMC','PLANT_MASTER','EQUIPMENT_MASTER','COSTCENTER_MASTER','DESIGNMIX_MASTER','REUSABLE_ITEMS_INWARD','REUSABLE_ITEMS_OUTWARD','REUSABLE_ITEMS_RETURN',
                  ])
                " [matMenuTriggerFor]="versions" matTooltip="Modules" placement="left">
                <i class="fa fa-folder-open"></i>
              </button>
              <!-- <mat-menu #versions="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="version1" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'DAILY_MATERIAL_RECEIPT',
                      'QUALITY_CHECK',
                      'GOODS_RECEIPT',
                      'GOODS_ISSUE',
                      'GOODS_RETURN',
                      'DELIVERY_CHALLAN',
                      'OUT_GATE_PASS_RGP',
                      'OUT_GATE_PASS_NRGP',
                      'INVENTORY_REPORTS',
                      'GSS_REPORTS',
                      'GATE_INWARD',
                      'GATE_OUTWARD_RGP',
                      'GATE_OUTWARD_NRGP',
                      'GATE_OUTWARD_GI',
                      'PURCHASE_ORDER',
                      'PO_EXCEL_UPLOAD'
                    ])
                  ">
                  Version 1
                </button>
                <button mat-menu-item [matMenuTriggerFor]="version2"  *ngIf="authService.checkUserPermission(['SUPER_ADMIN','GOODS_ISSUE_REQUEST_V2','GATE_INWARD_V2','GATE_OUTWARD_RGP_V2','GATE_OUTWARD_NRGP_V2','GATE_OUTWARD_GI_V2','INVENTORY_REPORTS_V2','GSS_REPORTS_V2'])">Version 2</button>
              </mat-menu> -->
              <mat-menu #versions="matMenu">
                <!-- <div *ngFor="let item of VersiondropdownData">
              <button mat-menu-item [matMenuTriggerFor]="item.trigger">{{item.menu}}</button>
            </div> -->
                <button mat-menu-item [matMenuTriggerFor]="v1Inventory" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'DAILY_MATERIAL_RECEIPT',
                      'QUALITY_CHECK',
                      'GOODS_RECEIPT',
                      'GOODS_ISSUE',
                      'GOODS_RETURN',
                      'DELIVERY_CHALLAN',
                      'OUT_GATE_PASS_RGP',
                      'OUT_GATE_PASS_NRGP',
                      'STORAGE_LOCATION',
                      'AUDIT',
                      'DMR_BULK_UPDATE',
                      'ESDN',
                      
                    ]) && !amGreenEnv
                  ">
                  Stores
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v1planning" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PURCHASE_REQUEST','MATERIAL_REQUEST',
                      'WORK_REQUEST','NFA_REQUEST','BILL_APPROVAL_NOTE'
                    ])
                  ">
                  Planning/ QSD
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v1reports" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'INVENTORY_REPORTS',
                      'GSS_REPORTS',
                      'TRANSIT_REPORTS',
                      'PO_REPORTS',
                      'PR_REPORTS',
                      'RMC_REPORTS','NFA_REPORTS',
                      'ESDN_REPORTS','MACHINERY_REPORTS','MATERIAL_ORDER_STATUS_REPORTS',
                      'REUSABLE_ITEMS_REPORTS'
                    ]) && !amGreenEnv
                  ">
                  Reports
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v1gss" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_INWARD',
                      'GATE_OUTWARD_RGP',
                      'GATE_OUTWARD_NRGP',
                      'GATE_OUTWARD_GI'
                    ]) && !amGreenEnv
                  ">
                  GSS
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v1cp" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PURCHASE_ORDER',
                      'PO_BOQ',
                      'SALE_ORDER_SUPPLY_MATERIALS',
                      'PO_EXCEL_UPLOAD'
                    ]) && !amGreenEnv
                  ">
                  C&P
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v1rmc" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN','RMC_RECEIPT','RMC_CONSUMPTION','RMC_RETURN','RMC_SUPPLY'
                ]) && !amGreenEnv
              ">
             RMC
            </button>
           
            <button mat-menu-item [matMenuTriggerFor]="v1reusableitems" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'REUSABLE_ITEMS_INWARD',
                      'REUSABLE_ITEMS_OUTWARD',
                      'REUSABLE_ITEMS_RETURN'
                    ]) && !amGreenEnv
                  ">
                  Reusable Items
                </button>
                
             <button mat-menu-item  (click)="machineryrouting()" *ngIf="
            authService.checkUserPermission([
              'SUPER_ADMIN','MACHINERY'
            ])
          ">
         Machinery
        </button> 
            <!-- [matMenuTriggerFor]="v1documentapp" -->
            <button mat-menu-item (click)="documentapprovalsrouting()"  *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN','DOCUMENT_APPROVALS'
                ])
              ">
             Document Approvals
            </button>
            <button mat-menu-item (click)="materialorderstatusrouting()"  *ngIf="
            authService.checkUserPermission([
              'SUPER_ADMIN','MATERIAL_ORDER_STATUS'
            ])
          ">
         Material Order Status
        </button>
            <button mat-menu-item (click)="cmpdocumentapprovalsrouting()"  *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN','GOODS_RECEIPT_COMPLETED_APPROVALS'
                ])
              ">
             Document View
            </button>
              </mat-menu>
              <mat-menu #version2="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="v2planning" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GOODS_ISSUE_REQUEST_V2'
                    ]) && !amGreenEnv
                  ">
                  Planning
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v2gss" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_INWARD_V2',
                      'GATE_OUTWARD_RGP_V2',
                      'GATE_OUTWARD_NRGP_V2',
                      'GATE_OUTWARD_GI_V2'
                    ]) && !amGreenEnv
                  ">
                  GSS
                </button>
                <button mat-menu-item [matMenuTriggerFor]="v2reports" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'INVENTORY_REPORTS_V2',
                      'GSS_REPORTS_V2'
                    ]) && !amGreenEnv
                  ">
                  Reports
                </button>
              </mat-menu>
              <mat-menu #v1Inventory="matMenu">
                <button mat-menu-item (click)="storesdashboardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'STORES_DASHBOARD'
                    ])
                  ">
                  Stores Dashboard
                </button>
                <button mat-menu-item (click)="gamsdashboardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GAM_DASHBOARD'
                    ])
                  ">
                  Gam Dashboard
                </button>
                <button mat-menu-item (click)="dmrrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'DAILY_MATERIAL_RECEIPT'
                    ])
                  ">
                  Daily Material Receipt
                </button>
                <button mat-menu-item (click)="qualitycheckrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'QUALITY_CHECK'
                    ])
                  ">
                  Quality Check
                </button>
                <button mat-menu-item (click)="goodsreceiptrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GOODS_RECEIPT'
                    ])
                  ">
                  Goods Receipt
                </button>
                <button mat-menu-item (click)="goodsissuerouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GOODS_ISSUE'
                    ])
                  ">
                  Goods Issue
                </button>
                <button mat-menu-item (click)="goodsreturnrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GOODS_RETURN'
                    ])
                  ">
                  Goods Return
                </button>
                <button mat-menu-item (click)="deliverychallanrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'DELIVERY_CHALLAN'
                    ])
                  ">
                  Delivery Challan
                </button>
                <button mat-menu-item (click)="outgatepassrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'OUT_GATE_PASS_RGP'
                    ])
                  ">
                  OutGatePass RGP
                </button>
                <button mat-menu-item (click)="outgatepassnrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'OUT_GATE_PASS_NRGP'
                    ])
                  ">
                  OutGatePass NRGP
                </button>
                <button mat-menu-item (click)="esdnrouting()" 
                *ngIf="authService.checkUserPermission([
                  'SUPER_ADMIN','ESDN'
                  ])">
                  ESDN
                </button>
                <button mat-menu-item (click)="storagelocationrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'STORAGE_LOCATION'
                    ])
                  ">
                  Storage Location
                </button>
                <button mat-menu-item (click)="auditrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'AUDIT'
                ])
              ">
              Audit
            </button>
            <button mat-menu-item (click)="dmrbulkupdate()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'DMR_BULK_UPDATE'
                ])
              ">
              DMR Bulk Update
            </button>
            <button mat-menu-item (click)="qrprintdmr()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'QR_PRINT_DMR'
                ])
              ">
              QR Print DMR  
            </button>
              </mat-menu>
              <mat-menu #v1reports="matMenu">
                <button mat-menu-item (click)="inventoryreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'INVENTORY_REPORTS'
                    ])
                  ">
                  Store Reports
                </button>
                <button mat-menu-item (click)="gssreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GSS_REPORTS'
                    ])
                  ">
                  GSS Reports
                </button>
                <button mat-menu-item (click)="transitreportsrouting()"
                  *ngIf="authService.checkUserPermission(['SUPER_ADMIN','TRANSIT_REPORTS'])">
                  Transit Reports
                </button>
                <button mat-menu-item (click)="poreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PO_REPORTS'
                    ])
                  ">
                  PO Reports
                </button>
                <button mat-menu-item (click)="prreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PR_REPORTS'
                    ])
                  ">
                  PR Reports
                </button>
                <button mat-menu-item (click)="rmcreportsrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'RMC_REPORTS'
                ])
              "> RMC Reports
            </button>
            <button mat-menu-item (click)="nfareportsrouting()" *ngIf="
            authService.checkUserPermission([
              'SUPER_ADMIN',
              'NFA_REPORTS'
            ])
          "> NFA Reports
        </button>
            <button mat-menu-item (click)="esdnreportsrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'ESDN_REPORTS'
                ])
              "> ESDN Reports
            </button>

            <button mat-menu-item (click)="machineryreports()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'MACHINERY_REPORTS'
                ])
              "> Machinery Reports
            </button>
            <button mat-menu-item (click)="mosreports()" *ngIf="
            authService.checkUserPermission([
              'SUPER_ADMIN',
              'MATERIAL_ORDER_STATUS_REPORTS'
            ])
          "> MOS Reports
        </button>
            <button mat-menu-item (click)="reusableitemsreportsrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'REUSABLE_ITEMS_REPORTS'
                ])
              "> Reusable Item Reports
            </button>
              </mat-menu>
              <mat-menu #v1gss="matMenu">
                <button mat-menu-item (click)="gateinwardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_INWARD'
                    ])
                  ">
                  Gate Inward
                </button>
                <button mat-menu-item (click)="gateoutwardrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_RGP'
                    ])
                  ">
                  Gate Outward RGP
                </button>
                <button mat-menu-item (click)="gateoutwardnrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_NRGP'
                    ])
                  ">
                  Gate Outward NRGP
                </button>
                <button mat-menu-item (click)="gateoutwardgirouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_GI'
                    ])
                  ">
                  Gate Outward GI
                </button>
              </mat-menu>
              <mat-menu #v1cp="matMenu">
                <button mat-menu-item (click)="purchaseorderrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PURCHASE_ORDER'
                    ])
                  ">
                  Purchase Order
                </button>
                <button mat-menu-item (click)="purchaseorderboqrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PO_BOQ'
                    ])
                  ">
                  Purchase Order BOQ
                </button>
                <button mat-menu-item (click)="v1workorderrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'WORK_ORDER'
                    ])
                  ">
                  Work Order
                </button>
                <button mat-menu-item (click)="v1manrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'MAN'
                ])
              ">
             MAN
            </button>
                <button mat-menu-item (click)="v1workordersupplyrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'SALE_ORDER_SUPPLY_MATERIALS'
                ])
              ">
              Sale Order Supply Materials
            </button>
                <button mat-menu-item (click)="exceluploadrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PO_EXCEL_UPLOAD'
                    ])
                  ">
                  Excel Upload
                </button>
              </mat-menu>

              <!-- rmc -->
              <mat-menu #v1rmc="matMenu">
                <button mat-menu-item (click)="rmcgoodsreceiptrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'RMC_RECEIPT'
                ])">
                Raw Material Goods Receipt
                </button>
                <button mat-menu-item (click)="rmcgoodsissuerouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'RMC_CONSUMPTION'
                ])">
                  Raw Material Goods Consumption
                 </button>
                 <button mat-menu-item (click)="rmcgoodsreturnrouting()" *ngIf="
                 authService.checkUserPermission([
                   'SUPER_ADMIN',
                   'RMC_RETURN'
                 ])">
                  Raw Material Goods Return
                 </button>
                 <button mat-menu-item (click)="rmcissuerouting()" *ngIf="
                 authService.checkUserPermission([
                   'SUPER_ADMIN',
                   'RMC_SUPPLY'
                 ])">
                 RMC Supply
                 </button>
              </mat-menu>
              <!-- Reusable Items -->
              <mat-menu #v1reusableitems="matMenu">
                <button mat-menu-item (click)="reusableItemInwardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'REUSABLE_ITEMS_INWARD'
                    ])
                  ">
                  Reusable Items Inward
                </button>
                <button mat-menu-item (click)="reusableItemoutwardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'REUSABLE_ITEMS_OUTWARD'
                    ])
                  ">
                  Reusable Items Outward
                </button>
                <button mat-menu-item (click)="reusableItemreturnrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'REUSABLE_ITEMS_RETURN'
                    ])
                  ">
                  Reusable Items Return
                </button>
              </mat-menu>
              <!-- end -->
              <mat-menu #v1planning="matMenu">
                <button mat-menu-item (click)="v1purchaserequestrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PURCHASE_REQUEST'
                    ]) && !amGreenEnv
                  ">
                  Purchase Request
                </button>
              
                <button mat-menu-item (click)="v1goodsIssueRequestrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN' ,'MATERIAL_REQUEST'
                ]) && !amGreenEnv ">
              Material Request
            </button>
                <button mat-menu-item (click)="v1workrequestrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'WORK_REQUEST'
                    ]) && !amGreenEnv
                  ">
                  Work Request
                </button>
                <button mat-menu-item (click)="nfadocuments()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'NFA_REQUEST'
                    ])
                  ">
                  NFA/Otherdocuments
                </button>
                <button mat-menu-item (click)="billAprrovals()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'BILL_APPROVAL_NOTE'
                ])
              ">
              Bill Approval
            </button>
                <!-- <button mat-menu-item (click)="v1authorizationchainrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'AUTHORIZATION_CHAIN'
                ])
              ">
              Authorization Chain
            </button> -->
                <!-- <button mat-menu-item (click)="v1addpurchaserequestrouting()" *ngIf="authService.checkUserPermission(['SUPER_ADMIN', 'Add_PURCHASE_REQUEST',])">Added Purchase Request</button> -->
              </mat-menu>
              <mat-menu #v2planning="matMenu">
                <button mat-menu-item (click)="v2goodsissuerequestrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GOODS_ISSUE_REQUEST_V2'
                    ])
                  ">
                  Goods Issue Request
                </button>
              </mat-menu>
              <mat-menu #v2gss="matMenu">
                <button mat-menu-item (click)="v2gateinwardrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_INWARD_V2'
                    ])
                  ">
                  Gate Inward
                </button>
                <button mat-menu-item (click)="v2gateoutwardrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_RGP_V2'
                    ])
                  ">
                  Gate Outward RGP
                </button>
                <button mat-menu-item (click)="v2gateoutwardnrgprouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_NRGP_V2'
                    ])
                  ">
                  Gate Outward NRGP
                </button>
                <button mat-menu-item (click)="v2gateoutwardgirouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GATE_OUTWARD_GI_V2'
                    ])
                  ">
                  Gate Outward GI
                </button>
              </mat-menu>
              <mat-menu #v2reports="matMenu">
                <button mat-menu-item (click)="v2inventoryreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'INVENTORY_REPORTS_V2'
                    ])
                  ">
                  Inventory Reports
                </button>
                <button mat-menu-item (click)="v2gssreportsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'GSS_REPORTS_V2'
                    ])
                  ">
                  GSS Reports
                </button>
              </mat-menu>
            </ul>
            <ul class="list-style-none">
              <button class="mt-3" mat-button matTooltip="Master" [matMenuTriggerFor]="master" placement="left" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_MATERIAL',
                    'VIEW_VENDORS',
                    'VIEW_CONTRACTORS',
                    'VIEW_COMPANIES',
                    'VIEW_TAX_LIST',
                    'VIEW_FINANCIAL_YEAR',
                    'SERVICE_MASTER',
                    'VEHICLE_MASTER','STORAGE_LOCATION_MASTER',
                    'PLANT_MASTER','EQUIPMENT_MASTER','COSTCENTER_MASTER','DESIGNMIX_MASTER','PROJECT_MASTER',
                    'CLUSTER_MASTER','BUSSINESS_MASTER','VERTICAL_MASTER','AUTHORIZATION_CHAIN_MAS'
                  ])
                ">
                <i class="fas fa-list"></i>
              </button>
              <mat-menu class="custom-menu" #master="matMenu">
                <button mat-menu-item (click)="materialmasterrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_MATERIAL'
                    ]) && !amGreenEnv
                  ">
                  Material
                </button>
                <button mat-menu-item (click)="vendormasterrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_VENDORS'
                    ]) && !amGreenEnv
                  ">
                  Vendors
                </button>
                <button mat-menu-item (click)="contractorsrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_CONTRACTORS'
                    ]) && !amGreenEnv
                  ">
                  Contractors
                </button>
                <button mat-menu-item (click)="companiesrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_COMPANIES'
                    ]) && !amGreenEnv
                  ">
                  Companies
                </button>
                <button mat-menu-item (click)="taxlistrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_TAX_LIST'
                    ]) && !amGreenEnv
                  ">
                  Tax List
                </button>
                <button mat-menu-item (click)="financialrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VIEW_FINANCIAL_YEAR'
                    ]) && !amGreenEnv
                  ">
                  Financial Year
                </button>
                <button mat-menu-item (click)="servicemasterrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'SERVICE_MASTER'
                    ]) && !amGreenEnv
                  ">
                  Service Master
                </button>
                <button mat-menu-item (click)="vehiclemasterrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'VEHICLE_MASTER'
                    ]) && !amGreenEnv
                  ">
                  Vehicle Master
                </button>
                <button mat-menu-item (click)="projectmasterrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'PROJECT_MASTER'
                ]) && !amGreenEnv
              "> Project Master </button>
                <button mat-menu-item (click)="costcenterrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'COSTCENTER_MASTER'
                ]) && !amGreenEnv
              "> Cost Center </button>
                <button mat-menu-item (click)="plantmasterrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'PLANT_MASTER'
                ]) && !amGreenEnv
              "> Plant Master </button>
                <button mat-menu-item (click)="equipmentmasterrouting()" *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'EQUIPMENT_MASTER'
                ]) && !amGreenEnv
              "> Equipment Master </button>
                <button mat-menu-item (click)="designmixmasterrouting()"
                *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'DESIGNMIX_MASTER'
                ]) && !amGreenEnv
              "> Design Mix Master </button>
                <button mat-menu-item (click)="verticalmasterrouting()"
                *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'VERTICAL_MASTER'
                ]) && !amGreenEnv
              "> Vertical Master </button>
                <button mat-menu-item (click)="businessunitrouting()"
                *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'BUSSINESS_MASTER'
                ]) && !amGreenEnv
              "> Business Unit </button>
                <button mat-menu-item (click)="clustermasterrouting()"
                *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'CLUSTER_MASTER'
                ]) && !amGreenEnv
              "> Cluster Master </button>
                <button mat-menu-item (click)="authorizationmasterrouting()"
                *ngIf="
                authService.checkUserPermission([
                  'SUPER_ADMIN',
                  'AUTHORIZATION_CHAIN_MAS'
                ])
              "> Authorization Chain </button>
               <button mat-menu-item (click)="storagelocationsrouting()"
               *ngIf="
               authService.checkUserPermission([
                 'SUPER_ADMIN',
                 'STORAGE_LOCATION_MASTER'
               ])
             "> Storage Locations </button>
              </mat-menu>
            </ul>
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <button class="mt-3" mat-button matTooltip="Tickets" placement="left" (click)="ticketrouting()" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_TICKETS'
                  ])
                ">
                <i class="fa fa-ticket-alt"></i>
              </button>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="Tickets"
                placement="left"><i class="fa fa-ticket-alt mt-1"></i></button>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
              <span class="with-arrow">
                <span class="bg-success"></span>
              </span>
              <a class="dropdown-item " href="javascript:void(0)">
                dropdown</a>
              <a class="dropdown-item" href="javascript:void(0)">
                dropdown</a>
            </div>
          </li> -->
            </ul>
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <button class="mt-3" mat-button matTooltip="Gallery" placement="left" (click)="galleryrouting()" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_GALLERY'
                  ])
                ">
                <i class="fa fa-image"></i>
              </button>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="Gallery"
                placement="left"><i class="fas fa-image mt-1"></i></button>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
              <span class="with-arrow">
                <span class="bg-success"></span>
              </span>
              <a class="dropdown-item " href="javascript:void(0)">
                dropdown</a>
              <a class="dropdown-item" href="javascript:void(0)">
                dropdown</a>

            </div>
          </li> -->
            </ul>
            <ul class="list-style-none" >
              <button class="mt-3" mat-button [matMenuTriggerFor]="userDetail" matTooltip="User Details"
                placement="left" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'PENDING_SIGNATURES',
                    'USER_PROFILE'
                  ])
                ">
                <i class="fas fa-id-card"></i>
              </button>
              <mat-menu #userDetail="matMenu">
                <button mat-menu-item (click)="userprofilerouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'USER_PROFILE'
                    ])
                  ">
                  User Profile
                </button>
                <button mat-menu-item (click)="pendingsignaturesrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'PENDING_SIGNATURES'
                    ])
                  ">
                  Pending Signatures
                </button>
                <button mat-menu-item (click)="autotriggeremailrouting()" *ngIf="
                    authService.checkUserPermission([
                      'SUPER_ADMIN',
                      'AUTO_TRIGGER_EMAIL'
                    ])
                  ">
                  Auto Trigger Emails
                </button>
              </mat-menu>
            </ul>
            <ul class="list-style-none">
              <button class="mt-3" mat-button matTooltip="User Management" placement="left"
                (click)="usermanagementrouting()" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_USER_MANAGEMENT'
                  ])
                ">
                <i class="fa fa-users"></i>
              </button>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="User Management"
                placement="left"><i class="fas fa-user mt-1 "></i></button>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
              <span class="with-arrow">
                <span class="bg-success"></span>
              </span>
              <a class="dropdown-item " href="javascript:void(0)">
                dropdown</a>
              <a class="dropdown-item" href="javascript:void(0)">
                dropdown</a>
            </div>
          </li> -->
            </ul>
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <button class="mt-3" mat-button matTooltip="UserProjectsWBS" placement="left"
                (click)="userprofileswbsrouting()" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_USER_PROJECT_WBS'
                  ])
                ">
                <i class="fa fa-user"></i>
              </button>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="UserProjectsWBS "
                placement="bottom-right"><i class="fas fa-users mt-1 "></i></button>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
              <span class="with-arrow">
                <span class="bg-success"></span>
              </span>
              <a class="dropdown-item " href="javascript:void(0)">
                dropdown</a>
              <a class="dropdown-item" href="javascript:void(0)">
                dropdown</a>
            </div>
          </li> -->
            </ul>
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <button class="mt-3" mat-button matTooltip="Menu Role" placement="left" (click)="menurolesrouting()"
                *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_MENU_ROLES'
                  ])
                ">
                <i class="fa fa-th-large"></i>
              </button>
              <!-- <li class="nav-item dropdown  top-navbar" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" matTooltip="Menu Role"
                placement="left"><i class="fas fa-bars mt-1"></i></button>
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd pb-0 " ngbDropdownMenu>
              <span class="with-arrow">
                <span class="bg-success"></span>
              </span>
              <a class="dropdown-item " href="javascript:void(0)">
                dropdown</a>
              <a class="dropdown-item" href="javascript:void(0)">
                dropdown</a>
            </div>
          </li> -->
            </ul>
            <ul class="list-style-none" *ngIf="!amGreenEnv">
              <button class="mt-3" mat-button matTooltip="Settings" placement="left" (click)="settingsrouting()" *ngIf="
                  authService.checkUserPermission([
                    'SUPER_ADMIN',
                    'VIEW_SETTINGS'
                  ])
                ">
                <i class="fa fa-cog"></i>
              </button>
            </ul>
            <!-- <ul class="list-style-none">
              <span class="mt-4 d-flex align-middle">
                <mat-icon>exit_to_app</mat-icon><span class="pt-1"> {{ Displaytimer }}</span></span>
            </ul> -->
            <!-- <li class="nav-item d-none d-lg-block">
              <a (click)="changeSideBar()" class="nav-link sidebartoggler waves-effect waves-light"
                href="javascript:void(0)">
                <i class="mdi mdi-menu font-24"></i>
              </a>
            </li> -->

            <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
              <!-- <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
                *ngIf="applications$.length > 0" id="2" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="font-24 mdi mdi-view-dashboard text-dark"></i>

              </a> -->
              <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
                <span class="with-arrow">
                  <span class="bg-success"></span>
                </span>
                <ul class="list-style-none">
                  <li>
                    <div class="drop-title text-white bg-success">
                      <h4 class="m-b-0 m-t-5">All Apps</h4>
                      <span class="font-light">{{ applications$.length }} Apps</span>
                    </div>
                  </li>
                  <li>
                    <div class="message-center message-body" [perfectScrollbar]="config">
                      <!-- Message -->
                      <a href="javascript:void(0)" class="message-item" *ngFor="let app of applications$"
                        (click)="openNewApplication(app)">
                        <span class="user-img">
                          <img src="{{ app.app_icon }}" alt="user" class="rounded-circle" />
                        </span>
                        <span class="mail-contnet">
                          <h3 class="message-title">{{ app.app_name }}</h3>
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
              <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="assets/greenko/profile.png" alt="user" class="rounded-circle" width="31" />
              </a>
              <div class="dropdown-menu dropdown-menu-right user-dd pb-0" ngbDropdownMenu>
                <span class="with-arrow">
                  <span class="bg-success"></span>
                </span>
                <div class="d-flex no-block align-items-center bg-success text-white">
                  <div class="">
                    <img src="assets/greenko/profile.png" alt="user" class="img-circle" width="60" />
                  </div>
                  <div class="m-l-10">
                    <h4 class="m-b-0">{{ userDetails.preferred_username }}</h4>
                    <p class="m-b-0">{{ userDetails.email }}</p>
                  </div>
                </div>

                <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">
                  <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <ng-container *ngIf="showNavbar">
    <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()">
      <!-- Horizontal Header -->
      <div class="scroll-sidebar" [perfectScrollbar]="config">
        <app-vertical-sidebar (menuClick)="sideMenuClick()"></app-vertical-sidebar>
      </div>

      <!-- End Sidebar scroll-->
    </aside>
  </ng-container>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper" [ngStyle]="{ 'margin-left': showNavbar ? '250px' : '0px' }">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet #outlet="outlet"></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <aside class="customizer" [ngClass]="{ 'show-service-panel': showSettings }">
    <a href="javascript:void(0)" class="service-panel-toggle" (click)="showSettings = !showSettings">
      <i class="far fa-bell"></i>
      <!-- fa-spin -->
    </a>
    <div class="customizer-body custom-pills ps" [perfectScrollbar]="config">
      <div class="p-15">
        <h3 class="m-t-20 m-b-20">Recent Activity Log</h3>
        <div class="steamline">
          <div class="sl-item" *ngFor="let item of activities$">
            <div class="sl-left bg-success">
              <i class="ti-user"></i>
            </div>
            <div class="sl-right">
              <div class="font-medium">
                {{ item.proj_name }}
                <br />
                <span class="m-r-20"><i class="fas fa-user-circle"></i> {{ item.username }}
                </span>
                <span class="sl-date">
                  <i class="far fa-calendar-alt"></i>
                  {{ item.created_date | date : "medium" }}
                </span>
              </div>
              <div class="desc">{{ item.description }}</div>
            </div>
          </div>
          <mat-paginator class="pagenation1" [length]="totalRecords" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 75, 100]"
          aria-label="Select page" (page)="onpageevent($event)">
        </mat-paginator>
        </div>

      </div>
    </div>
  </aside>

  <!-- <button class="btn btn-sm chatbutton" *ngIf="nongroupedCompanyNames"
    [ngStyle]="{ visibility: !showMe ? 'visible' : 'hidden' }" (click)="showMe = !showMe">
    <i class="fa fa-comments-o" aria-hidden="true"></i>&nbsp;&nbsp;
    {{ urlName[0] }} Chat
  </button> -->
</div>

<div *ngIf="showMe" class="chatWindow">
  <div class="row m-0" style="background-color: rgb(10, 96, 71)">
    <div class="col-md-10">
      <a style="
          color: white;
          font-size: larger;
          font-family: fantasy sans-serif;
          text-transform: capitalize;
        ">{{ urlName[0] }} Chat
      </a>
    </div>
    <div class="col-md-2 d-flex justify-content-center" style="cursor: pointer">
      <i class="fa fa-window-minimize" aria-hidden="true" style="color: white" (click)="showMe = !showMe"></i>
    </div>
  </div>
  <div [ngStyle]="{ visibility: showMe ? 'visible' : 'hidden' }" style="height: 90vh; width: 100%; overflow: scroll">
    <iframe id="chatFrame" [src]="VerifySecureUrl(iframeSrc)" height="100%" width="100%" style="margin-bottom: 0px"
      allow="camera; microphone"></iframe>
  </div>
</div>                                                                    