import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
@Injectable({
  providedIn: "root",
})
export class AuthGuardKeycloak extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloak: KeycloakService,
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    return this.authenticated;
  }
}
