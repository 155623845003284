import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Injector, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Overlay } from 'ngx-toastr';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
// import { DomSanitizer } from '@angular/platform-browser';
import { MatSort } from '@angular/material/sort'; 
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-outgatepassrgp1',
  templateUrl: './outgatepassrgp1.component.html',
  styleUrls: ['./outgatepassrgp1.component.scss']
})
export class Outgatepassrgp1Component implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorap') paginatorap: MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatSort) sort: MatSort;
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  @ViewChild('deletedocsigned') deletedocsigned: TemplateRef<any>;
//SCANER
public scannerEnabled: boolean = true;
public information: string = "";
public errorScanner: string="";
doc_no: any; 
  dcnumber: any;
  DCDATA: any;
  selecteddcnumber: any;
  matcodedata: any;
  matDesc: string;
  documnet_nodata: any;
  viewnumber: any;
  viewData: boolean=false;
  logsearchData: any;
  SignData: any;
  approvalbtn: boolean=false;
  docSignature: any;
  goTo:any;
  document_no: any;
  txnId: any;
  reviewedexpectedReturnDays: any;
  reviewedexpectedredate: string;
  purposeData: any[]=[];
  rgp_type: any[]=[];
  exp_ret_days: any[]=[];
  editeddata: boolean=false;
  columnnaplist: any[]=[];
  searchDataap: any;
  columnnameap: any;
  previousTabIndex: number;
  approvalstatus: boolean=false;
  btnenable: boolean= false;
  gamCompanyfields: boolean=false;
  // signData_document_no: any;
  // paramsData: any;
  // signStatus: boolean=false;
  // signNull: any;
  // position: any;
  // Viewsign: any;
  // signature: any;
  // document_no: any;
  // document_name: any;
  // description: any;
  // level: any;
public scanSuccessHandler($event: any) {
  this.scannerEnabled = false;
  this.information = "";
  this.information = $event;
  console.log(this.information,"scan");
  
}

public enableScanner() {
  this.scannerEnabled = !this.scannerEnabled;
  this.information = "";
  this.scannerEnabled = true;
}
//END
  displayedColumns: any[] = [
    'action',
    'sno',
    'material_code',
    'material_description',
    'unit_of_measurement',
    'storage_location',
    'request_quantity',
    'quantity',
    'unit_price',
    'total_price',
    'item_remarks',
    // 'batch_no',
    'valution_type',
     

  ];
  displayedColumns1: any[] = [
    'sno',
    'Date',
    'number',
    'company_Name',
    'tocompanyname',
    'request_number',
    'count',
    // 'transfer_type',
    'action'
  ];
  displayedColumnsap: any[] = [
    'sno',
    'document_number',
    // 'document_name',
    'description',
    'company_Name',
    'concern_name',
    'level',
    'pending_at',
    'action'
  ];
  //variable declaration
  demo1TabIndex: any = 0;
  dialogRef: any = null;
  dataSource = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  formdata: any = {};
  companyData: any;
  selectedmaterial1: any;
  dialogdata: any = {quantity:0,unit_price:0,valutiontype:""};
  materialCODE: any;
  materialNAME: any;
  masterData: any;
  storageData: any;
  selectedstorage: any;
  valutiondata: any;
  saveddataarray: any[] = [];
  btn: any = "Save";
  SNO: any[] = [];
  matCode: any[] = [];
  matName: any[] = [];
  UOM: any[] = [];
  storageLocation: any[] = [];
  quantity: any[] = [];
  ReqQTY: any[] = [];
  itemremarks: any[] = [];
  unitPrice:any=[]
  basic_price:any=[]
  // batchno: any[] = [];
  valutionType: any[] = [];
  pageIndex: any = 1;
  pageIndex1: any = 1;
  pageSize: any = 10;
  logpageSize:any = 10;
  totalRecords: any = 0
  reaData: boolean;
  loadingRecords: any = false;
  resultogpnumber: any;
  fileUploadUrlsgt: any[] = [];
  logdata: any;
  editednumber: any;
  editModel: any = {};
  dialogdataedit: any = {qty:0,unit_price:0};
  systemref: any;
  selectedIndex: number;
  dialogdataeditmatcode: any;
  dialogdataeditmatdes: any;
  editdataa: any;
  deleteid: any;
  outgatepassrgpnum: any;
  createNewFile: any = {};
  filedata: any=[];
  imageUrl= environment.base_url;
  deleteNumber: any;
  deletemodel: any = {};
  fileUploadUrls: any[] = [];
  filenamearray: any[] = [];
  filenamearray1: any[] = [];
  selectedfiles: any[] = [];
  searchData: any;
  columnname: string;
  smsdata: any;
  smsdata1: any;
  companyData1: any;
  total:number=0
  purposeList:any=[]
  selectedPurpose: any;
  selectedvendor: string;
  vendorData: any=[];
  docsign_status:boolean=false
  DCID: any[] = []
  editDATAA: any = false;
  pageNumbers:any=[]
  today:any;
  rgptype:string="";
  companyname:any="";
  expectedReturnDays:any="";
   //end

   //
   pageNumbersap:any=[];
   pageIndexap: any = 1;
   pageSizeap: any = 10;
   totalRecordsap: any = 0;
   dataSourcemainap = new MatTableDataSource();
   goToap:any;
   reaDataap: boolean=false;
   userDetails: any = {}
   //
  constructor(private dialog: MatDialog,
    private custservice: CustomerService,
    public overlay: Overlay,
    private alertcall: AlertCallsService,
    private injector: Injector,
    private router: Router,
    public route: ActivatedRoute,public keycloackService: KeycloakService,
    // public sanitizer: DomSanitizer,
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.columnname = 'number';
  }

  ngOnInit(): void {
    this.columnnameap=""
    this.formdata.companyname=""
    this.formdata.rgptype=""
    this.formdata.expectedReturnDays=""
    this.today=moment(new Date()).format("YYYY-MM-DD")
    //print view
    // this.route.queryParams.subscribe((params: any)  => {
    //   if (params.tab == "viewSignatures") {
    //     this.signData_document_no=params['document_number'];
    //     this.position=params['position'];
    //     this.paramsData= params
    //     // console.log(params);
    //   }
      
    // });
    // if(this.paramsData){
    //   this.pendingSignaturesRGPview(this.paramsData)
    // }else{
      this.gamRestrictfields();
      this.getTransactionId();
      this.getcompanydata();
      this.getcompanydata1();
      this.getmasterdata();
      this.getstoragelocData();
      this.getvalutionData();
      this.getData();
      this.getPurposeList();
      this.getvendordata();
      this.multidropdowns();
      this.getDataap();
    // }
    this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
    // this.formdata.requestdate = moment(new Date()).format("YYYY-MM-DD");
    // this.formdata.lrdate = moment(new Date()).format("YYYY-MM-DD");
    // this.formdata.expectedredate = moment(new Date()).format("YYYY-MM-DD");
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab == "notificationsissue") {
        this.demo1TabIndex = 1;
      } else {
        this.demo1TabIndex = 0;
      }
    });
  }
  getTransactionId(){
    this.txnId=""
    this.custservice.getTransactionId().subscribe((res:any)=>{
      if(res){
        // console.log(res.txn_id);
        this.txnId=res.txn_id
      }
    })
  }
  //consignee 
  getvendordropdowndata(ev:any){
    console.log(ev.target.value);
    this.selectedvendor=ev.target.value
    if( this.selectedvendor.length>2){
   this.getvendordata()
    }
    if(! this.selectedvendor){
      this.getvendordata()
    }
  }
  getvendordata(){
    let obj={
      "command":"lst",
      "lmt":100000,
      "key":this.selectedvendor ||"",
    }
    this.custservice.getvendormasterdata(obj).subscribe((res:any)=>{
      this.vendorData=res.data
    })
  }
  selectedConsignee(){
    this.vendorData.forEach((el: any) => {
      if (el.name == this.formdata.consigneename) {
        this.formdata.consignee_address=el.address_1
      }
    });
}
  //get companies list
  getcompanydata() {
    let obj = {
      "command": "lst",
      lmt:10000,
      pid:1
    }
    this.custservice.getcompanymasterdata(obj).subscribe((res: any) => {
      this.companyData = res.data
    })
  }
  getcompanydata1() {
    let obj = {
      command: "lst",
      lmt:10000,
      pid:1
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1 = res.data;
    });
  }
  //get material code/name
  getmaterialDATA(ev: any) {
    this.selectedmaterial1 = ev.target.value
    if (this.selectedmaterial1.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial1) {
      this.getmasterdata();
    }
  }
  //edit get material code/name
  getmaterialDATA1(ev: any) {
    this.selectedmaterial1 = ev.target.value
    if (this.selectedmaterial1.length > 2) {
      this.getmasterdata()
    }
    if (!this.selectedmaterial1) {
      this.getmasterdata()
    }
  }
  getmasterdata() {
    let obj = {
      "command": 'lst',
      "lmt": 100,
      "pid": 1,
      "key": this.selectedmaterial1,
      // "code":this.matcodedata
    }
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      this.masterData = res.data
    //   this.masterData.forEach((ele: any) => {
    //     if (ele.code == this.matcodedata) {
    //       // alert(1)
    //       this.dialogdataedit.matcode = ele.system_reference_1
    //       // this.systemref = ele.system_reference_1
    //     }
    // });
    })
  }
  selectedmastergroup() {
    // console.log(this.dialogdata.matcode);
    this.masterData.forEach((ele: any) => {
      if (ele.system_reference_1 == this.dialogdata.matcode) {
        this.dialogdata.uom = ele.uom_1
        this.materialCODE = ele.code
        this.materialNAME = ele.name
      }
    });
    this.getSMSdata();
  }
  //get storage data
  getstorageDATA(ev: any) {
    // console.log(ev.target.value);
    this.selectedstorage = ev.target.value
    if (this.selectedstorage.length > 2) {
      this.getstoragelocData();
    }
  }
  getstoragelocData() {
    let obj = {
      "command": "mat",
      "field": "storage_location",
      "key": this.selectedstorage
    }
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data
    })
  }
  //get valuation data
  getvalutionData() {
    let obj = {
      "lmt": 100000,
      'pid': 1,
      "command": "lst",
      "key": ""
    }
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data
    })
  }
  //add service integration start
  savefinaldata(fr: any) {
    if (this.btn === "Save") {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1)
        this.matCode.push(ele.material_code)
        this.matName.push(ele.material_description  || ele.description || ele.matcode)
        this.UOM.push(ele.uom || ele.unit_of_measurement)
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.ReqQTY.push(Number(ele.reqqty || ele.quantity))
        this.quantity.push(Number(ele.quantity || ele.qty))
        this.itemremarks.push(ele.itemremarks || "NA")
        this.unitPrice.push(ele.unit_price)
        this.basic_price.push(ele.basic_price)
        // this.batchno.push(ele.batchno)
        this.valutionType.push(ele.valutiontype  || ele.valuation_type)
      })
      let obj = {
        "work_order_number": this.formdata.workordernum,
        "date": moment(this.formdata.dateee).format("YYYY-MM-DD"),
        "company_name": this.formdata.companyname,
        "to_company_name": this.formdata.tocompanyname,
        "consignee_name": this.formdata.consigneename,
        "request_number": this.formdata.requestnumber,
        "request_date": moment(this.formdata.requestdate).format("YYYY-MM-DD"),
        "receiver_name": this.formdata.receivername,
        "Transfer_type":this.formdata.rgptype,
        // "Transfer_type": this.formdata.transfertype,
        "expected_return_date": moment(this.formdata.expectedredate).format("YYYY-MM-DD"),
        "expected_return_days": this.formdata.expectedReturnDays,
        "vehicle_number": this.formdata.vehiclenumber,
        "transporter_name": this.formdata.transportername,
        "lr_number": this.formdata.lrnumber,
        "lr_date": moment(this.formdata.lrdate).format("YYYY-MM-DD"),
        "line_item": this.SNO,
        "material_code": this.matCode,
        "material_description": this.matName,
        "unit_of_measurment": this.UOM,
        "request_quantity": this.ReqQTY,
        "quantity": this.quantity,
        "item_remarks": this.itemremarks,
        "valuation_type": this.valutionType,
        "storage_location": this.storageLocation,
        "unit_price":this.unitPrice,
        "basic_price":this.basic_price,
        "to_locations":this.formdata.tolocation,
        "eway_bill":this.formdata.ewaybillno,
        // "batch_number": this.batchno,
        "comments": this.formdata.comments,
        "locations": this.formdata.location,
        "purpose": this.formdata.purpose,
        "work_order_value":this.formdata.workordervalue,
        "work_order_date":this.formdata.Workorderdate,
        "delivery_challan_number":this.dcnumber || "",
        "txn_id":this.txnId,
        "consignee_address":this.formdata.consignee_address,
        "command": "add"

      }
      if( this.formdata.rgptype == 'Hiring'){
        if(this.selectedfiles.length > 0 ){
          this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
            if (res && res['status_code'] == "200") {
              // this.alertcall.showSuccess('Accepted', res['message']);
              Swal.fire({
                text: res['message'],
                title: res['reference'],
                icon: 'success',
                // title: res['reference'],
                width: 500,
              });            
              // fr.reset();
              this.saveddataarray = []
              this.dataSource.data = []
              this.SNO = []
              this.matCode = []
              this.matName = []
              this.UOM = []
              this.storageLocation = []
              this.ReqQTY = []
              this.quantity = []
              this.itemremarks = []
              this.unitPrice=[],
              this.basic_price=[]
              this.valutionType=[]
              this.dcnumber=""
              // this.batchno = []
              this.getTransactionId();  
              this.getData()
              this.resultogpnumber = res['reference']              
              // if (this.fileUploadUrlsgt.length > 0) {
                this.uploadselectedfiles()
              // }
              this.formdata.companyname=""
              this.formdata.rgptype=""
              this.formdata.expectedReturnDays=""
              this.selectedfiles = []
              this.total=0
              this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
            } else {
              this.alertcall.showWarning('Accepted', res['message']);
              this.SNO = []
              this.matCode = []
              this.matName = []
              this.UOM = []
              this.storageLocation = []
              this.ReqQTY = []
              this.quantity = []
              this.itemremarks = []
              this.unitPrice=[],
              this.basic_price=[]
              this.valutionType=[]
              this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
              // this.batchno = []
              this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
            }
          })
        }else{
          Swal.fire("Hiring agreement required");
        }
      }else{
        this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
          if (res && res['status_code'] == "200") {
            // this.alertcall.showSuccess('Accepted', res['message']);
            Swal.fire({
              text: res['message'],
              title: res['reference'],
              icon: 'success',
              // title: res['reference'],
              width: 500,
            });
            // this.demo1TabIndex=1
            // fr.reset();
            this.saveddataarray = []
            this.dataSource.data = []
            this.SNO = []
            this.matCode = []
            this.matName = []
            this.UOM = []
            this.storageLocation = []
            this.ReqQTY = []
            this.quantity = []
            this.itemremarks = []
            this.unitPrice=[],
            this.basic_price=[]
            this.valutionType=[]
            this.dcnumber=""
            // this.batchno = []
            this.getTransactionId();  
            this.getData()
            this.resultogpnumber = res['reference']            
            // if (this.fileUploadUrlsgt.length > 0) {
              this.uploadselectedfiles()
            // }
            this.formdata = {};
            this.formdata.companyname=""
            this.formdata.rgptype=""
            this.formdata.expectedReturnDays=""
            this.selectedfiles = []
            this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
            this.total = 0;
          } else {
            this.alertcall.showWarning('Accepted', res['message']);
            this.SNO = []
            this.matCode = []
            this.matName = []
            this.UOM = []
            this.storageLocation = []
            this.ReqQTY = []
            this.quantity = []
            this.itemremarks = []
            this.unitPrice=[],
            this.basic_price=[]
            this.valutionType=[]
            this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
            // this.batchno = []
            // this.formdata.companyname=""
            // this.formdata.rgptype=""
            // this.formdata.expectedReturnDays=""
            this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
          }
        })
      }
   
    } else {
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1)
        this.matCode.push(ele.material_code)
        this.matName.push(ele.material_description || ele.description || ele.matcode)
        this.UOM.push(ele.uom || ele.unit_of_measurment || ele.unit_of_measurement)
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity || ele.quantity))
        this.quantity.push(Number(ele.quantity || ele.qty || ele.quantity))
        this.itemremarks.push(ele.itemremarks || ele.item_remarks || "NA")
        // this.batchno.push(ele.batchno || ele.batch_number)
        this.valutionType.push(ele.valutiontype || ele.valuation_type)
        this.unitPrice.push(ele.unit_price)
        this.basic_price.push(ele.basic_price)
      }
      )


      let obj = {
        "reason": this.editModel.reason,
        "work_order_number": this.formdata.workordernum,
        "date": moment(this.formdata.dateee).format("YYYY-MM-DD"),
        "company_name": this.formdata.companyname,
        "to_company_name": this.formdata.tocompanyname,
        "consignee_name": this.formdata.consigneename,
        "consignee_address":this.formdata.consignee_address,
        "request_number": this.formdata.requestnumber,
        "request_date": moment(this.formdata.requestdate).format("YYYY-MM-DD"),
        "receiver_name": this.formdata.receivername,
        "Transfer_type":this.formdata.rgptype,
        // "Transfer_type": this.formdata.transfertype,
        "expected_return_date": moment(this.formdata.expectedredate).format("YYYY-MM-DD"),
        "expected_return_days": this.formdata.expectedReturnDays,
        "vehicle_number": this.formdata.vehiclenumber,
        "transporter_name": this.formdata.transportername,
        "lr_number": this.formdata.lrnumber,
        "lr_date": moment(this.formdata.lrdate).format("YYYY-MM-DD"),
        "line_item": this.SNO,
        "material_code": this.matCode,
        "material_description": this.matName,
        "unit_of_measurment": this.UOM,
        "request_quantity": this.ReqQTY,
        "quantity": this.quantity,
        "item_remarks": this.itemremarks,
        "valuation_type": this.valutionType,
        "unit_price": this.unitPrice,
        "basic_price": this.basic_price,
        "storage_location": this.storageLocation,
        // "batch_number": this.batchno,
        "comments": this.formdata.comments,
        "command": "edt",
        "number": this.editednumber || this.doc_no,
        "locations": this.formdata.location,
        "to_locations":this.formdata.tolocation,
        "eway_bill":this.formdata.ewaybillno,
        "work_order_value":this.formdata.workordervalue,
        "work_order_date":this.formdata.Workorderdate,
        "purpose": this.formdata.purpose,
        "txn_id":this.txnId,
        "delivery_challan_number":this.dcnumber || ""
      }
      this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
        if (res && res['status_code'] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          });
          fr.reset();
          this.saveddataarray = []
          this.dataSource.data = []
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.ReqQTY = []
          this.quantity = []
          this.itemremarks = []
          this.unitPrice=[]
          this.basic_price=[]
          // this.batchno = []
          this.getTransactionId();
          this.getData()
          this.editModel.reason = ""
          this.resultogpnumber = this.editednumber
          // if (this.fileUploadUrlsgt.length > 0) {
            this.uploadselectedfiles()
          // }
          this.btn = "Save"
          this.selectedfiles = []
          this.formdata.companyname=""
          this.formdata.rgptype=""
          this.formdata.expectedReturnDays=""
          this.total=0
          this.demo1TabIndex = 1;
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.ReqQTY = []
          this.quantity = []
          this.itemremarks = []
          this.unitPrice=[]
          this.basic_price=[]
          // this.batchno = []
          this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
        }
      })
    }
  }
  //end

  //add outgatepassrgp model
  addoutgatePassRequest(data: any) {
    this.materialNAME=""
    this.materialCODE=""
    this.dialogdata.basic_price = 0
    this.dialog.open(data, {
      width: "1100px",
    });
    this.dialogdata.matcode=""
    this.dialogdata.storagelocation=""
    this.dialogdata.quantity=""
    this.dialogdata.unit_price=""
    this.dialogdata.uom=""
    this.dialogdata.reqqty=""
    this.dialogdata.basic_price=""
    this.dialogdata.itemremarks=""
    this.dialogdata.valutiontype=""
  }
  closedialogdata() {
    this.dialog.closeAll();
  }
  saveaddeddata(_form: any) {
    this.dialogdata['material_code'] = this.materialCODE
    this.dialogdata['material_description'] = this.materialNAME
    console.log(this.dialogdata);
    this.saveddataarray.push(this.dialogdata)
    this.total +=this.dialogdata.basic_price
    console.log(this.saveddataarray);
    this.dataSource.data = this.saveddataarray
    console.log(this.dataSource.data)
    this.dialogdata = {}
    this.dialog.closeAll()
     
    
  }
  getData1(){
    this.columnname=""
    this.searchData=""
    this.getData();
  }
  getData() {
    this.pageNumbers=[]
    let obj = {
      "command": "lst",
      "lmt": this.pageSize,
      "pid": this.pageIndex,
    }
    this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data
      this.dataSourcemain.sort = this.sort
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo=this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true
      }

    })
  }
  goToChange(){
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      // previousPageIndex: this.pageIndex-1,
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
    // this.emitPageEvent(event);
// this.pageIndex=this.goTo
// this.pageSize= 10;
// let pageevent:PageEvent={
//   length: this.totalRecords,
// pageIndex: this.goTo,
// pageSize: this.pageSize,
// previousPageIndex: this.goTo-1
// }
// this.onpageevent(pageevent)
  }
  emitPageEvent(pageEvent: PageEvent) {
    this.paginator.page.next(pageEvent);
  }

  // Approval in process
  //search
  calculateSerialNumber(index: number): number {
    const displayedIndex = index + 1 + (this.pageIndexap - 1) * this.pageSizeap;
    return isNaN(displayedIndex) ? 0 : Math.min(displayedIndex, this.totalRecordsap);
  }
  onChangeap() {
    console.log('Selected:',this.columnnameap);
    this.searchDataap=""
    if(!this.searchDataap){
      this.getDataap()
    }    
  }
  searchap1(){
    if(this.searchDataap.length>2){
      if(this.paginatorap){
        this.paginatorap.firstPage();
      }
      this.searchap();
    }else if(!this.searchDataap){
      this.getDataap()
      this.columnnameap=""
    }
  }
  searchap(){
    this.pageNumbersap = [];
    // this.pageIndexap=1
    console.log(this.searchDataap); 
   let obj= {"command":"pnd",  "document_name": "OutGatePassRGP","field":this.columnnameap ,"key":this.searchDataap,"lmt":this.pageSizeap,"pid":this.pageIndexap, showLoader: 'hide'}
    if(this.searchDataap.length>2 && this.columnnameap){
      this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
        if(res['status_code'] == 200){
          this.reaDataap = false;
          this.totalRecordsap = res?.count;
          this.columnnaplist=res?.field;
          this.dataSourcemainap.data = res.data
          this.dataSourcemainap.sort = this.sort
          for (let i = 1; i <= Math.ceil(this.totalRecordsap / this.pageSizeap); i++) {
            this.pageNumbersap.push(i);
          }
          this.goToap=this.pageIndexap
          if (res.data.length == 0) {
            this.reaDataap = true
          }
        }else{
          this.reaDataap = true
        }
      })
    }else if(!this.searchDataap){
      this.getDataap()
      this.columnnameap=""
    }
  }
  onpageeventap(event:any){ 
    this.pageIndexap=event.pageIndex+1;
    this.pageSizeap= event.pageSize;
    if(!this.searchDataap){
    this.getDataap()
    }
    else{
      this.searchap();
    }
  }
  getDataap1(){
    this.columnnameap="";
    this.searchDataap="";
    this.getDataap();
  }
  getDataap() {
    
    this.pageNumbersap=[]
    let obj = {
      "command": "pnd",
      "lmt": this.pageSizeap,
      "pid": this.pageIndexap,
      "document_name": "OutGatePassRGP"
    }
    this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
      if(res['status_code'] == 200){
        this.reaDataap = false;
        this.totalRecordsap = res?.count;
        this.columnnaplist=res?.field;
        this.dataSourcemainap.data = res.data
        this.dataSourcemainap.sort = this.sort
        for (let i = 1; i <= Math.ceil(this.totalRecordsap / this.pageSizeap); i++) {
          this.pageNumbersap.push(i);
        }
        this.goToap=this.pageIndexap
        if (res.data.length == 0) {
          this.reaDataap = true
        }
      }else{
        this.reaDataap = true
      }

    })
  }
  goToChangeap(){
    this.paginatorap.pageIndex = this.goToap - 1;
    const event: PageEvent = {
      length: this.paginatorap.length,
      pageIndex: this.paginatorap.pageIndex,
      pageSize: this.paginatorap.pageSize,
    };
    this.paginatorap.page.next(event);
  }
  emitPageEventap(pageEvent: PageEvent) {
    this.paginatorap.page.next(pageEvent);
  }

  //Edit service integration
  editdata(row1: any, _index: any, data: any) {
    // this.matcodedata=row1.material_code
    // this.getmasterdata()
    this.materialCODE=""
    this.materialNAME=""
    this.selectedIndex = this.saveddataarray.indexOf(row1)
    console.log(this.selectedIndex);
    this.dialog.open(data, {
      width: '1100px'
    })
    // this.masterData.forEach((ele: any) => {
    //   if (ele.code == row1.material_code) {
    //     this.systemref = ele.system_reference_1
    //   }
    // });
    this.matDesc=row1.material_code+"-"+ (row1.description || row1.material_description || row1.matcode);
    this.materialCODE=row1.material_code;
    this.materialNAME=row1.description || row1.material_description || row1.matcode;
    this.dialogdataedit.matcode = this.systemref || row1.matcode || this.matDesc
    this.dialogdataedit.uom = row1.uom || row1.unit_of_measurment || row1.unit_of_measurement 
    this.dialogdataedit.storagelocation = row1.storagelocation || row1.storage_location
    this.dialogdataedit.reqqty = row1.reqqty || row1.request_quantity || row1.quantity
    this.dialogdataedit.qty = row1.qty || row1.quantity
    this.dialogdataedit.itemremarks = row1.itemremarks || row1.item_remarks
    // this.dialogdataedit.batchno = row1.batchno || row1.batch_number
    this.dialogdataedit.valutiontype = row1.valutiontype || row1.valuation_type
    this.dialogdataedit.unit_price = row1.unit_price
    this.dialogdataedit.basic_price = row1.basic_price
    // this.getmasterdata()
    // this.getstoragelocData()
    // this.getvalutionData()
  }
  saveaddededitdata(_fr: any) {
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 === this.dialogdataedit.matcode) {
        console.log(this.dialogdataedit.matcode);
        
        this.dialogdataedit.matcode = el.code
        this.materialCODE = el.code
        this.materialNAME = el.name 
      }
    });
    this.dialogdataedit['material_code'] = this.materialCODE
    this.dialogdataedit['material_description'] = this.materialNAME 
    this.saveddataarray.splice(this.selectedIndex, 1, this.dialogdataedit);
    this.dataSource.data = this.saveddataarray
    console.log(this.dataSource.data);
    console.log(this.dialogdataedit.matcode);
    this.saveddataarray[this.selectedIndex].matcode = this.dialogdataedit.matcode
    this.dialogdataedit = {}
    this.dialog.closeAll()
    this.total=0
    this.saveddataarray.forEach((ele:any)=>{
    this.total += ele.basic_price || ele.total_value
    })
  }
  selectedmastereditgroup() {
    this.masterData.forEach((el: any) => {
      console.log(this.dialogdataedit.matcode);  
      if (el.system_reference_1 == this.dialogdataedit.matcode) {
        this.dialogdataedit.uom = el.uom_1
        this.materialCODE = el.code
        this.materialNAME = el.name
      }


    });
    this.getSMSdata();
    console.log(this.dialogdataedit.matcode, this.dialogdataedit.matdes);
  }

  keyPressNumbers(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  editgtdata(data: any, dialog: any) {
    this.dialog.open(dialog, {
      width: "400px"
    })
    this.editednumber = data.number
    this.editModel.reason = "";
  }
  saveeditreason() {
    this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
  ];
    let obj = {
      command: "mat",
      // field: "number",
      number: this.editednumber || this.doc_no,

    }
    this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
      this.viewData=false
      this.dialog.closeAll()
      this.editdataa = res.data[0]
      this.saveddataarray = res.data
      this.dataSource.data = this.saveddataarray
      this.total=0
    this.saveddataarray.forEach((ele:any)=>{
    this.total += ele.basic_price || ele.total_value
    })
      this.formdata.workordernum = this.editdataa.work_order_number,
        this.formdata.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
        this.formdata.companyname = this.editdataa.company_name,
        this.formdata.tocompanyname = this.editdataa.to_company_name,
        this.formdata.requestnumber = this.editdataa.request_number,
        this.formdata.requestdate = moment(this.editdataa.request_date).format("YYYY-MM-DD"),
        this.formdata.receivername = this.editdataa.receiver_name,
        this.formdata.rgptype=this.editdataa.Transfer_type,
        // this.formdata.transfertype = this.editdataa.Transfer_type,
        this.formdata.expectedredate = moment(this.editdataa.expected_return_date).format("YYYY-MM-DD"),
        this.formdata.expectedReturnDays = this.editdataa.expected_return_days,
        this.formdata.vehiclenumber = this.editdataa.vehicle_number,
        this.formdata.transportername = this.editdataa.transporter_name,
        this.formdata.lrnumber = this.editdataa.lr_number,
        this.formdata.lrdate = moment(this.editdataa.lr_date).format("YYYY-MM-DD"),
        this.formdata.comments = this.editdataa.comments,
        this.formdata.consigneename = this.editdataa.consignee_name,
        this.formdata.location = this.editdataa.locations,
        this.formdata.purpose = this.editdataa.purpose,
        this.formdata.tolocation =this.editdataa.to_locations,
        this.formdata.ewaybillno=this.editdataa.eway_bill,
        this.formdata.workordervalue=this.editdataa.work_order_value,
        this.formdata.Workorderdate=moment(this.editdataa.work_order_date).format("YYYY-MM-DD"),
        this.formdata.consignee_address = this.editdataa.consignee_address
      this.demo1TabIndex = 0;
      this.btn = "Update"
      this.editeddata = true;
    })
  }
  //delete service integration
  deleteItem(rw: any, data: any) {
    console.log(rw);  
    this.document_no=rw.number;
    this.dialog.open(data, {
      width: '400px',
      // scrollStrategy: new NoopScrollStrategy()
    })
    this.deletemodel.reason = ""
  }
  deleteSeleted(data:any){
    this.dialog.closeAll()
    this.dialog.open(data, {
      width: '400px',
    })
  }
  deleteFile() {
    let obj = {
      "command": "del",
      "number": this.document_no ,
      "reason": this.deletemodel.reason,
      "pwd":this.deletemodel.pwd,
      "txn_id":this.txnId,
    }
    this.custservice.deleteoutgatepassrgp(obj).subscribe((res: any) => {
      if (res && res['status_code'] == "200") {
        this.alertcall.showSuccess('Accepted', res['message']);
        this.dialog.closeAll()
        this.deletemodel.reason = ""
        this.getTransactionId();
        this.getData()
        this.docsign_status=false
      }else if (res && res['status_code'] == "501"){
        this.docsign_status=true
        this.dialog.closeAll()
        this.dialog.open(this.deletedocsigned,{
          width:"400px"
        })
        this.deletemodel.reason = ""
        // this.getData()
        // this.deletemodel.reason = ""
      } else {
        this.dialog.closeAll()
        this.alertcall.showWarning('Accepted', res['message']);
      }
    })
  }
  deleterow(index: any) {
    console.log(index);

    this.saveddataarray.splice(index, 1);
    this.dataSource.data = this.saveddataarray
    this.total=0
    this.saveddataarray.forEach((ele:any)=>{
    this.total += ele.basic_price || ele.total_value
    
    })
    console.log(this.dataSource.data)
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id
    this.dialogRef = this.dialog.open(row, {
      width: "400px"
    })
  }
  deleteexistingfile() {
    let params = new HttpParams()
    params = new HttpParams()
      .set("document_number", this.outgatepassrgpnum,)
      .set("document_type", "Out_Gate_Pass_RGP")
      .set("id", this.deleteid)
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully")
        this.getexistingfiles()
        this.dialogRef.close()
      } else {
        this.alertcall.showWarning("Error", res['message'])



      }
    })
  }
  //upload service integration
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    if(row1.number){
      this.outgatepassrgpnum = row1.number
    }
    if(row1.document_number){
      this.outgatepassrgpnum = row1.document_number
    }
    this.getexistingfiles()
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.outgatepassrgpnum,)
      .set("document_type", "Out_Gate_Pass_RGP")
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedata = res.data
        this.createNewFile.fileName = ''
      } else {
        this.filedata = []
        console.log(this.filedata);
      }
    })
  }
//   viewDoc(file: any) {
//     console.log(file);  
//     // const url = this.imageUrl + '/' + file.file_path;
// const url =  file.file_path;
//     // console.log(url);  
//     window.open(url, '_blank');
//   }

  viewDoc1(file: any) {
    const url = file.file_path;
    window.open(url, '_blank');
  }

  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      for (const file of this.fileUploadUrls) {
        this.filenamearray1.push(file.name)

      }
    }
    console.log(this.fileUploadUrls);
    const postData = new FormData();
    postData.append("document_type", "Out_Gate_Pass_RGP");
    postData.append("document_number", this.outgatepassrgpnum);
    for (const file of this.fileUploadUrls) {
      postData.append("doc", file)
    }

    // let obj = {
    //   "document_type": "Out_Gate_Pass_RGP",
    //   "document_number": this.outgatepassrgpnum,
    //   "doc": this.fileUploadUrls
    // }
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (this.fileUploadUrls.length > 1 && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "Files are uploaded successfully")
        this.getexistingfiles()
        this.filenamearray1 = []
      }
      else if (this.fileUploadUrls.length === 1 && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File is uploaded successfully")
        this.getexistingfiles()
        this.filenamearray1 = []
      } else {
        this.alertcall.showWarning("Error", res['message'])
      }
      fileInput.target.value = '';
    })

}

  uploadgtfiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlsgt = fileInput.target.files;
      for (const file of this.fileUploadUrlsgt) {
        const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        if(this.isFileSelected(file.name) && existingFile){
          this.alertcall.showWarning('Alert', 'Some files are already selected');
        }else if(this.fileUploadUrlsgt.length > 1){
          this.alertcall.showSuccess('Accepted','Files are uploaded successfully')
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
        else{
          // this.alertcall.showSuccess('Accepted','File is uploaded successfully')
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
        // this.filenamearray.push(file.name)
        // this.selectedfiles.push(file)
      }
      fileInput.target.value = '';
    }
  }
  isFileSelected(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file:any) => file.name == selectedFilePath);
  }
  uploadselectedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Out_Gate_Pass_RGP");
    postData.append("document_number", this.resultogpnumber);
    for (const file of this.selectedfiles) {
      postData.append("doc", file)
    }

    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filenamearray = []
        this.selectedfiles = []
        this.fileUploadUrlsgt = []
      }
    })
  }
  //print service integration
  printoutgatepass(data: any) {
    this.getPrintStatus(data);   
    console.log(data.number);
    // this.router.navigate(['/inventory/printoutgatepassrgp1'], { queryParams: { 'ogpnumber': data.number ,'doc_sign':data.doc_sign} })
    // if (data.Transfer_type === "NRGP1") {
    //   this.router.navigate(['/planning/printoutgatepassrgpnrgp1'],{ queryParams: {'ogpnumber': data.number}})
    // } else {
    //   this.router.navigate(['/planning/printoutgatepassrgp1'], { queryParams: { 'ogpnumber': data.number } })

    // }
  }
  getPrintStatus(data:any) {
    const postData = new FormData();
    postData.append("command","mat");
    postData.append("document_number", data.number);
    postData.append("document_name", "OutGatePassRGP" );
    this.custservice.DocumentSign(postData).subscribe((res: any) => {
      if(res&& res.print_status === true){
        this.router.navigate(['/inventory/printoutgatepassrgp1'], { queryParams: { 'ogpnumber': data.number ,'doc_sign':data.doc_sign} })
      }else{
        this.alertcall.showWarning("Warning", "Not Approved,Unable to Print");
      }
    });
  }

  

  //search
  onChange() {
    console.log('Selected:',this.columnname);
    this.searchData="" 
    if(!this.searchData){
      this.getData();
    }   
  // this.searchData=this.columnname
  // let selectedColumn=this.searchData
  }
  search1(){
    if(this.searchData.length>2){
      if(this.paginator){
        this.paginator.firstPage();
      }
      this.search();
    }else if(!this.searchData){
      this.getData()
      this.columnname=""
    }
  }
  search(){
    this.pageNumbers = [];
    console.log(this.searchData); 
   let obj= {"command":"lst","field":this.columnname ,"key":this.searchData,"lmt":this.pageSize,"pid":this.pageIndex,showLoader:'Hide'}
    if(this.searchData.length>2 && this.columnname){
      this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
        this.reaData = false;
        this.totalRecords = res?.count;
        this.dataSourcemain.data = res.data
        for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
          this.pageNumbers.push(i);
        }
        this.goTo=this.pageIndex
        if (res.data.length == 0) {
          this.reaData = true
        }
      })
    }else if(!this.searchData){
      this.getData()
      this.columnname=""
    }
  }
  getSMSdata() {
    let obj = {
      material_code: this.materialCODE,
      command: "sms",
    };
    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res && res["status_code"] == "200") {
        console.log(res);

        this.smsdata = res.message;
        this.smsdata1=res.storage_locations;
      } else {
        this.smsdata = "";
        this.smsdata1="";
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }
  getPurpose(ev:any){
this.selectedPurpose=ev.target.value
if(this.selectedPurpose.length>2){
  this.getPurposeList()
}if(!this.selectedPurpose){
  this.getPurposeList()
}
  }
  getRGPQTY(_ev:any){
    if(this.dialogdata.quantity > 0 && this.dialogdata.unit_price > 0){
      this.dialogdata.basic_price= Number(this.dialogdata.quantity)*Number(this.dialogdata.unit_price)
    }else{
      this.dialogdata.basic_price=0
    }
  }
  getRGPQTYedit(){
    if(this.dialogdataedit.quantity > 0 && this.dialogdataedit.unit_price > 0){
      this.dialogdataedit.basic_price= Number(this.dialogdataedit.qty)*Number(this.dialogdataedit.unit_price)
      }else{
        this.dialogdataedit.basic_price=0
      }
  }
  getPurposeList(){
    let obj={
"command":"pps",
"key":this.selectedPurpose||""
    }
    this.custservice.getPurpose(obj).subscribe((res:any)=>{
     if(res){
      this.purposeList=res.data
     }
    })
  }

  //Scanner  model
  qrScanner(data: any) {
    this.dialog.open(data, {
      width: '400px',
    })
    this.information=""
    this.scannerEnabled=true
    this.errorScanner=""
  }
  confirmQrCode(){
    // alert(1)
    console.log(this.information,"aaaaaa");
    if(this.information.length > 0){  

      // let data:any;
      // alert(2)
      // data=JSON.parse(this.information).Document_number
      // console.log(data);
      // console.log(this.information,"infoooo");
      // console.log(JSON.parse(this.information),"json");
      // console.log(JSON.parse(this.information).Document_number,"doc");

      this.dcnumber=this.information;
      // this.doc_no=this.information.split(":")[1].split(",")[0]
      // console.log(this.dcnumber,"scandata");
      // alert(3)
      setTimeout(() => {
        // this.saveeditreason()
       this.submitdcno(this.dcnumber)
       console.log("oooo");
      }, 100);
    }
    else{
      // this.infoScan()
      this.alertcall.showWarning("Warning", "Please Scan QR Code");
      // this.errorScanner="Please Scan QR Code" 
    }
  }
  //  infoScan(){
  //   if(this.information.length ==0){
  //     this.errorScanner="Please Scan QR Code"    
  //   }else{
  //     this.errorScanner=""
  //   }
  //  }

  onpageevent(event:any){ 
    this.pageIndex=event.pageIndex+1;
    this.pageSize= event.pageSize; 
    if(!this.searchData){
      this.getData()
    }else{
      this.search()
    }
  }
  submitform(data:any){
    this.dialog.open(data,{
      width:"400px"
    })
  }

  getdcDATA11(ev: any) {
    console.log(ev.target.value);
    this.selecteddcnumber = ev.target.value
    if (this.selecteddcnumber.length > 2) {
      this.getdcData()
    }
    if (!this.selecteddcnumber) {
      this.getdcData()
    }
  }
  getdcData() {
    let obj = {
       "command":"dcn",
      // "command": "set",
      // "lmt" : 100000,
      // "pid" : 1 ,
      "key": this.dcnumber
    }
    this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
      this.DCDATA = res.data

    })
  }

  deliveryChallanData(data: any) {
     this.DCID = []
    this.dialog.open(data, {
      width: '600px'
    })
    this.getdcData()
  }

  submitdcno(_fr: any) {
    let obj = {
      "command": "mat",
      "key": this.dcnumber,
      field: "number",
    }
    this.custservice.addDeliveryChallan(obj).subscribe((res: any) => {
      this.viewData=false
      this.dialog.closeAll();
      this.editdataa = res.data[0]
      this.saveddataarray = res.data
      this.dataSource.data = this.saveddataarray
        this.formdata.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
        this.formdata.companyname = this.editdataa.company_name,
        this.formdata.tocompanyname = this.editdataa.to_company_name,
        this.formdata.vehiclenumber = this.editdataa.vehicle_number,
        this.formdata.transportername = this.editdataa.transporter_name,
        this.formdata.lrnumber = this.editdataa.lr_number,
        this.formdata.lrdate = moment(this.editdataa.lr_date).format("YYYY-MM-DD"),
        this.formdata.consigneename = this.editdataa.consignee_name,  
        this.formdata.consignee_address =this.editdataa.consignee_address
        this.formdata.dcnumber=this.editdataa.number  
        this.total=0
        this.saveddataarray.forEach((ele:any)=>{
        this.total += ele.basic_price || ele.total_value
        })
        
      this.demo1TabIndex = 0;
    });
  }
  getApprovals(data:any, row1: any){
    this.approvalbtn=false
    this.dialog.open(data, {
      width: '600px'
    })
    this.documnet_nodata=row1.number;
  }

  getApprovals1(data:any,row: any){
    this.approvalbtn=false
    this.doc_no= row.number
    this.dialog.open(data, { 
      width: '600px'
    })
  }

getConfirmData(){
  let obj = {
    command: "add",
    document_name: "OutGatePassRGP",
    document_number:this.documnet_nodata||this.viewnumber || this.doc_no
  };
  this.custservice.AutoDocUpdate2(obj).subscribe((res: any) => {
    if(res && res['status_code']==200){
      this.dialog.closeAll()
      this.alertcall.showSuccess("Accepted", res['message'])
      this.getData()
      this.approvalbtn=true
    } else {
      this.dialog.closeAll()
      this.alertcall.showWarning("Error", res['message'])
    }      
  });
}

 //view
//  viewsaveddata(data: any, dialog: any) {
//   this.dialog.open(dialog, {
//     width: "400px"
//   })
//   this.viewnumber = data.number

// }
viewreason(data:any) {
  this.displayedColumns = ['sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
  ];
  if(data.number){
    this.viewnumber = data.number
  }
  if(data.document_number){
    this.viewnumber = data.document_number
  }
  let obj = {
    "command": "mat",
    "key": data.number || data.document_number,
    // "field": "number"
  }
  this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
    if(res.data.length>0){
      if(data.document_number){
        this.approvalstatus=true
      }else{
        this.approvalstatus=false
      }
      // this.signData_document_no=""
      this.viewData=true;
      // this.dialog.closeAll()
      this.editdataa = res.data[0]
      this.saveddataarray = res.data
      this.dataSource.data = this.saveddataarray
      this.formdata.workordernum = this.editdataa.work_order_number!==null?this.editdataa.work_order_number:"NA",
        this.formdata.dateee = this.editdataa.date!==null?moment(this.editdataa.date).format("DD-MM-YYYY"):"NA",
        this.formdata.companyname = this.editdataa.company_name!==""?this.editdataa.company_name:"NA",
        this.formdata.tocompanyname = this.editdataa.to_company_name!==""?this.editdataa.to_company_name:"NA",
        this.formdata.requestnumber = this.editdataa.request_number!==null?this.editdataa.request_number:"NA",
        this.formdata.requestdate = this.editdataa.request_date!==null?moment(this.editdataa.request_date).format("DD-MM-YYYY"):"NA",
        this.formdata.receivername = this.editdataa.receiver_name!==null?this.editdataa.receiver_name:"NA",
        this.formdata.rgptype=this.editdataa.Transfer_type!==""?this.editdataa.Transfer_type:"NA",
        // this.formdata.transfertype = this.editdataa.Transfer_type,
        this.formdata.expectedredate = this.editdataa.expected_return_date!==null?moment(this.editdataa.expected_return_date).format("DD-MM-YYYY"):"NA",
        this.formdata.expectedReturnDays = this.editdataa.expected_return_days!==null?this.editdataa.expected_return_days:"NA",
        this.formdata.vehiclenumber = this.editdataa.vehicle_number!==null?this.editdataa.vehicle_number:"NA",
        this.formdata.transportername = this.editdataa.transporter_name!==""?this.editdataa.transporter_name:"NA",
        this.formdata.lrnumber = this.editdataa.lr_number!==null?this.editdataa.lr_number:"NA",
        this.formdata.lrdate = this.editdataa.lr_date!==null?moment(this.editdataa.lr_date).format("DD-MM-YYYY"):"NA",
        this.formdata.comments = this.editdataa.comments!==null?this.editdataa.comments:"NA",
        this.formdata.consigneename = this.editdataa.consignee_name!==""?this.editdataa.consignee_name:"NA",
        this.formdata.location = this.editdataa.locations!==""?this.editdataa.locations:"NA",
        this.formdata.purpose = this.editdataa.purpose!==""?this.editdataa.purpose:"NA",
        this.formdata.tolocation =this.editdataa.to_locations!==""?this.editdataa.to_locations:"NA",
        this.formdata.ewaybillno=this.editdataa.eway_bill!=null?this.editdataa.eway_bill:"NA",
        this.formdata.workordervalue=this.editdataa.work_order_value!==null?this.editdataa.work_order_value:"NA",
        this.formdata.Workorderdate=this.editdataa.work_order_date!==null?moment(this.editdataa.work_order_date).format("DD-MM-YYYY"):"NA",
        this.formdata.consignee_address=this.editdataa.consignee_address!==null ? this.editdataa.consignee_address :"NA",
        this.formdata.reviewed_return_date=this.editdataa.reviewed_return_date!==null?moment(this.editdataa.reviewed_return_date).format("DD-MM-YYYY"):"NA",
        this.formdata.reviewed_return_days=this.editdataa.reviewed_return_days!==null?this.editdataa.reviewed_return_days:'NA',
        // this.formdata.dcnumber=this.editdataa.delivery_challan_number,
        this.formdata.dcnumber=this.editdataa.number,
        this.docSignature=this.editdataa.doc_sign,
        this.formdata.reviewedexpectedReturnDays=this.editdataa.expected_return_date
        // this.getReviewedDays("","",this.reviewedexpectedReturnDays)

        
      this.demo1TabIndex = 0;
      // this.btn = "Update"
      // this.getSignatureData();
    }else{
      this.alertcall.showWarning("Accepted", "No Data Found");
    }
  })

 }
// pendingSignaturesRGPview(data:any) {
//   console.log(data);
//   // this.signData_document_no = data.document_number
//   let obj = {
//     "command": "mat",
//     "key": data.document_number,
//     // "field": "number"
//   }
//   this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
//     if(res){
//       this.viewData=true;
//       // this.dialog.closeAll()
//       this.editdataa = res.data[0]
//       this.saveddataarray = res.data
//       this.dataSource.data = this.saveddataarray
//       this.formdata.workordernum = this.editdataa.work_order_number!==null?this.editdataa.work_order_number:"NA",
//         this.formdata.dateee = this.editdataa.date!==null?moment(this.editdataa.date).format("YYYY-MM-DD"):"NA",
//         this.formdata.companyname = this.editdataa.company_name!==""?this.editdataa.company_name:"NA",
//         this.formdata.tocompanyname = this.editdataa.to_company_name!==""?this.editdataa.to_company_name:"NA",
//         this.formdata.requestnumber = this.editdataa.request_number!==null?this.editdataa.request_number:"NA",
//         this.formdata.requestdate = this.editdataa.request_date!==null?moment(this.editdataa.request_date).format("YYYY-MM-DD"):"NA",
//         this.formdata.receivername = this.editdataa.receiver_name!==""?this.editdataa.receiver_name:"NA",
//         this.formdata.rgptype=this.editdataa.Transfer_type!==""?this.editdataa.Transfer_type:"NA",
//         // this.formdata.transfertype = this.editdataa.Transfer_type,
//         this.formdata.expectedredate = this.editdataa.expected_return_date!==null?moment(this.editdataa.expected_return_date).format("YYYY-MM-DD"):"NA",
//         this.formdata.vehiclenumber = this.editdataa.vehicle_number!==null?this.editdataa.vehicle_number:"NA",
//         this.formdata.transportername = this.editdataa.transporter_name!==""?this.editdataa.transporter_name:"NA",
//         this.formdata.lrnumber = this.editdataa.lr_number!==null?this.editdataa.lr_number:"NA",
//         this.formdata.lrdate = this.editdataa.lr_date!==null?moment(this.editdataa.lr_date).format("YYYY-MM-DD"):"NA",
//         this.formdata.comments = this.editdataa.comments!==""?this.editdataa.comments:"NA",
//         this.formdata.consigneename = this.editdataa.consignee_name!==""?this.editdataa.consignee_name:"NA",
//         this.formdata.location = this.editdataa.locations!==""?this.editdataa.locations:"NA",
//         this.formdata.purpose = this.editdataa.purpose!==""?this.editdataa.purpose:"NA",
//         this.formdata.tolocation =this.editdataa.to_locations!==""?this.editdataa.to_locations:"NA",
//         this.formdata.ewaybillno=this.editdataa.eway_bill!=null?this.editdataa.eway_bill:"NA",
//         this.formdata.workordervalue=this.editdataa.work_order_value!==null?this.editdataa.work_order_value:"NA",
//         this.formdata.Workorderdate=this.editdataa.work_order_date!==null?moment(this.editdataa.work_order_date).format("YYYY-MM-DD"):"NA",
//         // this.formdata.dcnumber=this.editdataa.delivery_challan_number,
//         this.docSignature=this.editdataa.doc_sign
//       this.demo1TabIndex = 0;
//       this.getcompanydata();
//       this.getcompanydata1();
//       this.getmasterdata();
//       this.getstoragelocData();
//       this.getvalutionData();
//       this.getData();
//       this.getPurposeList();
//       this.getvendordata();
//       // this.btn = "Update"
//        this.getSignatureData();
//        this.getSignature();
//     }
//   })

// }
BacktoList(){
  if(this.approvalstatus){
    this.demo1TabIndex = 2;
  }else{
    this.demo1TabIndex = 1;
  }
    this.viewData=false
    this.formdata={};
    this.saveddataarray = []
    this.dataSource.data = []
    this.SNO = []
    this.matCode = []
    this.matName = []
    this.UOM = []
    this.storageLocation = []
    this.ReqQTY = []
    this.quantity = []
    this.itemremarks = []
    this.unitPrice=[]
    this.basic_price=[]
    this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
  ];
  // }
}

clearpage1(){
  this.viewData=false
  this.formdata={expectedReturnDays:"",companyname:"",rgptype:""};
  this.saveddataarray = []
  this.dataSource.data = []
  this.SNO = []
  this.matCode = []
  this.matName = []
  this.UOM = []
  this.storageLocation = []
  this.ReqQTY = []
  this.quantity = []
  this.itemremarks = []
  this.unitPrice=[]
  this.basic_price=[]
  this.dcnumber=""
  this.btn="Save"
  // this.getTransactionId();
  this.editeddata = false;
  this.selectedfiles = []
  this.total=0
  this.getTransactionId();
  this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
];
this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
}
//signature data
  getSignatureData() {
    const postData = new FormData();
    postData.append("command","mat");
    postData.append("document_number", this.viewnumber );
    postData.append("document_name", "OutGatePassRGP" );
    this.custservice.DocumentSign(postData).subscribe((res: any) => {
      if(res){
        this.SignData = res.data;   
        // console.log(this.SignData);
           
        // this.SignData.forEach((ele:any)=>{
        //   ele['signbtn']=false
        //   this.signNull =ele.signature
        //   if(ele.status ==="PENDING" && ele.signature === null){
        //     // alert(1)
        //     this.signStatus=false
        //       if(this.position == ele.level ){
        //         // console.log("entered");             
        //         ele['signbtn']=true
        //       //  this.signbtn=true;
        //       }
        //   }else if(ele.status ==="COMPLETED" && ele.signature !==null){
        //     this.signStatus=true
        //     ele.signature = this.sanitizer.bypassSecurityTrustResourceUrl(ele.signature);
        //   }
  
        // })
      }
    });
  }

  signatureStatus(data: any) {
   this.dialog.open(data, {
     width: '600px'
   })
   this.getSignatureData()
 }
 onTabChanged(_ev:any){
if(this.demo1TabIndex===1){
  this.viewData=false
  this.total=0
  this.formdata={expectedReturnDays:"",companyname:"",rgptype:""};
  this.saveddataarray = []
  this.dataSource.data = []
  this.dcnumber=""
  this.SNO = []
  this.matCode = []
  this.matName = []
  this.UOM = []
  this.storageLocation = []
  this.ReqQTY = []
  this.quantity = []
  this.itemremarks = []
  this.unitPrice=[]
  this.basic_price=[]
this.router.navigate(['/inventory/outgatepassrgp1'])
this.dataSourcemain.sort =this.sort;
this.paginator.pageIndex = this.pageIndex-1
this.editeddata = false;
this.searchDataap=""
this.columnnameap=""
this.searchap()
this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
this.dataSourcemain.sort = this.sort
this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
  ];
  this.pageSize=10;
  this.pageIndex=1;
  this.pageIndex1 =1;
  this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
  this.getData();
  this.btn = "Save";
}else if(this.demo1TabIndex===3){
  // alert(12);
  this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
  this.total=0
  this.viewData=false
  this.dcnumber=""
  this.formdata={expectedReturnDays:"",companyname:"",rgptype:""};
  this.saveddataarray = []
  this.dataSource.data = []
  this.SNO = []
  this.matCode = []
  this.matName = []
  this.UOM = []
  this.storageLocation = []
  this.ReqQTY = []
  this.quantity = []
  this.itemremarks = []
  this.unitPrice=[]
  this.basic_price=[]
  this.router.navigate(['/inventory/outgatepassrgp1'])
  this.dataSourcemainlog.sort = this.sort;
  // this.paginator.pageIndex = this.pageIndex-1
  this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
  ];
  this.editeddata = false;
  this.logpageSize=10;
  this.pageIndex=1;
  this.pageIndex1 =1;
  this.btn = "Save";
  }
  else if(this.demo1TabIndex===2){
    // alert(12);
    this.total=0
    this.viewData=false
    this.dcnumber=""
    this.formdata.dateee = moment(new Date()).format("YYYY-MM-DD");
    this.formdata={expectedReturnDays:"",companyname:"",rgptype:""};
    this.saveddataarray = []
    this.dataSource.data = []
    this.SNO = []
    this.matCode = []
    this.matName = []
    this.UOM = []
    this.storageLocation = []
    this.ReqQTY = []
    this.quantity = []
    this.itemremarks = []
    this.unitPrice=[]
    this.basic_price=[]
    this.router.navigate(['/inventory/outgatepassrgp1'])
    this.displayedColumns = ['action','sno','material_code','material_description','unit_of_measurement','storage_location', 'request_quantity','quantity','unit_price','total_price', 'item_remarks','valution_type'
    ];
    this.editeddata = false;
    this.pageSizeap=10;
    this.pageIndexap=1;
    this.pageIndex1 =1;
    this.dataSourcemainap.sort = this.sort
    this.getDataap();
    this.btn = "Save";
    }
// this.getData()
 }

 //view signature
//  getSignatureView(row:any,data:any){
//   console.log(row);
//   this.document_no=row.document_number;
//   this.document_name=row.document_name;
//   this.description=row.description;
//   this.level=row.level;
//   // this.document_data=row
//   // console.log(this.document_data);
//   this.dialog.open(data,{
//     width:"400px"
//   })

  // this.getSignatureData();
// }
// getSignature(){
//   const postData = new FormData();
//   postData.append("command", "lst");
//   this.custservice.SignatureUpload(postData).subscribe((res:any)=>{
//     console.log(res);
//     // this.Viewsign=res.data[0].signature;
//     this.Viewsign=  this.sanitizer.bypassSecurityTrustResourceUrl(res.data[0].signature);
//     console.log(this.Viewsign);  
//     this.signature= res.data[0].signature
//     });
// }

// SignatureFile(){
//   // this.imageUrl = this.Viewsign.toDataURL();
//   console.log(this.Viewsign);
//   const imageBlob = this.dataURItoBlob(this.signature);
//   var file = new File([imageBlob], "fileName.jpeg", {
//     type: "'image/jpeg'"
//   });
//   const postData = new FormData();
//   postData.append("command", "add");
//   postData.append("document_number", this.document_no);
//   postData.append("level",this.level);
//   postData.append('description', this.description);
//   postData.append("document_name", this.document_name  );
//   postData.append("image",file);
//   this.custservice.DocumentSign(postData).subscribe((res: any) => {
//     console.log(res);
//     if(res && res.status_code===200){
//       this.dialog.closeAll();
//       Swal.fire({
//         text: res["message"],
//         title: res["reference"],
//         icon: "success",
//         // title: res['reference'],
//         width: 500,
//       });
//       this.getSignature();
//       this.getSignatureData();
//     }else{
//       Swal.fire({
//         text: res["message"],
//         title: res["reference"],
//         icon: "success",
//         // title: res['reference'],
//         width: 500,
//       });
//     }
//     // this.reaData = false;
//     //  this.totalRecords = res?.count;
//     // this.dataSourcemain.data = res.data;
//     // console.log(this.dataSourcemain.data);
    
//     // if (res.data.length == 0) {
//     //   this.reaData = true;
//     // }
//   });
// }

// dataURItoBlob(dataURI: any) {
//   var byteString;
//   if (dataURI.split(',')[0].indexOf('base64') >= 0)
//     byteString = atob(dataURI.split(',')[1]);
//   else
//     byteString = unescape(dataURI.split(',')[1]);
//   // separate out the mime component
//   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
//   // write the bytes of the string to a typed array
//   var ia = new Uint8Array(byteString.length);
//   for (var i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i);
//   }
//   return new Blob([ia], { type: mimeString });
// }

// //cancel

// deleteItem1(rw: any, data: any) {
//   console.log(rw.document_number); 
//   this.document_no=rw.document_number;
//   this.dialog.open(data, {
//     width: '400px',
//   })
// }
// deleteSeleted1(data:any){
//   this.dialog.closeAll()
//   this.dialog.open(data, {
//     width: '400px',
//   })
// }
// deleteFile1() {
//   let obj = {
//     "command": "del",
//     "number": this.document_no,
//     "reason": this.deletemodel.reason,
//     "pwd":this.deletemodel.pwd
//   }
//   this.custservice.deleteoutgatepassrgp(obj).subscribe((res: any) => {
//     if (res && res['status_code'] == "200") {
//       this.alertcall.showSuccess('Accepted', res['message']);
//       this.dialog.closeAll()
//       this.deletemodel.reason = ""
//       this.docsign_status=false
//       this.router.navigate(['/pending-signatures'],{ queryParams: { tab: 'notifications'}})
//     }else if (res && res['status_code'] == "501"){
//       this.docsign_status=true
//       this.dialog.closeAll()
//       this.dialog.open(this.deletedocsigned,{
//         width:"400px"
//       })
//       this.deletemodel.reason = ""
//     } else {
//       this.dialog.closeAll()
//       this.alertcall.showWarning('Accepted', res['message']);
//     }
//   })
// }

// submitform(data:any){
//   this.dialog.open(data,{
//     width:"400px"
//   })
// }

      ExpectedReturnDaysCal(){
       let data = this.formdata.expectedReturnDays
      //  console.log(data,data.split(/(\d+)/));
      let numberdays= data.split(/(\d+)/)[1];
       let timeUnit: string = "days";
       const momStartDate = moment(this.formdata.dateee);
      //  console.info("momStartDate", momStartDate);
      //  console.info("amount",data);
       let result = momStartDate.add(numberdays, timeUnit as any).toDate();
      //  console.log(result);
       this.formdata.expectedredate=moment(result).format("YYYY-MM-DD")
      }

      // reviewed
      reviewedExpectedReturnDaysCal(){
        let data = this.reviewedexpectedReturnDays
        let timeUnit: string = "days";
        const momStartDate = moment(new Date());
        let result = momStartDate.add(data, timeUnit as any).toDate();
        this.reviewedexpectedredate=moment(result).format("YYYY-MM-DD")
       }
       //reviewed days model
       getReviewedDays(data:any,row: any){
        this.doc_no= row.number
        let obj:any = {
          command: "mat",
          number: this.doc_no,
        }
        this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
          let result = res.data
          if(result[0]['number'] == this.doc_no){
            let days =  result[0].expected_return_days.split(" ")
            this.reviewedexpectedReturnDays = days.length > 0 && days[0] || ""
            this.reviewedexpectedredate = moment(result[0].expected_return_date).format("YYYY-MM-DD")
            console.log(this.reviewedexpectedReturnDays,"jjjjjj");
            
          }
        })

        this.reviewedexpectedReturnDays = data.expected_return_date
       
        this.dialog.open(data, { 
          width: '600px'
        })
      }
    
      getReviewedData(){
      let obj = {
        command: "rwd",
        reviewed_return_days:this.reviewedexpectedReturnDays && this.reviewedexpectedReturnDays + " Days" || "0 Days",
        reviewed_return_date:this.reviewedexpectedredate,
        number: this.doc_no
      };
      this.custservice.addoutgatepassrgp(obj).subscribe((res: any) => {
        if(res && res['status_code']==200){
          this.dialog.closeAll()
          this.alertcall.showSuccess("Accepted", res['message'])
          this.getData()
          this.approvalbtn=true
        } else {
          this.dialog.closeAll()
          this.alertcall.showWarning("Error", res['message'])
        }      
      });
    }

    multidropdowns(){
      let obj={
        command:"pps"
      }
      this.custservice.getoutgatepassrgp(obj).subscribe((res: any) => {
        if(res){
          this.purposeData= res.purpose
          this.rgp_type=res.rgp_type
          this.exp_ret_days=res.exp_ret_days
        }
      });
    }
    selectHiring(){
      if(this.formdata.rgptype == 'Hiring'){
        Swal.fire("Hiring agreement required");
      }
    }
    openNewFileUploadModal(data: any) {
      this.dialog.open(data, {
        width: '800px'
      })
    if(this.btn === "Update"){
    if(this.editednumber || this.doc_no){
      this.outgatepassrgpnum = this.editednumber || this.doc_no
    }
    this.getexistingfiles()
  }
    }
  
    deleteFileItem(index:any){
      this.selectedfiles.splice(index,1)
     }
     trimInput(){
      this.dcnumber = this.dcnumber.trim();
      this.formdata.rgptype = this.formdata.rgptype.trim();
      this.formdata.companyname = this.formdata.companyname.trim();
      this.formdata.consigneename = this.formdata.consigneename.trim();
      this.formdata.consignee_address = this.formdata.consignee_address.trim();
      this.formdata.workordernum = this.formdata.workordernum.trim();
      this.formdata.transportername = this.formdata.transportername.trim();
      this.formdata.vehiclenumber = this.formdata.vehiclenumber.trim();
      this.formdata.expectedReturnDays = this.formdata.expectedReturnDays.trim();
      this.formdata.lrnumber = this.formdata.lrnumber.trim();
      this.formdata.ewaybillno = this.formdata.ewaybillno.trim();
      this.formdata.workordervalue = this.formdata.workordervalue.trim();
      this.formdata.location = this.formdata.location.trim();
      this.formdata.tolocation = this.formdata.tolocation.trim();
      this.formdata.purpose = this.formdata.purpose.trim();
      this.formdata.requestnumber = this.formdata.requestnumber.trim();
      this.formdata.receivername = this.formdata.receivername.trim();
      this.formdata.comments = this.formdata.comments.trim();
    }
    gamRestrictfields(){
      let origin = window.location.hostname;
      var companyName:any = origin.split(".", 1)[0];
      console.log(companyName,origin);
      if(companyName == 'gam'){
        this.gamCompanyfields = true
      }else{
        this.gamCompanyfields = false
      }
      
     }
}
