import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import * as moment from 'moment';
import {Overlay} from '@angular/cdk/overlay';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'; 
@Component({
  selector: 'app-goodsreturn',
  templateUrl: './goodsreturn.component.html',
  styleUrls: ['./goodsreturn.component.scss']
})
export class GoodsreturnComponent implements OnInit {
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef; 
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatSort) sort: MatSort;
  goTo:any;
  pageNumbers:any=[]
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  displayedColumns: any[] = [
    'sno',
    'material_code',
    'material_description',
    'unit_of_measurement',
    'storage_location',
    // 'request_quantity',
    'quantity',
    'item_remarks',
    // 'batch_no',
    'valution_type',
     'action'
      
    ];
    displayedColumns1: any[] = [
      'sno',
      'MRN_Number',
      'Date',
      'company_Name',
      // 'fromcompanyname',
      'contractor_name',
      'request_number',
      'return_type',
      // 'ActiveStatus',
      'action'
    ];
    model:any={};
    model1:any={};
    model2:any={}
    editmodel:any={}
    saveddataarray:any[]=[]
    dataSource = new MatTableDataSource();
    dataSource1=new MatTableDataSource()
    dataSourcemain = new MatTableDataSource();
    valuefrminv: any=0
    valuefrmrec: any=0
    unitValue:any=0
    discValue:any=0
    taxPercent:any=0
    masterData: any;
    taxData: any;
    companyData: any;
    vendorData: any;
    transporterName: any;
    vehicleNum: any;
    basicFreight: any=0
    freightPercentage:any=0
    freightPercentage1:any=0
    storageData: any;
    matCode:any[]=[];
    matName:any[]=[]
    UOM:any[]=[];
    storageLocation:any[]=[]
    InvoiceQuantity:any[]=[]
    ReqQTY:any[]=[];
    qty:any[]=[];
    itemremarks:any[]=[];
    // batchno:any[]=[];
    valutionType:any[]=[]
    shrQTY:any[]=[]
    excsQTY:any[]=[]
    unitPrice:any[]=[]
    discPERCENT:any[]=[]
    discVALUE:any[]=[]
    basicPRICE:any[]=[]
    taxDESC:any[]=[]
    taxPERCEN:any[]=[]
    taxVALUE:any[]=[]
    otherTAXSPLIT:any[]=[]
    SNO:any[]=[]
    freightSPLIT:any[]=[]
    othertaxSPLIT:any[]=[]
    otherchargesSPLIT:any=[]
    totalPRICE:any[]=[]
    addedTableData:any=true;
    savedTableData:any=false;
    totalRecords:any=0
    reaData: boolean;
    loadingRecords:any=false;
    pageIndex: any=1;
    pageSize:any=10;
    logpagesize:any=10;
    pageIndex1: any=1;
    deleteNumber: any;
    deletemodel:any={}
    model2matcode: any;
    model2matdes: any;
    data: any = [];
    valuefrminvedit: any=0
    valuefrmrecedit: any=0
    unitValueedit: any=0
    discValueedit: any=0
    editedtaxdescription: any;
    selectedIndex: number;
    SUM: any=0
    editabledata: any;
    editDATAA:any=false;
    materialCODE: any;
    materialNAME: any;
    systemref: any;
    NUMBER: any;
    basicFreight1: any=0
    selectedtransporter: any;
    selectedvehiclenum: any;
    selectedvehiclenum1: any;
    selectedtransporter1: any;
    selectedmaterial: any;
    selectedstorage: any;
    selectedstorage1: any;
    selectedmaterial1: any;
    demo1TabIndex:any=0
  valutiondata: any;
  contracordata: boolean;
  dialogRef:any=null;
  grnumber: any;
  filedata: any;
  createNewFile:any={}
  imageUrl=environment.base_url
  deleteid: any;
  fileUploadUrls: any[]=[]
  fileUploadUrlsgreturn:any[]=[]
  resultgoodsretnumber:any;
  selectedfiles:any[]=[]
  filenamearray:any[]=[]
  editednumber: any;
  editModel:any={}
  logdata: any;
  editdataa: any;
  btn:any="Save"
  searchData: any;
  columnname: string="";
  logsearchData: any;
  viewData: boolean=false;
  viewnumber: any;
  selectedconname: any;
  selectgoodsIssue: string;
  giDATA: any[]=[];
  ginumber: any;
  MATDATA: any;
  QTY:any[]=[]
  ISS_QTY:any[]=[]
  BAL_QTY:any[]=[]
  ITEMREMARKS:any[]=[]
  matDESCRIPTION:any[]=[]
  today:any
  txnId: any;
    constructor(private dialog:MatDialog,private custservice:CustomerService,
     public overlay: Overlay,
      private alertcall:AlertCallsService,private router:Router,
      public route:ActivatedRoute,private injector:Injector) { 
        this.dialogRef = this.injector.get(MatDialogRef, null);
      }
  
    ngOnInit(): void {
      this.today=moment(new Date()).format("YYYY-MM-DD")
      this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
      // this.model1.requestdate=moment(new Date()).format("YYYY-MM-DD")
      this.route.queryParams.subscribe((params:any) => {
     
        if(params.tab=='notificationsissue'){
          this.demo1TabIndex=1;
          console.log(params);
          
        }
      else{
        this.demo1TabIndex=0;
      }
      })
      this.getTransactionId();
       this.getmasterdata()
      this.getstoragelocData()
      this.getvalutionData()
       this.getcompanydata()
      this.getData()
      this.getContractdata()
    }
    getTransactionId(){
      this.txnId=""
      this.custservice.getTransactionId().subscribe((res:any)=>{
        if(res){
          // console.log(res.txn_id);
          this.txnId=res.txn_id
        }
      })
    }

    openNewFileUploadModal(data: any , type:any) {
      // this.selectedfiles = [];
      this.dialog.open(data, {
        width: '800px'
      })
      console.log(type , 'type');
  if(type === 'Update'){
    this.getexistingfiles()
  }
    }

    openfileuploadmodel(data:any,row1:any){
      this.dialog.open(data,{
        width:'800px'
      })
      this.grnumber=row1.number
      this.getexistingfiles()
    }
  //   getexistingfiles(){
  //     let params = new HttpParams();
  //     params = new HttpParams()
  //      .set("document_number", this.grnumber,)
  //      .set( "document_type","Goods Return")
  //     // let obj={
  //     //   "document_number" : this.dmrnumber,
  //     //   "document_type": "Daily Material Receipt"
  //     // }
  //     this.custservice.getexistingfies(params).subscribe((res:any)=>{
  //   if(res&&res['status_code']=='200'){
  // this.filedata=res.data
  // this.createNewFile.fileName=''
  // }else{
  //   this.filedata=''
  //   console.log(this.filedata);
  // }
  //     })
  //   }


  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.grnumber)
      .set("document_type", "Goods Return");

    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        const uniqueFiles = [];
        const uniqueFileNames = new Set();

        for (const file of res.data) {

          if (!uniqueFileNames.has(file.file_name)) {
            uniqueFileNames.add(file.file_name);
            uniqueFiles.push(file);
          }
        }
        this.filedata = uniqueFiles;
        console.log(this.filedata, "Unique files array");
        this.createNewFile.fileName = "";
      } else {
        this.filedata = [];
        console.log(this.filedata, "Empty filedata");
      }
    });

  }

    viewDoc(file: any) {
      // const url = this.imageUrl + '/' + file.file_path;
const url =  file.file_path;
      window.open(url, '_blank');
    }
    deleterowfile(row:any,data:any){
      this.deleteid=data.id
      this.dialogRef=this.dialog.open(row,{
        width:"400px"
      })
    }
    deleterowItem(index:any){
      this.MATDATA.splice(index,1)
        }
    deleteexistingfile(){ 
      let params=new HttpParams()
      params=new HttpParams()
      .set("document_number", this.grnumber,)
       .set( "document_type","Goods Return")
       .set( "id",this.deleteid)
       this.custservice.deletefiles(params).subscribe((res:any)=>{
        if(res&&res['status_code']=='200'){
          this.alertcall.showSuccess("Accepted","File Deleted Successfully")
          this.getexistingfiles()
          this.dialogRef.close()
        }else{
          this.alertcall.showWarning("Error",res['message'])
         
          
         
        }
       })
    }
  // uploadWbsFile(fileInput: any) {
  //     if (
  //       fileInput &&
  //       fileInput.target &&
  //       fileInput.target.files &&
  //       fileInput.target.files.length > 0
  //     ) {
  //       this.fileUploadUrls = fileInput.target.files;
  //       this.createNewFile.fileName = fileInput.target.files[0].name;
        
  //     }
  //     const postData = new FormData();
  //     postData.append("document_type","Goods Return");
  //     postData.append("document_number",this.grnumber);
  //     for(const file of this.fileUploadUrls){
  //       postData.append("doc",file)
  //     }
  //     this.custservice.addfileupload(postData).subscribe((res:any)=>{
  // if(res&&res['status_code']=='200'){
  // this.alertcall.showSuccess("Accepted",res['message'])
  // this.getexistingfiles()
  // this.fileUploadUrls=[]
  // }else{
  //   this.alertcall.showWarning("Error",res['message'])
  // }
  //     })
  //   }

  // uploadWbsFile(fileInput: any) {
  //   if (
  //     fileInput &&
  //     fileInput.target &&
  //     fileInput.target.files &&
  //     fileInput.target.files.length > 0
  //   ) {
  //     this.fileUploadUrls = fileInput.target.files;
  //     this.createNewFile.fileName = fileInput.target.files[0].name;
  
  //     const postData = new FormData();
  //     postData.append("document_type", "Goods Return");
  //     postData.append("document_number", this.grnumber);
  
  //     let hasDuplicates = false; 
  
  //     for (const file of this.fileUploadUrls) {
  //       if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
  //         hasDuplicates = true;
  //         break; 
  //       } else {
  //         postData.append("doc", file);
  //       }
  //     }
  
  //     if (hasDuplicates) {
  //       this.alertcall.showWarning('Alert', 'Some files are already uploaded');
  //     } else {
  //       this.custservice.addfileupload(postData).subscribe((res: any) => {
  //         if (res && res['status_code'] == '200') {
  //           this.alertcall.showSuccess("Accepted", res['message'])
  //           this.getexistingfiles()
  //           this.fileUploadUrls = []
  //         } else {
  //           this.alertcall.showWarning("Error", res['message'])
  //         }
  //       });
  //     }
  //   }
  // }


  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
  
      const postData = new FormData();
      postData.append("document_type","Goods Return");
          postData.append("document_number",this.grnumber);
  
      let hasDuplicates = false; 
  
      for (const file of this.fileUploadUrls) {
        if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
          hasDuplicates = true;
          break; 
        } else {
          postData.append("doc", file);
        }
      }
  
      if (hasDuplicates) {
        this.alertcall.showWarning('Alert', 'Some files are already uploaded');
      } else {
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          if (res && res['status_code'] == '200') {
            this.alertcall.showSuccess("Accepted", res['message'])
            this.getexistingfiles()
            this.fileUploadUrls = []
          } else {
            this.alertcall.showWarning("Error", res['message'])
          }
          fileInput.target.value = '';
        });
      }
    }
  }



    // uploadgrfiles(fileInput: any) {
    //   if (
    //     fileInput &&
    //     fileInput.target &&
    //     fileInput.target.files &&
    //     fileInput.target.files.length > 0
    //   ) {
    //     this.fileUploadUrlsgreturn = fileInput.target.files;
    //     this.createNewFile.fileName = fileInput.target.files[0].name;
    //     for(const file of this.fileUploadUrlsgreturn){
    //       this.filenamearray.push(file.name)
    //       this.selectedfiles.push(file)
    //     } 
    //   }
     
    // }


    uploadgifiles(fileInput: any) {
      if (
        fileInput &&
        fileInput.target &&
        fileInput.target.files &&
        fileInput.target.files.length > 0
      ) {
  
        console.log( fileInput.target.files , ' fileInput.target.files');
        
        this.fileUploadUrlsgreturn = fileInput.target.files;
        this.createNewFile.fileName = fileInput.target.files[0].name;
        for (const file of this.fileUploadUrlsgreturn) {
          if (this.isFileSelected1(file.name)) {
            this.alertcall.showWarning('Alert', 'Duplicate file: ' + file.name + ' already selected');
          } else {
            console.log(this.selectedfiles , 'this.selectedfiles');
            
            
            this.filenamearray.push(file.name);
            this.selectedfiles.push(file);
          }
        }
      }
    }
    isFileSelected1(selectedFilePath: string): boolean {
      return this.selectedfiles.some((file: any) => file.name === selectedFilePath);
    }












  //   uploadselectedfiles(){
  //     const postData = new FormData();
  //     postData.append("document_type","Goods Return");
  //     postData.append("document_number",this.resultgoodsretnumber);
  //     for(const file of this.fileUploadUrlsgreturn){
  //       postData.append("doc",file)
  //     }
  //     this.custservice.addfileupload(postData).subscribe((res:any)=>{
  // if(res&&res['status_code']=='200'){
  // this.fileUploadUrlsgreturn=[]
  // this.filenamearray=[]
  // this.selectedfiles=[]
  // }else{
  
  // }
  //     })
  //   }

  uploadselectedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Goods Issue");
    postData.append("document_number", this.resultgoodsretnumber);
  
    const uniqueFileNames = new Set(); 
  
    for (const file of this.selectedfiles) {
     
      if (!uniqueFileNames.has(file.name)) {
        postData.append("doc", file);
        uniqueFileNames.add(file.name); 
      }
    }
  
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.fileUploadUrlsgreturn=[]
        this.filenamearray = [];
        this.selectedfiles = [];
      } else {
        
      }
    });
  }
  deleteFileItem(index: any) {
    this.selectedfiles.splice(index, 1)
  }


    filtecontractordatadata(ev: any) {
      console.log(ev.target.value);
      this.selectedconname = ev.target.value;
      if (this.selectedconname.length > 2) {
        this.getContractdata();
      }
      if (!this.selectedconname) {
        this.getContractdata();
      }
    }
    getContractdata(){
      let obj={
        "command" : "lst",
        "lmt" : this.pageSize,
        "pid" : this.pageIndex,
        "key" : this.selectedconname || ""
      }
      this.custservice.getcontractormasterdata(obj).subscribe((res:any)=>{
        this.contracordata=res.data;
      //  this.totalRecords = res?.count;
      //  this.dataSource.data = res.data
      //  if(res.data.length==0){
      //   this.contracordata = true
      // }
      })
    }
    deleterow(index:any){
      console.log(index);
      
      this.saveddataarray.splice(index, 1);
      this.dataSource.data=this.saveddataarray
      console.log(this.dataSource.data)
    }
    
    onpageevent(event:any){
      this.pageIndex=event.pageIndex+1;
      this.pageSize= event.pageSize;
      this.getData()
    }
    deleteItem(rw:any,data:any){
     
  this.dialog.open(data,{
    width:'400px',
    // scrollStrategy: new NoopScrollStrategy()
  })
  this.deleteNumber=rw.number
    }
   
    deleteFile(){
  let obj={
    "command" : "del", 
    "number" : this.deleteNumber,
    "reason": this.deletemodel.reason,
    "txn_id":this.txnId,
  }
  this.custservice.deletegoodsreturn(obj).subscribe((res:any)=>{
    if(res&&res['status_code']=="200"){
      this.alertcall.showSuccess('Accepted', res['message']);
     
      this.dialog.closeAll()
      this.getTransactionId();
      this.deletemodel.reason=""
      this.getData()
         }else{
          this.alertcall.showWarning('Accepted', res['message']);
         }
  })
    }

  getData() {
    this.pageNumbers = []
    let obj = {
      command: "lst",
      lmt: this.pageSize,
      pid: this.pageIndex,
    };
    this.custservice.getSearchGoodsGoodsReturn(obj).subscribe((res: any) => {
      console.log(res);

      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo = this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true;
      }
      this.columnname=''
      this.searchData=''
    });
  }

    search() {
      console.log(this.searchData);
      let obj = { "command": "lst", "field": this.columnname, "key": this.searchData, "lmt": this.pageSize, "pid": this.pageIndex }
      if (this.searchData.length > 2 && this.columnname) {
        this.custservice.getSearchGoodsGoodsReturn(obj).subscribe((res: any) => {
          console.log(res);
          this.reaData = false;
          this.totalRecords = res?.count;
          this.dataSourcemain.data = res.data;
          if (res.data.length == 0) {
            this.reaData = true;
          }
        });
      } else if (!this.searchData) {
        this.getData()
        this.columnname = ""
      }
    }

    goToChange(){
      this.paginator.pageIndex = this.goTo - 1;
      const event: PageEvent = {
        // previousPageIndex: this.pageIndex-1,
        length: this.paginator.length,
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
      };
      this.paginator.page.next(event);
    }
    emitPageEvent(pageEvent: PageEvent) {
      this.paginator.page.next(pageEvent);
    }
  
  getstorageDATA(ev:any){
      console.log(ev.target.value);
      this.selectedstorage=ev.target.value
      if( this.selectedstorage.length>2){
  this.getstoragelocData()
      }
    }
    getstorageDATA1(ev:any){
      console.log(ev.target.value);
      this.selectedstorage1=ev.target.value
      if( this.selectedstorage1.length>2){
  this.getstoragelocData()
      }
    }
    getstoragelocData(){
      let obj={
        "command": "mat",
        "field": "storage_location",
        "key":this.selectedstorage || this.selectedstorage1
      }
      this.custservice.getmatstoragelocdata(obj).subscribe((res:any)=>{
         this.storageData=res.data
          
        
        
      })
    }
    getvehiclenumDATA(ev:any){
      console.log(ev.target.value);
      this.selectedvehiclenum=ev.target.value
      if( this.selectedvehiclenum.length>2){
  this.getvehiclenumdata()
      }
    }
    getvehiclenumDATA1(ev:any){
      console.log(ev.target.value);
      this.selectedvehiclenum1=ev.target.value
      if( this.selectedvehiclenum1.length>2){
  this.getvehiclenumdata()
      }
    }
    getvehiclenumdata(){
      let obj={
        "command": "mat",
        "field": "vehicle_number",
        "key":this.selectedvehiclenum ||this.selectedvehiclenum1,
      }
      this.custservice.getmatvehiclenumdata(obj).subscribe((res:any)=>{
        this.vehicleNum=res.data
      })
    }
    gettransportDATA(ev:any){
      console.log(ev.target.value);
      this.selectedtransporter=ev.target.value
      if( this.selectedtransporter.length>2){
  this.getTransporterdata()
      }
      if(! this.selectedtransporter){
        this.getTransporterdata()
      }
    }
    gettransportDATA1(ev:any){
      console.log(ev.target.value);
      this.selectedtransporter1=ev.target.value
      if( this.selectedtransporter1.length>2){
  this.getTransporterdata()
      }
      if(! this.selectedtransporter1){
        this.getTransporterdata()
      }
    }
    getTransporterdata(){
      
      let obj={
        "command": "mat",
        "field": "transporter_name",
        "key":this.selectedtransporter ||this.selectedtransporter1,
        
      }
      this.custservice.getmattransnamedata(obj).subscribe((res:any)=>{
        this.transporterName=res.data
      })
    }
    getvendordata(){
      let obj={
        "command":"lst"
      }
      this.custservice.getvendormasterdata(obj).subscribe((res:any)=>{
        this.vendorData=res.data
      })
    }
    getcompanydata(){
      let obj={
        "command":"lst",
        lmt:100000,
        pid:1
      }
      this.custservice.usercompanyData(obj).subscribe((res:any)=>{
      this.companyData=res.data
        
      })
    }
    getmaterialDATA(ev:any){
      console.log(ev.target.value);
      this.selectedmaterial=ev.target.value
      if(this.selectedmaterial.length>2){
  this.getmasterdata()
      }
      if(! this.selectedmaterial){
        this.getmasterdata()
      }
    }
    getmaterialDATA1(ev:any){
      this.selectedmaterial1=ev.target.value
      if(this.selectedmaterial1.length>2){
  this.getmasterdata()
      }
      if(! this.selectedmaterial1){
        this.getmasterdata()
      }
    }
    getmasterdata(){
      let obj={
        "command":'lst',
        "lmt":100,
        "pid":1,
        "key":this.selectedmaterial ||this.selectedmaterial1
      }
      this.custservice.getmaterialmasterdata(obj).subscribe((res:any)=>{
        console.log(res);
        this.masterData=res.data
        
      })
    }
    getTaxlistdata(){
      let obj={
        "command" : "lst",
       
      }
      this.custservice.gettaxlistdata(obj).subscribe((res:any)=>{
      this.taxData=res.data
      })
    }
    selectedmastergroup(){
      console.log(this.model.matcode);
      this.masterData.forEach((ele:any) => {
        if(ele.system_reference_1==this.model.matcode){
          this.model.uom=ele.uom_1
          this.materialCODE=ele.code
          this.materialNAME=ele.name
        }
      });
      
      
    }
  
    addgoodsreturn(data:any){
      // this.getmasterdata()
      // this.getstoragelocData()
      // this.getvalutionData()
      this.dialog.open(data,{
        width:'1100px',
        // scrollStrategy: this.overlay.scrollStrategies.noop()
        //  scrollStrategy: new NoopScrollStrategy()
      })
   
    }
  
    closemodel(){
      this.dialog.closeAll()
    }
    saveaddeddata(_form:any){
     this.model['material_code']=this.materialCODE
     this.model['material_description']=this.materialNAME
      console.log(this.model);
      
      // this.editDATAA=true;
     this.saveddataarray.push(this.model)
      this.dataSource.data = this.saveddataarray
      this.model={}
      this.dialog.closeAll()
     
  
  
    }
    getvalutionData(){
      let obj={
        "lmt": 100000,
        'pid': 1,
        "command" : "lst",
        "key": "" 
      }
      this.custservice.getvalutiondata(obj).subscribe((res:any)=>{
      this.valutiondata=res.data
     
      
      })
    }
    savefinaldata(fr:any){
      if(this.btn==="Save"){
    // console.log(this.saveddataarray);
    // this.saveddataarray.forEach((ele:any,index)=>{
    //   this.SNO.push(index+1)
    //   this.matCode.push(ele.material_code)
    //   this.matName.push(ele.material_description)
    //   this.UOM.push(ele.uom)
    //   this.storageLocation.push(ele.storagelocation)
    //   this.ReqQTY.push(Number(ele.reqqty))
    //   this.qty.push(Number(ele.qty))
    //   this.itemremarks.push(ele.itemremarks)
    //   // this.batchno.push(ele.batchno)
    //   this.valutionType.push(ele.valutiontype)
  
    //     })
 
        this.MATDATA.forEach((element:any,_index:any) => {
          // if(this.MATDATA[index].accepted_quantity>(this.MATDATA[index].received_quantity-this.MATDATA[index].rejeted_quantity)){
          //   // console.log((this.qcqty||data)-this.recqty);
            
          //   this.alertcall.showWarning("Accepted","Accepted Qty Exceed The Limit")
          // }
          // if(this.MATDATA[index].rejeted_quantity>(this.MATDATA[index].received_quantity-this.MATDATA[index].accepted_quantity)){
          //   this.alertcall.showWarning("Accepted","Rejected Qty Exceeds The Limit")
          // }
          this.QTY.push(Number(element.quantity))
          this.ISS_QTY.push(Number(element.issued_quantity))
          this.BAL_QTY.push(Number(element.balance_quantity))
          this.ITEMREMARKS.push(element.item_remarks)
        });
        
        this.MATDATA.forEach((element:any,index:any) => {
          this.matCode.push(element.material_code)
          this.matDESCRIPTION.push(element.material_description)
          this.UOM.push(element.unit_of_measurment)
          this.SNO.push(index+1)     
          this.storageLocation.push(element.storagelocation || element.storage_location)
          this.valutionType.push(element.valutiontype || element.valuation_type)
        });
     let obj={
        "work_order_number":this.model1.wonumber,
         "date": moment(this.model1.dateee).format("YYYY-MM-DD"),
      
         "company_name": this.model1.companyname,
         "from_company_name": this.model1.fromcompany,
         "contractor_name": this.model1.contractorname,
         "giver_name": this.model1.givername,
         "request_number": this.model1.requestnumber,
         "request_date": moment(this.model1.requestdate).format("YYYY-MM-DD"),
         "return_type": this.model1.returntype,
         "locations": this.model1.locations,
         "line_item": this.SNO,
         "material_code": this.matCode,
         "material_description": this.matDESCRIPTION,
         "unit_of_measurment": this.UOM,
        //  "request_quantity": this.ReqQTY,
         "quantity": this.QTY,
         "item_remarks": this.ITEMREMARKS,
         "valuation_type":this.valutionType,
         "storage_location": this.storageLocation,
         'issue_quantity': this.ISS_QTY,
         'balance_quantity':this.BAL_QTY,
        //  "batch_number": this.batchno,
         "comments": this.model1.comments,
         'goods_issue_number':this.ginumber,
         "txn_id":this.txnId,
         "command": "add"
       
      }
      this.custservice.addgoodsreturn(obj).subscribe((res:any)=>{
        if(res&&res['status_code']=="200"){
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
             icon: 'success',
            // title: res['reference'],
            width: 500,
            });
          fr.reset();
          this.MATDATA=[]
          this.saveddataarray=[]
          this.dataSource.data=[]
          this.SNO=[]
          this.matCode=[]
          this.matDESCRIPTION=[]
          this.UOM=[]
          this.storageLocation=[]
          this.ISS_QTY=[]
          this.BAL_QTY=[]
          this.QTY=[]
         this.ITEMREMARKS=[]
        this.valutionType=[]
       
        this.getTransactionId();
          this.getData()
          this.resultgoodsretnumber=res['reference']
          if(this.fileUploadUrlsgreturn.length > 0){
            this.uploadselectedfiles()
          }
          this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
        }else{
          this.alertcall.showWarning('Accepted', res['message']);
          // this.MATDATA=[]
          // this.saveddataarray=[]
          // this.dataSource.data=[]
          this.SNO=[]
          this.matCode=[]
          this.matDESCRIPTION=[]
          this.UOM=[]
          this.storageLocation=[]
          this.ISS_QTY=[]
          this.BAL_QTY=[]
          this.QTY=[]
         this.ITEMREMARKS=[]
        this.valutionType=[]
        }
      })
    }else{
      this.saveddataarray.forEach((ele:any,index)=>{
        this.SNO.push(index+1)
        this.matCode.push(ele.material_code)
        this.matName.push(ele.material_description)
        this.UOM.push(ele.uom || ele.unit_of_measurment)
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity))
        this.qty.push(Number(ele.qty || ele.quantity))
        this.itemremarks.push(ele.itemremarks || ele.item_remarks )
        // this.batchno.push(ele.batchno || ele.batch_number)
        this.valutionType.push(ele.valutiontype || ele.valuation_type)
    
          }
         )
   
     
       let obj={
        "reason": this.editModel.reason,
          "work_order_number":this.model1.wonumber,
           "date": moment(this.model1.dateee).format("YYYY-MM-DD"),
        
           "company_name": this.model1.companyname,
           "from_company_name": this.model1.fromcompany,
           "contractor_name": this.model1.contractorname,
           "giver_name": this.model1.givername,
           "request_number": this.model1.requestnumber,
           "request_date": moment(this.model1.requestdate).format("YYYY-MM-DD"),
           "return_type": this.model1.returntype,
           "locations": this.model1.locations,
           "line_item": this.SNO,
           "material_code": this.matCode,
           "material_description": this.matName,
           "unit_of_measurment": this.UOM,
          //  "request_quantity": this.ReqQTY,
           "quantity": this.qty,
           "item_remarks": this.itemremarks,
           "valuation_type":this.valutionType,
           "storage_location": this.storageLocation,
          //  "batch_number": this.batchno,
           "comments": this.model1.comments,
           "command": "edt",
           "txn_id":this.txnId,
           "number":this.editednumber
         
        }
        this.custservice.addgoodsreturn(obj).subscribe((res:any)=>{
          if(res&&res['status_code']=="200"){
            // this.alertcall.showSuccess('Accepted', res['message']);
            Swal.fire({
              text: res['message'],
              title: res['reference'],
               icon: 'success',
              // title: res['reference'],
              width: 500,
              });
       fr.reset();
              this.MATDATA=[]
              this.saveddataarray=[]
              this.dataSource.data=[]
              this.SNO=[]
              this.matCode=[]
              this.matDESCRIPTION=[]
              this.UOM=[]
              this.storageLocation=[]
              this.ISS_QTY=[]
              this.BAL_QTY=[]
              this.QTY=[]
              this.ITEMREMARKS=[]
            this.valutionType=[]
          
            this.getTransactionId();
            this.getData()
            this.editModel.reason=""
            this.resultgoodsretnumber=this.editednumber
            if(this.fileUploadUrlsgreturn.length > 0){
              this.uploadselectedfiles()
            }
            this.btn="Save"
            this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
          }else{
            this.alertcall.showWarning('Accepted', res['message']);
            // this.MATDATA=[]
            // this.saveddataarray=[]
            // this.dataSource.data=[]
            this.SNO=[]
            this.matCode=[]
            this.matDESCRIPTION=[]
            this.UOM=[]
            this.storageLocation=[]
            this.ISS_QTY=[]
            this.BAL_QTY=[]
            this.QTY=[]
           this.ITEMREMARKS=[]
          this.valutionType=[]
          }
        })  
    }
    }
    editdata(row1:any,_index:any,data:any){
      // this.getmasterdata()
      // this.getstoragelocData()
      // this.getvalutionData()
      console.log(row1);
      
      
      this.selectedIndex=this.saveddataarray.indexOf(row1)
      console.log(this.selectedIndex);
       this.dialog.open(data,{
        width:'1100px'
      })
      this.masterData.forEach((ele:any)=> {
        if(ele.code== row1.material_code){
  this.systemref=ele.system_reference_1
        }
      });
      this.model2.matcode= this.systemref || row1.matcode
      this.model2.uom=row1.uom || row1.unit_of_measurment
      this.model2.storagelocation=row1.storagelocation || row1.storage_location
      this.model2.reqqty=row1.reqqty  
      this.model2.qty=row1.qty || row1.quantity 
      this.model2.itemremarks=row1.itemremarks || row1.item_remarks!==null?row1.item_remarks:'NA'
      // this.model2.batchno=row1.batchno || row1.batch_number
      
      this.model2.valutiontype=row1.valutiontype || row1.valuation_type
     
    }
    saveaddededitdata(_fr:any){
      
      this.masterData.forEach((el:any) => {
        if(el.system_reference_1==this.model2.matcode){
           this.model2matcode=el.code
           this.materialCODE=el.code
           this.materialNAME=el.name
         } 
        });
        this.model2['material_code']=this.materialCODE
        this.model2['material_description']=this.materialNAME
      this.saveddataarray.splice(this.selectedIndex, 1, this.model2);
      this.dataSource.data = this.saveddataarray
      console.log(this.dataSource.data);
      
      this.saveddataarray[this.selectedIndex].matcode=this.model2matcode
     
       
     
      this.model2={}
      this.dialog.closeAll() 
    }
    selectedmastereditgroup(){
      this.masterData.forEach((el:any) => {
       if(el.system_reference_1==this.model2.matcode){
         this.model2.uom=el.uom_1
         this.materialCODE=el.code
         this.materialNAME=el.name
       } 
       
       
      });
      console.log( this.model2matcode,this.model2matdes);
    }
   
    keyPressNumbers(evt:any)
    {
       var charCode = (evt.which) ? evt.which : evt.keyCode;
       if (charCode != 46 && charCode > 31 
         && (charCode < 48 || charCode > 57))
          return false;
  
       return true;
    }

    printgoodsreturn(data:any){
      console.log(data.number);
     
      this.router.navigate(['/inventory/printgoodsreturn'],{ queryParams: {'dmrumber': data.number}})
    }
    editgrdata(data:any,dialog:any){
      this.dialog.open(dialog,{
        width:"400px"
      })
      this.editednumber=data.number
    }
    saveeditreason(){
      let obj={
        command: "mat",
        field: "number",
        key: this.editednumber,
      }
      this.custservice.addgoodsreturn(obj).subscribe((res:any)=>{
        this.viewData=false
        this.dialog.closeAll()
       this.editdataa=res.data[0]
       this.saveddataarray=res.data
       this.dataSource.data=this.saveddataarray
       this.model1.wonumber=this.editdataa.work_order_number,
       this.model1.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
       this.model1.companyname=this.editdataa.company_name,
       this.model1.fromcompany=this.editdataa.from_company_name,
       this.model1.contractorname=this.editdataa.contractor_name,
       this.model1.givername=this.editdataa.giver_name,
       this.model1.requestnumber=this.editdataa.request_number,
       this.model1.requestdate =moment(this.editdataa.request_date).format("YYYY-MM-DD"),
       this.model1.returntype=this.editdataa.return_type,
       this.model1.locations=this.editdataa.locations,
      
       this.model1.comments=this.editdataa.comments,
     
        this.demo1TabIndex=0;
        this.btn="Update"
      })
    }
    onChange() {
      console.log('Selected:',this.columnname);
      this.searchData=""    
    }
    // search(){
    //   if(this.searchData.length > 2){
    //     this.getData()
    //   }
    //   if(!this.searchData){
    //     this.getData()
    //   }
    // }
  //view
  viewreason(data:any) {
    this.viewnumber=data.number;
    let obj = {
      "command": "mat",
      "key": data.number,
      "field": "number"
    }
    this.custservice.addgoodsreturn(obj).subscribe((res: any) => {
      if(res.data.length>0){
        this.viewData=true
        // this.dialog.closeAll();
        this.editdataa=res.data[0]
        this.MATDATA=res.data
        console.log(this.MATDATA);
        
        // this.saveddataarray=res.data
        this.dataSource.data=this.MATDATA
        this.model1.wonumber=this.editdataa.work_order_number!==null?this.editdataa.work_order_number:"NA",
        this.model1.dateee = this.editdataa.date!==null?moment(this.editdataa.date).format("YYYY-MM-DD"):"NA",
        this.model1.companyname=this.editdataa.company_name!==""?this.editdataa.company_name:"NA",
        this.model1.fromcompany=this.editdataa.from_company_name !== null?this.editdataa.from_company_name:"NA",
        this.model1.contractorname=this.editdataa.contractor_name!==""?this.editdataa.contractor_name:"NA",
        this.model1.givername=this.editdataa.giver_name!==""?this.editdataa.giver_name:"NA",
        this.model1.requestnumber=this.editdataa.request_number!==null?this.editdataa.request_number:"NA",
        this.model1.requestdate =this.editdataa.request_date!==null?moment(this.editdataa.request_date).format("YYYY-MM-DD"):"NA",
        this.model1.returntype=this.editdataa.return_type!==""?this.editdataa.return_type:"NA",
        this.model1.locations=this.editdataa.locations!==""?this.editdataa.locations:"NA",
     
        this.model1.comments=this.editdataa.comments!==""?this.editdataa.comments:"NA",
      
         this.demo1TabIndex=0;
        // this.btn = "Update";
       
      }else{
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
    });
  }
  BacktoList(){
    this.demo1TabIndex = 1;
    this.viewData=false
  }
  //on change tab
onTabChanged(_ev:any){
  if(this.demo1TabIndex == 0){
    this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
  }
  if(this.demo1TabIndex===1){
    // alert(1);
    this.viewData=false
    this.model1={};
    this.MATDATA=[]
    this.saveddataarray=[]
    this.dataSource.data=[]
    this.SNO=[]
    this.matCode=[]
    this.matDESCRIPTION=[]
    this.UOM=[]
    this.storageLocation=[]
    this.ISS_QTY=[]
    this.BAL_QTY=[]
    this.QTY=[]
   this.ITEMREMARKS=[]
  this.valutionType=[]
    this.router.navigate(['/inventory/goodsreturn'])
    this.dataSourcemain.sort = this.sort;
  this.paginator.pageIndex = this.pageIndex-1
  this.pageSize=10;
  this.pageIndex=1;
  this.getData();
  }else if(this.demo1TabIndex===2){
    // alert(12);
    this.viewData=false
    this.model1={};
    this.MATDATA=[]
    this.saveddataarray=[]
    this.dataSource.data=[]
    this.SNO=[]
    this.matCode=[]
    this.matDESCRIPTION=[]
    this.UOM=[]
    this.storageLocation=[]
    this.ISS_QTY=[]
    this.BAL_QTY=[]
    this.QTY=[]
   this.ITEMREMARKS=[]
  this.valutionType=[]
    this.router.navigate(['/inventory/goodsreturn'])
    // this.dataSourcemainlog.sort = this.sort;
    this.logpagesize=10;
    this.pageIndex1=1;
    }

   }
   clearpage1(){
    this.viewData=false
    this.model1={dateee: moment(new Date()).format("YYYY-MM-DD")};
    this.MATDATA=[]
    this.saveddataarray=[]
    this.dataSource.data=[]
    this.SNO=[]
    this.matCode=[]
    this.matDESCRIPTION=[]
    this.UOM=[]
    this.storageLocation=[]
    this.ISS_QTY=[]
    this.BAL_QTY=[]
    this.QTY=[]
   this.ITEMREMARKS=[]
  this.valutionType=[]
  this.btn="Save"
  this.filenamearray = []
  this.selectedfiles = []
  this.getTransactionId();
}
   giData(data: any) {
    this.ginumber=""
    this.dialog.open(data, {
      width: '600px'
    })
    this.goodsIssue();
  }
  // onSearch(event: any) {
  //   const searchTerm = event.target.value.toLowerCase();
  //   this.giDATA = this.data.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
  // }
  // filteredGiData: any[] = [];
  // getgoodsIssueDATA(event: any) {
  //   this.goodsIssue()
  //   const searchTerm = event.target.value.toLowerCase();
  //   this.filteredGiData = this.giDATA.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
  //   this.selectgoodsIssue = event.target.value;
  //   if (this.selectgoodsIssue.length > 2 && !this.selectedtransporter) {
  //     this.goodsIssue();
  // }
  // }
  getgoodsIssueDATA(ev: any) {
    const searchTerm = ev.target.value.toLowerCase();
    this.giDATA = this.data.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
    this.selectgoodsIssue = ev.target.value;
    if (this.selectgoodsIssue.length > 2) {
      this.goodsIssue();
    }
    if (!this.selectedtransporter) {
      this.goodsIssue();
    }
  }
goodsIssue(){
  let obj = {
    command: "lst",
    lmt: 10,
    pid: 1,
    key:this.selectgoodsIssue || ""
  };
  this.custservice.getSearchGoodsIssue1(obj).subscribe((res: any) => {
      this.giDATA = res.data 
  })
}

submitgino(_fr: any) {
  let obj = {
    "command": "set",
    "key": this.ginumber,
  }
  this.custservice.addgoodsreturn(obj).subscribe((res: any) => {
    if(res.data.length>0){
      console.log(res);
      this.viewData=false
      this.dialog.closeAll()
     this.editdataa=res.data[0]
     this.saveddataarray=res.data
     this.MATDATA=res.data
     this.dataSource.data=this.saveddataarray
     this.model1.wonumber=this.editdataa.work_order_number,
     this.model1.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
     this.model1.companyname=this.editdataa.company_name,
     this.model1.fromcompany=this.editdataa.from_company_name,
     this.model1.contractorname=this.editdataa.contractor_name,
     this.model1.requestnumber=this.editdataa.request_number,
     this.model1.requestdate =moment(this.editdataa.request_date).format("YYYY-MM-DD"),
     this.model1.returntype=this.editdataa.return_type,
     this.model1.locations=this.editdataa.locations,
     this.model1.comments=this.editdataa.comments,
     this.model1.valutiontype=this.editdataa.valuation_type
      this.demo1TabIndex=0;
    }
    else if (res.data.length = []){
      this.alertcall.showWarning("Accepted", "No Data Found");
    }
  })
}

Quantity(item:any,data:any){
  if(item !== null){
    if(item >  data.balance_quantity ){
      data.quantity=""
      this.alertcall.showWarning("Error", 'Quantity Should be less than or equal to Issue Quantity & Balance Quantity')
    }
  }
}

      trimInput(){
        this.model1.wonumber = this.model1.wonumber.trim();
        this.model1.companyname = this.model1.companyname.trim();
        this.model1.contractorname = this.model1.contractorname.trim();
        this.model1.requestnumber = this.model1.requestnumber.trim();
        this.model1.givername = this.model1.givername.trim();
        this.model1.locations = this.model1.locations.trim();
        this.model1.returntype = this.model1.returntype.trim();
        this.model1.comments = this.model1.comments.trim();
      }
}
