import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ActivitylogComponent } from "./activitylog/activitylog.component"
import { RouterModule, Routes } from "@angular/router"
import { UserRolesAuthGuard } from "../auth/user-roles-auth.guard"
import { MatCardModule } from "@angular/material/card"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatTableModule } from "@angular/material/table"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatIconModule } from "@angular/material/icon"
import { SharedMaterialModule } from "../shared/material/shared-material-module"
import { MatDialogModule } from "@angular/material/dialog"
import { NgxPrintElementModule } from "ngx-print-element"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode"
import { ZXingScannerModule } from "@zxing/ngx-scanner"
import { MatExpansionModule } from "@angular/material/expansion"
import { NgxChartsModule } from "@swimlane/ngx-charts"
import { PdfViewerModule } from "ng2-pdf-viewer"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { SignaturePadModule } from "ngx-signaturepad"
import { MatStepperModule } from "@angular/material/stepper"
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material"
import { ReportsComponent } from "./reports/reports.component"
const routes: Routes = [
  {
    path: "activitylog",
    canActivate: [UserRolesAuthGuard],
    data: {
      title: "Activity Log",
    },
    component: ActivitylogComponent,
  },

  {
    path: "reports",
    component: ReportsComponent,
  },
]

@NgModule({
  declarations: [ActivitylogComponent, ReportsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    SharedMaterialModule,
    NgxPrintElementModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgxQRCodeModule,
    ZXingScannerModule,
    MatExpansionModule,
    NgxChartsModule,
    PdfViewerModule,
    DragDropModule,
    ScrollingModule,
    MatStepperModule,
    SignaturePadModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [ActivitylogComponent, ReportsComponent],
})
export class ReusableModule {}
