export const environment = {
  production: true,
  url: "https://testgeps.greenkogroup.com/app/api",
  sso_url: "https://testgeps.greenkogroup.com", 
  sso_api: "https://gee.greenko.net/ssov2",
  base_url: "https://testgeps.greenkogroup.com", 
  dms_base_url: "https://qa-dms.intellaire.com/dms",
  subfile_url: "https://digitele-geps-dms.s3.amazonaws.com/public",
  pdashboard_url: "https://testgeps.greenkogroup.com",
  pending_approval_url:'https://testgeps.greenkogroup.com/ms/planning',
  rmc_base_url:'https://testgeps.greenkogroup.com/geps/rmc', 
  logistics_url:"https://qa-logistics.intellaire.com/logistics",
  socket_url:"wss://qa-logistics.intellaire.com",
  clients:{
    testgeps:{
      url: "https://idp.greenkogroup.com/",
      realm:"greenko-all",
      clientId: "geps",
    },
  },
  logoimageUrl:'assets/greenko/newlogo_greeenko_eps.svg',
  isnonGroup:"no",
  amGreen: false,
};
