import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { CustomerService } from 'src/app/services/customer.service';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
@Component({
  selector: 'app-dmrbulkupdate',
  templateUrl: './dmrbulkupdate.component.html',
  styleUrls: ['./dmrbulkupdate.component.scss']
})

export class DmrbulkupdateComponent implements OnInit {
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef;
  @ViewChild(MatPaginator) masterpaginator: MatPaginator;
  @ViewChild('selectdmr') selectdmr: MatSelect;
  @ViewChild('selectcompany') selectcompany:MatSelect


  dataSourcemain = new MatTableDataSource;
  // dataSourcemain = new MatTableDataSource<any>([]);
  listcolumns: any[] = [
    "s.no",
    "DMR_Date",
    "DMR_Number",
    "Goods_Receipt_Number",
    "Goods_Receipt_Date",
    "ho_dispatch_ref",
    "Document_Dispatch_Date",
    "Ho_Receipt_Date",
    "purchase_order_date",
    "purchase_order_number",
    "F&A_Submission_Date",
    "Submitted_To",
  ];
  dmrbulkapidata: any[] = [];
  dmrbulkdata: any;
  companyData1: any;
  minDate: string;
  companyname: any="";
  saveddataarray: any[] = []
  editabledata: any;
  totalRecordslog: any=0;
  pageIndex: any = 1;
  pageSize: number = 10;
  statusCode: number;
  isFormValid: boolean = false;
  searchcol:any=""
  pagedata:boolean=true
  dateValidation: boolean[] = [];
  dateValidationFields: any[] = [];
  maxDate: string;
  loadingRecords: any = false;
  resData: boolean = true;
  noSpacePattern: RegExp = /^[^\s]+$/;
  invalidInputs: any;
  dmrbulkdatacopy: any;
  searchdmr:any
  searchgr:any
  submitClicked: boolean = false;
  today: string = new Date().toISOString().split('T')[0];
  yesterday: string =  moment().subtract(1, 'day').format('YYYY-MM-DD');
  fromdate: any=this.yesterday;
  todate: any=this.today;
  filteredData: any[] = [];
  grn_date: any;
  grn_number: any;
  grlength: boolean = false;
  searchfieldtable: boolean = false;
  searchpon: string;
  bulkdata: any;
  changedata: boolean=false;
  subbtn: boolean;
  showField: boolean;
  showError: boolean=true;
  searchData: any;
  constructor( private custservice: CustomerService, private alertcall: AlertCallsService,    private cdr: ChangeDetectorRef  // Add this line
  ) { 
    this.setMaxDate();
  }
  ngOnInit(): void {
    const currentDate = new Date();

    this.today = currentDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD

    this.getcompanydata1();
    console.log(this.resData ,'this.resData')
    this.todate = this.getCurrentDate();
    this.setYesterdayDate();
  }
  isGrnDateValid(row: any): boolean {
    const grnDate = new Date(row.grn_date);
    const dmrDate = new Date(row.date);
    return grnDate >= dmrDate;
  }

  // Function to handle the date change event
  onGrnDateChange(row: any, _index: number) {
    if (!this.isGrnDateValid(row)) {
      console.error('GRN date must be greater than or equal to the DMR date');
    }
  }
 getcompanydata1() {
  let obj = {
    command: "lst",
    lmt: 100000,
    pid: 1
  };
  this.custservice.usercompanyData(obj).subscribe((res: any) => {
    this.companyData1 = res.data.map((item: any) => ({
      ...item,
      date: this.convertDateString(item.date),
      grn_date: this.convertDateString(item.grn_date),
      ho_dispatch_date: this.convertDateString(item.ho_dispatch_date),
      ho_received_date: this.convertDateString(item.ho_received_date),
      purchase_order_date: this.convertDateString(item.purchase_order_date),
      fa_submitted_date: this.convertDateString(item.fa_submitted_date),
    }));

  });
}
formatDate1(date: string): string {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
convertDateString(dateString: string): string | null {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}

  formatDatee(dateString: any) {
    console.log("dateINput::-", dateString)
    const now = new Date(dateString);
    console.log("now::=", now)
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
  }

  getDmrbulkpdates1(){
    this.searchcol = "";
    this.searchData = "";
    this.getDmrbulkpdates()
  }
  getDmrbulkpdates() {
    if (this.isFormValid) {
      // Your submit logic here
      this.subbtn=true
      this.loadingRecords = true
      let obj = {
        "from_date": this.fromdate,
        "to_date": this.todate,
        "company_name": this.companyname,
        "command": "bud",
        "pid": this.pageIndex,
        "lmt": this.pageSize
      }
      this.custservice.getDmrbulkdata(obj).subscribe((res: any) => {
        this.loadingRecords = false
        this.dmrbulkdata = res
        this.dataSourcemain.data = res.data
        this.dataSourcemain = res.data.map((item: any) => ({
          ...item,
          date: this.convertDateString(item.date),
          grn_date: this.convertDateString(item.grn_date),
          ho_dispatch_date: this.convertDateString(item.ho_dispatch_date),
          ho_received_date: this.convertDateString(item.ho_received_date),
          purchase_order_date: this.convertDateString(item.purchase_order_date),
          fa_submitted_date: this.convertDateString(item.fa_submitted_date),
        }));

        this.totalRecordslog=res.count
        this.dmrbulkapidata = res.data
        this.bulkdata=res.data
        this.searchfieldtable = true
      if(this.showField==true){
        this.searchcol=""
        this.searchdmr=""
        this.searchgr=""
        this.searchpon=""
  
      }
        this.dmrbulkdatacopy=JSON.parse(JSON.stringify(res.data))
        if (this.dmrbulkapidata.length > 0) {
          this.resData = false
        }
      })
    }
   
  }
  search(){
    if (this.isFormValid) {
      this.subbtn=true
      this.loadingRecords = true
      let obj = {
        "from_date": this.fromdate,
        "to_date": this.todate,
        "company_name": this.companyname,
        "command": "bud",
        "pid": this.pageIndex,
        "lmt": this.pageSize,
        'field':this.searchcol,
        'key':this.searchData
      }
      this.custservice.getDmrbulkdata(obj).subscribe((res: any) => {
        this.loadingRecords = false
        this.dmrbulkdata = res
       this.resetTable();
        this.dataSourcemain.data = res.data
        this.totalRecordslog=res.count
        if (this.masterpaginator) {
          this.masterpaginator.firstPage();
        }
        this.dmrbulkapidata = res.data
        this.bulkdata=res.data
        this.searchfieldtable = true
        this.dmrbulkdatacopy=JSON.parse(JSON.stringify(res.data))
        if (this.dmrbulkapidata.length > 0) {
          this.resData = false
        }
      })
    }
  }
  resetTable(){
    this.dataSourcemain.data = [];
    this.dataSourcemain = new MatTableDataSource<unknown>([]);
  }
  searchNumberdata(ev: any) {
    this.searchData = ev.target.value;
    if (this.searchData.length > 2) {
      this.pageIndex=1;
      this.pageSize=10;

      this.search();
    }
    if (!this.searchData) {
      this.searchcol="";
      this.getDmrbulkpdates();
    }
  }
  Placeholder: string = 'Search';
  onChange1(){
    console.log('Selected search column:', this.searchcol);
    this.searchData = "";
    if (this.searchcol === 'number') {
        this.Placeholder = 'Search DMR Number';
    } else if (this.searchcol === 'grn_number') {
        this.Placeholder = 'Search Goods Receipt no.';
    } else if (this.searchcol === 'purchase_order_number') {
        this.Placeholder = 'Search Purchase Order Number';
    } else {
        this.Placeholder = 'Search';
    }
}


allSelected = false;


toggleAllSelection() {
  if (this.allSelected) {
    this.selectcompany.options.forEach((item: MatOption) => item.select());
    this.validateForm();

  } else {
    this.selectcompany.options.forEach((item: MatOption) => item.deselect());
    this.validateForm();

  }
}
optionClickCompany() {
  const allSelected = this.selectcompany.options.toArray().every((item: MatOption) => item.selected);
  this.allSelected = allSelected;
  this.validateForm();
}

  validateForm() {
    this.isFormValid = !!this.fromdate && !!this.todate && !!this.companyname && this.companyname.length > 0 && !this.dmrbulkerrormsg() ;
  }
  isValidDateFormat(dateString: string, field: string): boolean {
    if (field === "grn_date" || field ==='ho_received_date' || field ==='ho_dispatch_date' || field ==='fa_submitted_date' || field === 'purchase_order_date') {
     
      if (dateString === "") {
        return true; 
      }
    }
    const dateFormatRegex = /^(?!(00)-(00)-(0000))\d{2}-\d{2}-\d{4}$/;
    return dateFormatRegex.test(dateString);
  }
  
  isFieldValid(row:any, field:string){
    if(!row.hasOwnProperty('valid')){row['valid']={}; row['valid'][field]=false;}
    return  row['valid'][field];
  }
  dataInDraft(row:any, field:any){
    if(!row.hasOwnProperty('valid')){row['valid']={}; row['valid'][field]=true;}
    if(row.hasOwnProperty('valid')){
      row["valid"][field] = true
    }
    console.log(row);
  }

//   editDmrbulkupdate(row: any, i: any, val: any, field: any) {
//   if ( (field==='grn_date' || field ==='ho_received_date' || field ==='ho_dispatch_date' || field ==='fa_submitted_date' || field === 'purchase_order_date' ) && !this.isValidDateFormat(val,field)) {
//     this.dateValidation[i] = true;
//     this.dateValidationFields[i] = { 
//         grn_date: field === 'grn_date',
//         ho_received_date: field === 'ho_received_date',
//         ho_dispatch_date: field === 'ho_dispatch_date',
//         fa_submitted_date: field === 'fa_submitted_date',
//         purchase_order_date :field === 'purchase_order_date'
//     };       
// } else {
//     this.dateValidation[i] = false;
//     this.dateValidationFields[i] = { 
//         grn_date: false,
//         ho_received_date: false,
//         ho_dispatch_date: false,
//         fa_submitted_date: false,
//         purchase_order_date:false
//     };

//     let valueToUpdate = val.trim(); 
//     let isEmptyValue = valueToUpdate === ''; 
    
//       let obj = {
//         "command": "hod",
//         "dmr_number": row.number,
//         "field": field,
//         "value": isEmptyValue ? null : valueToUpdate, 
//             };
//       this.custservice.getDmrbulkdata(obj).subscribe((res: any) => {
//         if (res && res['status_code'] == "200") {
//           this.alertcall.showSuccess('Accepted', res.message);
//           this.dmrbulkdatacopy[i][field] = val
//           this.updatePending(i, val, field);
//           row["valid"][field] = false
//         } else {
//           this.alertcall.showWarning("Warning", res.message);
//         }
//       });
//     }
//   }
onDateSelected(row: any, i: any, field: string) {
  // Adjust the date format if needed before updating
  let selectedDate = field.includes('date') ? this.adjustDateFormat(row[field]) : row[field];

  // Update the row with the adjusted date or field value
  row[field] = selectedDate;

  // Call the update method with the adjusted date or field value
  this.editDmrbulkupdate(row, i, selectedDate, field);
}


adjustDateFormat(date: string): string {
  // Example: Adjust date from 'YYYY-MM-DD' to 'DD-MM-YYYY'
  if (date) {
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
  }
  return date;
}
saveRow(row: any, i: any) {
  // Create an object to hold all the fields and their updated values
  let updateObject: any = {
      command: 'hod',
      dmr_number: row.number,  // Assuming row.number is unique for each row
      fields: {}
  };

  // List of fields to update
  const fieldsToUpdate = [
      'grn_number',
      'grn_date',
      'ho_received_date',
      'ho_dispatch_date',
      'fa_submitted_date',
      'purchase_order_date',
      'ho_dispatch_ref',
      'purchase_order_number',
      'fa_submitted_to'
  ];

  // Loop through each field, validate and add to the update object
  fieldsToUpdate.forEach(field => {
      let fieldValue = row[field];

      // Skip if the field hasn't changed
      if (fieldValue === this.dmrbulkdatacopy[i][field]) {
          return;
      }

      // Check if the field is a date and adjust the format
      if (field.includes('date')) {
          fieldValue = this.adjustDateFormat(fieldValue);
          if (!this.isValidDateFormat(fieldValue, field)) {
              this.dateValidation[i] = true;
              this.dateValidationFields[i] = { [field]: true };
              return;
          } else {
              this.dateValidation[i] = false;
              this.dateValidationFields[i] = { [field]: false };
          }
      }

      // Trim text fields
      if (typeof fieldValue === 'string') {
          fieldValue = fieldValue.trim();
      }

      // Add the field to the update object
      updateObject.fields[field] = fieldValue || null;
  });

  // If no fields were modified, skip the update
  if (Object.keys(updateObject.fields).length === 0) {
      this.alertcall.showWarning('Info', 'No changes detected.');
      return;
  }

  // Send the update object to the backend
  this.custservice.getDmrbulkdata(updateObject).subscribe((res: any) => {
      if (res && res['status_code'] === "200") {
          this.alertcall.showSuccess('Accepted', res.message);
          Object.keys(updateObject.fields).forEach(field => {
              this.dmrbulkdatacopy[i][field] = updateObject.fields[field];
              this.updatePending(i, updateObject.fields[field], field);
              row["valid"][field] = false;
          });

          // Manually trigger change detection
          this.cdr.detectChanges();
      } else {
          this.alertcall.showWarning('Warning', res.message);
      }
  });
}

editDmrbulkupdate(row: any, i: any, val: any, field: any) {
  // Check if the field is a date and if the format is valid
  if (
      (field === 'grn_date' ||
          field === 'ho_received_date' ||
          field === 'ho_dispatch_date' ||
          field === 'fa_submitted_date' ||
          field === 'purchase_order_date') &&
      !this.isValidDateFormat(val, field)
  ) {
      this.dateValidation[i] = true;
      this.dateValidationFields[i] = {
          grn_date: field === 'grn_date',
          ho_received_date: field === 'ho_received_date',
          ho_dispatch_date: field === 'ho_dispatch_date',
          fa_submitted_date: field === 'fa_submitted_date',
          purchase_order_date: field === 'purchase_order_date',
      };
  } else {
      this.dateValidation[i] = false;
      this.dateValidationFields[i] = {
          grn_date: false,
          ho_received_date: false,
          ho_dispatch_date: false,
          fa_submitted_date: false,
          purchase_order_date: false,
      };

      let valueToUpdate = val.trim();
      let isEmptyValue = valueToUpdate === '';

      let obj = {
          command: 'hod',
          dmr_number: row.number,
          field: field,
          value: isEmptyValue ? null : valueToUpdate,
      };

      this.custservice.getDmrbulkdata(obj).subscribe((res: any) => {
          if (res && res['status_code'] == "200") {
              this.alertcall.showSuccess('Accepted', res.message);
              this.dmrbulkdatacopy[i][field] = val;
              this.updatePending(i, val, field);
              row['valid'][field] = false;

              // Adjust the date format for the relevant field
              if (field === 'grn_date' ||
                  field === 'ho_received_date' ||
                  field === 'ho_dispatch_date' ||
                  field === 'fa_submitted_date' ||
                  field === 'purchase_order_date') {
                  row[field] = this.adjustDateFormat(val);
              }

              // Manually trigger change detection
              this.cdr.detectChanges();
          } else {
              this.alertcall.showWarning('Warning', res.message);
          }
      });
  }
}


  searchbmr(dmr:any){
    this.showField=true
    if(dmr == "" ){
      this.showField=false 
      this.getDmrbulkpdates()
    }
  }
  searchbygrno(gr:any){
    this.showField=true
    if(gr ==""){
      this.showField=false
      this.getDmrbulkpdates()
    }

  }

  getdmrdataDataa() {
    this.showError = false;
    this.fromdate = this.yesterday;
    this.todate = this.today;
    this.companyname = '';
    this.totalRecordslog = 0;
    this.allSelected = false;
    this.searchcol = "";
    this.searchData = "";
    
    this.searchfieldtable = false;
    this.resetTable()
    this.validateForm();
    this.search();
    this.getDmrbulkpdates();
    this.dmrbulkerrormsg();
  }
  searchbypon(pon:any){
    this.showField=true
    if(pon==""){
      this.showField=false
      this.getDmrbulkpdates()
    }
  }
  onChange(){
    if(this.searchdmr==true){
      this.searchbmr(this.searchdmr)
    }
    
    
    }
    updatePending(index: number, fVal: string, field: string): boolean {
      
      if(this.dmrbulkdatacopy[index][field] == fVal &&  field){
        return false
      }else{
        return true
      }
  }
  onpageeventlog(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getDmrbulkpdates();

  }
  setMaxDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1; 
    let day: string | number = today.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    this.maxDate = `${year}-${month}-${day}`;
  }
  searchbydmr(dmrno: string) {
    if(dmrno==""){
      
    }
    if (!this.dmrbulkapidata) {
        this.getDmrbulkpdates();
    } else {
        const searchTerm = dmrno.toLowerCase();
        this.filteredData = this.dmrbulkapidata.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
        this.dmrbulkdatacopy = this.dmrbulkapidata.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
        this.dataSourcemain.data = this.filteredData;
        this.totalRecordslog=this.dataSourcemain.data.length
   
        
        if(this.filteredData.length==0){
          this.grlength=true
        }
    }
}
searchbygr(searchgr:string){
  if (!this.dmrbulkapidata) {
    this.getDmrbulkpdates();
  } else {
    const regex = new RegExp(searchgr, 'i');
    this.filteredData = this.dmrbulkapidata.filter((item: any) => regex.test(item.grn_number));
    this.dataSourcemain.data = this.filteredData; 
    this.totalRecordslog=this.dataSourcemain.data.length
    if(this.filteredData.length==0){
      this.grlength=true
      

    }
  }
}
dmrbulkerrormsg(){
  if(this.fromdate&& this.todate&& this.fromdate > this.todate){
    return true
  }
    return false  
}
searchbyversion(pon:any){
  if (!this.dmrbulkapidata) {
    this.getDmrbulkpdates();
  } else {
    const regex = new RegExp(pon, 'i');
    this.filteredData = this.dmrbulkapidata.filter((item: any) => regex.test(item.purchase_order_number));
    this.dataSourcemain.data = this.filteredData; 
    this.totalRecordslog=this.dataSourcemain.data.length
    if(this.filteredData.length==0){
      this.grlength=true
      

    }
  }

}
setYesterdayDate() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  this.fromdate = this.formatDate(yesterday);
}

formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
getCurrentDate(): string {
  // return new Date().toISOString().split('T')[0];
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().substring(0, 10);
  return formattedDate;
}
}






  

  







