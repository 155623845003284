import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss']
})
export class ContractorsComponent implements OnInit {
  displayedColumns: any[] = [
    'sno',
    'code',
    'name',
    'address',
    'contact_number',
    'gst_number',
    'pan_number',
    'city',
    'state',
    'country',
    'pincode',
    // 'ActiveStatus',
    'action'
  ];
  dataSource = new MatTableDataSource();
  totalRecords=0;
  contractorname:any;
  address:any;
  contactnumber:any;
  gstnumber:any;
  pincode:any;
  city:any;
  state:any;
  country:any;
  pageIndex: any=1;
  pageSize:any=10;
  vendordata: any;
  contracteditname: any;
  addressedit: any;
  contactnumberedit: any;
  gstnumberedit: any;
  cityedit: any;
  countryedit: any;
  stateedit: any;
  pincodeedit: any;
  contractId: any;
  contractCode: any;
  contracordata: any=false;
  deleteId: any;
  searchData: any;
  loadingRecords:any=false;
  pannumber: any;
  pannumberedit: any;
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  constructor(private dialog:MatDialog,private custservice:CustomerService,
    private alertcall:AlertCallsService) { }

  ngOnInit(): void {
    this.getContractdata()
  }
  onpageevent(event:any){
    this.pageIndex=event.pageIndex+1;
    this.pageSize= event.pageSize;
    this.getContractdata()
  }
  getContractdata(){
    let obj={
      "command" : "lst",
      "lmt" : this.pageSize,
      "pid" : this.pageIndex,
      "key" : "" || this.searchData
    }
    this.custservice.getcontractormasterdata(obj).subscribe((res:any)=>{
      this.contracordata=false;
     this.totalRecords = res?.count;
     this.dataSource.data = res.data
     if(res.data.length==0){
      this.contracordata = true
    }
    })
  }
  searchdata(){
    this.pageIndex=1;
    // this.searchData=event.target.value;
    if(this.searchData.length>2){
      this.masterpaginator.firstPage();
      this.getContractdata()
    }if(!this.searchData){
      this.searchData=""
      this.getContractdata()
    }
    }
  addcontractor(data:any){
this.dialog.open(data,{
  width:'800px'
})
this.contractorname="";
this.address="";
this.contactnumber="";
this.gstnumber="";
this.pannumber="";
this.city="";
this.state="";
this.country="";
this.pincode="";
this.showError = false;
this.showErrorCnt = false;
this.showErrorPan = false;
this.showErrorpin = false;
  }
  savecontractordata(form:any){
let obj={
  "command" : "add",
  "name": this.contractorname,
  "address_1": this.address,
  "contact_number": this.contactnumber,
  "gst_number": this.gstnumber,
  "pan_number": this.pannumber,
  "pin_code": this.pincode,
  "city": this.city,
  "state": this.state,
  "country": this.country
}
this.custservice.addcontractormaster(obj).subscribe((res:any)=>{
  if(res&&res['status_code']=='200'){
    this.alertcall.showSuccess('Accepted', res['message']);
    this.dialog.closeAll() ;
    form.reset()
    this.getContractdata()
  }else {
    this.alertcall.showWarning('Accepted', res['message']);
  }
})
  }
  editdata(row1:any,data:any){
    console.log(row1);
    this.dialog.open(data,{
      width:'800px'
    })
    this.contractId=row1.id;
    this.contractCode=row1.code;
    this.contracteditname=row1.name;
    this.addressedit=row1.address_1;
    this.contactnumberedit=row1.contact_number;
    this.gstnumberedit=row1.gst_number;
    this.cityedit=row1.city;
    this.stateedit=row1.state;
    this.countryedit=row1.country;
    this.pincodeedit=row1.pin_code;
    this.pannumberedit=row1.pan_number;
    this.showError = false;
    this.showErrorCnt = false;
    this.showErrorPan = false;
    this.showErrorpin = false;
  }
  savecontractoreditdata(fredit:any){
    let obj={
      "command" : "edt",
      "address_1": this.addressedit,
      "contact_number": this.contactnumberedit,
      "gst_number": this.gstnumberedit,
      "pan_number": this.pannumberedit,
      "pin_code": this.pincodeedit,
      "city": this.cityedit,
      "state": this.stateedit,
      "country": this.countryedit,
      'id':this.contractId,
      'code':this.contractCode
    }
    this.custservice.updatecontractormasterdata(obj).subscribe((res:any)=>{
      if(res&&res['status_code']=='200'){
        this.alertcall.showSuccess('Accepted', 'Updated Successfully');
        this.dialog.closeAll() ;
        fredit.reset()
        this.getContractdata()
      }else{
        this.alertcall.showWarning('Accepted', res['message']);
      }
    })
  }
  deletedata(rw:any,data:any){
this.dialog.open(data,{
  width:'400px'
})
this.deleteId=rw.id
  }
  deleteFile(){
    let obj={
      "command" : "del", 
      "id" : this.deleteId
    }
    this.custservice.deletecontractormasterdata(obj).subscribe((res:any)=>{
      if(res&&res['status_code']=='200'){
        this.alertcall.showSuccess('Accepted', 'Deleted Successfully');
        this.dialog.closeAll() ;
        this.getContractdata()
      }else{
        this.alertcall.showWarning('Accepted', res['message']);
      }
    })
  }
 
  keyPressAlphanumeric(event:any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  showError: boolean = false;
  showErrorPan:boolean = false;
  showErrorCnt:boolean = false;
  showErrorpin:boolean = false;
  onGstNumberChange(value: string) {
    this.gstnumber = value.toUpperCase();
    this.gstnumberedit = value.toUpperCase();
    this.showError = false;
}
  checkGstNumber() {
    this.showError = this.gstnumber.length !== 15 || this.gstnumberedit.length !== 15;
}
onPanNumberChange(value:string){
  this.pannumber = value.toUpperCase();
    this.pannumberedit = value.toUpperCase();
    this.showErrorPan = false;
}
checkPanNumber(){
  this.showErrorPan = this.pannumber.length !== 10 || this.pannumberedit.length !== 10;
}
onContactNumber(value:number){
  this.contactnumber = value;
  this.contactnumberedit = value;
  this.showErrorCnt = false;
}
checkContactNumber(){
  this.showErrorCnt = this.contactnumber.length !== 10 || this.contactnumberedit.length !== 10
}
onPinCode(value:number){
  this.pincode = value;
  this.pincodeedit = value;
  this.showErrorpin = false;
}
checkPinCode(){
  this.showErrorpin = this.pincode.length !== 6 || this.pincodeedit.length !== 6
}
}
